import clsx from 'clsx'

import PreviewImage from '../../../../assets/img/placeholder_engine.png'

import './style.scss'

interface TalentIDSkeletonProps {
    className?: string
}

const TalentIDSkeleton: React.FC<TalentIDSkeletonProps> = ({ className }) => {
    return (
        <div
            className={clsx('talent-id-skeleton', className)}
        >
            <div
                className='talent-id-skeleton__img-wrapper'
            >
                <img
                    alt='Talent ID Preview'
                    src={PreviewImage}
                />
            </div>
        </div>
    )
}

export default TalentIDSkeleton
