import { IonAlert } from '@ionic/react'
import { bookmark } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import type { Talent , User } from '../../../../lib/apollo/types'
import {
    useNetworkUsersLazyQuery, useTransferTalentRequestMutation,
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from '../../../ContextMenu/context'
import InfiniteScrollWithHook from '../../../InfiniteScrollWithHook'
import ItemList from '../../../ItemList'
import Modal from '../../../Modal'
import UserListItem from '../../../User/UserListItem'

import './style.scss'

interface TalentTransferContextMenuItemProps {
    talent: Talent
    networkId: string
}

const TalentTransferContextMenuItem: React.FC<TalentTransferContextMenuItemProps> = ({ talent, networkId }) => {
    const [targetUser, setTargetUser] = useState<User | null>(null)
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()

    const [transferTalentRequest] = useTransferTalentRequestMutation({
        onError: (e: any) => {
            if (e.graphQLErrors?.[0]?.extensions.status === 409) {
                present(t('talent.transfer.pendingRequest'), 'warning')
            } else {
                present(t('talent.transfer.requestFailed'),'danger')
            }
        },
    })

    const [showModal, setShowModal] = useState(false)
    const closeContextMenu = useContextSelector(ContextMenuContext, ctx => ctx.closeContextMenu)

    const handler = useCallback(async ({ message }: any) => {
        if (!targetUser) return

        try {
            const { data } = await transferTalentRequest({
                variables: {
                    input: {
                        targetUserId: +targetUser.id,
                        message,
                        objectId: +talent.id,
                        networkId: +networkId,
                    },
                },
            })

            if (data) {
                present(t('talent.transfer.requestSent'), 'success')
            }
        } catch (e) {
            //
        }

        setTargetUser(() => null)
        setShowModal(() => false)
        closeContextMenu()

    }, [targetUser, closeContextMenu, transferTalentRequest, talent.id, networkId, present, t])

    return (
        <>
            <ContextMenuItem
                disableCloseContextMenuOnClick
                icon={bookmark}
                onClick={() => { setShowModal(() => true) }}
                label={t('talent.transfer.buttonLabel')}
            />
            <Modal
                onClose={() => {
                    setShowModal(() => false)
                }}
                isOpen={showModal}
                modalTitle={t('talent.transfer.title')}
            >
                <ItemList>
                    <InfiniteScrollWithHook
                        placeholder={(
                            <>
                                <UserListItem />
                                <UserListItem />
                                <UserListItem />
                                <UserListItem />
                            </>
                        )}
                        variables={{
                            networkId,
                        }}
                        lazyHook={useNetworkUsersLazyQuery}
                    >
                        {(users: User[]) => users
                            .filter(u => u.id !== me?.user.id)
                            .map((u) => (
                                <UserListItem
                                    disableLink
                                    key={u.id}
                                    user={u}
                                    showNetworkRole
                                    onClick={() => {
                                        setTargetUser(() => u)
                                    }}
                                />
                            ),
                            )}
                    </InfiniteScrollWithHook>
                </ItemList>
            </Modal>
            <IonAlert
                cssClass='talent-transfer__message-alert'
                isOpen={!!targetUser}
                inputs={[
                    {
                        value: t('talent.transfer.message'),
                        name: 'message',
                        type: 'textarea',
                        attributes: {
                            rows: '6',
                        },
                    },
                ]}
                buttons={[
                    {
                        text: t('buttons.cancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setTargetUser(() => null)
                        },
                    },
                    {
                        text: t('buttons.ok'),
                        handler,
                    },
                ]}
            />
        </>
    )
}

export default TalentTransferContextMenuItem

