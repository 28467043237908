import { IonText } from '@ionic/react'
import {ellipsisVertical, shirt} from 'ionicons/icons'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../components/Content'
import ContextMenu from '../../components/ContextMenu'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import NetworkApplyContextMenuItem from '../../components/Network/NetworkApplyContextMenuItem'
import NetworkListItem from '../../components/Network/NetworkListItem'
import Page from '../../components/Page'
import UserCardMedium from '../../components/User/UserCard/UserCardMedium'
import {User, useTalentQuery} from '../../lib/apollo/types'
import { useJmtNetworksQuery, useUserQuery, useNetworksOfUserQuery } from '../../lib/apollo/types'

import './style.scss'
import TalentCard from '../../components/NewTalentCard'
import TalentActionButtons from '../../components/TalentActionButtons'
import TopSkills from '../../components/Talent/TopSkills'
import Buttons from '../../components/Buttons'
import Button from '../../components/Button'

interface UserParams {
    userId: string
    networkId?: string
}

const UserPage: React.FC = () => {
    const {
        params: {
            userId,
            networkId,
        },
    } = useRouteMatch<UserParams>()
    const { t } = useTranslation()
    const { data: jmtNetworks } = useJmtNetworksQuery()
    const jmtNetworkId = useMemo(() => jmtNetworks?.jmtNetworks.JMTnetworks.collection.find(jn => jn.caption === 'Join.My.Talent.Engine')?.id, [jmtNetworks])

    const { data } = useUserQuery({
        variables:
            {
                userId,
                networkId: jmtNetworkId ?? '0'
            },
        skip: !jmtNetworkId
    })

    const title = data?.user
        ? `${data.user.firstName} ${data.user.lastName}`
        : ''

    const { data: userNetworks } = useNetworksOfUserQuery({
        variables: {
            userId: data?.user?.id ?? '',
        },
        skip: !data?.user?.id,
    })

    const { data: userPersonification } = useTalentQuery({
        variables: {
            userId: data?.user?.id ?? '',
            talentId: data?.user?.personificationId ?? '',
        },
        skip: !data?.user?.personificationId,
    })

    return (
        <Page
            trackingTitle='user'
            className='user-page'
        >
            <Header
                title={title}
            />
            <Content
                fullscreen
            >
                <Helmet>
                    <title>{`Join My Talent - ${title}`}</title>
                    <meta
                        property='og:title'
                        content={`Join My Talent - ${title}`}
                    />
                    <meta
                        property='og:description'
                        content={t('og.user.description', { name: title })}
                    />
                    <meta
                        property='og:image'
                        content={data?.user?.image ?? 'https://app.joinmytalent.com/assets/icon/favicon.png'}
                    />
                </Helmet>
                <UserCardMedium
                    user={data?.user as User ?? null}
                />
                {(!userPersonification?.talent?.id && data?.user?.personificationId) && (
                    <>
                        <Divider/>
                        <ItemList>
                            <div className="display-flex ion-justify-content-center">
                                <TalentCard
                                    type='small'
                                    networkId={networkId}
                                />
                            </div>
                            <Buttons
                                title={t('user.talentNotVisible.inviteToNetwork.title')}
                                subtitle={t('user.talentNotVisible.inviteToNetwork.description')}
                            >
                                <Button
                                    color='secondary'
                                    icon={shirt}
                                    onClick={() => {
                                      alert(t('talent.contact.comingSoon'))
                                    }}
                                >
                                    {t('networks.inviteTalents')}
                                </Button>
                            </Buttons>
                        </ItemList>
                    </>
                )}
                {userPersonification?.talent?.id && (
                    <>
                        <Divider/>
                        <div>
                            <h2
                                className='ion-margin-bottom'
                            >
                            {t('user.personification.headline', { firstName: data?.user?.firstName })}
                            </h2>
                            <IonText
                                className='ion-text-left ion-margin-bottom n2br'
                                color='medium'
                            >
                                {t('user.personification.description', { firstName: data?.user?.firstName })}
                            </IonText>
                        </div>
                        <ItemList>
                            <div className="display-flex ion-justify-content-center">
                                <TalentCard
                                    talent={userPersonification.talent}
                                    networkId={networkId}
                                />
                            </div>
                            <div className="ion-margin-top ion-padding-top">
                                {(userPersonification.talent.rateCount ?? 0) > 0 && (
                                    <TopSkills
                                        asCard
                                        talent={userPersonification.talent}
                                        withHeadline
                                    />
                                )}
                                <TalentActionButtons
                                    mode={'default'}
                                    talent={userPersonification.talent}
                                    networkId={networkId}
                                />
                            </div>
                        </ItemList>
                    </>
                )}
                {(userNetworks?.networks?.collection?.length ?? 0) > 0 && (
                    <>
                        <Divider />
                        <div>
                            <h2
                                className='ion-margin-bottom'
                            >
                                {t('user.networks.headline', { firstName: data?.user?.firstName })}
                            </h2>
                            <IonText
                                className='ion-text-left ion-margin-bottom n2br'
                                color='medium'
                            >
                                {t('user.networks.description', { firstName: data?.user?.firstName })}
                            </IonText>
                        </div>
                        <ItemList>
                            {userNetworks?.networks?.collection.map(network => (
                                <NetworkListItem
                                    key={network.id}
                                    onClick={e => {
                                        e.preventDefault()
                                    }}
                                    network={network}
                                >
                                    <ContextMenu
                                        size='small'
                                        fill='clear'
                                        icon={ellipsisVertical}
                                    >
                                        <NetworkApplyContextMenuItem
                                            network={network}
                                        />
                                    </ContextMenu>
                                </NetworkListItem>
                            ))}
                        </ItemList>
                    </>
                )}
            </Content>
        </Page>
    )
}

export default UserPage
