import { useTranslation } from 'react-i18next'

import Content from '../../components/Content'
import Header from '../../components/Header'
import PlaylistHelp from '../../components/HelpSlider/Help/Playlist'
import InfiniteScrollWithHook from '../../components/InfiniteScrollWithHook'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import PlaylistListItem from '../../components/Playlist/PlaylistListItem'
import type { Playlist } from '../../lib/apollo/types'
import { usePlaylistsLazyQuery } from '../../lib/apollo/types'

import PlaylistsContextMenu from './PlaylistsContextMenu'

const Playlists: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Page
            trackingTitle='playlists'
        >
            <Header
                buttonLeft='menu'
                title={t('title.playlists')}
            >
                <PlaylistsContextMenu />
            </Header>
            <Content
                fullscreen
            >
                <PlaylistHelp />
                <InfiniteScrollWithHook
                    lazyHook={usePlaylistsLazyQuery}
                >
                    {(playlists: Playlist[]) => (
                        <ItemList>
                            {playlists.map(playlist => (
                                <PlaylistListItem
                                    key={playlist.id}
                                    playlist={playlist}
                                />
                            ))}
                        </ItemList>
                    )}
                </InfiniteScrollWithHook>

            </Content>
        </Page>
    )
}

export default Playlists
