import { IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { person } from 'ionicons/icons'

import type { Network } from '../../../lib/apollo/types'
import stopPropagation from '../../../lib/utils/stopPropagation'
import CustomAvatar from '../../CustomAvatar'

import NetworkListItemSkeleton from './NetworkListItemSkeleton'

import './style.scss'
import {PropsWithChildren} from 'react'

interface NetworkListItemProps {
    network?: Network | null
    hideContextMenu?: boolean
    onClick?: (e: any) => void
}

const NetworkListItem: React.FC<NetworkListItemProps & PropsWithChildren> = ({ onClick, hideContextMenu, network, children }) => {
    if (!network) return <NetworkListItemSkeleton />

    return (
        <IonCard
            onClick={onClick}
            routerLink={onClick ? undefined : `/home/network/${network.id}/detail`}
            className='network-list-item'
        >
            <CustomAvatar
                width={106.66}
                img={network.image}
                type='network'
            />
            <IonGrid
                className='network-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='network-list-item__name'
                        >
                            { network.caption }
                        </IonCardTitle>
                        {network.owner && (
                            <IonCardSubtitle
                                className='network-list-item__owner'
                            >
                                <IonIcon
                                    icon={person}
                                />
                                {network.owner}
                            </IonCardSubtitle>
                        )}
                    </IonCol>

                    { !hideContextMenu && (
                        <IonCol
                            className='network-list-item__menu'
                            onClick={stopPropagation}
                        >
                            { children }
                        </IonCol>
                    )}
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default NetworkListItem
