import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'
import * as IoniCons from 'ionicons/icons'
import OneSignal from 'onesignal-cordova-plugin'
import { useEffect, useMemo } from 'react'

import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import type { ActivityFragment, TalentTransferRequest } from '../apollo/types'
import {
    useNewActivitiesQuery,
    useRelationshipsLazyQuery,
    UserType,
    useTransferTalentRequestsQuery,
    useUserMetaDataQuery,
} from '../apollo/types'

type GlobalHooksReturnType = {
    newActivities: ActivityFragment[] | undefined
    newForeignActivities: ActivityFragment[] | undefined
    pendingTalentTransferRequests: TalentTransferRequest[] | undefined
}

const useGlobalHooks = (): GlobalHooksReturnType => {
    const user = useUser()
    const [present] = useToast()
    const { push } = useIonRouter()
    const { data: userMetaData } = useUserMetaDataQuery({ skip: !user, variables: { userId: user?.user.id ?? '' } })
    const isoDate = useMemo(() => {
        const aLV = userMetaData?.userMetaData?.user?.data?.activitiesLastViewed
        if (aLV) {
            return aLV
        }
        const createdTimeStamp = userMetaData?.userMetaData?.user?._created
        if (createdTimeStamp) {
            return new Date(createdTimeStamp).toISOString()
        }
        return ''
    }, [userMetaData])

    const { data: { newActivities } = {} } = useNewActivitiesQuery({
        pollInterval: 30000,
        skip: !isoDate,
        variables: {
            isoDate,
        },
    })

    // const newForeignActivities = useMemo(() => (newActivities ?? []).filter(a => a.targetUserId !== user?.user.id), [newActivities])
    const newForeignActivities = useMemo(() => newActivities ?? [], [newActivities])

    // REFETCH FFF
    const [fetchFFF] = useRelationshipsLazyQuery({ fetchPolicy: 'network-only' })
    useEffect(() => {
        if (user && newForeignActivities?.filter(a => ['FOLLOW', 'UNDFOLLOW'].includes(a.type)).length > 0) {
            fetchFFF()
        }
    }, [fetchFFF, newForeignActivities, user])

    useEffect(() => {
        if(!Capacitor.isNativePlatform()) return

        if (userMetaData?.userMetaData?.user?.data?.userType) {
            OneSignal.initialize('f546689b-3647-4017-9742-059cde042a02')
            OneSignal.Notifications.addEventListener("click", (jsonData) => {
                push((jsonData.notification.additionalData as any).target)
            })

            OneSignal.Notifications.addEventListener("foregroundWillDisplay", (event) => {
                present(event.getNotification().body, 'secondary', {
                    position: 'bottom',
                    header: event.getNotification().title,
                    duration: 0,
                    icon: IoniCons.informationCircleOutline,
                    buttons: (event.getNotification().additionalData as any).target ? [
                        {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            icon: IoniCons[(event.getNotification().additionalData as any).icon ?? 'arrowRedoOutline'],
                            handler: () => {
                                push((event.getNotification().additionalData as any).target)
                            },
                        },
                    ] : undefined,
                })
            })

            OneSignal.User.pushSubscription.addEventListener("change", event => {
                if (event.current.optedIn) {
                    if(user?.user?.id) {
                        OneSignal.login(user.user.id.toString())
                        OneSignal.setConsentGiven(true)
                    }
                    if(userMetaData?.userMetaData?.user?.data?.userType) {
                        OneSignal.User.addTags({
                            'trainer': userMetaData.userMetaData.user.data.userType.includes(UserType.Trainer) ? '1' : '0',
                            'player': userMetaData.userMetaData.user.data.userType.includes(UserType.Player) ? '1' : '0',
                            'scout': userMetaData.userMetaData.user.data.userType.includes(UserType.Scout) ? '1' : '0',
                            'relatives': userMetaData.userMetaData.user.data.userType.includes(UserType.Relatives) ? '1' : '0',
                        })
                    }
                }
            })


            if(OneSignal.Notifications.hasPermission() && user?.user?.id && userMetaData?.userMetaData?.user?.data?.userType) {
                OneSignal.login(user.user.id.toString())
                OneSignal.User.addTag('trainer', userMetaData.userMetaData.user.data.userType.includes(UserType.Trainer) ? '1' : '0')
                OneSignal.User.addTag('player', userMetaData.userMetaData.user.data.userType.includes(UserType.Player) ? '1' : '0')
                OneSignal.User.addTag('scout', userMetaData.userMetaData.user.data.userType.includes(UserType.Scout) ? '1' : '0')
                OneSignal.User.addTag('relatives', userMetaData.userMetaData.user.data.userType.includes(UserType.Relatives) ? '1' : '0')
            }
        }
    }, [present, push, user?.user.id, userMetaData])

    const { data } = useTransferTalentRequestsQuery({
        pollInterval: 30000,
        skip: !user,
    })

    const pendingTalentTransferRequests = useMemo(() => {
        return data?.transferTalentRequests?.collection ?? []
    }, [data?.transferTalentRequests])

    return {
        newActivities,
        newForeignActivities,
        pendingTalentTransferRequests,
    }
}

export default useGlobalHooks
