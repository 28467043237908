import { IonCol, IonRow, IonText } from '@ionic/react'

import './style.scss'

interface CompareMetaRowProps {
    value1: string | number
    value2: string | number
    label: string
}

const CompareMetaRow: React.FC<CompareMetaRowProps> = ({
    value1,
    value2,
    label,
}) => (
    <IonRow
        className='ion-justify-content-between'
    >
        <IonCol
            className='ion-align-items-center ion-justify-content-center'
        >
            <IonText
                color='secondary'
            >
                <h3>
                    {value1}
                </h3>
            </IonText>
        </IonCol>

        <IonCol
            className='ion-align-items-center ion-justify-content-center'
        >
            <span
                className='font-l-regular'
            >
                {label}
            </span>
        </IonCol>

        <IonCol
            className='ion-align-items-center ion-justify-content-center'
        >
            <IonText
                color='tertiary'
            >
                <h3>
                    {value2}
                </h3>
            </IonText>
        </IonCol>
    </IonRow>
)

export default CompareMetaRow
