import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../components/Content'
import Header from '../../../components/Header'
import ItemList from '../../../components/ItemList'
import TalentCard from '../../../components/NewTalentCard'
import Page from '../../../components/Page'
import RoleModel from '../../../components/RoleModel'
import TalentActionButtons from '../../../components/TalentActionButtons'
import { FE_URL } from '../../../lib/apollo/config'
import { useSharedTalentQuery } from '../../../lib/apollo/types'
import TalentTimeline from '../../../components/Talent/TalentTimeline'
import React from 'react'

const SharedTalent: React.FC = () => {
    const { params: { hash } } = useRouteMatch<{ hash: string }>()
    const { t } = useTranslation()

    const { data } = useSharedTalentQuery({
        variables: {
            hash,
        },
        skip: !hash,
    })

    if(!data?.sharedTalent.talent.caption) {
        return null
    }

    return (
        <Page
            trackingTitle={data?.sharedTalent ? `shared-talent-${data?.sharedTalent.talent.id}` : undefined}
        >
            <Header
                buttonLeft='back'
                title={data?.sharedTalent.talent.caption ?? 'Kekse'}
            />
            <Content
                fullscreen
                className='shared-talent'
            >
                {data?.sharedTalent.talent.id && (
                    <>
                        <Helmet>
                            <title>{`Join My Talent - ${data?.sharedTalent.talent.caption}`}</title>
                            <meta
                                property='og:title'
                                content={`Join My Talent - ${data?.sharedTalent.talent.caption}`}
                            />
                            <meta
                                property='og:image'
                                content={`${FE_URL}/shared/${hash}.png`}
                            />
                            <meta
                                property='og:description'
                                content={t(`og.${data?.sharedTalent.sharedRoleModel ? 'talentWithRoleModel' : 'talent'}.description`, {
                                    name: data?.sharedTalent.talent.caption,
                                    roleModel: data?.sharedTalent.roleModel?.caption,
                                })}
                            />
                        </Helmet>
                        <ItemList>
                            <div
                                id='shared-talent-card'
                                className='display-flex ion-justify-content-center'
                            >
                                <TalentCard
                                    talent={data?.sharedTalent.talent}
                                    shared
                                />
                            </div>
                            <TalentActionButtons
                                mode='details'
                                talent={data?.sharedTalent.talent}
                                shared
                            />
                        </ItemList>

                        <RoleModel
                            talent={data?.sharedTalent.talent}
                            roleModel={data?.sharedTalent.roleModel ?? undefined}
                            shared
                        />
                        <TalentTimeline talent={data?.sharedTalent.talent} history={data?.sharedTalent.talentHistory} talentIds={data?.sharedTalent.talentIds} certificates={data?.sharedTalent.certificates} shared />
                    </>
                )}
            </Content>
        </Page>
    )
}

export default SharedTalent
