import { IonLoading } from '@ionic/react'
import { useEffect } from 'react'

import Content from '../../components/Content'
import Page from '../../components/Page'
import { signOut } from '../../providers/Auth/hooks'

const LogoutPage: React.FC = () => {

    useEffect(() => {
        signOut()
    }, [])

    return (
        <Page>
            <Content>
                <IonLoading
                    isOpen
                />
            </Content>
        </Page>
    )
}

export default LogoutPage
