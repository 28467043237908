import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import type { HelpSliderModalProps } from '../TalentId'

const LayersHelp: React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()

    return (
        <OnboardingModal
            open={open}
            setOpen={setOpen}
            id='layers-page'
        >
            <SwiperSlide>
                <h1>
                    {t('talent.layers.selectLayers')}
                </h1>
                <div>
                    <p>{t('layers.onboarding.2')}</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div>
                    <p>{t('layers.onboarding.3')}</p>
                </div>
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default LayersHelp
