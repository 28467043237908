import { useTranslation } from 'react-i18next'

import { PermissionLevel } from '../../../../lib/apollo/types'
import TalentVoteButton from '../../../Talent/TalentVoteButton'
import { useTalentCardContext } from '../../_Core/Context'
import { PREVIEW_THRESHOLD } from '../../_Core/useIsPreviewMode'

import './style.scss'

const TalentCardPreviewOverlay: React.FC = () => {
    const { t } = useTranslation()
    const { permissionLevel, talent, networkId } = useTalentCardContext()

    if (!talent) return null

    return (
        <div
            className='talentcard-medium-preview-overlay'
            key={`preview-overlay-medium-${talent.id}`}
        >
            {(permissionLevel === PermissionLevel.Personal || permissionLevel === PermissionLevel.Administrative) ? (
                <>
                    <span
                        className='ion-text-left n2br font-l-medium'
                    >
                        {+(talent.rateCount ?? '0') === 0 ?
                            t('talent.missingOwnVoteHint', {
                                votes: PREVIEW_THRESHOLD - +(talent.rateCount ?? '0'),
                            })
                            :
                            t('talent.missingVotesHint', {
                                votes: PREVIEW_THRESHOLD - +(talent.rateCount ?? '0'),
                            })}

                    </span>
                </>
            ) : (
                <>
                    <h3
                        className='ion-text-center'
                    >
                        {t('talent.missingVoteForHint', {
                            name: talent.caption,
                            votes: PREVIEW_THRESHOLD - +(talent.rateCount ?? '0'),
                        })}
                    </h3>
                    <TalentVoteButton
                        talent={talent}
                        networkId={networkId}
                    />
                </>
            )}
        </div>
    )
}

export default TalentCardPreviewOverlay
