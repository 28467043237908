import { IonText } from '@ionic/react'
import './style.scss'
import {PropsWithChildren} from 'react'

interface ButtonsProps {
    title?: string
    subtitle?: string
}

const Buttons: React.FC<ButtonsProps & PropsWithChildren> = ({ title, subtitle, children }) => (
    <div
        className='buttons'
    >
        <div
            className='buttons__wrapper'
        >
            { title && (
                <h2
                    className='ion-margin-bottom buttons__title'
                >
                    { title }
                </h2>
            )}
            { subtitle && (
                <IonText
                    className='ion-text-justify buttons__subtitle font-s-regular'
                    color='medium'
                >
                    <span>
                        { subtitle }
                    </span>
                </IonText>
            )}
            { children }
        </div>
    </div>
)

export default Buttons
