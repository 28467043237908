import { barChart, personOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import talentid from '../../assets/img/help_talent-id.svg'
import network from '../../assets/img/network-help.png'
import plusLayer from '../../assets/img/plus_layer.svg'
import Slider from '../Slider'

import LayersHelp from './Help/Layers'
import MySpaceHelp from './Help/MySpace'
import NetworkHelp from './Help/Network'
import PlaylistHelp from './Help/Playlist'
import TalentIdHelp from './Help/TalentId'
import VoteFormHelp from './Help/VoteForm'
import HelpCard from './card'

import './style.scss'

const HelpSlider: React.FC = () => {
    const { t } = useTranslation()

    const [talentHelpOpen, setTalentHelpOpen] = useState<boolean>(false)
    const [layerHelpOpen, setLayerHelpOpen] = useState<boolean>(false)
    const [voteFormHelpOpen, setVoteFormHelpOpen] = useState<boolean>(false)
    const [networkHelpOpen, setNetworkHelpOpen] = useState<boolean>(false)
    const [mySpaceHelpOpen, setMySpaceHelpOpen] = useState<boolean>(false)
    const [watchListHelpOpen, setWatchListHelpOpen] = useState<boolean>(false)

    return (
        <Slider
            freeMode
            title={t('home.helpSliderTitle')}
            itemWidth={200}
        >
            <SwiperSlide>
                <HelpCard
                    onClick={() => setTalentHelpOpen(true)}
                    title={t('talentId.onboarding.title')}
                    img={talentid}
                >
                    <TalentIdHelp
                        open={talentHelpOpen}
                        setOpen={setTalentHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
            <SwiperSlide>
                <HelpCard
                    onClick={() => setVoteFormHelpOpen(true)}
                    title={t('vote.onboarding.title')}
                    icon={barChart}
                >
                    <VoteFormHelp
                        open={voteFormHelpOpen}
                        setOpen={setVoteFormHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
            <SwiperSlide>
                <HelpCard
                    onClick={() => setLayerHelpOpen(true)}
                    title={t('layers.onboarding.title')}
                    iconSrc={plusLayer}
                >
                    <LayersHelp
                        open={layerHelpOpen}
                        setOpen={setLayerHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
            <SwiperSlide>
                <HelpCard
                    title={t('Network')}
                    img={network}
                    onClick={() => setNetworkHelpOpen(true)}
                >
                    <NetworkHelp
                        open={networkHelpOpen}
                        setOpen={setNetworkHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
            <SwiperSlide>
                <HelpCard
                    title={t('myspace.onboarding.title')}
                    icon={personOutline}
                    onClick={() => setMySpaceHelpOpen(true)}
                >
                    <MySpaceHelp
                        open={mySpaceHelpOpen}
                        setOpen={setMySpaceHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
            <SwiperSlide>
                <HelpCard
                    title={t('Watchlists')}
                    onClick={() => setWatchListHelpOpen(true)}
                >
                    <PlaylistHelp
                        open={watchListHelpOpen}
                        setOpen={setWatchListHelpOpen}
                    />
                </HelpCard>
            </SwiperSlide>
        </Slider>
    )
}

export default HelpSlider
