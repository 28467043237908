import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { cloudUploadOutline, trashBinOutline } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { FileUploadResponse } from '../../../lib/apollo/types'
import Button from '../../Button'
import CollapsibleContent from '../../Collapsible/CollapsibleContent'

import CropModal from './CropModal'

import './style.scss'

interface ImageInputProps {
    name: string
    label: string
    size: { width: number, height: number }
    required?: boolean
    avatarWrapperClassName?: string
    onPreviewChange?: (src?: string) => any
    showPreview?: boolean
}

const FormImageInput: React.FC<ImageInputProps> = ({
    label,
    name,
    required,
    size,
    onPreviewChange,
    avatarWrapperClassName,
    showPreview = true }) => {
    const { control, formState, setValue, setError, clearErrors } = useFormContext()
    const error = formState.errors[name]
    const { t } = useTranslation()
    const [src, setSrc] = useState<string>()
    const [preview, setPreview] = useState<string>()

    const rules = useMemo(() =>
        required ? { required: { value: true, message: t('errors.imageRequired', { label }) } } : {}
    , [required, label, t])

    const onChange = useCallback(async (ev: any) => {
        const file = ev.target.files[0]
        if (file) {
            const img = new Image()
            const objectUrl = URL.createObjectURL(file)
            img.onload = () => {
                if(img.width < size.width || img.height < size.height) {
                    setValue(name,null)
                    setError(name, { type: 'validate', message: t('errors.imageSize', size) })
                } else {
                    clearErrors(name)
                    setSrc(objectUrl)
                }
            }
            img.src = objectUrl
        }
    }, [size, setValue, name, setError, t, clearErrors])

    const onSave = useCallback((uploadResponse: FileUploadResponse) => {
        setPreview(uploadResponse.url)
        onPreviewChange?.(uploadResponse.url)
        setValue(name, uploadResponse.id)
        setSrc(undefined)

    }, [onPreviewChange, setValue, name])

    const onDeleteButtonClick = useCallback(() => {
        setPreview(undefined)
        onPreviewChange?.(undefined)
        setValue(name, undefined)
    }, [onPreviewChange, setValue, name])

    return (
        <div>
            <CropModal
                isOpen={!!src}
                imageUrl={src ?? ''}
                size={size}
                onClose={() => setSrc(undefined)}
                onSave={onSave}
                avatarWrapperClassName={avatarWrapperClassName}
            />
            <div
                className='form-upload'
            >
                <IonItem
                    lines='none'
                    className='form-upload__item'
                >
                    <Controller
                        control={control}
                        name={name}
                        rules={rules}
                        render={({ field }) => {
                            if (field.value) {
                                return (
                                    <>
                                        {showPreview && (
                                            <IonAvatar>
                                                <img
                                                    alt='preview'
                                                    src={preview}
                                                    className='form-upload__item__preview-image'
                                                    style={{ width: size.width, height: size.height }}
                                                />
                                            </IonAvatar>
                                        )}
                                        <IonLabel
                                            color='danger'
                                        >
                                            <Button
                                                icon={trashBinOutline}
                                                fill='clear'
                                                color='danger'
                                                onClick={onDeleteButtonClick}
                                            >
                                                {t('buttons.delete')}
                                            </Button>
                                        </IonLabel>
                                    </>
                                )
                            }

                            return (
                                <IonButton
                                    size='default'
                                >
                                    <label
                                        className='form-upload__item__label'
                                        htmlFor={name}
                                    >
                                        <input
                                            id={name}
                                            name={name}
                                            type='file'
                                            onChange={onChange}
                                            accept='image/*'
                                        />
                                        <IonIcon
                                            icon={cloudUploadOutline}
                                        />
                                        {label + (required ? '*' : '')}
                                    </label>
                                </IonButton>
                            )
                        }}
                    />
                </IonItem>
            </div>
            <CollapsibleContent
                className='form--upload__error-container'
                open={!!error}
            >
                <IonText
                    className='form-upload__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message as string}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default FormImageInput
