import { ApolloError, useApolloClient } from '@apollo/client'
import type { MutationHookOptions } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { useIonActionSheet } from '@ionic/react'
import OneSignal from 'onesignal-cordova-plugin'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useSigninMutation,
    useSigninWithTokenMutation,
    useWhoamiQuery,
    WhoamiDocument,
    useSigninWithHsvidCodeMutation,
} from '../../lib/apollo/types'
import type { Authentication , SigninMutation, SigninMutationVariables , SigninWithHsvidCodeMutation, SigninWithHsvidCodeMutationVariables } from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'

export const useSignIn = (baseOptions?: MutationHookOptions<SigninMutation, SigninMutationVariables>): (name: string, password: string, permanent: boolean) => Promise<void> => {
    const client = useApolloClient()
    const [signin] = useSigninMutation(baseOptions)

    return useCallback(async (name: string, password: string, permanent: boolean): Promise<void> => {
        const response = await signin({ variables: { input: { name, password, permanent } } })

        if (response.data?.signin && !response.errors) {
            await Preferences.set({
                key: STORAGE_KEYS.API.LOGIN_TOKEN,
                value: response.data?.signin.token,
            })
            await Preferences.set({
                key: STORAGE_KEYS.API.API_TOKEN,
                value: response.data?.signin.apiToken,
            })

            client.writeQuery({
                query: WhoamiDocument,
                data: { whoami: response.data?.signin },
            })
        } else if(baseOptions?.onError) {
            baseOptions.onError(new ApolloError({}))
        }
    },[baseOptions, client, signin])
}

export const useSignInWithCode = (baseOptions?: MutationHookOptions<SigninWithHsvidCodeMutation, SigninWithHsvidCodeMutationVariables>): (code: string) => Promise<void> => {
    const client = useApolloClient()
    const [signin] = useSigninWithHsvidCodeMutation(baseOptions)
    const [present] = useIonActionSheet()
    const { t } = useTranslation()

    return useCallback(async (code: string): Promise<void> => {
        const response = await signin({ variables: { input: { code } } })

        if (response.data?.signinWithHSVIDCode && !response.errors) {
            const setSignInData = async (user: Authentication) => {
                await Preferences.set({
                    key: STORAGE_KEYS.API.LOGIN_TOKEN,
                    value: user.token,
                })
                await Preferences.set({
                    key: STORAGE_KEYS.API.API_TOKEN,
                    value: user.apiToken,
                })

                client.writeQuery({
                    query: WhoamiDocument,
                    data: { whoami: user },
                })
            }

            if(response.data.signinWithHSVIDCode.length === 1) {
                await setSignInData(response.data.signinWithHSVIDCode[0])
            } else {
                const buttons = response.data.signinWithHSVIDCode.map((user) => ({
                    text: `${user.user.firstName} ${user.user.lastName}`,
                    handler: async () => {
                        await setSignInData(user)
                    },
                }))
                await present({
                    header: t('login.whichAccount') as string,
                    buttons,
                })
            }

        } else if(baseOptions?.onError) {
            baseOptions.onError(new ApolloError({}))
        }
    },[baseOptions, client, present, signin, t])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSignInWithToken = () =>
    useSigninWithTokenMutation({
        update: (cache, response) => {
            if (!response.data) return

            cache.writeQuery({
                query: WhoamiDocument,
                data: { whoami: response.data?.signinWithToken },
            })
        },
    })

export const signOut: () => Promise<void> = async () => {
    await Preferences.remove({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
    await Preferences.remove({ key: STORAGE_KEYS.API.API_TOKEN })
    if(Capacitor.isNativePlatform()) {
        OneSignal.logout()
    }
    window.location.href = '/login'
}

export const useUser = (): Authentication | undefined => {
    const { data } = useWhoamiQuery({ fetchPolicy: 'cache-only' })
    return data?.whoami
}
