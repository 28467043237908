import { useMemo } from 'react'

import type { Talent } from '../../../lib/apollo/types'
import { useRoleModelIdsQuery } from '../../../lib/apollo/types'
import type { TalentType } from '../TalentCardTypes'

const useTalentType = (talent?: Talent): TalentType | undefined => {
    const { data } = useRoleModelIdsQuery()

    return useMemo(() => {
        if (!talent || !data) return 'loading'
        if (data?.roleModelIds.some(id => id === +talent.id)) return 'roleModel'
        if (talent.personification) return 'talent'
        return 'scout'
    }, [data, talent])
}

export default useTalentType
