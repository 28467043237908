const BorussiaIcon: React.FC = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 119 190'
    >
        <path
            fill='#FFF'
            d='M59.4 0L0 95.1 59.5 190 119 95.1z'
        />
        <path
            d='M59.4 6L3.8 95.1 59.5 184l55.7-88.9z'
        />
        <path
            fill='#FFF'
            d='M59.4 20.4L12.8 95.1l46.7 74.5 46.7-74.5z'
        />
        <path
            d='M59.5 27L16.9 95.1 59.5 163l42.6-67.9z'
        />
        <path
            fill='#FFF'
            d='M59.5 32.3L20.2 95.1l39.3 62.6 39.3-62.6z'
        />
        <path
            d='M59.4 30.9L47.6 48.6h24zm21.3 95.8h-42L23 101.8h72.7zm-41.6-64h41l15 24.9H23.4zm20.3 97.5l-12.2-19.6h25.2z'
        />
        <path
            d='M39.8 126.4h29.5c8.5 0 15.9-8.1 15.9-19.2 0-6.3-2.8-10.1-4.3-12.1 1.7-1.8 4.2-4.1 4.2-13 0-6.4-5.4-18.8-18-18.8H39.8v63.1z'
        />
        <path
            fill='#FFF'
            d='M42 124.3h24.5c7.9 0 16.3-5.1 16.3-16.9 0-5.9-2.7-9.4-4.7-12.2C79.7 93.6 83 91 83 82.6c0-5.9-3.4-17.3-17.8-17.3H42v59z'
        />
        <path
            d='M44.1 122.2h20.7c9.1 0 15.9-4.8 15.9-15.7 0-5.4-3.8-10.2-5.7-11.3 1.7-1 5.9-3.9 5.9-11.7 0-6.2-3.3-16.1-16.7-16.1H44.1v54.8z'
        />
        <path
            fill='#FFF'
            d='M53.2 99.6h10.6c3.5 0 6.8 2 6.8 5.7 0 4.5-3.2 6-7.4 6h-9.9l-.1-11.7zm0-22h9.1c3 0 7.2.6 7.2 5.7 0 5.6-4 5.9-7.2 5.9h-9.1V77.6z'
        />
        <path
            d='M55.4 79.7H62c2.1 0 5.1.1 5.1 3.7 0 3.5-2.8 3.7-5.1 3.7h-6.6v-7.4zm.2 22h7.2c2.3 0 5.3 1.3 5.3 3.8 0 3.2-2.9 3.7-5.7 3.7h-6.8v-7.5z'
        />
    </svg>
)

export default BorussiaIcon
