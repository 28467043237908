import { IonCard, IonCardContent, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react'
import { logInOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSignIn, useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import Form from '../Form'
import FormCheckbox from '../Form/FormCheckbox'
import FormTextField from '../Form/FormTextField'
import Modal from '../Modal'

import type { ButtonProps } from './index'
import Button from './index'

const LoginButton: React.FC<ButtonProps & { onClick: (e?: any) => any }> = ({
    onClick,
    children,
    ...buttonProps
}) => {
    const user = useUser()

    const { t } = useTranslation()
    const [showLogin, setShowLogin] = useState(false)
    const [loggingIn, setLoggingIn] = useState(false)

    const onLoginButtonClick = useCallback(() => {
        if(user?.user?.id) { // user logged in, all okay
            onClick?.()
            return
        }
        setShowLogin(true)

    }, [user, setShowLogin, onClick])

    const [showToast] = useToast()
    const signin = useSignIn({
        onError: () => {
            setLoggingIn(false)
            showToast(t('login.failed'), 'danger')
        },
    })

    const onLoginSubmit = useCallback(async ({ name, password, permanent }: {name: string, password: string, permanent: boolean}) => {
        setLoggingIn(true)
        await signin(name, password, permanent)
        setLoggingIn(false)
        setShowLogin(false)
        onClick?.()
    }, [signin, setLoggingIn, onClick])

    return (
        <>
            <Modal
                modalTitle={t('title.login')}
                isOpen={showLogin}
                onClose={() => setShowLogin(false)}
            >
                <IonGrid
                    className='login__grid'
                >
                    <IonRow>
                        <IonCol>
                            <IonCard
                                className='login-card'
                            >
                                <IonImg
                                    className='login-card__image'
                                    src='/assets/icon/favicon.png'
                                />
                                <IonCardContent>
                                    <Form
                                        onSubmit={onLoginSubmit}
                                    >
                                        <FormTextField
                                            className='id__login-name'
                                            required
                                            name='name'
                                            label={t('login.username')}
                                        />
                                        <FormTextField
                                            required
                                            className='id__login-password'
                                            name='password'
                                            label={t('login.password')}
                                            type='password'
                                        />
                                        <FormCheckbox
                                            defaultValue
                                            className='id__login-permanent'
                                            name='permanent'
                                            label={t('login.permanent')}
                                        />
                                        <div
                                            className='login-card__button-bar'
                                        >
                                            <Button
                                                loading={loggingIn}
                                                fill='solid'
                                                color='secondary'
                                                size='default'
                                                type='submit'
                                                slot='end'
                                                className='id__login-button'
                                                icon={logInOutline}
                                            >
                                                {t('login.login')}
                                            </Button>
                                        </div>
                                    </Form>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </Modal>

            <Button
                {...buttonProps}
                onClick={onLoginButtonClick}
            >
                {children}
            </Button>
        </>
    )
}

export default LoginButton
