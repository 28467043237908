import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { heart } from 'ionicons/icons'
import './style.scss'

interface FriendsIconProps {
    className?: string
}

const FriendsIcon: React.FC<FriendsIconProps> = ({ className }) => {
    return (
        <IonIcon
            className={clsx(className, 'relationship-icon', 'relationship-icon--colored')}
            icon={heart}
        />
    )
}

export default FriendsIcon
