import { IonButton, IonItem, IonLabel, IonText } from '@ionic/react'
import clsx from 'clsx'

import CollapsibleContent from '../../../Collapsible/CollapsibleContent'

import '../style.scss'

interface SelectButtonsProps {
    label: string,
    options: { text: string, value: string | number }[]
    value?: (string | number)[] | (string | number)
    onChange: (value: string | number) => void
    className?: string
    error?: string
}

const SelectButtons: React.FC<SelectButtonsProps> = ({
    label,
    options,
    value,
    onChange,
    className,
    error,
}) => {
    const getFill = (val: string | number) => {
        switch (typeof value) {
            case 'object': {
                return value.some((v: string | number) => v === val) ? 'solid' : 'outline'
            }

            case 'string':
            case 'number': {
                return value === val ? 'solid' : 'outline'
            }

            default: return undefined
        }
    }

    return (
        <>
            <div
                className={clsx('form-select-buttons', className)}
            >
                <IonItem
                    lines='none'
                    className='form-select-buttons__item'
                >
                    <IonLabel
                        position='floating'
                        className={clsx('form-select-buttons__label', { 'error': !!error })}
                    >
                        {label}
                    </IonLabel>
                    <div
                        className='form-select-buttons__button-list'
                    >
                        {options.map(({ text, value: vvalue }) => (
                            <IonButton
                                fill={getFill(vvalue)}
                                color='primary'
                                key={vvalue}
                                onClick={() => {
                                    onChange(vvalue)
                                }}
                            >
                                {text}
                            </IonButton>
                        ))}
                    </div>
                </IonItem>
            </div>
            <CollapsibleContent
                className='form-select-buttons__error-container'
                open={!!error}
            >
                <IonText
                    className='form-select-buttons__error-text font-s-regular'
                    color='danger'
                >
                    {error}
                </IonText>
            </CollapsibleContent>
        </>
    )
}

export default SelectButtons
