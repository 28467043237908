import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { ellipsisVertical, informationCircleOutline } from 'ionicons/icons'
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Content from '../../components/Content'
import ContextMenu from '../../components/ContextMenu'
import ItemList from '../../components/ItemList'
import NetworkApplyContextMenuItem from '../../components/Network/NetworkApplyContextMenuItem'
import NetworkListItem from '../../components/Network/NetworkListItem'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import SearchHeader from '../../components/Search/SearchHeader'
import SearchResultList from '../../components/Search/SearchResultList'
import Segment from '../../components/Segment'
import UserListItem from '../../components/User/UserListItem'
import {
    useJmtNetworksQuery,
    useSearchNetworkLazyQuery,
    useSearchTalentLazyQuery,
    useSearchUserLazyQuery,
} from '../../lib/apollo/types'
import type {
    SearchNetworkQuery,
    Network,
    Talent,
    User,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import searchReducer, { initialSearchState, tabs } from './reducer'

import './style.scss'

const slideOptions = {
    autoHeight: true,
    allowTouchMove: false,
}

const Search: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const timeoutSearchRef = useRef<NodeJS.Timeout | null>(null)

    const [state, dispatch] = useReducer(searchReducer, initialSearchState)

    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    const { data: systemNetworks } = useJmtNetworksQuery()
    const systemNetworkId = useMemo(() =>
        systemNetworks?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine')?.id
    , [systemNetworks])

    const roleModelNetworkId = useMemo(() =>
        systemNetworks?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.RoleModels')?.id
    , [systemNetworks])

    const [lazySearchUserQuery, { loading: userSearchLoading }] = useSearchUserLazyQuery({
        onCompleted: data => {
            dispatch({
                type: 'AddResults',
                users: data.searchUser?.collection ?? [],
            })
            dispatch({
                type: 'SetSortOption',
                user: data.searchUser?.meta.sortOptions ?? [],
            })
        },
    })

    const [lazySearchTalentQuery, { loading: talentSearchLoading }] = useSearchTalentLazyQuery({
        onCompleted: (data) => {
            dispatch({
                type: 'AddResults',
                talents: data.searchTalent?.collection ?? [],
            })
            dispatch({
                type: 'SetSortOption',
                talent: data.searchTalent?.meta.sortOptions ?? [],
            })
        },
    })

    const [lazySearchNetworkQuery, { loading: networkSearchLoading }] = useSearchNetworkLazyQuery({
        onCompleted: (data: SearchNetworkQuery) => {
            dispatch({
                type: 'AddResults',
                networks: data.searchNetwork?.collection ?? [],
            })
            dispatch({
                type: 'SetSortOption',
                network: data.searchNetwork?.meta.sortOptions ?? [],
            })
        },
    })

    const onSegmentChange = useCallback((e: CustomEvent) => {
        if (!controlledSwiper) {
            return
        }
        dispatch({
            type: 'SetTab',
            tab: e.detail.value,
        })
        controlledSwiper.slideTo(e.detail.value)
    }, [controlledSwiper, dispatch])

    useEffect(() => {
        if (!state.search?.value) {
            return undefined
        }

        if (timeoutSearchRef.current) {
            clearTimeout(timeoutSearchRef.current)
            timeoutSearchRef.current = null
        }

        timeoutSearchRef.current = setTimeout(() => {
            timeoutSearchRef.current = null
            lazySearchUserQuery({
                variables: {
                    query: state.search?.value ?? '',
                    sort: state.sort?.user ?? '',
                    meId: user?.user.id ?? '',
                    networkId: systemNetworkId ?? '0',
                },
            })
            lazySearchTalentQuery({
                variables: {
                    query: state.search?.value ?? '',
                    sort: state.sort?.talent ?? '',
                    networkId: roleModelNetworkId ?? '0',
                },
            })
            lazySearchNetworkQuery({
                variables: {
                    query: state.search?.value ?? '',
                    sort: state.sort?.network ?? '',
                },
            })
        }, 350)

        return () => { if (timeoutSearchRef.current) clearTimeout(timeoutSearchRef.current) }
    }, [lazySearchNetworkQuery, lazySearchTalentQuery, lazySearchUserQuery, roleModelNetworkId, state.search, state.sort?.network, state.sort?.talent, state.sort?.user, systemNetworkId, user?.user.id])

    useEffect(() => {
        if (state.sort?.user && state.search?.value) {
            lazySearchUserQuery({
                variables: {
                    query: state.search.value,
                    sort: state.sort.user,
                    meId: user?.user.id ?? '',
                    networkId: systemNetworkId ?? '0',
                },
            })
        }
    }, [lazySearchUserQuery, state?.search?.value, state?.sort?.user, systemNetworkId, user?.user.id])

    useEffect(() => {
        if (state.sort?.talent && state.search?.value) {
            lazySearchTalentQuery({
                variables: {
                    query: state.search.value,
                    sort: state.sort.talent,
                    networkId: roleModelNetworkId ?? '0',
                },
            })
        }
    }, [lazySearchTalentQuery, roleModelNetworkId, state?.search?.value, state?.sort?.talent])

    const setSearch = useCallback(({ value, event }: { value?: string, event: any }) => {
        dispatch({
            type: 'SetSearch',
            value,
            event,
        })
        dispatch({ type: 'ResetSuggestions' })
    }, [])

    const setUserSortOption = useCallback((value: string) => dispatch({
        type: 'SetSort',
        user: value,
    }), [])

    const setTalentSortOption = useCallback((value: string) => dispatch({
        type: 'SetSort',
        talent: value,
    }), [])

    const setNetworkSortOption = useCallback((value: string) => dispatch({
        type: 'SetSort',
        network: value,
    }), [])

    useEffect(() => {
        requestAnimationFrame(() => {
            controlledSwiper?.updateAutoHeight()
        })
    }, [controlledSwiper, state.results])

    return (
        <Page
            trackingTitle='search'
            className='search'
        >
            <SearchHeader
                setSearch={setSearch as Dispatch<SetStateAction<{ value?: string | null, event: any } | undefined>>}
                search={state.search}
            />
            <Content
                fullscreen
            >
                {(state.results.talents.length === 0 && state.results.users.length === 0 && state.results.networks.length === 0) && (
                    <IonItem
                        lines='none'
                    >
                        <IonIcon
                            icon={informationCircleOutline}
                            slot='start'
                            color='medium'
                        />
                        <IonLabel
                            className='ion-text-wrap n2br'
                        >
                            <IonText
                                color='medium'
                                className='n2br'
                            >
                                {t('search.description')}
                            </IonText>
                        </IonLabel>
                    </IonItem>
                )}
                {(state.results.talents.length > 0 || state.results.users.length > 0 || state.results.networks.length > 0) && (
                    <Segment
                        value={state.tab}
                        onIonChange={onSegmentChange}
                        tabs={tabs.map((v: string) => ({ tab: `${t(`search.${v}`)} (${(state.results[v as 'users' | 'talents' | 'networks']).length ?? 0})` }))}
                    />
                )}
                <Swiper
                    {...slideOptions}
                    modules={[Controller]}
                    onSwiper={setControlledSwiper}
                >
                    <SwiperSlide>
                        <SearchResultList
                            headline={t('search.talents')}
                            loading={talentSearchLoading}
                            results={state.results.talents}
                            sortOption={state.sort?.talent}
                            sortOptions={state.sortOptions?.talent ?? []}
                            onSortOptionChange={setTalentSortOption}
                        >
                            { (listResults: Talent[]) => (
                                <ItemList>
                                    { listResults.map(r => (
                                        <TalentCard
                                            type='listItem'
                                            key={r.id}
                                            talent={r}
                                        />
                                    ))}
                                </ItemList>
                            )}
                        </SearchResultList>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SearchResultList
                            headline={t('search.users')}
                            loading={userSearchLoading}
                            results={state.results.users}
                            sortOption={state.sort?.user}
                            sortOptions={state.sortOptions?.user ?? []}
                            onSortOptionChange={setUserSortOption}
                        >
                            { (listResults: User[]) => (
                                <ItemList>
                                    { listResults.map(r => (
                                        <UserListItem
                                            key={r.id}
                                            user={r}
                                        />
                                    ))}
                                </ItemList>
                            )}
                        </SearchResultList>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SearchResultList
                            headline={t('search.networks')}
                            loading={networkSearchLoading}
                            results={state.results.networks}
                            sortOption={state.sort?.network}
                            sortOptions={state.sortOptions?.network ?? []}
                            onSortOptionChange={setNetworkSortOption}
                        >
                            { (listResults: Network[]) => (
                                <ItemList>
                                    { listResults.map(r => (
                                        <NetworkListItem
                                            key={r.id}
                                            network={r as Network}
                                            onClick={e => {
                                                e.preventDefault()
                                            }}
                                        >
                                            <ContextMenu
                                                size='small'
                                                fill='clear'
                                                icon={ellipsisVertical}
                                            >
                                                <NetworkApplyContextMenuItem
                                                    network={r as Network}
                                                />
                                            </ContextMenu>
                                        </NetworkListItem>
                                    ))}
                                </ItemList>
                            )}
                        </SearchResultList>
                    </SwiperSlide>
                </Swiper>
            </Content>
        </Page>
    )
}

export default Search
