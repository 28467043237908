const handleCollection = (): Record<string, unknown> => ({
    keyArgs: (keys: any) => Object.keys(keys).filter(k => k !== 'start' && k !== 'limit'),
    merge(existing: any, incoming: any) {
        if (!existing || incoming.meta.range.from === 1) {
            return incoming
        }

        return {
            ...incoming,
            collection: [
                ...existing.collection,
                ...incoming.collection,
            ],
            meta: {
                ...incoming.meta,
                from: existing.meta.from,
            },
        }
    },
})

export default handleCollection
