import { IonCard, IonSkeletonText, IonText } from '@ionic/react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TalentPosition, useTalentQuery } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { useUser } from '../../../providers/Auth/hooks'
import CustomAvatar from '../../CustomAvatar'
import type { LayerI } from '../../Talent/TalentID/Layer'
import SkillsListViewButton from '../../Talent/TalentID/SkillsListView/SkillsListViewButton'
import useLayers from '../../Talent/TalentID/useLayers'
import type { ActivityProps } from '../ActivityProps'
import DeletedActivity from '../DeletedActivity'
import useHours from '../useHours'

import './style.scss'

const ShortVoteActivity: React.FC<ActivityProps> = ({ activity }) => {
    const { t } = useTranslation()

    const me = useUser()
    const myAction = activity.from.id === me?.user.id.toString()

    const talentId = activity.targetUserId.split('t-').pop() ?? ''

    const { data: talentData, loading } = useTalentQuery({ skip: !myAction || !me, variables: { userId: me?.user.id ?? '', talentId } })
    const hours = useHours(activity)

    const layers = useLayers(talentData?.talent.metas.fieldPosition ?? TalentPosition.Midfield, talentData?.talent.dataViews, undefined, (getAge(talentData?.talent.metas.date) ?? 99) < 16)

    const layer: { layer: LayerI; idx: number } | undefined = useMemo(() => {
        const idx = layers.findIndex(l => l.values.length === 8)
        if (idx === -1) return undefined
        return {
            idx,
            layer: layers[idx],
        }
    }, [layers])

    if (talentData && !talentData.talent) return <DeletedActivity />

    return (
        <IonCard
            color='dark'
            className='vote-activity'
        >
            <CustomAvatar
                className='vote-activity__avatar'
                img={talentData?.talent.image}
                alt={talentData?.talent?.id}
            />

            <IonText
                className='vote-activity__text'
                color='light'
            >
                <Trans
                    i18nKey={`activity.vote.${myAction ? 'you' : 'me'}`}
                    values={{
                        name: talentData?.talent?.caption,
                        date: hours,
                    }}
                    components={{
                        strong: !loading ? <strong /> : (
                            <IonSkeletonText
                                className='vote-activity__skeleton'
                            />
                        ),
                        date: (
                            <IonText
                                className='vote-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>

            { talentData && (
                <SkillsListViewButton
                    talentPosition={talentData.talent.metas.fieldPosition}
                    color1='secondary'
                    color='secondary'
                    fill='solid'
                    size='small'
                    disableIcon
                    label={t('talent.theVote')}
                    activeIndex={0}
                    dataView1={[{
                        key: typeof +(layer?.layer.id ?? 0) === 'number' ? +(layer?.layer.id ?? 0) : 0,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        values: JSON.parse(activity.data).values,
                    }]}
                    under16={(getAge(talentData.talent.metas.date) ?? 99) < 16}
                />
            )}
        </IonCard>
    )
}

export default ShortVoteActivity
