import { IonContent, IonPage } from '@ionic/react'
import { useLayoutEffect } from 'react'
import { useRouteMatch } from 'react-router'

import CertificateComponent from '../../../components/Certificate'
import { useShanghaiTalentQuery } from '../../../lib/apollo/types'

const BorussiaShanghaiPage: React.FC = () => {
    const { params: { token } } = useRouteMatch<{ token: string }>()

    useLayoutEffect(() => {
        document.body.classList.add('cert')
        return () => document.body.classList.remove('cert')
    }, [])

    const { data } = useShanghaiTalentQuery({
        variables: {
            token,
        },
    })

    const talent = data?.shanghaiTalent

    if(!talent) return null

    return (
        <IonPage>
            <IonContent>
                <CertificateComponent
                    talent={talent}
                    certificate={{
                        talentId: talent.id,
                        club: 'borussia-shanghai',
                        firstName: talent.metas.firstName,
                        lastName: talent.metas.lastName,
                        camp: {
                            caption: 'Borussia Shanghai',
                            campType: 'borussia-shanghai',
                            start: '2023-08-01',
                            end: '2023-08-14',
                        },
                        id: '1',
                        vote: [],
                        image: talent.image ?? '',
                        player: '',
                        birthday: talent.metas.date,
                    }}
                />
            </IonContent>
        </IonPage>
    )
}

export default BorussiaShanghaiPage
