import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../../CustomAvatar'

import './style.scss'

const PlaylistAddCardSmall: React.FC = () => {

    const { t } = useTranslation()

    return (
        <IonCard
            routerLink='/playlists/addoredit/new'
            className='playlist-card-small'
        >
            <div
                className='playlist-card-small__image-wrapper'
            >
                <CustomAvatar
                    type='network'
                    width='100%'
                    icon={addCircleOutline}
                />
            </div>

            <IonCardContent>
                <IonCardTitle>
                    { t('playlist.add') }
                </IonCardTitle>
                <IonCardSubtitle>
                    { t('playlist.addSubTitle') }
                </IonCardSubtitle>
            </IonCardContent>
        </IonCard>
    )
}

export default PlaylistAddCardSmall
