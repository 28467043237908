import ItemList from '../../../../components/ItemList'
import TalentCard from '../../../../components/NewTalentCard'
import type { Talent } from '../../../../lib/apollo/types'
import usePlayerTypes from '../../../../lib/hooks/usePlayerTypes'

const getRouterLink = (talent: Talent): string => `/marketplace/similar/${talent.id}`

const MarketplacePlayertypesSearch: React.FC = () => {
    // RoleModels
    const playerTypes = usePlayerTypes()

    return (
        <ItemList
            className='similar-category-tabs__talent-wrapper'
        >
            { playerTypes?.collection.map(talent => (
                <TalentCard
                    type='listItem'
                    routerLink={getRouterLink(talent)}
                    key={talent.id}
                    talent={talent}
                />
            )) }
        </ItemList>
    )
}

export default MarketplacePlayertypesSearch
