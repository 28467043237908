import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { heartOutline } from 'ionicons/icons'
import './style.scss'

interface FollowerIconProps {
    className?: string
}

const FollowerIcon: React.FC<FollowerIconProps> = ({ className }) => {
    return (
        <IonIcon
            className={clsx(className, 'relationship-icon')}
            icon={heartOutline}
        />
    )
}

export default FollowerIcon
