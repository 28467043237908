import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import type { Playlist } from '../../../lib/apollo/types'
import CustomAvatar from '../../CustomAvatar'
import { WATCHLIST_CAPTION } from '../PlayListConstants'

import './style.scss'

const PlaylistCardSmall: React.FC<{ playlist: Playlist }> = ({ playlist }) => {
    const { t } = useTranslation()

    if (!playlist) {
        return null
    }

    return (
        <IonCard
            routerLink={`/playlists/detail/${playlist.id}`}
            className='playlist-card-small'
        >
            <div
                className='playlist-card-small__image-wrapper'
            >
                <CustomAvatar
                    className='playlist-card-small__avatar'
                    width='100%'
                    type='playlist'
                    img={playlist.latestObject?.image}
                />
            </div>

            <IonCardContent>
                <IonCardTitle>
                    { playlist.caption === WATCHLIST_CAPTION ? t('playlist.watchlist') : playlist.caption }
                </IonCardTitle>
                { playlist.public && (
                    <IonCardSubtitle>
                        { t('playlist.isPublic') }
                    </IonCardSubtitle>
                )}
                { !playlist.latestObject && (
                    <IonCardSubtitle>
                        <em>
                            { t('playlist.noTalents')}
                        </em>
                    </IonCardSubtitle>
                )}
            </IonCardContent>
        </IonCard>
    )
}

export default PlaylistCardSmall
