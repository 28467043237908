import { IonCard, IonSkeletonText, IonText } from '@ionic/react'

import CustomAvatarSkeleton from '../../CustomAvatar/CustomAvatarSkeleton'

import './style.scss'

const VoteActivitySkeleton: React.FC = () => (
    <IonCard
        color='dark'
        className='vote-activity'
    >
        <CustomAvatarSkeleton
            className='vote-activity__avatar-skeleton'
        />

        <IonText
            className='vote-activity__text'
            color='light'
        >
            <div>
                <IonSkeletonText
                    className='vote-activity__skeleton'
                    style={{
                        width: '160px',
                    }}
                />
            </div>
            <IonSkeletonText
                className='vote-activity__skeleton'
                style={{
                    width: '80px',
                }}
            />
        </IonText>
    </IonCard>
)

export default VoteActivitySkeleton
