import './style.scss'
import {IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow} from '@ionic/react'
import {star} from 'ionicons/icons'

type TimelineItemProps = {
    date: string
    data: { title: string, content: string }
    disableOnClick: boolean
}

const TeamEntry: React.FC<TimelineItemProps> = ({ date, data }) => {

    return (
            <IonCard
                className="talent-timeline-item__entry__card"
            >
                <IonGrid
                    className='talentcard-list-item__grid'
                >
                    <IonRow
                        className='ion-align-items-center ion-justify-content-between ion-nowrap'
                    >
                        <IonCol
                            className='talentcard-list-item__caption-wrapper'
                        >
                            <IonCardTitle>
                            {data.title}
                            </IonCardTitle>
                            <IonRow>
                                <IonCardSubtitle color='dark'>
                                    {data.content}
                                </IonCardSubtitle>
                            </IonRow>
                            <div className='talentcard-list-item__time'>
                                {`${new Date(date).toLocaleDateString(navigator.language, {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                })}`}
                            </div>
                        </IonCol>
                        <IonCol
                            className='talentcard-list-item__talent-id'
                        >
                            <IonIcon src={star} className='talentcard-list-item__icon'/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
    )

}

export default TeamEntry