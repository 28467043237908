import './style.scss'
import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
} from '@ionic/react'
import { checkmark, close, footballOutline, informationCircleOutline } from 'ionicons/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'

import Button from '../../components/Button'
import Buttons from '../../components/Buttons'
import Content from '../../components/Content'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import Segment from '../../components/Segment'
import TalentActionButtons from '../../components/TalentActionButtons'
import UserCardMedium from '../../components/User/UserCard/UserCardMedium'
import type { TalentTransferRequest  } from '../../lib/apollo/types'
import {
    ListMyPersonificationsDocument,
    PermissionLevel,
    TalentTransferRequestStatus,
    TransferTalentRequestsDocument,
    useEditTransferTalentRequestMutation,
    useListMyPersonificationsQuery,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

export const tabs = ['user', 'personification']

const slideOptions = {
    spaceBetween: 16,
}

interface ProfileProps {
    pendingTalentTransferRequests?: TalentTransferRequest[]
}

const Profile: React.FC<ProfileProps> = ({ pendingTalentTransferRequests }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [currentTab, setCurrentTab] = useState<string>('0')
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const { params: { tab } } = useRouteMatch<{ tab: 'detail' | 'personification' }>()
    const [present] = useToast()

    const onSegmentChange = useCallback((e: CustomEvent) => {
        if (!controlledSwiper) {
            return
        }
        setCurrentTab(e.detail.value)
        controlledSwiper.slideTo(e.detail.value)
    }, [controlledSwiper, setCurrentTab])

    const onSlideWillChange = useCallback((e: SwiperClass) => {
        setCurrentTab(e.activeIndex.toString())
    }, [])

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
        skip: !user,
    })

    const [editTransferTalentRequest] = useEditTransferTalentRequestMutation({
        refetchQueries: [{
            query: TransferTalentRequestsDocument,
        }, {
            query: ListMyPersonificationsDocument,
        }],
    })

    const acceptTalentTransfer = useCallback(async (transferId: string) => {
        await editTransferTalentRequest({
            variables: {
                transferId,
                input: {
                    status: TalentTransferRequestStatus.Accepted,
                },
            },
        })
        present(t('talent.transfer.accepted'), 'success')
    }, [editTransferTalentRequest, present, t])

    const declineTalentTransfer = useCallback(async (transferId: string) => {
        await editTransferTalentRequest({
            variables: {
                transferId,
                input: {
                    status: TalentTransferRequestStatus.Declined,
                },
            },
        })
        present(t('talent.transfer.declined'), 'success')
    }, [editTransferTalentRequest, present, t])

    useEffect(() => {
        if (!tab || !controlledSwiper || controlledSwiper.destroyed) {
            return
        }
        controlledSwiper?.slideTo(tabs.indexOf(tab))
    }, [tab, controlledSwiper])

    return (
        <Page
            trackingTitle='profile'
        >
            <Header
                title={t('title.profile')}
                buttonLeft='menu'
            />
            {user?.user && !!myPersonifications?.listMyPersonifications.collection && (
                <Content
                    fullscreen
                    className='profile'
                >
                    <Segment
                        value={currentTab}
                        onIonChange={onSegmentChange}
                        tabs={tabs.map((v: string) => ({ tab: t(`profile.${v}`) }))}
                    />
                    <Swiper
                        {...slideOptions}
                        modules={[Controller]}
                        onSwiper={setControlledSwiper}
                        onSlideChange={onSlideWillChange}
                    >
                        <SwiperSlide>
                            <div
                                className='profile__card-wrapper'
                            >
                                <UserCardMedium
                                    user={user.user}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                {(pendingTalentTransferRequests?.length ?? 0) > 0 && (
                                    <>
                                        <ItemList>
                                            {pendingTalentTransferRequests?.map(request => (
                                                <React.Fragment
                                                    key={request.id}
                                                >
                                                    <div
                                                        className='display-flex ion-justify-content-center ion-padding-top ion-padding-bottom'
                                                    >
                                                        {request.object && (
                                                            <TalentCard
                                                                talent={request.object}
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className='display-flex ion-align-items-end ion-margin-bottom'
                                                    >
                                                        <Buttons
                                                            title={t('talent.transfer.buttonsTitle')}
                                                            subtitle={t('talent.transfer.buttonsSubtitle',
                                                                {
                                                                    message: request.message,
                                                                    from: `${request.from.firstName} ${request.from.lastName}`,
                                                                },
                                                            )}
                                                        >
                                                            <Button
                                                                icon={checkmark}
                                                                color='secondary'
                                                                onClick={() => {
                                                                    acceptTalentTransfer(request.id.toString())
                                                                }}
                                                            >
                                                                {t('buttons.accept')}
                                                            </Button>
                                                            <Button
                                                                icon={close}
                                                                onClick={() => {
                                                                    declineTalentTransfer(request.id.toString())
                                                                }}
                                                            >
                                                                {t('buttons.decline')}
                                                            </Button>
                                                        </Buttons>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </ItemList>
                                        <Divider />
                                    </>
                                )}
                            </div>
                            {myPersonifications?.listMyPersonifications.collection?.[0]?.id ? (
                                <div>
                                    <ItemList>
                                        <div>
                                            <div
                                                className='display-flex ion-justify-content-center'
                                            >
                                                <TalentCard
                                                    permissionLevel={PermissionLevel.Personal}
                                                    talent={myPersonifications.listMyPersonifications.collection[0]}
                                                />
                                            </div>
                                        </div>
                                        <TalentActionButtons
                                            permissionLevel={PermissionLevel.Personal}
                                            talent={myPersonifications.listMyPersonifications.collection[0]}
                                        />
                                    </ItemList>
                                </div>
                            ) : (
                                <div>
                                    <IonCard
                                        color='primary'
                                    >
                                        <IonCardContent>
                                            <IonCardTitle>{t('profile.createTalent.title')}</IonCardTitle>
                                            <IonItem
                                                lines='none'
                                            >
                                                <IonIcon
                                                    icon={informationCircleOutline}
                                                    slot='start'
                                                    color='medium'
                                                />
                                                <IonLabel
                                                    className='ion-text-wrap'
                                                >
                                                    <IonText
                                                        color='medium'
                                                    >
                                                        {t('profile.createTalent.info')}
                                                    </IonText>
                                                </IonLabel>
                                            </IonItem>
                                            <IonItem
                                                lines='none'
                                            >
                                                <Button
                                                    icon={footballOutline}
                                                    color='secondary'
                                                    size='default'
                                                    className='profile__create-btn'
                                                    routerLink='/profile/edit/talent/new/true'
                                                >
                                                    {t('profile.createTalent.create')}
                                                </Button>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                            )}
                        </SwiperSlide>
                    </Swiper>
                </Content>
            )}
        </Page>
    )
}

export default Profile
