
import {IonCard, IonCardContent, IonText} from '@ionic/react'
import {format} from 'date-fns'
import React from 'react'
import {CertificateItemProps} from '../index'

const HSVCertificateItem: React.FC<CertificateItemProps> = ({ certificate, setCert }) => {
    return (
        <div
        className='talent-timeline__entry-wrapper'
        key={certificate.id}
    >
        <div
            className='talent-timeline__entry'
        >
            <IonCard
                onClick={() => setCert(certificate)}
                key={certificate.camp.caption}
                className='talent-timeline__certificate'
            >
                <img
                    src='/assets/hsv-fubaschu.jpeg'
                    alt='hsv fussballschule'
                />
                <IonCardContent>
                    <div className="talent-timeline__entry__caption">
                        {certificate.camp.caption}
                    </div>
                    <div>
                    <IonText
                            color='medium'
                        >
                            {format(new Date(certificate.camp.end), 'MM/yyyy')}
                        </IonText>
                    </div>
                </IonCardContent>
            </IonCard>
        </div>
    </div>)
}

export default HSVCertificateItem