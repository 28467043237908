import './style.scss'
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { Trans, useTranslation } from 'react-i18next'

import type { Certificate, Talent } from '../../../lib/apollo/types'
import { TalentPosition, useTalentQuery } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { useUser } from '../../../providers/Auth/hooks'
import Bundesliga from '../../Icon/Bundesliga'
import TalentCard from '../../NewTalentCard'
import SkillsListViewRadarWithoutGroupLabels
    from '../../Talent/TalentID/SkillsListView/SkillsListViewRadarWithoutGroupLabels'
import useLayers from '../../Talent/TalentID/useLayers'

import BorussiaIcon from './BorussiaIcon'

const Footer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div
            className='borussia-shanghai-wrapper__footer'
        >
            <div
                className='borussia-shanghai__logo'
            >
                <BorussiaIcon />
                <div
                    className='borussia-shanghai__logo__text'
                >
                    <h1>Borussia</h1>
                    <h1>Academy</h1>
                    <h2>Shanghai</h2>
                </div>
            </div>
            <div
                className='n2br ion-text-justify borussia-shanghai-wrapper__footer__text'
            >
                {t('i18n.borussiaShanghai.footer')}
            </div>
            <div>
                <img
                    className='borussia-shanghai-wrapper__footer__jmt-logo'
                    src='/assets/logo_colored.svg'
                    alt='certificate footer logo'
                />
            </div>
        </div>
    )
}

const BorussiaShanghaiCertificate: React.FC<Certificate & { talent?: Talent }> = (props) => {
    const { t } = useTranslation()
    const user = useUser()
    const { data } = useTalentQuery({
        variables: {
            talentId: props.talentId,
            userId: user?.user.id ?? '',
        },
        skip: !!props.talent,
    })

    const talent = props.talent ?? data?.talent

    const talentLayers = useLayers(talent?.metas.fieldPosition ?? TalentPosition.Midfield, talent?.dataViews, undefined, (getAge(talent?.metas.date) ?? 99) < 16, undefined)

    // split in 4 groups
    const tl1 = talentLayers[0] ? {
        ...talentLayers[0],
        values: [...talentLayers[0].values].slice(0, 8),
        translations: [...(talentLayers[0].translations ?? [])].slice(0, 8),
        translationKeys: talentLayers[0].translationKeys?.slice(0, 8),
    } : null
    const tl2 = talentLayers[0] ? {
        ...talentLayers[0],
        values: [...talentLayers[0].values].slice(8, 16),
        translations: [...(talentLayers[0].translations ?? [])].slice(8, 16),
        translationKeys: talentLayers[0].translationKeys?.slice(8, 16),
    } : null
    const tl3 = talentLayers[0] ? {
        ...talentLayers[0],
        values: [...talentLayers[0].values].slice(16, 24),
        translations: [...(talentLayers[0].translations ?? [])].slice(16, 24),
        translationKeys: talentLayers[0].translationKeys?.slice(16, 24),
    } : null
    const tl4 = talentLayers[0] ? {
        ...talentLayers[0],
        values: [...talentLayers[0].values].slice(24, 32),
        translations: [...(talentLayers[0].translations ?? [])].slice(24, 32),
        translationKeys: talentLayers[0].translationKeys?.slice(24, 32),
    } : null

    if (!talent) {
        return null
    }

    return (
        <div
            className='borussia-shanghai'
        >
            <div
                className='borussia-shanghai-wrapper'
                id='certificate-page-1'
            >
                <IonGrid>
                    <IonRow>
                        <IonCol
                            className='ion-padding borussia-shanghai-wrapper__talent-card-col'
                        >
                            <TalentCard
                                talent={talent}
                                type='medium'
                                JerseySvgComponent={<BorussiaIcon />}
                            />
                            <div
                                className='borussia-shanghai-wrapper__arrow-right'
                            />
                        </IonCol>
                        <IonCol
                            className='ion-padding-top borussia-shanghai-wrapper__cert-col'
                        >
                            <IonGrid>
                                <IonRow>
                                    <IonCol
                                        size='6'
                                    >
                                        <div
                                            className='borussia-shanghai__logo'
                                        >
                                            <Bundesliga />
                                        </div>
                                    </IonCol>
                                    <IonCol
                                        size='6'
                                    >
                                        <div
                                            className='borussia-shanghai__logo'
                                        >
                                            <BorussiaIcon />
                                            <div
                                                className='borussia-shanghai__logo__text'
                                            >
                                                <h1>Borussia</h1>
                                                <h1>Academy</h1>
                                                <h2>Shanghai</h2>
                                            </div>
                                        </div>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol
                                        className='ion-padding'
                                    >
                                        <div>
                                            <IonText
                                                color='primary'
                                            >
                                                <h1
                                                    className='borussia-shanghai__headline'
                                                >
                                                    Certificate
                                                </h1>
                                            </IonText>
                                        </div>
                                        <div
                                            className='borussia-shanghai__text n2br'
                                        >
                                            <Trans
                                                i18nKey='i18n.borussiaShanghai.intro'
                                                values={{ name: talent.caption }}
                                            >
                                                Awarded to
                                                <span
                                                    className='borussia-shanghai__text__name'
                                                >
                                                Spielername!
                                                </span>
                                            </Trans>
                                            <p>{t('i18n.borussiaShanghai.text')}</p>
                                        </div>
                                        <div
                                            className='borussia-shanghai__brush'
                                        >
                                            Foals
                                            <br />
                                            Philosophy
                                        </div>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </div>
            <div
                className='borussia-shanghai-wrapper'
                id='certificate-page-2'
            >
                <IonGrid>
                    {talentLayers[0]?.values.length === 32 && (
                        <IonRow>
                            <IonCol
                                className='ion-padding'
                                size='3'
                            >
                                <div
                                    className='borussia-shanghai__group-headline'
                                >
                                    <IonText
                                        color='primary'
                                    >
                                        <h3>{t('i18n.group.0')}</h3>
                                    </IonText>
                                </div>
                                {tl1 && (
                                    <SkillsListViewRadarWithoutGroupLabels
                                        layer={tl1}
                                        talentPosition={talent.metas.fieldPosition}
                                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    />
                                )}
                            </IonCol>
                            <IonCol
                                className='ion-padding'
                                size='3'
                            >
                                <div
                                    className='borussia-shanghai__group-headline'
                                >
                                    <IonText
                                        color='primary'
                                    >
                                        <h3>{t('i18n.group.1')}</h3>
                                    </IonText>
                                </div>
                                {tl2 && (
                                    <SkillsListViewRadarWithoutGroupLabels
                                        layer={tl2}
                                        talentPosition={talent.metas.fieldPosition}
                                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    />
                                )}
                            </IonCol>
                            <IonCol
                                className='ion-padding'
                                size='3'
                            >
                                <div
                                    className='borussia-shanghai__group-headline'
                                >
                                    <IonText
                                        color='primary'
                                    >
                                        <h3>{t('i18n.group.2')}</h3>
                                    </IonText>
                                </div>
                                {tl3 && (
                                    <SkillsListViewRadarWithoutGroupLabels
                                        layer={tl3}
                                        talentPosition={talent.metas.fieldPosition}
                                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    />
                                )}
                            </IonCol>
                            <IonCol
                                className='ion-padding'
                                size='3'
                            >
                                <div
                                    className='borussia-shanghai__group-headline'
                                >
                                    <IonText
                                        color='primary'
                                    >
                                        <h3>{t('i18n.group.3')}</h3>
                                    </IonText>
                                </div>
                                {tl4 && (
                                    <SkillsListViewRadarWithoutGroupLabels
                                        layer={tl4}
                                        talentPosition={talent.metas.fieldPosition}
                                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    />
                                )}
                            </IonCol>
                        </IonRow>
                    )}
                    {talentLayers[0]?.values.length === 8 && (
                        <IonRow>
                            <IonCol>
                                <SkillsListViewRadarWithoutGroupLabels
                                    layer={talentLayers[0]}
                                    talentPosition={talent.metas.fieldPosition}
                                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                                />
                            </IonCol>
                        </IonRow>
                    )}
                </IonGrid>
                <Footer />
            </div>
        </div>
    )
}

export default BorussiaShanghaiCertificate
