const NurDerHsv: React.FC = () => (
    <svg
        clipRule='evenodd'
        fillRule='evenodd'
        imageRendering='optimizeQuality'
        shapeRendering='geometricPrecision'
        textRendering='geometricPrecision'
        viewBox='0 0 133.411 12.956'
    >
        <g
            fill='#ffffff'
            id='Ebene_x0020_1'
        >
            <metadata
                id='Layer'
            />
            <polygon
                points='0,9.867 0.576,9.691 0.576,3.271 0,3.095 0,0.176 8.2,0.176 9.852,7.952 9.852,3.271 9.279,3.095 9.279,0.176 14.442,0.176 14.442,3.095 13.869,3.271 13.869,12.786 6.691,12.786 4.65,4.503 4.65,9.691 5.24,9.867 5.24,12.786 0,12.786 '
                id='polygon7'
            />
            <path
                id='1'
                d='M21.536 12.956c-2.03,0 -3.507,-0.394 -4.434,-1.177 -0.924,-0.787 -1.388,-1.898 -1.388,-3.337l0 -5.171 -0.564 -0.176 0 -2.919 6.587 0 0 2.919 -0.581 0.176 0 5.214c0,0.213 0.074,0.388 0.224,0.521 0.147,0.135 0.369,0.204 0.662,0.204 0.291,0 0.513,-0.069 0.662,-0.204 0.147,-0.133 0.222,-0.308 0.222,-0.521l0 -5.214 -0.573 -0.176 0 -2.919 6.041 0 0 2.919 -0.591 0.176 0 5.188c0,0.956 -0.238,1.771 -0.717,2.441 -0.477,0.674 -1.186,1.184 -2.121,1.532 -0.936,0.348 -2.079,0.524 -3.429,0.524z'
            />
            <path
                id='2'
                d='M42.53 9.835l0 2.951 -5.465 0 -1.393 -4.183 -0.674 0 0 1.088 0.665 0.176 0 2.919 -6.486 0 0 -2.919 0.576 -0.176 0 -6.42 -0.576 -0.176 0 -2.919 8.646 0c1.603,0 2.787,0.322 3.547,0.967 0.763,0.642 1.143,1.664 1.143,3.066 0,0.815 -0.173,1.512 -0.519,2.096 -0.345,0.582 -0.837,1.04 -1.479,1.379l1.324 1.947 0.691 0.204zm-7.532 -4.091l0.953 0c0.444,0 0.798,-0.11 1.063,-0.331 0.264,-0.219 0.397,-0.559 0.397,-1.019 0,-0.467 -0.133,-0.798 -0.392,-0.997 -0.262,-0.195 -0.633,-0.293 -1.111,-0.293l-0.91 0 0 2.64z'
            />
            <path
                id='3'
                d='M54.953 0.176c2.171,0 3.76,0.521 4.771,1.56 1.01,1.039 1.514,2.574 1.514,4.598 0,1.394 -0.247,2.571 -0.742,3.533 -0.496,0.961 -1.215,1.69 -2.163,2.182 -0.95,0.492 -2.093,0.737 -3.437,0.737l-7.037 0 0 -2.919 0.576 -0.176 0 -6.42 -0.576 -0.176 0 -2.919 7.094 0zm-1.01 9.547c0.443,0 0.8,-0.098 1.074,-0.3 0.273,-0.198 0.477,-0.538 0.613,-1.019 0.132,-0.481 0.201,-1.14 0.201,-1.978 0,-0.878 -0.069,-1.543 -0.201,-1.998 -0.136,-0.458 -0.34,-0.766 -0.613,-0.93 -0.274,-0.161 -0.648,-0.245 -1.126,-0.245l0 6.47 0.052 0z'
            />
            <polygon
                id='4'
                points='67.641,3.458 67.641,4.975 72.481,4.975 72.481,7.935 67.641,7.935 67.641,9.504 69.867,9.504 70.045,8.764 73.425,8.764 73.425,12.786 61.685,12.786 61.685,9.867 62.26,9.691 62.26,3.271 61.685,3.095 61.685,0.176 73.425,0.176 73.425,4.201 70.045,4.201 69.867,3.458 '
            />
            <path
                id='5'
                d='M87.596 9.835l0 2.951 -5.464 0 -1.394 -4.183 -0.673 0 0 1.088 0.665 0.176 0 2.919 -6.487 0 0 -2.919 0.576 -0.176 0 -6.42 -0.576 -0.176 0 -2.919 8.646 0c1.604,0 2.787,0.322 3.547,0.967 0.763,0.642 1.143,1.664 1.143,3.066 0,0.815 -0.173,1.512 -0.518,2.096 -0.346,0.582 -0.838,1.04 -1.48,1.379l1.324 1.947 0.691 0.204zm-7.531 -4.091l0.953 0c0.443,0 0.797,-0.11 1.062,-0.331 0.265,-0.219 0.397,-0.559 0.397,-1.019 0,-0.467 -0.132,-0.798 -0.391,-0.997 -0.262,-0.195 -0.634,-0.293 -1.112,-0.293l-0.909 0 0 2.64z'
            />
            <polygon
                id='6'
                points='106.331,3.271 106.331,9.691 106.895,9.867 106.895,12.786 100.215,12.786 100.215,9.867 100.78,9.691 100.78,7.768 99.041,7.768 99.041,9.691 99.616,9.867 99.616,12.786 92.926,12.786 92.926,9.867 93.501,9.691 93.501,3.271 92.926,3.095 92.926,0.176 99.616,0.176 99.616,3.095 99.041,3.271 99.041,4.866 100.78,4.866 100.78,3.271 100.215,3.095 100.215,0.176 106.895,0.176 106.895,3.095 '
            />
            <path
                id='7'
                d='M115.996 4.918c1.14,0.144 2.041,0.535 2.697,1.166 0.66,0.633 0.988,1.477 0.988,2.527 0,0.867 -0.216,1.621 -0.645,2.269 -0.429,0.645 -1.106,1.155 -2.024,1.523 -0.921,0.369 -2.087,0.553 -3.504,0.553 -1.059,0 -2.01,-0.066 -2.853,-0.199 -0.844,-0.132 -1.526,-0.276 -2.05,-0.432 -0.521,-0.152 -0.783,-0.241 -0.783,-0.264l0 -3.357 0.118 0.051 3.374 0 0.184 0.867c0.525,0.063 1.06,0.095 1.613,0.095 0.927,0 1.39,-0.204 1.39,-0.607 0,-0.17 -0.049,-0.3 -0.149,-0.389 -0.104,-0.089 -0.248,-0.159 -0.441,-0.202 -0.19,-0.046 -0.472,-0.095 -0.843,-0.144l-1.636 -0.244c-0.725,-0.107 -1.362,-0.32 -1.906,-0.637 -0.547,-0.319 -0.97,-0.737 -1.269,-1.258 -0.303,-0.521 -0.452,-1.128 -0.452,-1.825 0,-0.872 0.216,-1.638 0.644,-2.301 0.432,-0.659 1.1,-1.177 2.013,-1.551 0.91,-0.372 2.064,-0.559 3.458,-0.559 1.416,0 2.579,0.109 3.484,0.328 0.906,0.219 1.557,0.421 1.957,0.608l0.018 0 0 3.374 -0.061 -0.026 -3.414 0 -0.179 -0.878c-0.478,-0.043 -0.947,-0.066 -1.408,-0.066 -0.489,0 -0.838,0.052 -1.042,0.155 -0.205,0.104 -0.308,0.254 -0.308,0.452 0,0.242 0.106,0.415 0.319,0.519 0.216,0.103 0.573,0.187 1.071,0.247l1.639 0.205z'
            />
            <polygon
                id='8'
                points='120.533,3.239 120.018,3.052 120.018,0.176 126.513,0.176 126.513,3.078 126.032,3.239 126.824,8.983 127.642,3.222 127.161,3.078 127.161,0.176 133.411,0.176 133.411,3.052 132.873,3.239 130.518,12.786 123.021,12.786 '
            />
        </g>
    </svg>
)

export default NurDerHsv
