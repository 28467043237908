import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { football } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    MyTalentLayerDocument,
    TalentDocument, TalentHistoryDocument,
    TalentLatestVoteDocument, TalentPosition,
    useShortVoteMutation, useTalentClassQuery,
    useTalentLatestVoteQuery,
    useTalentQuery,
    VoteType,
} from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import Button from '../../Button'
import Buttons from '../../Buttons'
import Form from '../../Form'
import FormSlider from '../../Form/FormSlider'
import TalentID from '../TalentID'
import type { LayerI } from '../TalentID/Layer'
import type { TalentVoteFormProps } from '../TalentVoteForm'
import { LABEL_TO_VALUE_FACTOR, VALUE_TO_LABEL_ARRAY } from '../TalentVoteForm'

import './style.scss'
import DatePickerButton from '../../Form/DatePickerButton'

const TalentShortVoteForm: React.FC<TalentVoteFormProps> = ({ talent, onVote, networkId }) => {
    const user = useUser()
    const { trackEvent } = useMatomo()

    const [date, setDate] = useState<Date | undefined>()

    const { refetch } = useTalentQuery({
        variables: {
            talentId: talent.id,
            userId: user?.user.id ?? '',
        },
        fetchPolicy: 'network-only',
    })

    const [vote, { loading }] = useShortVoteMutation({
        update: ((cache, { data }) => {
            if (!data?.shortVote) {
                return
            }
            cache.writeQuery({
                query: TalentLatestVoteDocument,
                data: {
                    talentLatestVote: data.shortVote,
                },
                variables: { userId: user?.user.id ?? '', talentId: talent.id },
            })
        }),
        refetchQueries: [
            {
                query: TalentDocument,
                variables: { userId: user?.user.id ?? '', talentId: talent.id },
            }, {
                query: MyTalentLayerDocument,
                variables: {
                    userId: user?.user.id ?? '',
                    talentId: talent?.id ?? '',
                    layerParams: '?layers=self',
                },
            },
            {
                query: MyTalentLayerDocument,
                variables: {
                    userId: user?.user.id ?? '',
                    talentId: talent?.id ?? '',
                    layerParams: '',
                },
            },
            {
                query: TalentHistoryDocument,
                variables: {
                    talentId: talent?.id ?? '',
                },
            },
        ],
        onCompleted: () => {
            setTimeout(() => {
                refetch({
                    talentId: talent.id,
                    userId: user?.user.id ?? '',
                })
            }, 1000)
        },
    })

    const { t } = useTranslation()
    const [present] = useToast()

    const { data: tLVData, loading: tLVLoading } = useTalentLatestVoteQuery({ variables: { type: VoteType.ShortVote, talentId: talent.id } })
    const { talentLatestVote } = tLVData ?? {}

    const [rating, setRating] = useState<number[]>([0,0,0,0,0,0,0,0])

    const zeroCount = rating.reduce((acc, n) => n === 0 ? acc + 1 : acc, 0)

    const latestShortVote = tLVData?.talentLatestVote?.values

    const { data } = useTalentClassQuery({
        variables: {
            id: talent.metas.fieldPosition === TalentPosition.Goal ? '2' : '1',
        },
    })

    const layers = useMemo(() => {

        return data?.talentClass.dataViews.map(dataView => ({
            id: dataView.id,
            backgroundColor: dataView.backgroundColor ?? undefined,
            borderColor: dataView.borderColor ?? undefined,
            textColor: dataView.textColor ?? undefined,
            withHover: dataView.withLabelHover ?? false,
            withGroupLabels: dataView.withGroupLabels ?? false,
            values: talent.dataViews?.find((dv) => dv.key === +dataView.id)?.values ?? [],
            translations: dataView.voteScalarGroups?.[0].scalars.map(scalar => t(scalar.caption)),
            translationKeys: dataView.voteScalarGroups?.[0].scalars.map(scalar => scalar.caption),
            type: dataView.chartType,
        }))
    }, [data?.talentClass.dataViews, t, talent.dataViews])

    const layer: { layer: LayerI; idx: number } | undefined = useMemo(() => {
        if(!layers) return undefined

        const idx = layers.findIndex(l => l.translationKeys?.length === 8)
        if (idx === -1) return undefined
        return {
            idx,
            layer: layers[idx],
        }
    }, [layers])

    useEffect(() => {
        if(layer && latestShortVote) {
            setRating(latestShortVote)
        }
    }, [layer, latestShortVote])

    const doVote = useCallback(async (values: any) => {
        await vote({
            variables: {
                talentId: talent.id,
                input: {
                    values,
                    networkId,
                    date: date?.toISOString(),
                },
            },
        })

        present(t('talent.voteComplete', { name: talent.caption }), 'success')
        onVote?.()
    }, [vote, talent.id, talent.caption, networkId, present, t, onVote, date])

    const getPinLabel = useCallback((value: any) => VALUE_TO_LABEL_ARRAY[+value.toFixed(0)], [])

    const onSubmit = () => {
        if (zeroCount > 1) {
            present(t('talent.voteZeroCount'), 'warning')
            return
        }

        trackEvent({
            action: 'save-short-vote',
            category: MatomoEventCategory.Talent,
        })

        doVote(rating)
    }

    return (
        <Form
            className='talent-vote-form talent-hort-vote-form'
            onSubmit={onSubmit}
        >
            <div
                className='talent-vote-form__wrapper'
            >
                <div
                    className='talent-vote-form__talent-id-wrapper-short'
                >
                    <TalentID
                        dataView1={[{key: +(layer?.layer.id ?? 0), values: rating}]}
                        talentPosition={talent.metas.fieldPosition}
                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                        defaultIndex={0}
                    />
                </div>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => (
                    <div
                        key={value}
                        className='talent-vote-form__slider-wrapper talent-short-vote-form__slider-wrapper'
                    >
                        {tLVData && !tLVLoading && (
                            <FormSlider
                                key={`${(talentLatestVote?.id ?? 0)} ${value}`}
                                onChange={(e) => {
                                    setRating((prev) => {
                                        const next = [...prev]
                                        next[value] = +e.detail.value
                                        return next
                                    })
                                }}
                                color='scout'
                                defaultValue={latestShortVote?.[value]}
                                label={t(layer?.layer.translationKeys?.[value] ?? '')}
                                info={t(`${layer?.layer.translationKeys?.[value]}_tip`)}
                                name={`${value}`}
                                step={1}
                                min={0}
                                max={100}
                                required
                            />
                        )}
                        <h3
                            className='talent-vote-form__value'
                        >
                            {getPinLabel(rating[value] / LABEL_TO_VALUE_FACTOR)}
                        </h3>
                    </div>
                ))}
                <Buttons>
                    <DatePickerButton
                        // @ts-ignore
                        onChange={setDate}
                        value={date}
                        range={false}
                        label={t('talent.voteDate')}
                        color='primary'
                    />
                </Buttons>
                <Buttons>
                    <Button
                        className='talent-vote-form__preview-btn'
                        fill='solid'
                        color='scout'
                        type='submit'
                        loading={loading}
                        icon={football}
                    >
                        {t('talent.vote')}
                    </Button>
                </Buttons>
            </div>
        </Form>
    )
}

export default TalentShortVoteForm
