import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import type { ShareOptions } from '@capacitor/share/dist/esm/definitions'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { share } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import './style.scss'

interface ShareScreenButtonsProps {
    color?: 'light' | 'dark',
    shareProps?: ShareOptions,
}

const ShareScreenButtons: React.FC<ShareScreenButtonsProps> = ({ color= 'light', shareProps }) => {
    const { t } = useTranslation()

    const onShareClick = useCallback(async () => {
        if(shareProps) {
            await Share.share(shareProps)
        }

    }, [shareProps])

    if(!Capacitor.isNativePlatform()) {
        return null
    }

    return (
        <IonGrid
            className='share-screen'
        >
            <IonRow
                className='ion-justify-content-center ion-align-items-center ion-nowrap'
            >
                <IonCol
                    className='share-screen__title'
                >
                    <Button
                        icon={share}
                        color={color}
                        fill='clear'
                        onClick={onShareClick}
                    >
                        {t('buttons.share')}
                    </Button>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ShareScreenButtons
