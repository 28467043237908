import './style.scss'
import clsx from 'clsx'
import { useState } from 'react'

import type { Certificate } from '../../../../lib/apollo/types'

import TalentCardMediumBack from './TalentCardMediumBack'
import TalentCardMediumFront from './TalentCardMediumFront'
import { TalentCardContext } from './_Core/Context'

interface TalentCardMediumProps {
    certificate: Certificate,
    clubImg?: string
    schoolImg?: string
}
const TalentCardMedium: React.FC<TalentCardMediumProps> = ({ certificate, clubImg, schoolImg }) => {
    const [showBack, setShowBack] = useState(false)

    return (
        <TalentCardContext.Provider
            value={{
                certificate,
                clubImg,
                schoolImg,
            }}
        >
            <div
                className={clsx('hsv-talentcard-medium')}
            >
                <TalentCardMediumFront
                    onFlipCardClick={() => { setShowBack(() => true) }}
                    visible={!showBack}
                />
                <TalentCardMediumBack
                    onFlipCardClick={() => { setShowBack(() => false) }}
                    visible={showBack}
                />
            </div>

        </TalentCardContext.Provider>
    )
}

export default TalentCardMedium
