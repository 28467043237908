import {
    IonBackButton,
    IonButtons, IonHeader,
    IonSearchbar,
    IonToolbar,
} from '@ionic/react'
import type {Dispatch, KeyboardEventHandler, SetStateAction} from 'react'
import { useTranslation } from 'react-i18next'

import IS_IOS from '../../../lib/isIOS'

import './style.scss'

interface SearchHeaderProps {
    setSearch: Dispatch<SetStateAction<{ value?: string | null, event: any } | undefined>>
    search?: { value?: string | null, event: any }
    onKeyDown?: KeyboardEventHandler<HTMLIonSearchbarElement>
    defaultHref?: string
    backButtonLabel?: string
}

const SearchHeader: React.FC<SearchHeaderProps> = ({
    backButtonLabel,
    defaultHref = '/',
    onKeyDown,
    search,
    setSearch,
}) => {
    const { t } = useTranslation()

    return (
        <IonHeader
            className='search-header'
        >
            <IonToolbar
                className='search-header__toolbar'
            >
                <IonButtons
                    className='search__left-buttons'
                    slot='start'
                >
                    <IonBackButton
                        text={backButtonLabel ?? (IS_IOS ? t('buttons.back') : undefined)}
                        defaultHref={defaultHref}
                    />
                </IonButtons>

                <IonSearchbar
                    className='search__input'
                    mode='ios'
                    placeholder={t('inputs.search')}
                    value={search?.value}
                    onIonChange={e => {
                        setSearch({ value: e.detail.value, event: e })
                    }}
                    showCancelButton='never'
                    showClearButton='always'
                    debounce={0}
                    enterkeyhint='search'
                    onKeyDown={onKeyDown}
                    autocomplete='on'
                />
            </IonToolbar>
        </IonHeader>
    )
}

export default SearchHeader
