import { IonItem, IonLabel, IonSelect, IonText } from '@ionic/react'
import clsx from 'clsx'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

import CollapsibleContent from '../../Collapsible/CollapsibleContent'

interface FormSelectFieldPropsI {
    label: string
    name: string
    required?: boolean
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export type FormSelectFieldProps = React.ComponentProps<typeof IonSelect> & FormSelectFieldPropsI

const FormSelectField: React.FC<FormSelectFieldProps> = ({
    required,
    name,
    rules,
    label,
    defaultValue,
    ...props
}) => {
    const { control, formState } = useFormContext()
    const error = formState.errors[name]
    const { t } = useTranslation()

    const [focused, setFocused] = useState<boolean>(false)

    const mergedRules = rules ?? {}
    if (required) {
        mergedRules.required = { value: true, message: t('errors.required', { label }) }
    }

    return (
        <div>
            <div
                className={clsx('form-input', { 'form-input--focused': focused })}
            >
                <IonItem
                    lines='none'
                    className='form-input__item'
                >
                    <IonLabel
                        position='floating'
                        className={clsx('form-input__label', { 'error': !!error })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>
                    <Controller
                        control={control}
                        name={name}
                        rules={mergedRules}
                        defaultValue={defaultValue}
                        render={({ field }) => {
                            return (
                                <IonSelect
                                    value={field.value}
                                    cancelText={t('buttons.cancel')}
                                    onIonChange={e => {
                                        field.onChange(e)
                                        setFocused(false)
                                        field.onBlur()
                                    }}
                                    onClick={() => {
                                        setFocused(true)
                                    }}
                                    onIonCancel={() => {
                                        setFocused(false)
                                        field.onBlur()
                                    }}
                                    {...props}
                                />
                            )
                        }}
                    />
                </IonItem>
            </div>
            <CollapsibleContent
                className='form-input__error-container'
                open={!!error}
            >
                <IonText
                    className='form-input__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message as string}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default FormSelectField
