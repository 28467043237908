import { IonCard, IonCardContent, IonCardTitle, IonText } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'
import CollapsibleContent from '../../components/Collapsible/CollapsibleContent'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormTextField from '../../components/Form/FormTextField'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import { useDoimailMutation, useRecoverUsernameMutation, useRequestPasswordChangeMutation } from '../../lib/apollo/types'
import useToast from '../../providers/Toast/hooks'

const ForgotPasswordOrName: React.FC = () => {
    const { t } = useTranslation()

    const [recoverUsername, { data: recoverData, loading: recoverLoading, reset }] = useRecoverUsernameMutation()
    const [requestPasswordChange] = useRequestPasswordChangeMutation()
    const [doimail] = useDoimailMutation()
    const [present] = useToast()

    const onRecoverUsernameSubmit = async ({ email }: any) => {
        const response = await recoverUsername({
            variables: {
                input: {
                    email,
                },
            },
            onError: () => {
                present(t('login.userNotFound'), 'danger')
                reset()
            },
        })

        if (!response.data?.recoverUsername) {
            present(t('login.userNotFound'), 'danger')
        }
    }

    const onRequestPasswordChangeSubmit = async (values: any) => {
        if (values.password !== values.password2) {
            present(t('login.differentPasswords'), 'danger')
        }

        try {
            const response = await requestPasswordChange({
                variables: {
                    input: {
                        name: values.name,
                        password: values.password,
                    },
                },
            })

            if (!response.data?.requestPasswordChange) {
                present(t('login.userNotFound'), 'danger')
                return
            }

            await doimail({
                variables: {
                    input: {
                        token: response.data?.requestPasswordChange?.token ?? '',
                        email: response.data?.requestPasswordChange?.user.email ?? '',
                        subject: t('login.requestPasswordChange') ?? '',
                        name: `${response.data?.requestPasswordChange?.user.firstName} ${response.data?.requestPasswordChange?.user.lastName}`,
                    },
                },
            })

            present(t('login.requestPasswordChangeSuccess'), 'success')
        } catch (e: any) {
            if (e.networkError.statusCode === 409) {
                present(t('login.isOldPassword'), 'danger')
            } else {
                present(e.networkError.result.message ?? e.message, 'danger')
            }
        }
    }

    return (
        <Page
            trackingTitle='Forgot Password'
        >
            <Header
                title={t('title.login')}
            />
            <Content
                fullscreen
            >
                <h2>
                    {t('login.forgotUsername')}
                </h2>
                <IonCard>
                    <IonCardContent>
                        <Form
                            onSubmit={onRecoverUsernameSubmit}
                        >
                            <ItemList
                                className='ion-margin-bottom'
                            >
                                <FormTextField
                                    required
                                    type='email'
                                    name='email'
                                    label={t('email')}
                                />
                            </ItemList>
                            <Button
                                loading={recoverLoading}
                                className='ion-margin-top ion-margin-bottom'
                                icon={checkmark}
                                type='submit'
                                color='secondary'
                            >
                                {t('buttons.request')}
                            </Button>
                        </Form>
                        <CollapsibleContent
                            open={!!recoverData?.recoverUsername}
                        >
                            <div
                                className='ion-margin-top'
                            >
                                <div
                                    className='ion-margin-bottom'
                                >
                                    <IonText
                                        color='dark'
                                    >
                                        <h1>
                                            {t('login.userNames')}
                                        </h1>
                                    </IonText>
                                </div>
                                {recoverData?.recoverUsername?.collection.map(user => (
                                    <IonText
                                        key={user.name}
                                        color='dark'
                                    >
                                        <IonCardTitle>
                                            {user.name}
                                        </IonCardTitle>
                                    </IonText>
                                )) ?? null}
                            </div>
                        </CollapsibleContent>
                    </IonCardContent>
                </IonCard>

                <h2>
                    {t('login.requestPasswordChange')}
                </h2>
                <IonCard>
                    <IonCardContent>
                        <Form
                            onSubmit={onRequestPasswordChangeSubmit}
                        >
                            <ItemList
                                className='ion-margin-bottom'
                            >
                                <FormTextField
                                    required
                                    name='name'
                                    label={t('name')}
                                />
                                <FormTextField
                                    required
                                    type='password'
                                    name='password'
                                    label={t('password1')}
                                />
                                <FormTextField
                                    required
                                    type='password'
                                    name='password2'
                                    label={t('password2')}
                                />
                            </ItemList>
                            <Button
                                className='ion-margin-top'
                                icon={checkmark}
                                type='submit'
                                color='secondary'
                            >
                                {t('buttons.request')}
                            </Button>
                        </Form>
                    </IonCardContent>
                </IonCard>
            </Content>
        </Page>
    )
}

export default ForgotPasswordOrName
