import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import { TalentPosition } from '../../../../lib/apollo/types'
import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import TalentID from '../../../Talent/TalentID'

export type HelpSliderModalProps =
    { open?: boolean, setOpen?: React.Dispatch<React.SetStateAction<boolean>> }

const TalentIdHelp: React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()

    return (
        <OnboardingModal
            id='talentId-page'
            open={open}
            setOpen={setOpen}
        >
            <SwiperSlide>
                <h1>
                    {t('talentId.title')}
                </h1>
                <div>
                    {t('talentId.onboarding.1')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div
                    className='ion-margin-bottom'
                >
                    <TalentID
                        className='talent-card-medium__talent-id'
                        dataView1={[
                            {
                                key: 0,
                                values: [41, 61, 41, 61, 61, 61, 71, 81, 51, 41, 50, 61, 61, 71, 81, 61, 51, 45, 59, 71, 81, 91, 71, 61, 81, 51, 61, 61, 61, 61, 61, 61],
                            },
                            {
                                key: 1,
                                values: [40, 56, 58, 43, 57],
                            },
                            {
                                key: 2,
                                values: [40, 56, 58, 50],
                            },
                        ]}
                        defaultIndex={0}
                        talentPosition={TalentPosition.Midfield}
                        backgroundColor='secondary'
                        color1='primary'
                        under16={false}
                        interactive
                    />
                </div>
                <div>
                    {t('talentId.onboarding.2')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div
                    className='ion-margin-bottom'
                >
                    <TalentID
                        className='talent-card-medium__talent-id'
                        dataView1={[
                            {
                                key: 0,
                                values: [41, 61, 41, 61, 61, 61, 71, 81, 51, 41, 50, 61, 61, 71, 81, 61, 51, 45, 59, 71, 81, 91, 71, 61, 81, 51, 61, 61, 61, 61, 61, 61],
                            },
                            {
                                key: 1,
                                values: [40, 56, 58, 43, 57],
                            },
                            {
                                key: 2,
                                values: [40, 56, 58, 50],
                            },
                        ]}
                        defaultIndex={0}
                        talentPosition={TalentPosition.Midfield}
                        backgroundColor='secondary'
                        color1='primary'
                        under16={false}
                        interactive
                    />
                </div>
                <div>
                    {t('talentId.onboarding.3')}
                </div>
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default TalentIdHelp
