import { searchOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import background from '../../../assets/img/marketplace-teaser.png'
import TeaserCard from '../../../components/TeaserCard'

const MarketplaceTeaserCard: React.FC = () => {
    const { t } = useTranslation()
    return (
        <TeaserCard
            darkColor
            img={background}
            imgAlt='marketplace'
            title={t('dashboard.marketplace.title')}
            subtitle={t('dashboard.marketplace.subtitle')}
            routerLink='/marketplace'
            buttonText={t('dashboard.marketplace.button')}
            buttonIcon={searchOutline}
        />
    )
}

export default MarketplaceTeaserCard
