import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import type { ServerError } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Preferences } from '@capacitor/preferences'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

import { signOut } from '../../providers/Auth/hooks'
import STORAGE_KEYS from '../storageKeys'

import { API_TOKEN, GRAPHQL_URL } from './config'
import handleCollection from './helper/handleCollection'
import { PermissionLevel } from './types'

const { link, useApolloNetworkStatus: uANS } = createNetworkStatusNotifier()

export const useApolloNetworkStatus = uANS

const errorLink = onError((error) => {
    // eslint-disable-next-line no-console
    console.log({ error })

    if(window.location.href.includes('/s/')) {
        return
    }

    error.graphQLErrors?.forEach(e => {
        if((e.extensions?.response as any)?.statusCode === 401 || e.extensions?.code === 'UNAUTHENTICATED') {
            signOut()
        }
    })

    if ((error.networkError as ServerError)?.statusCode === 401) {
        signOut()
    }
})

const authLink = setContext(async (req, { headers }) => {
    const { value: token } = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
    const { value: apiToken } = await Preferences.get({ key: STORAGE_KEYS.API.API_TOKEN })
    const additionalHeaders: any = {}
    if (apiToken) {
        additionalHeaders.authorization = `Bearer ${apiToken}`
    }
    return {
        headers: {
            ...headers,
            ...additionalHeaders,
            'X-Authorization': `EMOTIONUM apikey="${API_TOKEN}", user="${token ?? ''}"`,
        },
    }
})

const graphqlLink = new HttpLink({
    uri: GRAPHQL_URL,
})

// Set up your client
const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    activities: handleCollection(),
                    playlists: handleCollection(),
                    playlistTalents: handleCollection(),
                    networks: handleCollection(),
                    listMyTalents: handleCollection(),
                    similarNetwork: handleCollection(),
                    networkUsers: handleCollection(),
                    networkTalents: handleCollection(),
                },
            },
            UserMetaData: {
                keyFields: ['username'],
            },
            Talent: {
                fields: {
                    permissionLevel: {
                        merge(existing, incoming) {
                            if(existing && incoming === PermissionLevel.None) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    dataViews: {
                        merge(existing, incoming) {
                            if(existing?.length > incoming?.length) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    publicRating: {
                        merge(existing, incoming) {
                            if(incoming.length === 0) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    rateCount: {
                        merge(existing, incoming) {
                            if(!incoming) {
                                return existing
                            }
                            return incoming
                        },
                    },
                },
            },
            Network: {
                fields: {
                    networkRoles: {
                        merge(existing, incoming) {
                            if(!incoming || incoming.length === 0 || existing?.length > incoming?.length) {
                                return existing
                            }
                            return incoming
                        },
                    },
                },
            },
        },
    }),
    link: ApolloLink.from([
        errorLink,
        link,
        authLink,
        // restLink,
        graphqlLink,
    ]),
    uri: GRAPHQL_URL,
})

export default apolloClient
