import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Content from '../../components/Content'
import Header from '../../components/Header'
import NetworkHelp from '../../components/HelpSlider/Help/Network'
import Page from '../../components/Page'
import Segment from '../../components/Segment'
import { useNetworkQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import NetworkContextMenu from './NetworkContextMenu'
import NetworkDetails from './NetworkDetails'
import NetworkTalents from './NetworkTalents'
import NetworkUsers from './NetworkUsers'

import './style.scss'

interface NetworkParams {
    networkId: string
}

const slideOptions = {
    spaceBetween: 16,
}

const Network: React.FC = () => {
    const { t } = useTranslation()
    const { params: { networkId } } = useRouteMatch<NetworkParams>()
    const user = useUser()
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [tab, setTab] = useState<string>('0')
    const onSlideWillChange = useCallback((e: SwiperClass) => {
        setTab(e.activeIndex.toString())
    }, [setTab])
    const { data } = useNetworkQuery({ variables: { networkId, userId: user?.user.id ?? '' }, skip: !user })

    if (!data?.network) {
        return null
    }

    return (
        <Page
            className='network-page'
            trackingTitle='network'
        >
            <Header
                title={data.network?.caption}
            >
                <NetworkContextMenu
                    network={data.network}
                />
            </Header>
            <Content
                fullscreen
            >
                <NetworkHelp />
                <div>

                    <Segment
                        scrollable
                        value={tab}
                        onIonChange={(e) => {
                            if (e.detail.value) {
                                controlledSwiper?.slideTo(+e.detail.value)
                            }
                        }}
                        tabs={['details', 'talents', 'users'].map(v => ({ tab: t(`networks.${v}`) }))}
                    />
                    <Swiper
                        {...slideOptions}
                        modules={[Controller]}
                        onSwiper={setControlledSwiper}
                        onSlideChange={onSlideWillChange}
                    >
                        <SwiperSlide>
                            <NetworkDetails
                                network={data.network}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <NetworkTalents
                                network={data.network}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <NetworkUsers
                                network={data.network}
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </Content>
        </Page>
    )
}

export default Network
