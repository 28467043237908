import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import CompareWith from '../../components/Comparison/CompareWith'
import Content from '../../components/Content'
import Header from '../../components/Header'
import Page from '../../components/Page'

type RouteMatch = {
    talentId1: string,
}

const ComparisonSelectionPage: React.FC = () => {
    const { t } = useTranslation()
    const {
        params: {
            talentId1,
        },
    } = useRouteMatch<RouteMatch>()

    return (
        <Page
            trackingTitle='comparison selection'
        >
            <Header
                buttonLeft='menu'
                title={t('title.comparison')}
            />
            <Content
                fullscreen
            >
                <CompareWith
                    talentId={talentId1}
                />
            </Content>
        </Page>
    )
}

export default ComparisonSelectionPage
