import {
    IonAccordionGroup,
    IonGrid,
    IonRow,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import Content from '../../components/Content'
import Header from '../../components/Header'
import Page from '../../components/Page'

import DeleteAccount from './DeleteAccount'
import LanguageSettings from './LanguageSettings'
import PasswordSettings from './PasswordSettings'

import './style.scss'

const Settings: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Page
            trackingTitle='settings'
            className='settings'
        >
            <Header
                title={t('title.settings')}
            />
            <Content
                fullscreen
            >
                <IonGrid>
                    <IonRow>
                        <IonAccordionGroup
                            defaultValue='language'
                        >
                            <LanguageSettings />
                            <PasswordSettings />
                            <DeleteAccount />
                        </IonAccordionGroup>
                    </IonRow>
                </IonGrid>
            </Content>
        </Page>
    )
}

export default Settings
