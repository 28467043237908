import './style.scss'
import { Trans, useTranslation } from 'react-i18next'
import CertificateTalentCardMedium from './CertificateTalentCardMedium'
import getRblSignature from './getRblSignature'
import { QRCode } from 'react-qrcode-logo'
import {CertificateProps} from '../index'

const CLUB_IMAGE = '/assets/rbl/logo.svg'
const SCHOOL_IMAGE = '/assets/rbl/logo.svg'

const getKey = (vote: number) => {
    if (vote < 24) return '5'
    if (vote < 44) return '4'
    if (vote < 51) return '3'
    if (vote < 64) return '3+'
    if (vote < 84) return '2'
    return '1'
}

const RBLCertificate = (props: CertificateProps) => {
  const { t } = useTranslation()
  const position = props.certificate.talentClass === 2 ? 'goalie.' : ''
  return (
        <>
            <div className='rbl-certificate-wrapper' id='certificate-page-1'>
                <div className="certificate">
                    <div className='certificate__upper'>
                        <div className='certificate__upper__logo hide-big'>
                            <div>
                                <h1>
                                    {t('rbl.certificate.title')}
                                </h1>
                            </div>
                            <div>
                                <img src="/assets/rbl/schule_logo.png" alt='RBL Logo'
                                     className='certificate__texts__logo'/>
                            </div>
                        </div>
                        <div className='hide-big'>
                            <span className='certificate__texts__saison'>Saison 23 / 24</span>
                        </div>
                        <div className="certificate__talent-card-wrapper">
                            <CertificateTalentCardMedium
                                certificate={props.certificate}
                                clubImg={CLUB_IMAGE}
                                schoolImg={SCHOOL_IMAGE}
                            />
                        </div>
                        <div className="certificate__texts n2br">
                            <h1 className="hide-small">
                                {t('rbl.certificate.title')}
                            </h1>
                            <span className='certificate__texts__saison hide-small'>Saison 2023 / 24</span>
                            <h2>
                                {props.certificate.caption}
                            </h2>
                            <p>
                                <Trans
                                    i18nKey='rbl.certificate.thankYou'
                                >
                                    certificate.thankYou
                                    <a href="https://rbl.joinmytalent.com">
                                        https://rbl.joinmytalent.com
                                    </a>
                                </Trans>
                            </p>
                            <div className='certificate__texts__signature'>
                                <div className='certificate__texts__signature__date'>
                                    <div className='certificate__texts__signature__date__date'>
                                        {getRblSignature(props.certificate, t)}
                                    </div>
                                    <div className='certificate__texts__signature__date__footer'>
                                        {t('rbl.cert.signatureFooter.date')}
                                    </div>
                                </div>
                                <div className='certificate__texts__signature__signature'>
                                    <div className='certificate__texts__signature__signature__svg'>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 2302.000000 1059.000000"
                                             preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,1059.000000) scale(0.100000,-0.100000)"
                                               fill="#000000" stroke="none">
                                                <path shapeRendering="geometricPrecision" d="M8475 10569 c-555 -34 -896 -81 -1273 -174 -528 -131 -986 -353
                                                -1362 -659 -348 -283 -531 -567 -596 -921 -25 -140 -31 -532 -10 -715 32 -281
                                                89 -508 203 -810 85 -227 280 -716 377 -947 93 -221 96 -236 65 -256 -13 -9
                                                -125 -34 -249 -57 -524 -97 -899 -183 -2630 -606 -395 -96 -711 -185 -1050
                                                -296 -178 -58 -934 -331 -1150 -415 -276 -108 -697 -340 -758 -418 -51 -65
                                                -52 -144 -3 -231 44 -75 88 -100 268 -150 245 -69 250 -69 929 -56 795 16
                                                1177 36 1599 83 374 41 543 70 1140 194 149 31 457 90 685 131 1075 193 1415
                                                264 1813 373 172 48 215 51 251 18 36 -32 531 -857 703 -1172 115 -210 193
                                                -368 193 -392 0 -26 -18 -36 -285 -153 -195 -86 -422 -194 -940 -447 -269
                                                -132 -551 -268 -625 -303 -209 -97 -819 -403 -1085 -545 -359 -191 -533 -286
                                                -1180 -645 -542 -301 -956 -528 -1259 -691 -86 -46 -125 -73 -142 -99 -40 -58
                                                -28 -125 31 -180 40 -38 100 -33 168 12 28 20 117 71 197 114 233 124 493 269
                                                1005 559 963 546 1056 595 1875 1002 1381 686 2220 1079 2325 1090 47 5 55 3
                                                83 -23 17 -16 56 -77 86 -136 127 -249 331 -481 460 -524 94 -32 189 7 229 94
                                                21 45 22 60 21 312 0 146 -6 308 -13 360 -7 52 -15 131 -18 176 -5 79 -5 82
                                                23 112 19 20 66 46 139 76 61 24 209 88 330 141 256 113 291 127 820 332 1133
                                                438 1662 620 2275 782 187 49 641 152 695 158 l50 5 -115 -77 c-232 -154 -552
                                                -340 -1095 -635 -500 -272 -748 -412 -1590 -899 -440 -254 -475 -280 -475
                                                -352 0 -90 56 -149 143 -149 39 0 69 11 170 63 161 84 555 311 1132 652 767
                                                453 1055 613 1470 816 317 154 512 238 845 364 318 121 612 227 616 223 1 -2
                                                -99 -59 -224 -127 -571 -313 -819 -484 -850 -588 -34 -115 20 -231 125 -267
                                                55 -19 343 -22 439 -6 101 18 1323 415 1694 550 102 37 460 172 795 300 1331
                                                506 1956 729 2654 946 625 194 1050 303 1506 388 201 38 526 89 531 84 3 -4
                                                -172 -105 -531 -308 -538 -303 -656 -400 -672 -555 -21 -198 306 -423 652
                                                -450 62 -5 145 2 360 29 154 20 557 69 895 111 338 41 752 93 920 115 168 21
                                                351 44 407 49 138 14 205 41 270 108 29 30 53 58 53 62 0 5 -25 23 -55 40 -67
                                                38 -57 38 -433 6 -163 -14 -463 -39 -667 -55 -631 -52 -1004 -96 -1413 -165
                                                -301 -51 -397 -45 -553 36 -84 44 -129 80 -129 103 0 36 193 164 565 376 61
                                                35 184 107 275 160 91 54 198 117 238 140 94 53 136 91 161 144 52 107 -3 252
                                                -119 312 -65 33 -179 30 -420 -11 -928 -157 -1778 -388 -2850 -775 -140 -51
                                                -442 -156 -670 -235 -556 -191 -720 -251 -1082 -393 -437 -173 -816 -309
                                                -1428 -513 -724 -241 -995 -326 -1060 -334 l-55 -6 130 86 c72 47 220 138 330
                                                202 512 297 675 397 841 520 53 40 110 71 169 93 105 40 160 86 170 144 9 47
                                                -7 105 -41 146 -63 74 -144 81 -299 26 -55 -19 -165 -54 -245 -76 -353 -101
                                                -682 -210 -985 -326 -12 -5 -15 2 -15 39 0 82 -33 135 -105 168 -57 26 -168
                                                24 -353 -6 -442 -73 -877 -193 -1752 -484 -788 -263 -1495 -531 -2298 -870
                                                -145 -61 -273 -112 -284 -114 -31 -3 -35 27 -42 353 -7 335 -21 626 -41 909
                                                -24 328 -20 358 53 399 20 11 264 84 542 162 278 78 811 229 1185 337 1553
                                                446 1484 425 1690 523 222 105 365 215 395 304 23 72 1 147 -72 238 -12 15
                                                -20 30 -17 32 5 5 439 58 709 85 531 55 1021 92 1515 116 477 23 767 40 1670
                                                100 1203 80 1467 93 2335 120 1366 42 1779 67 1995 119 101 25 293 90 308 105
                                                11 11 -82 77 -151 109 -80 36 -140 37 -437 3 -351 -39 -493 -48 -940 -61
                                                -1002 -29 -2362 -76 -3030 -105 -1279 -56 -2710 -165 -3840 -292 -196 -22
                                                -287 -27 -495 -27 -277 -1 -272 -2 -595 69 -547 120 -864 172 -1275 210 -452
                                                42 -827 140 -1123 292 -108 56 -120 73 -75 106 89 67 375 196 834 376 832 325
                                                1268 547 1684 854 234 173 343 285 411 422 150 307 -18 591 -496 842 -372 194
                                                -811 296 -1570 364 -155 14 -1048 20 -1230 8z m1190 -278 c875 -65 1279 -172
                                                1685 -444 201 -136 254 -255 178 -401 -65 -123 -309 -339 -560 -495 -237 -148
                                                -400 -224 -1218 -566 -333 -139 -668 -281 -745 -315 -77 -34 -174 -75 -215
                                                -92 -192 -80 -271 -176 -257 -313 16 -154 105 -240 348 -335 383 -149 794
                                                -237 1491 -320 334 -40 533 -79 605 -119 16 -8 -2482 -384 -2523 -379 l-29 3
                                                -5 145 c-4 121 -8 151 -25 179 -31 53 -69 76 -126 76 -41 0 -53 -5 -79 -31
                                                -28 -27 -32 -41 -45 -135 -32 -225 -45 -262 -108 -289 -24 -11 -238 -51 -501
                                                -95 -252 -42 -621 -105 -820 -141 -198 -35 -383 -64 -411 -64 -65 1 -123 28
                                                -164 76 -62 74 -235 454 -351 769 -156 428 -301 1007 -330 1320 -18 193 10
                                                417 73 586 142 376 437 668 942 930 524 273 1118 408 2010 458 204 12 984 6
                                                1180 -8z m2196 -3615 c67 -15 132 -52 174 -96 l30 -32 -24 -17 c-13 -9 -78
                                                -46 -145 -84 -100 -56 -160 -80 -351 -142 -609 -197 -1449 -440 -2575 -745
                                                -397 -108 -477 -123 -499 -97 -8 10 -16 69 -21 164 -5 81 -23 235 -40 341 -32
                                                202 -31 220 10 242 11 6 242 42 512 81 643 91 940 137 1343 210 422 76 643
                                                113 830 139 338 47 650 62 756 36z m-3760 -522 c12 -15 88 -645 89 -741 0 -72
                                                -20 -81 -401 -177 -184 -46 -454 -116 -601 -155 -163 -43 -287 -71 -318 -71
                                                -45 0 -55 4 -90 38 -21 20 -70 91 -108 157 -37 66 -105 184 -150 262 -100 172
                                                -161 297 -168 345 -5 31 -3 38 19 53 16 10 181 42 464 90 241 41 607 106 813
                                                144 206 38 389 69 406 70 18 1 37 -6 45 -15z m-2067 -383 c48 -30 94 -98 221
                                                -326 65 -115 155 -274 201 -352 46 -78 84 -147 84 -152 0 -18 -57 -36 -306
                                                -99 -369 -94 -677 -157 -1252 -257 -446 -78 -484 -85 -987 -190 -651 -136
                                                -848 -168 -1225 -199 -355 -30 -553 -38 -655 -26 -297 33 -814 31 -1382 -5
                                                -237 -15 -269 -14 -325 7 -34 13 -37 46 -9 72 11 10 89 46 173 81 84 34 225
                                                93 313 129 561 235 1375 501 2152 705 490 129 1052 257 2148 490 336 71 619
                                                131 630 134 11 3 58 5 104 6 67 1 90 -3 115 -18z m2156 -734 c0 -2 18 -273 40
                                                -603 44 -660 49 -869 24 -955 -41 -140 -160 -249 -304 -280 -78 -17 -94 -9
                                                -126 63 -128 282 -228 460 -513 916 -237 379 -344 563 -334 573 7 7 317 84
                                                998 249 141 34 215 47 215 37z m97 -1999 c4 -7 11 -112 15 -233 4 -121 10
                                                -244 14 -272 6 -50 5 -53 -16 -53 -12 0 -31 10 -42 23 -33 37 -248 398 -248
                                                416 0 26 110 100 180 122 40 12 88 10 97 -3z"/>
                                            </g>
                                        </svg>

                                    </div>
                                    <div className='certificate__texts__signature__signature__footer'>
                                        {t('rbl.cert.signatureFooter.signature')}
                                    </div>
                                </div>
                            </div>
                            <img src="/assets/rbl/schule_logo.png" alt='RBL Logo' className='certificate__texts__logo hide-small'/>
                        </div>
                    </div>
                    <div className="certificate page-2 hide-big">
                        <h1>Deine Skills</h1>
                        <div className='certificate__values'>
                            <div>
                                {[0, 1, 2, 3].map((i) => (
                                    <div className='certificate__values__block' key={i}>
                                        <h5>{t(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                        <div className='certificate__values__block__description'>
                                            <p>
                                                {t(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {[4, 5, 6, 7].map((i) => (
                                    <div className='certificate__values__block' key={i}>
                                        <h5>{t(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                        <div className='certificate__values__block__description'>
                                            <p>
                                                {t(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='certificate__footer'>
                        <div className='certificate__footer__logo'>
                            <img src="/assets/rbl/die_roten_bullen.png" alt='rote bullen'/>
                        </div>
                        <div className='certificate__footer__text'>
                            <p>
                                {t('rbl.certificate.footer')}
                            </p>
                        </div>
                        <div className='certificate__footer__qr'>
                            <QRCode
                                value="https://rbl.joinmytalent.com"
                                fgColor='#001f47'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rbl-certificate-wrapper' id='certificate-page-2'>
                <div className="certificate page-2 hide-small">
                    <h1>Deine Skills</h1>
                    <div className='certificate__values'>
                        <div className="certificate__values__col">
                            {[0, 1, 2, 3].map((i) => (
                                <div className='certificate__values__col__block' key={i}>
                                    <h5>{t(`rbl.i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                    <div className='certificate__values__block__description'>
                                        <p>
                                            {t(`rbl.certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="certificate__values__col">
                            {[4, 5, 6, 7].map((i) => (
                                <div className='certificate__values__col__block' key={i}>
                                    <h5>{t(`rbl.i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                    <div className='certificate__values__block__description'>
                                        <p>
                                            {t(`rbl.certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='certificate__footer'>
                        <div className='certificate__footer__logo'>
                            <img src="/assets/rbl/die_roten_bullen.png" alt='rote bullen'/>
                        </div>
                        <div className='certificate__footer__text'>
                            <p>
                                {t('rbl.certificate.footer')}
                            </p>
                        </div>
                        <div className='certificate__footer__qr'>
                            <QRCode
                                value="https://rbl.joinmytalent.com"
                                fgColor='#001f47'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default RBLCertificate
