import './style.scss'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { InAppBrowser } from '@capgo/inappbrowser'
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonImg, IonLoading,
    IonRouterContext,
    IonRow,
    IonText,
    useIonRouter,
} from '@ionic/react'
import { footballOutline, help, logInOutline, person } from 'ionicons/icons'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormCheckbox from '../../components/Form/FormCheckbox'
import FormTextField from '../../components/Form/FormTextField'
import Header from '../../components/Header'
import HsvRaute from '../../components/Icon/HsvRaute'
import Page from '../../components/Page'
import { ENV_NAME, CLIENT_ID, HSV_LOGIN_URL, REDIRECT_URI } from '../../lib/apollo/config'
import STORAGE_KEYS from '../../lib/storageKeys'
import { useSignIn, useSignInWithCode, useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

const REDIRECT_ENCODED_URI = encodeURI(REDIRECT_URI)

const LoginURL = `${HSV_LOGIN_URL}/oauth/authorize?redirect_uri=${REDIRECT_ENCODED_URI}&client_id=${CLIENT_ID}&response_type=token&scope=openid+profile+email`

const Login: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const [showToast] = useToast()

    const [loggingIn, setLoggingIn] = useState(false)
    const signin = useSignIn({
        onError: () => {
            setLoggingIn(false)
            showToast(t('login.failed'), 'danger')
        },
    })

    const history = useHistory<{ referrer?: string }>()
    const { push } = useIonRouter()
    const ionRouterContext = useContext(IonRouterContext)
    const queryParams = new URLSearchParams(window.location.search)
    const loginhack123 = queryParams.get('loginhack123')
    const [username, setUsername] = useState<string | null>()
    // const biometricAuth = useBiometricAuth()
    // const [backdrop, setBackdrop] = useState(false)

    // useEffect(() => {
    //     if (!biometricAuth.enabled) {
    //         return
    //     }
    //
    //     setBackdrop(true)
    //     biometricAuth.getCredentials().then((credentials: Credentials | null) => {
    //         setBackdrop(false)
    //         if (credentials) {
    //             signin(credentials.username, credentials.password, false)
    //         }
    //     })
    // }, [setBackdrop, biometricAuth.available])

    useEffect(() => {
        const setUserName = async () => {
            const uname = await Preferences.get({ key: STORAGE_KEYS.LOGIN.LAST_USERNAME })
            setUsername(uname.value)
        }

        setUserName()
    }, [])

    useEffect(() => {
        if (user?.user && ionRouterContext.routeInfo.pathname === '/login') {
            console.log('redirecting to', history.location.state?.referrer ?? '/')
            ionRouterContext.push(history.location.state?.referrer ?? '/', 'root')
        }
    }, [user, ionRouterContext.routeInfo.pathname, ionRouterContext, history.location.state?.referrer])

    const [token, setToken] = useState<string>()
    const { hash } = useLocation()

    const onSubmit = useCallback(({ name, password, permanent }: {name: string, password: string, permanent: boolean}) => {
        setLoggingIn(true)
        try {
            Preferences.set({ key: STORAGE_KEYS.LOGIN.LAST_USERNAME, value: name })
            signin(name, password, permanent)
        } catch (e) {
            setLoggingIn(false)
        }
    }, [signin, setLoggingIn])

    const signinWithCode = useSignInWithCode()

    useEffect(() => {
        const tkn = new URLSearchParams(hash?.substring(1)).get('access_token')
        if (tkn) {
            setToken(tkn)
        } else {
            setToken('')
        }
    }, [hash, setToken])

    useEffect(() => {
        if(token) {
            signinWithCode(token).then(() => {
                push('/home', undefined,  'replace')
            })
        }
    }, [push, signinWithCode, token])

    return (
        <Page
            trackingTitle='login'
        >
            {/* {backdrop && ( */}
            {/*    <IonBackdrop */}
            {/*        tappable={false} */}
            {/*    /> */}
            {/* )} */}
            <Header
                title={t('title.login')}
                buttonLeft='none'
            />
            <Content
                fullscreen
            >
                {t('jmt.maintenance.status') === 'true' ? (
                    <IonGrid>
                        <IonRow>
                            <IonCol
                                className='ion-text-center'
                            >
                                <h2>{t('jmt.maintenance.headline')}</h2>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonText>
                                <p
                                    className='n2br ion-margin-top'
                                >
                                    {t('jmt.maintenance.text')}
                                </p>
                            </IonText>
                        </IonRow>
                    </IonGrid>
                ) :
                    (
                        <>
                            <IonLoading
                                isOpen={!!token}
                                message={t('login.loading')}
                            />
                            <IonGrid
                                className='login__grid'
                            >
                                <IonRow>
                                    <IonCol>
                                        <IonCard
                                            className='login-card ion-margin-bottom'
                                        >
                                            <IonImg
                                                className='login-card__image'
                                                src='/assets/icon/favicon.png'
                                            />
                                            <IonCardContent>
                                                {(ENV_NAME !== 'sandbox' || loginhack123 !== null) ? (
                                                    <Form
                                                        onSubmit={onSubmit}
                                                    >
                                                        {typeof username !== 'undefined' && (
                                                            <FormTextField
                                                                className='id__login-name'
                                                                required
                                                                name='name'
                                                                label={t('login.username')}
                                                                defaultValue={username}
                                                            />
                                                        )}
                                                        <FormTextField
                                                            required
                                                            className='id__login-password'
                                                            name='password'
                                                            label={t('login.password')}
                                                            type='password'
                                                        />
                                                        <FormCheckbox
                                                            defaultValue
                                                            className='id__login-permanent'
                                                            name='permanent'
                                                            label={t('login.permanent')}
                                                        />
                                                        <div
                                                            className='login-card__button-bar'
                                                        >
                                                            <Button
                                                                loading={loggingIn}
                                                                fill='solid'
                                                                color='secondary'
                                                                size='default'
                                                                type='submit'
                                                                slot='end'
                                                                className='id__login-button'
                                                                icon={logInOutline}
                                                            >
                                                                {t('login.login')}
                                                            </Button>
                                                            <Button
                                                                color='primary'
                                                                size='default'
                                                                slot='start'
                                                                fill='outline'
                                                                className='id__register-button'
                                                                icon={footballOutline}
                                                                routerLink='/register'
                                                            >
                                                                {t('login.register')}
                                                            </Button>
                                                            <Button
                                                                color='transparent'
                                                                size='default'
                                                                slot='start'
                                                                fill='clear'
                                                                className='id__register-button'
                                                                icon={help}
                                                                routerLink='/forgotpassword'
                                                            >
                                                                {t('login.help')}
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                ) : (
                                                    <>
                                                        <div
                                                            className='login-card__intro-text'
                                                        >
                                                            {t('sandbox.login.intro')}
                                                        </div>
                                                        <div
                                                            className='login-card__button-bar'
                                                        >
                                                            <Button
                                                                loading={loggingIn}
                                                                fill='solid'
                                                                color='secondary'
                                                                size='default'
                                                                slot='end'
                                                                className='id__login-button'
                                                                icon={person}
                                                                onClick={() =>
                                                                    onSubmit({
                                                                        name: 'jmtTrainer',
                                                                        password: 'eiThoomipah9',
                                                                        permanent: false,
                                                                    })}
                                                            >
                                                                {`Markus (${t('user.userType.trainer')})`}
                                                            </Button>
                                                            <Button
                                                                loading={loggingIn}
                                                                color='secondary'
                                                                size='default'
                                                                slot='start'
                                                                className='id__register-button'
                                                                icon={footballOutline}
                                                                onClick={() => {
                                                                    onSubmit({
                                                                        name: 'user1001571',
                                                                        password: 'eiThoomipah9',
                                                                        permanent: false,
                                                                    })
                                                                    setTimeout(() => {
                                                                        push('/profile/network/undefined/talents/1104/details')
                                                                    }, 350)
                                                                }}
                                                            >
                                                                {`Luca (${t('user.userType.player')})`}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {(ENV_NAME !== 'sandbox') && (
                                    <IonRow
                                        className='ion-margin-top ion-padding-top'
                                    >
                                        <IonCol>
                                            <IonCard
                                                className='login-card'
                                            >
                                                <IonCardContent>
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol
                                                                size='6'
                                                                sizeMd='3'
                                                                offsetMd='0'
                                                                offset='3'
                                                            >
                                                                <div
                                                                    className='login-card__hsvraute'
                                                                >
                                                                    <HsvRaute />
                                                                </div>
                                                            </IonCol>
                                                            <IonCol
                                                                size='12'
                                                                sizeMd='9'
                                                            >
                                                                <div
                                                                    className='n2br'
                                                                >
                                                                    <h2>
                                                                        {t('login.hsvid.title')}
                                                                    </h2>
                                                                    <Trans
                                                                        i18nKey='login.hsvid.description'
                                                                    >
                                                            Text und so
                                                                        <IonText
                                                                            color='secondary'
                                                                        >
                                                                Join My Talent
                                                                        </IonText>
                                                            Übersetzung bla bla
                                                                    </Trans>
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                    <div
                                                        className='login-card__button-bar'
                                                    >
                                                        <Button
                                                            loading={!!token}
                                                            color='primary'
                                                            onClick={async () => {
                                                                if(!Capacitor.isNativePlatform()) {
                                                                    window.location.href = LoginURL
                                                                    return
                                                                }

                                                                await InAppBrowser.removeAllListeners()
                                                                await InAppBrowser.addListener('urlChangeEvent', (event) => {
                                                                    if(event.url.startsWith(REDIRECT_URI)) {
                                                                        const tkn = new URLSearchParams(event.url.split('#')[1]).get('access_token')
                                                                        if(tkn) {
                                                                            setToken(tkn)
                                                                            InAppBrowser.close()
                                                                        }
                                                                    }
                                                                })
                                                                if(Capacitor.getPlatform() === 'ios') {
                                                                    InAppBrowser.open({ url: LoginURL })
                                                                } else {
                                                                    InAppBrowser.openWebView({ url: LoginURL, title: 'Login' })
                                                                }
                                                            }}
                                                        >
                                                            {t('login.hsvid.button')}
                                                        </Button>
                                                    </div>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </IonGrid>
                        </>
                    )}
            </Content>
        </Page>
    )
}

export default Login
