import { IonCard, IonCol, IonGrid, IonHeader, IonRow, IonText, IonToolbar, isPlatform } from '@ionic/react'
import { search } from 'ionicons/icons'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'
import Buttons from '../../components/Buttons'
import Content from '../../components/Content'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import {
    NetworkState,
    useIsTalentInNetworkQuery,
    useJmtNetworksQuery,
    useListMyPersonificationsQuery, useTalentJoinMarketplaceMutation,
} from '../../lib/apollo/types'
import usePlayerTypes from '../../lib/hooks/usePlayerTypes'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import MarketplaceTeaser from './Teaser'

import './style.scss'

const Marketplace: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const playerTypes = usePlayerTypes()
    const [talentIsNotInMarketplace, setTalentIsNotInMarketplace] = useState<boolean | undefined>(undefined)

    const isIOs = isPlatform('ios')

    const { data: personification } = useListMyPersonificationsQuery({
        skip: !user,
        variables: {
            userId: user?.user?.id ?? '',
        },
    })
    const jmtNetworks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        jmtNetworks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [jmtNetworks])
    useIsTalentInNetworkQuery({
        skip: !marketplaceNetwork?.id || !personification?.listMyPersonifications?.collection?.[0]?.id,
        variables: {
            talentId: personification?.listMyPersonifications?.collection?.[0]?.id ?? '',
            networkId: marketplaceNetwork?.id ?? '',
        },
        onCompleted: ({ isTalentInNetwork }) => {
            if (isTalentInNetwork.state === NetworkState.InNetwork) {
                setTalentIsNotInMarketplace(() => false)
            } else {
                setTalentIsNotInMarketplace(() => true)
            }
        },
        onError: () => { setTalentIsNotInMarketplace(() => true) },
    })

    const [present] = useToast()
    const [joinMarketplace] = useTalentJoinMarketplaceMutation()
    const joinMarketplaceWithTalent = useCallback(async (e: any) => {
        e.persist()
        e.stopPropagation()

        if (!talentIsNotInMarketplace) {
            // TODO: LEAVE
            // setIsInMarketplace(() => false)
        } else {
            await joinMarketplace()
            present(t('talent.addedToMarketplace'), 'success')
            setTalentIsNotInMarketplace(() => false)
        }
    }, [joinMarketplace, present, t, talentIsNotInMarketplace])

    return (
        <Page
            trackingTitle='marketplace'
        >
            <Header
                title={t('title.marketplace')}
                buttonLeft='menu'
                collapse='fade'
            >
                <Button
                    round
                    fill='solid'
                    color='secondary'
                    icon={search}
                    routerLink='/marketplace/search'
                />
            </Header>
            <Content
                fullscreen
                className='marketplace'
            >
                {!isIOs ? (
                    <IonToolbar
                        className='marketplace__condense-header'
                    >
                        <h1>{t('title.marketplace')}</h1>
                        <IonText
                            color='medium'
                        >
                            {t('marketplace.searchHeaderHint')}
                        </IonText>
                        <Buttons>
                            <Button
                                icon={search}
                                color='secondary'
                                expand='block'
                                className='marketplace__search-btn'
                                routerLink='/marketplace/search'
                            >
                                {t('marketplace.searchButton')}
                            </Button>
                        </Buttons>
                    </IonToolbar>
                ) : (
                    <IonHeader
                        collapse='condense'
                    >
                        <IonToolbar
                            className='marketplace__condense-header'
                        >
                            <h1>{t('title.marketplace')}</h1>
                            <IonText
                                color='medium'
                            >
                                {t('marketplace.searchHeaderHint')}
                            </IonText>
                            <Button
                                icon={search}
                                color='secondary'
                                expand='block'
                                className='marketplace__search-btn'
                                routerLink='/marketplace/search'
                            >
                                {t('marketplace.searchButton')}
                            </Button>
                        </IonToolbar>
                    </IonHeader>
                )}
                {(talentIsNotInMarketplace && personification?.listMyPersonifications?.collection?.[0]?.id) && (
                    <IonCard
                        className='marketplace__join-with-talent'
                    >
                        <IonGrid>
                            <IonRow>
                                <IonCol
                                    className='ion-text-center ion-padding'
                                >
                                    <TalentCard
                                        talent={personification.listMyPersonifications.collection[0]}
                                        type='small'
                                    />
                                </IonCol>
                                <IonCol
                                    className='ion-padding'
                                >
                                    <Buttons
                                        title={t('marketplace.joinWithTalent.title')}
                                        subtitle={t('marketplace.joinWithTalent.subtitle')}
                                    >
                                        <Button
                                            onClick={joinMarketplaceWithTalent}
                                        >
                                            {t('marketplace.joinWithTalent.button')}
                                        </Button>
                                    </Buttons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                )}
                {playerTypes?.collection.map((talent, i) => (
                    <Fragment
                        key={talent.id}
                    >
                        { i !== 0 && (<Divider />)}
                        <MarketplaceTeaser
                            // isSlider={i % 2 === 0}
                            isSlider={false}
                            talent={talent}
                        />
                    </Fragment>
                )) ?? (
                    <>
                        <MarketplaceTeaser
                            isSlider
                        />

                        <Divider />

                        <MarketplaceTeaser />

                        <Divider />

                        <MarketplaceTeaser
                            isSlider
                        />
                    </>
                )}
            </Content>
        </Page>
    )
}

export default Marketplace
