import { IonCard, IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../../CustomAvatar'

import './style.scss'

const DeletedActivity: React.FC = () => {
    const { t } = useTranslation()

    return (
        <IonCard
            className='delete-activity'
        >
            <CustomAvatar
                className='delete-activity__avatar'
            />
            <IonText
                className='delete-activity__text'
                color='dark'
            >
                {t('activity.deleted')}
            </IonText>
        </IonCard>
    )
}

export default DeletedActivity
