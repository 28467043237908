import './style.scss'
import Button from '../../Button'
import {calendarSharp} from 'ionicons/icons'
import Modal from '../../Modal'
import {DatetimeChangeEventDetail, IonDatetime, IonLabel, IonText} from '@ionic/react'
import Buttons from '../../Buttons'
import {useTranslation} from 'react-i18next'
import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react'
import {IonDatetimeCustomEvent} from '@ionic/core'
import {format} from 'date-fns'
import clsx from 'clsx'
import CollapsibleContent from '../../Collapsible/CollapsibleContent'

type DatePickerButtonProps = {
    onChange: (date?: Date | [Date | undefined, Date | undefined]) => any | Dispatch<SetStateAction<Date>> | Dispatch<SetStateAction<[Date | undefined, Date | undefined]>>
    label: string
    value?: Date | [Date | undefined, Date | undefined] | string | [string, string]
    min?: Date
    max?: Date
    range?: boolean
    required?: boolean
    className?: string
    error?: string
    color?: string
    size?: 'small' | 'default' | 'large'
}

const DatePickerButton: React.FC<DatePickerButtonProps> = ({ onChange, value, range = false, label, required = false, className, min, max, error, color = 'secondary', size = 'default' }) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const usedValue = useMemo(() => {
        if (Array.isArray(value)) {
            return value.map(v => v ? (typeof v === 'string' ? new Date(v) : v) : undefined) as [Date | undefined, Date | undefined]
        }
        return value ? (typeof value === 'string' ? new Date(value) : value) : undefined
    }, [value])

    const highlightedDates = useCallback((isoString: string) => {
        if(!range) return undefined

        const date = new Date(isoString);
        // if date is between values, highlight it
        const dates = (usedValue as [Date, Date])?.sort((a, b) => +a - +b)
        if(dates && dates[0] && dates[1] && date >= dates[0] && date <= dates[1]) {
            return {
                textColor: 'var(--ion-color-step-950)',
                backgroundColor: 'var(--ion-color-step-400)',
            }
        }

        return undefined
    }, [range, usedValue])

    const change = useCallback((e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
        if(!range) {
            onChange(e.target.value ? new Date(e.target.value as string) : undefined)
            return
        }

        if(e.target.value && e.target.value.length > 2) {
            onChange([new Date(e.target.value[1]), new Date(e.target.value[2])])
        } else {
            onChange([
                e.target.value?.[0] ? new Date(e.target.value?.[0]) : undefined,
                e.target.value?.[1] ? new Date(e.target.value?.[1]) : undefined
            ])
        }

    }, [onChange, range])

    const getStringValue = () => {
        if(!usedValue) return t('datepicker.selectDate')
        if(!range) return (usedValue as Date)?.toLocaleDateString() ?? t('datepicker.selectDate')

        let text = ''
        if((usedValue as [Date, Date])?.[0]) {
            text += new Date((usedValue as [Date, Date])[0]).toLocaleDateString()
        }

        if((usedValue as [Date, Date])?.[1]) {
            text += ' - ' + new Date((usedValue as [Date, Date])[1]).toLocaleDateString()
        }

        return text ? text : t('datepicker.selectDate')
    }

    const getValue = () => {
        if(!usedValue) return undefined
        if(!range) return format((usedValue as Date), 'yyyy-MM-dd')

        return [
            (usedValue as [Date, Date])?.[0] ? format((usedValue as [Date, Date])[0], 'yyyy-MM-dd') : undefined,
            (usedValue as [Date, Date])?.[1] ? format((usedValue as [Date, Date])[1], 'yyyy-MM-dd') : undefined,
        ].filter(Boolean) as string[]
    }



    return (
        <div
            className={clsx('date-picker-button', className)}
        >
            {/*<IonItem*/}
            {/*    lines='none'*/}
            {/*    className='date-picker-button__item'*/}
            {/*>*/}
                <IonLabel
                    position='floating'
                    className={clsx('date-picker-button__label')}
                >
                    {label + (required ? '*' : '')}
                </IonLabel>
                <Button
                    color={color}
                    icon={calendarSharp}
                    size={size}
                    onClick={() => setOpen(true)}
                >
                    {getStringValue()}
                </Button>
            {/*</IonItem>*/}
            <Modal
                modalTitle={t('datepicker.title')}
                keepContentsMounted
                isOpen={open}
                onClose={() => setOpen(false)}
                onDidDismiss={() => setOpen(false)}
            >
                <IonDatetime
                    firstDayOfWeek={navigator.language === 'de' ? 1 : 0}
                    className='date-picker-button__picker'
                    value={getValue()}
                    multiple={range}
                    presentation='date'
                    onIonChange={change}
                    highlightedDates={highlightedDates}
                    max={max ? format(max, 'yyyy-MM-dd') : undefined}
                    min={min ? format(min, 'yyyy-MM-dd') : undefined}
                />
                <Buttons>
                    <Button onClick={() => setOpen(false)}>{t('buttons.close')}</Button>
                    {!required && (
                        <Button
                            color='danger'
                            onClick={() => {
                                setOpen(false)
                                onChange(undefined)
                            }}
                        >
                            {t('buttons.date.clear')}
                        </Button>
                    )}
                </Buttons>
            </Modal>
            <CollapsibleContent
                className='form-input__error-container'
                open={!!error}
            >
                <IonText
                    className='form-input__error-text font-s-regular'
                    color='danger'
                >
                    {error}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default DatePickerButton