import type { HsvPlayerSignature } from './index'

const PlayerSignature: React.FC<{ player: HsvPlayerSignature }> = ({ player }) => {
    return (
        <>
            <img
                alt={player.caption}
                src={`/assets/${player.signature}`}
                style={{ position:'relative', maxHeight: '75px', ...player.styles }}
            />
            <span
                style={{ position: 'absolute', left: '8px', bottom: '-8px', borderTop: '1px solid white' }}
            >
                {player.caption}
            </span>
        </>
    )
}

export default PlayerSignature
