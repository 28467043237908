const HSVTalentcard: React.FC = () => (
    <svg
        clipRule='evenodd'
        fillRule='evenodd'
        imageRendering='optimizeQuality'
        shapeRendering='geometricPrecision'
        textRendering='geometricPrecision'
        viewBox='0 0 244.24 17.529'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            fill='#fff'
            fillRule='nonzero'
        >
            <path
                d='m18.137 4.425v8.687l.763.237v3.95h-9.037v-3.95l.763-.237v-2.602h-2.352v2.602l.779.237v3.95h-9.053v-3.95l.779-.237v-8.687l-.779-.237v-3.95h9.053v3.95l-.779.237v2.158h2.352v-2.158l-.763-.237v-3.95h9.037v3.95z'
            />
            <path
                d='m31.213 6.653c1.542.195 2.762.725 3.65 1.578.892.857 1.336 1.998 1.336 3.42 0 1.172-.292 2.193-.872 3.069-.581.873-1.496 1.563-2.739 2.061-1.246.499-2.824.748-4.741.748-1.433 0-2.718-.09-3.86-.269-1.141-.179-2.064-.374-2.773-.584-.705-.206-1.06-.327-1.06-.359v-4.541l.16.07h4.565l.25 1.172c.709.086 1.433.129 2.181.129 1.254 0 1.881-.277 1.881-.822 0-.23-.066-.405-.202-.526-.14-.121-.335-.214-.596-.273-.257-.062-.639-.128-1.141-.194l-2.213-.332c-.982-.144-1.843-.432-2.579-.86-.74-.433-1.313-.998-1.718-1.703-.409-.705-.611-1.527-.611-2.469 0-1.181.292-2.217.872-3.113.585-.892 1.488-1.593 2.723-2.099 1.231-.503 2.793-.756 4.678-.756 1.917 0 3.491.148 4.714.444 1.227.296 2.107.569 2.649.822h.023v4.565l-.082-.035h-4.62l-.241-1.188c-.647-.058-1.282-.089-1.905-.089-.662 0-1.133.07-1.41.21s-.417.343-.417.611c0 .328.144.561.432.702.293.14.776.253 1.45.335z'
            />
            <path
                d='m37.352 4.382-.697-.253v-3.891h8.788v3.926l-.651.218 1.071 7.771 1.106-7.794-.65-.195v-3.926h8.457v3.891l-.729.253-3.186 12.917h-10.143z'
            />
            <path
                d='m54.172 8.422h6.747v4.117h-6.747z'
            />
            <path
                d='m77.661 5.683h-4.087l-.253-1.005h-.798v8.434l1.028.237v3.95h-9.54v-3.95l1.005-.237v-8.434h-.81l-.265 1.005h-4.098v-5.445h17.818z'
            />
            <path
                d='m96.798 13.349v3.95h-8.468v-3.992l.65-.195-.159-.639h-4.246l-.148.639.662.195v3.992h-8.558v-3.95l.923-.237 2.193-8.687h-.07l-1.106-.237v-3.95h14.37l3.1 12.874zm-8.982-4.803-1.196-4.725-1.118 4.725z'
            />
            <path
                d='m112.434 12.107v5.192h-14.565v-3.95l.78-.237v-8.687l-.78-.237v-3.95h9.131v3.95l-1.028.237v8.687h1.643l.242-1.005z'
            />
            <path
                d='m121.495 4.678v2.053h6.547v4.004h-6.547v2.123h3.011l.241-1.001h4.573v5.442h-15.885v-3.95l.779-.237v-8.687l-.779-.237v-3.95h15.885v5.445h-4.573l-.241-1.005z'
            />
            <path
                d='m130.427 13.349.779-.237v-8.687l-.779-.237v-3.95h11.093l2.236 10.521v-6.334l-.775-.237v-3.95h6.984v3.95l-.775.237v12.874h-9.711l-2.762-11.207v7.02l.799.237v3.95h-7.089z'
            />
            <path
                d='m168.671 5.683h-4.087l-.253-1.005h-.798v8.434l1.028.237v3.95h-9.54v-3.95l1.005-.237v-8.434h-.81l-.265 1.005h-4.098v-5.445h17.818z'
            />
            <path
                d='m177.63 0c1.48 0 2.766.086 3.848.261 1.087.175 1.936.366 2.552.569.615.206.923.323.923.354v4.784h-4.721l-.242-.947c-.744.008-1.351.129-1.819.362-.467.238-.818.627-1.051 1.173-.23.541-.347 1.278-.347 2.209 0 .927.117 1.663.347 2.212.233.549.584.939 1.051 1.176.468.234 1.075.355 1.819.363l.242-.947h4.721v4.807c0 .031-.308.144-.923.343-.616.195-1.465.382-2.552.553-1.082.171-2.368.257-3.848.257-1.66 0-3.136-.304-4.429-.904s-2.318-1.554-3.078-2.859c-.755-1.305-1.133-2.972-1.133-5.001 0-2.034.378-3.701 1.133-5.006.76-1.305 1.785-2.259 3.078-2.859s2.769-.9 4.429-.9z'
            />
            <path
                d='m205.976 13.349v3.95h-8.468v-3.992l.65-.195-.16-.639h-4.245l-.149.639.663.195v3.992h-8.558v-3.95l.923-.237 2.193-8.687h-.07l-1.106-.237v-3.95h14.369l3.101 12.874zm-8.983-4.803-1.195-4.725-1.118 4.725z'
            />
            <path
                d='m225.114 13.307v3.992h-7.393l-1.886-5.66h-.911v1.473l.899.237v3.95h-8.776v-3.95l.779-.237v-8.687l-.779-.237v-3.95h11.698c2.17 0 3.771.436 4.799 1.308 1.032.869 1.546 2.252 1.546 4.149 0 1.102-.233 2.045-.701 2.836-.467.787-1.133 1.406-2.002 1.866l1.792 2.633zm-10.19-5.536h1.289c.6 0 1.079-.148 1.437-.448.359-.296.538-.755.538-1.379 0-.631-.179-1.079-.53-1.348-.354-.264-.857-.397-1.504-.397h-1.23z'
            />
            <path
                d='m235.736.238c2.937 0 5.088.705 6.455 2.111s2.049 3.482 2.049 6.221c0 1.885-.335 3.478-1.005 4.779s-1.644 2.287-2.926 2.953c-1.285.666-2.832.997-4.651.997h-9.52v-3.95l.779-.237v-8.687l-.779-.237v-3.95h9.598zm-1.367 12.916c.6 0 1.083-.132 1.453-.405.37-.268.647-.728.83-1.379.179-.65.272-1.542.272-2.676 0-1.188-.093-2.087-.272-2.703-.183-.619-.46-1.036-.83-1.258-.37-.218-.877-.331-1.523-.331v8.752z'
            />
        </g>
    </svg>
)

export default HSVTalentcard
