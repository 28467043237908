import { useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { bookmarks, home, notifications, person, search } from 'ionicons/icons'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import type { ActivityFragment, TalentTransferRequest } from '../../lib/apollo/types'
import useTabContext from '../../lib/hooks/useTabContext'
import { AppContext } from '../AppState'
import TabButton from '../TabButton'

import './style.scss'
import {Keyboard} from '@capacitor/keyboard'
import {Capacitor} from '@capacitor/core'

export const showTabBarRoutes = ['/home', '/profile', '/activities', '/playlists', '/comparison', '/marketplace', '/settings']

export const getNewForeignActivitesBadge = (newForeignActivities: ActivityFragment[] | undefined) : string | number | undefined => {
    if (!newForeignActivities || newForeignActivities.length === 0) return undefined

    return newForeignActivities.length <= 9 ? newForeignActivities.length : '9+'
}

export const getTalentTransferBadge = (talentTransferRequests: TalentTransferRequest[] | undefined) : string | number | undefined => {
    if (!talentTransferRequests || talentTransferRequests.length === 0) return undefined

    return talentTransferRequests.length <= 9 ? talentTransferRequests.length : '9+'
}

const TabBar: React.FC = () => {
    const { t } = useTranslation()
    const [hideTabBar, setHideTabBar] = useState(true)
    const { routeInfo: { pathname } } = useIonRouter()
    const activeUrl = useTabContext()
    const [activeTab, setActiveTab] = useState<string>(activeUrl)

    const { newForeignActivities, pendingTalentTransferRequests } = useContextSelector(AppContext, state => state.state)

    useEffect(() => {
        if (activeTab !== activeUrl) {
            setActiveTab(() => activeUrl)
        }
    }, [activeTab, activeUrl])

    useLayoutEffect(() => {
        setHideTabBar(!showTabBarRoutes.some((route: string) => pathname.startsWith(route)))
    }, [pathname])

    useEffect(() => {
        if(Capacitor.isNativePlatform()) {
            Keyboard.addListener('keyboardWillShow', (info) => {
                setHideTabBar(true)
            })

            Keyboard.addListener('keyboardWillHide', () => {
                setHideTabBar(!showTabBarRoutes.some((route: string) => pathname.startsWith(route)))
            })
            return () => {
                Keyboard.removeAllListeners()
            }
        }
    }, [pathname]);

    return (
        <div
            className={clsx('tab-bar', { 'tab-bar--hidden': hideTabBar })}
        >
            <TabButton
                tab='home'
                icon={home}
                active={activeTab === 'home'}
                onClick={() => setActiveTab('home')}
            >
                {t('tabbar.home')}
            </TabButton>

            <TabButton
                badge={getTalentTransferBadge(pendingTalentTransferRequests)}
                tab='profile'
                icon={person}
                active={activeTab === 'profile'}
                onClick={() => setActiveTab('profile')}
            >
                {t('tabbar.profile')}
            </TabButton>

            <div
                className='tab-bar__search'
            >
                <TabButton
                    tab='marketplace/search'
                    icon={search}
                    color='secondary'
                    fill='solid'
                    active={activeTab === 'marketplace'}
                    round
                    onClick={() => setActiveTab('marketplace')}
                />
            </div>

            <TabButton
                badge={getNewForeignActivitesBadge(newForeignActivities)}
                tab='activities'
                icon={notifications}
                active={activeTab === 'activities'}
                onClick={() => setActiveTab('activities')}
            >
                {t('tabbar.activities')}
            </TabButton>

            <TabButton
                tab='playlists'
                icon={bookmarks}
                active={activeTab === 'playlists'}
                onClick={() => setActiveTab('playlists')}
            >
                {t('tabbar.playlists')}
            </TabButton>
        </div>
    )
}

export default TabBar
