import { statsChart } from 'ionicons/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../lib/apollo/types'
import { useTalentQuery } from '../../lib/apollo/types'
import isTalentOwnPersonification from '../../lib/utils/isTalentOwnPersonification'
import { useUser } from '../../providers/Auth/hooks'
import Button from '../Button'
import Buttons from '../Buttons'
import ItemList from '../ItemList'
import TalentCard from '../NewTalentCard'
import ShareTalentButton from '../ShareTalentButton'
import ChoseRoleModelButton from '../Talent/TalentCard/TalentCardRoleModel/ChoseRoleModelButton'

import RoleModelTeaserCard from './RoleModelTeaserCard'

import './style.scss'

interface RoleModelProps {
    talent?: Talent,
    roleModel?: Talent
    shared?: boolean
}

const RoleModel: React.FC<RoleModelProps> = ({ talent, roleModel, shared }) => {
    const { t } = useTranslation()
    const user = useUser()
    const { data } = useTalentQuery({
        variables: {
            talentId: talent?.customMeta?.rolemodelid?.toString() ?? '',
            userId: user?.user.id ?? '',
        },
        skip: !user || !!roleModel || !talent?.customMeta?.rolemodelid || shared,
    })

    const usedRoleModel = useMemo(() => {
        if (roleModel) return roleModel
        return data?.talent
    }, [data?.talent, roleModel])

    const isOwnPersonification = isTalentOwnPersonification(talent)

    return (
        <ItemList
            className='role-model'
        >
            {isOwnPersonification && !talent?.customMeta?.rolemodelid && (
                <RoleModelTeaserCard
                    talent={talent}
                />
            )}
            {usedRoleModel && (
                <div>
                    <h2
                        className='role-model__headline'
                    >
                        {t(isOwnPersonification && !!talent?.personification ? 'talent.roleModelIntro2' : 'talent.otherRoleModel', { name: talent?.caption })}
                    </h2>
                    <div
                        className='display-flex ion-justify-content-center'
                    >
                        <TalentCard
                            talent={usedRoleModel}
                            similarTalent={talent}
                            shared
                        />
                    </div>
                </div>
            )}
            { !shared && (talent?.customMeta?.rolemodelid || (isTalentOwnPersonification(talent) && talent?.customMeta?.rolemodelid)) && (
                <div
                    className='role-model__btn-wrapper'
                >
                    <Buttons
                        title={t(isOwnPersonification && !!talent?.personification ? 'talent.roleModelButtonsTitle' : 'talent.otherRoleModelButtonsTitle', { name: talent.caption, roleModel: usedRoleModel?.caption })}
                        subtitle={t(isOwnPersonification && !!talent?.personification ? 'talent.roleModelButtonsSubtitle' : 'talent.otherRoleModelButtonsSubtitle', { name: talent.caption, roleModel: usedRoleModel?.caption })}
                    >
                        <Button
                            disabled={!talent?.customMeta?.rolemodelid}
                            icon={statsChart}
                            color='secondary'
                            routerLink={`/comparison/${talent.id}/${talent?.customMeta?.rolemodelid}`}
                        >
                            {t('talent.compareWith')}
                        </Button>
                        {
                            (isTalentOwnPersonification(talent) && talent?.customMeta?.rolemodelid) && (
                                <>
                                    <ChoseRoleModelButton
                                        color='primary'
                                        talent={talent}
                                    />
                                    <ShareTalentButton
                                        disabled={!usedRoleModel}
                                        talent={talent}
                                        roleModel={usedRoleModel}
                                    />
                                </>
                            )
                        }
                    </Buttons>
                </div>
            )}
        </ItemList>
    )
}

export default RoleModel
