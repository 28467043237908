import { IonCardTitle } from '@ionic/react'
import { searchCircle } from 'ionicons/icons'
import { useContextSelector } from 'use-context-selector'

import { AppContext } from '../../../components/AppState'
import IconListItem from '../../../components/IconListItem'
import ItemList from '../../../components/ItemList'
import TalentQueryContainer from '../../../components/Talent/TalentQueryContainer'

interface MarketplaceSearchHistoryProps {
    onClick: (value: string) => void
}

const MarketplaceSearchHistory: React.FC<MarketplaceSearchHistoryProps> = ({ onClick }) => {
    const recentSearches = useContextSelector(AppContext, state => state.state.marketplaceRecentSearches)

    return (
        <ItemList>
            {recentSearches.map(search => {
                switch (search.type) {
                    case 'search': {
                        return (
                            <IconListItem
                                key={search.type + search.data}
                                icon={searchCircle}
                                onClick={() => { onClick(search.data) }}
                            >
                                <IonCardTitle
                                    className='ion-no-margin'
                                >
                                    {search.data}
                                </IonCardTitle>
                            </IconListItem>
                        )
                    }
                    case 'similar': {
                        return (
                            <TalentQueryContainer
                                routerLink={`/marketplace/similar/${search.data}`}
                                key={search.type + search.data}
                                talentId={search.data}
                            />
                        )
                    }
                    default: return null
                }
            })}
        </ItemList>
    )
}

export default MarketplaceSearchHistory
