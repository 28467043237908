import {
    IonCard,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRange,
    IonRow,
    IonText,
    useIonAlert,
} from '@ionic/react'
import clsx from 'clsx'
import { informationCircleOutline } from 'ionicons/icons'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Segment from '../../../../Segment'
import useLayers from '../../useLayers'
import type { SkillsListViewProps } from '../index'

const SkillsListViewRadarWithGroupLabels: React.FC<SkillsListViewProps> = ({
    activeIndex = 0,
    talentPosition,
    dataView1,
    dataView2,
    color1 = 'secondary',
    color2 = 'tertiary',
    under16,
}) => {
    const [activeSegment, setActiveSegment] = useState('0')
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const onIonSlideWillChange = useCallback((e: SwiperClass) => { setActiveSegment(e.activeIndex.toString()) }, [setActiveSegment])
    const layers = useLayers(talentPosition, dataView1, dataView2, under16)
    const layer = layers[activeIndex]

    const { t, i18n } = useTranslation()
    const [present] = useIonAlert()

    const onSegmentChange = useCallback((e: any) => {
        if (controlledSwiper) {
            controlledSwiper.slideTo(e.detail.value)
            setActiveSegment(() => e.detail.value)
        }
    }, [controlledSwiper, setActiveSegment])

    const getColor1 = useCallback((value: number, value2?: number) => {
        if(value2 === undefined)
            return 'light'

        return value < value2 ? 'medium' : color1
    }, [color1])

    const getTranslation = useCallback((key: string) => {
        if(under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [under16, i18n, t])

    useEffect(() => {
        setTimeout(() => {
            controlledSwiper?.update()
        }, 250)
    }, [controlledSwiper])

    return (
        <>
            <Segment
                value={activeSegment}
                onIonChange={onSegmentChange}
                tabs={[0, 1, 2, 3].map((i) => ({ tab: t(`i18n.group.${i}`) }))}
            />

            <Swiper
                modules={[Controller]}
                className={
                    clsx('skills-list-view', {
                        'skills-list-view--comparison': !!dataView2,
                    })
                }
                onSwiper={setControlledSwiper}
                onSlideChange={onIonSlideWillChange}
            >
                {([0, 1, 2, 3].map((segment) => (
                    <SwiperSlide
                        key={segment}
                    >
                        <IonCard
                            color='primary'
                            className='skills-list-view__card'
                        >
                            <IonGrid>
                                {layer.values.slice(segment * 8, (segment + 1) * 8).map((value, j) => {
                                    const i = segment * 8 + j
                                    const value2 = layer.compareValues?.[i]

                                    return (
                                        <Fragment
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={i}
                                        >
                                            <IonRow>
                                                <IonLabel
                                                    onClick={() => present(getTranslation( `${layer.translationKeys?.[i]}_tip`))}
                                                >
                                                    {layer.translations?.[i]}
                                                    <IonIcon
                                                        icon={informationCircleOutline}
                                                    />
                                                </IonLabel>
                                            </IonRow>
                                            <IonRow
                                                className='skills-list-view__slider-row ion-align-items-center ion-justify-content-between'
                                            >
                                                <IonRange
                                                    mode='ios'
                                                    color={getColor1(value, value2)}
                                                    min={0}
                                                    max={100}
                                                    value={value}
                                                    disabled
                                                />
                                                <IonText
                                                    color={getColor1(value, value2)}
                                                >
                                                    <h3
                                                        className='skills-list-view__value'
                                                    >
                                                        {value.toFixed(0)}
                                                    </h3>
                                                </IonText>
                                            </IonRow>
                                            {value2 !== undefined && (
                                                <IonRow
                                                    className='skills-list-view__slider-row skills-list-view__slider-row--compare ion-align-items-center ion-justify-content-between'
                                                >
                                                    <IonRange
                                                        mode='ios'
                                                        color={value > value2 ? 'medium' : color2}
                                                        min={0}
                                                        max={100}
                                                        value={value2}
                                                        disabled
                                                    />
                                                    <IonText
                                                        color={value > value2 ? 'medium' : color2}
                                                    >
                                                        <h3
                                                            className='skills-list-view__value'
                                                        >
                                                            {value2.toFixed(0)}
                                                        </h3>
                                                    </IonText>
                                                </IonRow>
                                            )}
                                        </Fragment>
                                    )
                                })}
                            </IonGrid>
                        </IonCard>
                    </SwiperSlide>
                )))}
            </Swiper>

        </>
    )
}

export default SkillsListViewRadarWithGroupLabels
