import './style.scss'
import {IonCard, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow} from '@ionic/react'
import {useTranslation} from 'react-i18next'
import {medical} from 'ionicons/icons'
import React from 'react'

type TimelineItemProps = {
    start: string
    end?: string
    data: { title: string, content: string }
    disableOnClick: boolean
}

const TeamEntry: React.FC<TimelineItemProps> = ({ start, end, data }) => {
    const { t } = useTranslation()


    return (
            <IonCard
                className="talent-timeline-item__entry__card"
            >
                <IonGrid
                    className='talentcard-list-item__grid'
                >
                    <IonRow
                        className='ion-align-items-center ion-justify-content-between ion-nowrap'
                    >
                        <IonCol
                            className='talentcard-list-item__caption-wrapper'
                        >
                            <IonCardTitle>
                                {data.title}
                            </IonCardTitle>
                            <div className='talentcard-list-item__time'>
                                {`${new Date(start).toLocaleDateString(navigator.language, {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                })} - ${!end ? t('talent.timeline.today') : new Date(end).toLocaleDateString(navigator.language, {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                })}`}
                            </div>
                        </IonCol>
                        <IonCol
                            className='talentcard-list-item__talent-id'
                        >
                            <IonIcon src={medical} className='talentcard-list-item__icon'/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
    )

}

export default TeamEntry