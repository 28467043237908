import './style.scss'
import { IonSelectOption } from '@ionic/react'
import { searchOutline } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Form from '../../../components/Form'
import FormSelectField from '../../../components/Form/FormSelectField'
import FormSlider from '../../../components/Form/FormSlider'
import FormTextField from '../../../components/Form/FormTextField'
import ItemList from '../../../components/ItemList'
import TalentCard from '../../../components/NewTalentCard'
import { useJmtNetworksQuery, useSearchTalentLazyQuery } from '../../../lib/apollo/types'

const MarketplaceKeywordSearch: React.FC = () => {
    const { t } = useTranslation()

    const networks = useJmtNetworksQuery()

    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    const [lazySearchTalents, { data: { searchTalent = undefined } = {}, loading }] = useSearchTalentLazyQuery()

    const search = useCallback((v: any) => {
        lazySearchTalents({
            variables: {
                query: v.query ?? '',
                networkId: marketplaceNetwork?.id ?? '',
                ageFrom: v.age?.lower,
                ageTo: v.age?.upper,
                fieldPosition: v.fieldPosition,
            },
        })
    }, [lazySearchTalents, marketplaceNetwork?.id])

    const [age, setAge] = useState<{ lower: number, upper: number }>({
        lower: 5,
        upper: 50,
    })

    return (
        <>
            <div
                className='ion-margin-top ion-margin-bottom marketplace-search'
            >
                <Form
                    onSubmit={search}
                >
                    <ItemList>
                        <FormSlider
                            className='marketplace-search__age-slider'
                            dualKnobs
                            name='age'
                            label={`Alter${age ? ` (${age.lower} - ${age.upper})` : ''}`}
                            onChange={(value) => setAge(value.detail.value as { lower: number, upper: number })}
                            defaultValue={{
                                lower: 5,
                                upper: 50,
                            }}
                        />
                        <FormSelectField
                            label={t('talent.metas.fieldPosition')}
                            name='fieldPosition'
                        >
                            <IonSelectOption
                                value={null}
                            >
                                Alle
                            </IonSelectOption>
                            <IonSelectOption
                                value='goal'
                            >
                                {t('i18n._fieldPosition_values.goal')}
                            </IonSelectOption>
                            <IonSelectOption
                                value='defensive'
                            >
                                {t('i18n._fieldPosition_values.defensive')}
                            </IonSelectOption>
                            <IonSelectOption
                                value='midfield'
                            >
                                {t('i18n._fieldPosition_values.midfield')}
                            </IonSelectOption>
                            <IonSelectOption
                                value='offensive'
                            >
                                {t('i18n._fieldPosition_values.offensive')}
                            </IonSelectOption>
                        </FormSelectField>
                        <FormTextField
                            label={t('marketplace.search.talentCaption')}
                            type='search'
                            name='query'
                        />
                    </ItemList>
                    <Button
                        color='secondary'
                        type='submit'
                        icon={searchOutline}
                        loading={loading}
                    >
                        {t('buttons.search')}
                    </Button>
                </Form>
            </div>
            {!searchTalent && !loading && (
                <div
                    className='ion-text-center'
                >
                    {t('marketplace.searchHint')}
                </div>
            )}
            {searchTalent?.collection?.length === 0 && !loading && (
                <div
                    className='ion-text-center'
                >
                    {t('marketplace.searchNoResults')}
                </div>
            )}
            <ItemList>
                {searchTalent?.collection?.map(talent => (
                    <TalentCard
                        type='listItem'
                        key={talent.id}
                        talent={talent}
                        networkId={marketplaceNetwork?.id ?? ''}
                    />
                ))}
            </ItemList>
        </>
    )

}

export default MarketplaceKeywordSearch
