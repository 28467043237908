import './style.scss'
import {
    HistoryItem,
    Talent,
    TalentHistoryDocument,
    useCreateHistoryItemMutation, useDeleteHistoryItemMutation,
    useEditHistoryItemMutation
} from '../../../../lib/apollo/types'
import React, {useCallback, useState} from 'react'
import Form from '../../../Form'
import {format} from 'date-fns'
import FormSelectField from '../../../Form/FormSelectField'
import {useTranslation} from 'react-i18next'
import {IonSelectOption, useIonAlert} from '@ionic/react'
import ItemList from '../../../ItemList'
import TextField from '../../../Form/FormTextField/TextField'
import DatePickerButton from '../../../Form/DatePickerButton'
import Button from '../../../Button'
import {checkmark, trashBin} from 'ionicons/icons'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import {MatomoEventCategory} from '../../../../lib/tracking/eventCategories'

type TalentHistoryItemEditFormProps = {
    item?: HistoryItem,
    talent: Talent,
    onSave: () => any
}

const TalentHistoryItemEditForm: React.FC<TalentHistoryItemEditFormProps> = ({item, talent, onSave}) => {
    const {t} = useTranslation()
    const { trackEvent } = useMatomo()

    const [createHistoryItem] = useCreateHistoryItemMutation({
        refetchQueries: [
            {
                query: TalentHistoryDocument,
                variables: {
                    talentId: talent.id ?? '',
                }
            }
        ]
    })

    const [editHistoryItem] = useEditHistoryItemMutation({
        refetchQueries: [
            {
                query: TalentHistoryDocument,
                variables: {
                    talentId: talent.id ?? '',
                }
            }
        ]
    })

    const [itemType, setItemType] = useState(item ? item.type : undefined)
    const [itemData, setItemData] = useState(item ? JSON.parse(item.data) : undefined)
    const [itemDate, setItemDate] = useState<Date | undefined>(item?.date ? new Date(item.date) : new Date())
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState<{[key: string]: string | undefined}>({})

    const [ deleteItem ] = useDeleteHistoryItemMutation({
        refetchQueries: [
            {
                query: TalentHistoryDocument,
                variables: {
                    talentId: talent.id ?? '',
                }
            }
        ]
    })

    const [present] = useIonAlert()

    const deleteClick = useCallback(() => {
        present({
            message: t('talent.timeline.deleteAlert', { name: itemData?.title }),
            buttons: [
                t('buttons.cancel'),
                {
                    text: t('buttons.ok'),
                    handler: async () => {
                        trackEvent({
                            action: 'confirm-delete-talent-history',
                            category: MatomoEventCategory.Talent,
                        })
                        await deleteItem({
                            variables: {
                                id: item?.id ?? ''
                            }
                        })
                        onSave()
                    }
                },
            ],
        })
    }, [deleteItem, item?.id, itemData?.title, onSave, present, t, trackEvent])

    const save = useCallback(() => {
        setError({})

        if (!itemType || !itemDate) {
            return
        }

        let hasError = false

        setLoading(true)

        if(!itemData?.start) {
            setError((error) => {
                return ({...error, start: t('errors.required', { label: itemType === 'EVENT' ? t('talent.history.team.date') : t('talent.history.team.startDate')})})
            })
            hasError = true
        }

        if(!itemData?.title) {
            setError((error) => ({...error, title: t('errors.required', { label: t('talent.history.item.title') })}))
            hasError = true
        }


        if(hasError) {
            setLoading(false)
            return
        }

        const data = {
            type: itemType,
            talentId: talent.id,
            data: JSON.stringify(itemData),
            date: format(itemDate, 'yyyy-MM-dd'),
        }

        if (item?.id) {
            trackEvent({
                action: 'save-edit-talent-history',
                category: MatomoEventCategory.Talent,
            })
            editHistoryItem({
                variables: {
                    input: {
                        ...data,
                        id: item.id,
                        talentId: talent.id,
                    }
                }
            })
        } else {
            trackEvent({
                action: 'save-create-talent-history',
                category: MatomoEventCategory.Talent,
            })
            createHistoryItem({
                variables: {
                    input: {
                        ...data,
                        talentId: talent.id,
                    }
                }
            })
        }

        setLoading(false)
        onSave()
    }, [createHistoryItem, editHistoryItem, item?.id, itemData, itemDate, itemType, onSave, t, talent.id, trackEvent])

    return (
        <Form onSubmit={save} className='talent-history-form'>
            <ItemList>
                <FormSelectField
                    value={itemType}
                    label={t('talent.history.itemType')}
                    name='type'
                    onIonChange={(e) => setItemType(e.target.value)}
                >
                    <IonSelectOption value='TEAM'>{t('talent.history.type.team')}</IonSelectOption>
                    <IonSelectOption value='INJURY'>{t('talent.history.type.injury')}</IonSelectOption>
                    <IonSelectOption value='EVENT'>{t('talent.history.type.event')}</IonSelectOption>
                </FormSelectField>
            </ItemList>
                {itemType === 'TEAM' && (
                    <div className='ion-margin-top ion-padding-top'>
                        <div className='ion-justify-content-between display-flex'>
                            <DatePickerButton
                                size='small'
                                required
                                label={t('talent.history.team.startDate')}
                                onChange={(date => {
                                    setItemData((data: any) => ({...data, start: date}))
                                    if (date) {
                                        setItemDate(date as Date)
                                    }
                                    setError(e => ({...e, start: undefined}))
                                })}
                                value={itemData?.start}
                                max={itemData?.end}
                                error={error.start}
                            />
                            <DatePickerButton
                                size='small'
                                label={t('talent.history.team.endDate')}
                                onChange={(date => setItemData((data: any) => ({...data, end: date})))}
                                value={itemData?.end}
                                min={itemData?.start}
                            />
                        </div>
                        <div>
                            <TextField
                                name="title"
                                label={t('talent.history.item.title')}
                                required
                                value={itemData?.title}
                                onChange={(e) => {
                                    setItemData({...itemData, title: e})
                                    setError(e => ({...e, title: undefined}))
                                }}
                                error={error.title}
                            />
                            <TextField
                                name="content"
                                multiline
                                label={t('talent.history.item.content')}
                                value={itemData?.content}
                                maxLength={200}
                                onChange={(e) => setItemData({...itemData, content: e})}
                            />
                        </div>
                        <div className='ion-justify-content-end display-flex ion-margin-top ion-padding-top'>
                            {!!item?.id && (
                                <Button
                                    className='ion-margin-end'
                                    loading={loading}
                                    type='button'
                                    icon={trashBin}
                                    onClick={deleteClick}
                                    color='danger'
                                >
                                    {t('buttons.delete')}
                                </Button>
                            )}
                            <Button loading={loading} type='submit' icon={checkmark} color='secondary'>{t('buttons.save')}</Button>
                        </div>
                    </div>
                )}
            {itemType === 'INJURY' && (
                <div className='ion-margin-top ion-padding-top'>
                    <div className='ion-justify-content-between display-flex'>
                        <DatePickerButton
                            size='small'
                            required
                            label={t('talent.history.team.startDate')}
                            onChange={(date => {
                                setItemData((data: any) => ({...data, start: date}))
                                if (date) {
                                    setItemDate(date as Date)
                                }
                                setError(e => ({...e, start: undefined}))
                            })}
                            value={itemData?.start}
                            max={itemData?.end}
                            error={error.start}
                        />
                        <DatePickerButton
                            size='small'
                            label={t('talent.history.team.endDate')}
                            onChange={(date => setItemData((data: any) => ({...data, end: date})))}
                            value={itemData?.end}
                            min={itemData?.start}
                        />
                    </div>
                    <div>
                        <TextField
                            name="title"
                            label={t('talent.history.item.title')}
                            required
                            value={itemData?.title}
                            onChange={(e) => {
                                setItemData({...itemData, title: e})
                                setError(e => ({...e, title: undefined}))
                            }}
                            error={error.title}
                        />
                    </div>
                    <div className='ion-justify-content-end display-flex ion-margin-top ion-padding-top'>
                        {!!item?.id && (
                            <Button
                                className='ion-margin-end'
                                loading={loading}
                                type='button'
                                icon={trashBin}
                                onClick={deleteClick}
                                color='danger'
                            >
                                {t('buttons.delete')}
                            </Button>
                        )}
                        <Button loading={loading} type='submit' icon={checkmark}
                                color='secondary'>{t('buttons.save')}</Button>
                    </div>
                </div>
            )}
            {itemType === 'EVENT' && (
                <div className='ion-margin-top ion-padding-top'>
                    <div className='ion-justify-content-between display-flex'>
                        <DatePickerButton
                            size='small'
                            required
                            label={t('talent.history.team.date')}
                            onChange={(date => {
                                setItemData((data: any) => ({...data, start: date}))
                                if (date) {
                                    setItemDate(date as Date)
                                }
                                setError(e => ({...e, start: undefined}))
                            })}
                            value={itemData?.start}
                            max={itemData?.end}
                            error={error.start}
                        />
                    </div>
                    <div>
                        <TextField
                            name="title"
                            label={t('talent.history.item.title')}
                            required
                            value={itemData?.title}
                            onChange={(e) => {
                                setItemData({...itemData, title: e})
                                setError(e => ({...e, title: undefined}))
                            }}
                            error={error.title}
                        />
                        <TextField
                            name="content"
                            multiline
                            label={t('talent.history.item.content')}
                            value={itemData?.content}
                            maxLength={200}
                            onChange={(e) => setItemData({...itemData, content: e})}
                        />
                    </div>
                    <div className='ion-justify-content-end display-flex ion-margin-top ion-padding-top'>
                        {!!item?.id && (
                            <Button
                                className='ion-margin-end'
                                loading={loading}
                                type='button'
                                icon={trashBin}
                                onClick={deleteClick}
                                color='danger'
                            >
                                {t('buttons.delete')}
                            </Button>
                        )}
                        <Button
                            loading={loading}
                            type='submit'
                            icon={checkmark}
                            color='secondary'
                        >
                            {t('buttons.save')}
                        </Button>
                    </div>
                </div>
            )}
        </Form>
    )
}

export default TalentHistoryItemEditForm