import ContextMenu from '../../components/ContextMenu'
import NetworkAddOrEditContextMenuItem from '../../components/Network/NetworkAddOrEditContextMenuItem'
import ShowOnboardingContextMenuItem from '../../components/OnboardingWizard/ShowOnboardingContextMenuItem'

const NetworksContextMenu: React.FC = () => (
    <ContextMenu>
        <ShowOnboardingContextMenuItem
            ids={['networks-page']}
        />
        <NetworkAddOrEditContextMenuItem />
    </ContextMenu>
)

export default NetworksContextMenu
