import { useIonAlert, useIonRouter } from '@ionic/react'
import { ellipsisHorizontal, ellipsisVertical, pencilOutline, statsChart, trashBinOutline } from 'ionicons/icons'
import {PropsWithChildren, useMemo} from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import type { Talent } from '../../../lib/apollo/types'
import { PermissionLevel, useDeleteTalentMutation, useRoleModelIdsQuery } from '../../../lib/apollo/types'
import usePlayerTypes from '../../../lib/hooks/usePlayerTypes'
import ContextMenu from '../../ContextMenu'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

import TalentJoinMarketplaceContextMenuItem from './TalentJoinMarketplaceContextMenuItem'
import TalentNetworkContextMenuItem from './TalentNetworkContextMenuItem'
import TalentPlaylistContextMenuItem from './TalentPlaylistContextMenuItem'
import TalentRateRoleModelContextMenuItem from './TalentRateRoleModelContextMenuItem'
import TalentTransferContextMenuItem from './TalentTransferContextMenuItem'
import TalentWatchlistContextMenuItem from './TalentWatchlistContextMenuItem'

interface TalentContextMenuProps {
    talent: Talent
    horizontalIcon?: boolean
    permissionLevel?: PermissionLevel
    color?: string
    size?: 'small' | 'default' | 'large' | undefined
    networkId?: string
}

const TalentContextMenu: React.FC<TalentContextMenuProps & PropsWithChildren> = ({
    horizontalIcon = true,
    talent,
    children,
    permissionLevel = talent.permissionLevel,
    color = 'light',
    size = 'small',
    networkId: nwId,
}) => {
    const { t } = useTranslation()
    const { push } = useIonRouter()

    const { data: rmIds } = useRoleModelIdsQuery()

    const { params: { networkId } } = useRouteMatch<{ networkId?: string }>()

    const usedNetworkId = useMemo(() => {
        return nwId ?? networkId
    }, [nwId, networkId])

    const [present] = useIonAlert()
    const [deleteTalentMutation] = useDeleteTalentMutation()
    const onDeletePlayerClick = (talentCard: Talent) => {
        present({
            message: t('talent.delete', { talent: talentCard.caption }),
            buttons: [
                {
                    text: t('buttons.cancel'),
                    role: 'cancel',
                },
                {
                    text:  t('buttons.delete'),
                    handler: () => {
                        deleteTalentMutation({
                            variables: {
                                talentId: talentCard.id,
                            },
                            update(cache) {
                                const id = cache.identify({ id: talentCard.id, __typename: 'Talent' })
                                cache.evict({ id })
                                cache.gc()
                            },
                        })
                    },
                },
            ],
        })
    }

    const playerTypes = usePlayerTypes()
    const isPlayerType = useMemo(() => playerTypes?.collection.map(type => type.id)?.includes(talent.id), [talent.id, playerTypes])

    return (
        <ContextMenu
            size={size}
            fill='clear'
            color={color}
            icon={horizontalIcon ? ellipsisHorizontal : ellipsisVertical}
        >
            { children }

            {permissionLevel !== PermissionLevel.None && (
                <>
                    <ContextMenuItem
                        icon={pencilOutline}
                        label={t('profile.talentMenu.edit')}
                        onClick={() => {
                            if(usedNetworkId) {
                                push(`/home/network/${usedNetworkId}/edit/talents/${talent.id}`)
                            } else {
                                push(`/profile/edit/talent/${talent.id}/${permissionLevel === PermissionLevel.Personal ? 'true' : 'false'}`)
                            }
                        }}
                    />
                    <ContextMenuItem
                        icon={trashBinOutline}
                        label={t('profile.talentMenu.delete')}
                        onClick={() => onDeletePlayerClick(talent)}
                    />
                </>
            )}

            { !isPlayerType && (
                <>
                    <ContextMenuItem
                        label={t('talent.compareWith')}
                        icon={statsChart}
                        routerLink={`/comparison/${talent.id}`}
                        disabled={talent.rateCount === 0}
                    />
                    <TalentWatchlistContextMenuItem
                        talent={talent}
                    />
                    <TalentPlaylistContextMenuItem
                        talent={talent}
                    />
                    { (permissionLevel === PermissionLevel.Personal
                        || permissionLevel === PermissionLevel.Administrative
                        || talent.public) && (
                        <TalentNetworkContextMenuItem
                            talent={talent}
                        />
                    )}
                    { (permissionLevel === PermissionLevel.Administrative && (rmIds?.roleModelIds ?? []).includes(+talent.id)) && (
                        <TalentRateRoleModelContextMenuItem
                            talent={talent}
                        />
                    ) }
                    { permissionLevel === PermissionLevel.Personal && (
                        <TalentJoinMarketplaceContextMenuItem
                            talent={talent}

                        />
                    )}
                    { networkId && talent.permissionLevel === PermissionLevel.Administrative  && !talent.personification && (
                        <TalentTransferContextMenuItem
                            talent={talent}
                            networkId={networkId}
                        />
                    )}
                </>
            )}
        </ContextMenu>
    )
}

export default TalentContextMenu
