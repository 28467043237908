import './style.scss'
import clsx from 'clsx'
import type { PropsWithChildren } from 'react'

import NurDerHsv from '../../../HsvRaute/NurDerHsv'
import { useTalentCardContext } from '../Context'
import TalentCardPaperSvg from '../TalentCardPaperSvg'

interface TalentCardPaperProps {
    className?: string
    isBackground?: boolean
}

const TalentCardPaper: React.FC<PropsWithChildren<TalentCardPaperProps>> = ({ isBackground, className, children }) => {
    const { schoolImg } = useTalentCardContext()
    return (
        <div
            className={clsx(className, 'hsv-talentcard-paper', { 'hsv-talentcard-paper--is-background': isBackground })}
        >
            <TalentCardPaperSvg
                isBackground={isBackground}
            />

            <div
                tabIndex={-1}
                role='button'
                className='hsv-talentcard-paper__overlay'
            >
                {children}
            </div>

            {!isBackground && (
                <div
                    className='hsv-talentcard-paper__logo'
                >
                    <NurDerHsv />
                </div>
            )}

            {schoolImg && isBackground && (
                <img
                    className='hsv-talentcard-paper__logo__hsv'
                    alt='Logo'
                    src={schoolImg}
                />
            )}
        </div>
    )
}

export default TalentCardPaper
