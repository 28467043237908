import { useIonRouter } from '@ionic/react'
import { trash } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import { NetworksDocument, useRemoveUserFromNetworkMutation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkDeleteContextMenuItemProps {
    network: Network
}

const NetworkLeaveContextMenuItem: React.FC<NetworkDeleteContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [present] = useToast()
    const router = useIonRouter()
    const [leaveNetworkMutation] = useRemoveUserFromNetworkMutation()
    const onLeaveNetworkClick = async () => {
        const response = await leaveNetworkMutation({
            variables: {
                id: network.id,
                user: {
                    _links: {
                        userByNetwork: {
                            url: [`/user/${user?.user.id}`],
                        },
                    },
                },
            },
            refetchQueries: [{
                query: NetworksDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
            {
                query: NetworksDocument,
                variables: {
                    userId: user?.user.id ?? '',
                    start: 1,
                    limit: 15,
                },
            }],
        })
        if(!response.errors) {
            present(t('networks.leaveComplete', { name: network.caption }), 'success')
            router.goBack()
        }
    }

    return (
        <ContextMenuItem
            alertText={t('networks.leaveAlert', { name: network.caption })}
            onClick={onLeaveNetworkClick}
            label={t('networks.leave')}
            icon={trash}
        />
    )
}

export default NetworkLeaveContextMenuItem
