import { IonButton, IonGrid, IonRow, IonSkeletonText, IonText } from '@ionic/react'
import clsx from 'clsx'
import type {PropsWithChildren, ReactChild} from 'react'
import { Children, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper } from 'swiper/react'

import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import resolveCssVariable from '../../lib/resolveCssVariable'
import './style.scss'

interface SliderProps {
    title: string
    subtitle?: string
    link?: string
    itemWidth?: number
    className?: string
    freeMode?: boolean
    onSwiper?: (s: SwiperClass) => void
}

const Slider: React.FC<SliderProps & PropsWithChildren> = ({
    title,
    link,
    itemWidth = 216,
    className,
    children,
    freeMode = false,
    onSwiper,
    subtitle,
}) => {
    const window = useWindowResizeListener()
    const { t } = useTranslation()

    const slidesPerView = useMemo(() =>
        Math.min(
            window.width,
            resolveCssVariable('--jmt-max-content-width', true) as number,
        ) / itemWidth,
    [itemWidth, window.width])

    return (
        <IonGrid>
            {title
                ? (
                    <IonRow>
                        <h2
                            className='ion-margin-bottom'
                        >
                            {title}
                        </h2>
                    </IonRow>
                )
                : (
                    <IonRow>
                        <h2
                            className='ion-margin-bottom'
                        >
                            <IonSkeletonText
                                color='light'
                                style={{ width: '160px', height: '22px' }}
                            />
                        </h2>
                    </IonRow>
                )}

            {subtitle && (
                <IonRow>
                    <IonText
                        className='ion-margin-bottom n2br'
                        color='medium'
                    >
                        <span>
                            { subtitle }
                        </span>
                    </IonText>
                </IonRow>
            )}

            <IonRow
                className='ion-margin-top'
            >
                {!freeMode ? (
                    <Swiper
                        modules={[Controller]}
                        onSwiper={onSwiper}
                        spaceBetween={16}
                        slidesPerView={slidesPerView}
                        className={className}
                    >
                        { children }
                    </Swiper>
                ) : (() => {
                    const arrayChildren = Children.toArray(children)

                    return (
                        <div
                            className={clsx(className, 'slider__freemode')}
                        >
                            { Children.map(arrayChildren as ReactChild[], (child: ReactChild) => (
                                <div
                                    className='slider__freemode__child-wrapper'
                                    style={{ width: itemWidth }}
                                >
                                    { child }
                                </div>

                            ))}
                        </div>
                    )
                })()}
            </IonRow>

            {link && (
                <IonRow
                    className='ion-justify-content-center ion-margin-top'
                >
                    <IonButton
                        routerLink={link}
                        fill='clear'
                        color='tertiary'
                        size='small'
                    >
                        {t('buttons.showAll')}
                    </IonButton>
                </IonRow>
            )}
        </IonGrid>
    )
}

export default Slider
