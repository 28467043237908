import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../../../CustomAvatar'

import './style.scss'

const NetworkAddCardSmall: React.FC<{ subtitleKey?: string }> = ({ subtitleKey = 'networks.addSubTitle' }) => {

    const { t } = useTranslation()

    return (
        <IonCard
            routerLink='/home/network/new/edit'
            className='network-card-small'
        >
            <CustomAvatar
                type='network'
                width='100%'
                icon={addCircleOutline}
            />

            <IonCardContent>
                <IonCardTitle>
                    { t('networks.add') }
                </IonCardTitle>
                <IonCardSubtitle>
                    { t(subtitleKey) }
                </IonCardSubtitle>
            </IonCardContent>
        </IonCard>
    )
}

export default NetworkAddCardSmall
