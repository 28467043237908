import { IonCard, IonText } from '@ionic/react'
import { Trans } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import { useNetworkQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import AnswerTalentApplicationButton from '../../Button/AnswerTalentApplicationButton'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

export type TalentApplication = { talent: Talent, targetUserIds: { [key: number]: string }, networkId: number, pending?: boolean }

const InviteTalentActivity: React.FC<ActivityProps> = ({ activity }) => {
    const me = useUser()
    const myActivity = activity.targetUserId !== me?.user.id.toString()
    const application = JSON.parse(activity.data) as TalentApplication

    const hours = useHours(activity)

    const { data: network } = useNetworkQuery({
        variables: {
            userId: me?.user.id ?? '',
            networkId: `${application.networkId}`,
        },
        skip: !me,
    })

    console.log('InviteTalentActivity', JSON.parse(activity.data))

    return (
        <IonCard
            className='invite-activity'
        >
            <CustomAvatar
                width={!myActivity ? undefined : 106.66}
                type={!myActivity ? 'person' : 'network'}
                className='invite-activity__avatar'
                img={!myActivity ? application.talent.image : network?.network?.image}
                alt={!myActivity ? application.talent.id : network?.network?.id}
            />
            <IonText
                className='invite-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey={`activity.invite.${myActivity ? 'invite' : 'network'}`}
                    values={{
                        network: network?.network?.caption ?? '???',
                        user: `${application.talent.caption}`,
                        date: hours,
                    }}
                    components={{
                        strong: <strong />,
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            {!myActivity && (
                <AnswerTalentApplicationButton
                    activity={activity}
                    size='small'
                    fill='solid'
                    color='secondary'
                    application={application}
                    from={activity.from}
                    userId={activity.targetUserId}
                />
            )}
        </IonCard>
    )
}

export default InviteTalentActivity
