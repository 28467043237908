import { IonCard, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { mailOpen } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import UserListItem from '../../../../../components/User/UserListItem'
import type { Network } from '../../../../../lib/apollo/types'
import useNetworkInvite from '../../../../../lib/hooks/useNetworkInvite'

import './style.scss'

const NetworkInviteUserViaLink: React.FC<{ network?: Network, personalSpace?: boolean }> = ({ network, personalSpace = false }) => {
    const { t } = useTranslation()

    const invite = useNetworkInvite(network, personalSpace)

    if(!network) {
        return (
            <UserListItem />
        )
    }

    return (
        <IonCard
            onClick={invite}
            className='network-invite-item'
        >
            <IonGrid
                className='network-invite-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='network-invite-item__title'
                        >
                            {t('networks.invite.userNotFound')}
                        </IonCardTitle>
                    </IonCol>
                    <IonCol
                        className='network-invite-item__icon'
                    >
                        <IonIcon
                            icon={mailOpen}
                            size='large'
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default NetworkInviteUserViaLink
