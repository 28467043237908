import './style.scss'
import { IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'
import { useContext } from 'use-context-selector'

import getAge from '../../../../../lib/getAge'
import Divider from '../../../../Divider'
import HsvTalentID from '../../HsvTalentID'
import HsvTopSkills from '../../HsvTopSkills'
import getSignature from '../../getSignature'
import { TalentCardContext } from '../_Core/Context'
import TalentCardPaper from '../_Core/TalentCardPaper'

interface TalentCardMediumFrontProps {
    visible: boolean
    onFlipCardClick: () => void
}

const TalentCardMediumFront: React.FC<TalentCardMediumFrontProps> = ({ visible, onFlipCardClick }) => {
    const { certificate, clubImg } = useContext(TalentCardContext)
    if (!certificate) return null

    const age = getAge(certificate.birthday)

    return (
        <TalentCardPaper
            className={clsx('hsv-talentcard-medium-front hsv-talentcard-medium-front--talent', {
                'hsv-talentcard-medium-front--visible': visible,
            })}
        >
            <div
                className='hsv-talentcard-medium-front__top-container'
            >
                <div
                    className='display-flex-column ion-justify-content-center'
                >
                    <span className="hsv-talentcard-medium-front__top-container__age">ALTER</span>
                    <h2
                        className='ion-text-center'
                    >
                        {age ?? (
                            <IonSkeletonText
                                style={{ width: 40 }}
                            />
                        )}
                    </h2>
                    {clubImg && (
                        <img
                            className='hsv-talentcard-medium-front__fifa-country'
                            alt='club img'
                            src={clubImg}
                        />
                    )}
                </div>
            </div>

            <div
                className='hsv-talentcard-medium-front__bottom-container'
            >
                <div
                    className='hsv-talentcard-medium-front__profile-link'
                >
                    <h2
                        className='ion-text-center'
                    >
                        {certificate.caption ?? (
                            <IonSkeletonText
                                style={{ width: 200, margin: '0 auto 4px' }}
                            />
                        )}
                    </h2>
                </div>

                <Divider
                    margin={6}
                />

                <div
                    className='hsv-talentcard-medium-front__team-container'
                >
                    <div
                        className='hsv-talentcard-medium-front__team-container__meta'
                    >
                        <h4
                            className='ion-text-left'
                        >
                            {getSignature(certificate)}
                        </h4>
                        {/* <h4 */}
                        {/*    className='ion-text-left hsv-talentcard-medium-front__team' */}
                        {/* > */}
                        {/*    TEST2 */}
                        {/* </h4> */}
                    </div>
                </div>

                <Divider
                    margin={6}
                />

                <div
                    className='hsv-talentcard-medium-front__skills-wrapper'
                >
                    <HsvTopSkills
                        values={certificate.vote}
                    />

                    <div
                        className='hsv-talentcard-medium-front__talentid-wrapper'
                        tabIndex={-1}
                        role='button'
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onFlipCardClick?.()
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onFlipCardClick()
                        }}
                    >
                        <HsvTalentID
                            values={certificate.vote}
                        />
                    </div>

                </div>
            </div>
        </TalentCardPaper>
    )
}

export default TalentCardMediumFront
