import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Comparison from '../../components/Comparison'
import Content from '../../components/Content'
import Header from '../../components/Header'
import Page from '../../components/Page'

type RouteMatch = {
    talentId1: string,
    talentId2: string,
}

const ComparisonPage: React.FC = () => {
    const { t } = useTranslation()
    const {
        params: {
            talentId1,
            talentId2,
        },
    } = useRouteMatch<RouteMatch>()

    return (
        <Page
            trackingTitle='comparison'
        >
            <Header
                buttonLeft='back'
                title={t('title.comparison')}
            />
            <Content
                fullscreen
            >
                <Comparison
                    talentId1={talentId1}
                    talentId2={talentId2}
                />
            </Content>
        </Page>
    )
}

export default ComparisonPage
