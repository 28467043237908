import type { PropsWithChildren, ReactElement, ReactNode } from 'react'

import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'

interface HideProps {
    on: 'mobile' | 'desktop'
    fallback?: ReactNode
}

const Hide = ({ on, children, fallback= null }: PropsWithChildren<HideProps>) : ReactElement => {
    const { isDesktop } = useWindowResizeListener()

    if ((on === 'desktop' && isDesktop)
        || (on === 'mobile' && !isDesktop)) {
        return fallback as ReactElement
    }

    return children as ReactElement
}

export default Hide
