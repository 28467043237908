import { IonSelectOption } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { Salutation } from '../../lib/apollo/types'
import FormSelectField from '../Form/FormSelectField'

interface SalutationSelectProps {
    defaultValue?: number | string
    required?: boolean,
}

const SalutationSelect: React.FC<SalutationSelectProps> = (props) => {
    const { t } = useTranslation()

    return (
        <FormSelectField
            {...props}
            label={t('salutation.label')}
            name='salutation'
        >
            {Object.values(Salutation).map((s: string) => (
                <IonSelectOption
                    value={s}
                    key={s}
                >
                    {t(`salutation.${s}`)}
                </IonSelectOption>
            ))}
        </FormSelectField>
    )
}

export default SalutationSelect
