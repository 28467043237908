import { search } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import HelpSlider from '../../components/HelpSlider'
import NetworksTeaserSlider from '../../components/Network/NetworkTeaserSlider'
import NextSteps from '../../components/NextSteps'
import Page from '../../components/Page'
import PlaylistsTeaserSlider from '../../components/Playlist/PlaylistTeaserSlider'
// import FemaleRoleModelTeaserSlider from '../../components/RoleModel/FemaleRoleModelTeaserSlider'
import RoleModelTeaserCard from '../../components/RoleModel/RoleModelTeaserCard'
import RoleModelTeaserSlider from '../../components/RoleModel/RoleModelTeaserSlider'
import FFFUserTeaserSlider from '../../components/User/UserTeaserSlider/FFFUserTeaserSlider'
import RelatedUserTeaserSlider from '../../components/User/UserTeaserSlider/RelatedUserTeaserSlider'
import WhatsNew from '../../components/WhatsNew'
import MarketplaceTeaserCard from '../Marketplace/MarketplaceTeaserCard'

import './style.scss'

const Home: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Page
            trackingTitle='home'
        >
            <Header
                buttonLeft='menu'
                title={t('title.home')}
            >
                <Button
                    id='home-search'
                    icon={search}
                    routerLink='/home/search'
                />
            </Header>
            <Content
                fullscreen
            >
                <NextSteps />
                <WhatsNew />
                <NetworksTeaserSlider />
                <Divider />
                {/* <FemaleRoleModelTeaserSlider /> */}
                <MarketplaceTeaserCard />
                <Divider />
                <RoleModelTeaserSlider />
                <Divider />
                <PlaylistsTeaserSlider />
                <Divider />
                <RelatedUserTeaserSlider />
                <Divider />
                <FFFUserTeaserSlider />
                <Divider />
                <HelpSlider />
                <Divider />
                <RoleModelTeaserCard
                    usePersonification
                />
            </Content>
        </Page>
    )
}

export default Home
