// HELPER FUNCTIONS
const deg2rad = (deg: number) => deg * (Math.PI / 180)
const callSin = (base: number, a: number, m: number) => +(base + Math.sin(deg2rad(a - 90)) * m).toFixed(1)
const callCos = (base: number, a: number, m: number) => +(base + Math.cos(deg2rad(a - 90)) * m).toFixed(1)

// PATH SEGMENT
const pathSegment = (px1: number, py1: number, px2: number, py2: number, x2: number, y2: number) => `C ${px1} ${py1} ${px2} ${py2} ${x2} ${y2}`

// COMPUTE CONTROL POINTS
const computeControlPoints = (K: number[]) => {
    const p1 = []
    const p2 = []
    const n = K.length - 1

    const a = [0]
    const b = [2]
    const c = [1]
    const r = [K[0] + 2 * K[1]]

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < n - 1; i++) {
        a[i] = 1
        b[i] = 4
        c[i] = 1
        r[i] = 4 * K[i] + 2 * K[i + 1]
    }

    a[n - 1] = 2
    b[n - 1] = 7
    c[n - 1] = 0
    r[n - 1] = 8 * K[n - 1] + K[n]

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < n; i++) {
        const m = a[i] / b[i - 1]
        b[i] -= m * c[i - 1]
        r[i] -= m * r[i - 1]
    }

    p1[n - 1] = r[n - 1] / b[n - 1]

    // eslint-disable-next-line no-plusplus
    for (let i = n - 2; i >= 0; --i) {
        p1[i] = (r[i] - c[i] * p1[i + 1]) / b[i]
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < n - 1; i++) {
        p2[i] = 2 * K[i + 1] - p1[i + 1]
    }

    p2[n - 1] = 0.5 * (K[n] + p1[n - 1])

    return [p1, p2]
}

// CREATE RADER VALUES
type Snipped = [number, number, number][]
const defaultSnipped: Snipped = []

export const createRadarValues = (values: number[], size = 250): Snipped => {

    if (values.length === 0) return defaultSnipped

    const snippets: Snipped = []

    const r = size / 100
    const step = 360 / values.length

    values.forEach((value, i) => {
        const a = (i + 1) * step
        const cx = size + Math.cos(deg2rad(a - 90)) * (value * r)
        const cy = size + Math.sin(deg2rad(a - 90)) * (value * r)

        snippets.push([
            +cx.toFixed(2),
            +cy.toFixed(2),
            value,
        ])
    })

    return snippets
}

// CREATE PIE GRAPHS
type GraphsMatrix = string[][]
export const createPieGraphs = (valuesFine: number[] | number[][]): GraphsMatrix => {
    const graphs: GraphsMatrix = []
    const base = 252

    valuesFine.forEach((values: number | number[], key: number) => {
        const amount = Array.isArray(values) ? (values.length || 1) : 1
        const step = (360 / amount)

        const arr = Array.isArray(values) ? values : [values]

        arr.forEach((value: number, i: number) => {
            const r = base / 100 * value
            const angle1 = i * step
            const angle2 = (i + 1) * step

            const graph = `M${callCos(0, angle2, r)}, ${callSin(0, angle2, r)}A${r}, ${r} 0 0, 0 ${callCos(0, angle1, r)}, ${callSin(0, angle1, r)}L0, 0Z`

            if (!graphs[key]) {
                graphs[key] = [graph]
            } else {
                graphs[key].push(graph)
            }
        })
    })

    return graphs
}

// RENDER PIE GROUPS
export const renderPieGroup = (values: number[]): string => {
    const graphs = createPieGraphs([values])[0]
    let result = ''
    graphs.forEach((graph) => {
        result += `<path d="${graph.trim()}" transform="translate(250,250)"></path>`
    })
    return result
}

// CREATE RADAR GRAPHS
export const createRadarGraphs = (values: number[], radius = 250, reverse = false): string => {
    const r = radius / 100

    if (values.length === 0) return ''

    const points: [number, number][] = []
    const amount = values.length
    const step = 360 / amount

    values.forEach((value, i) => {
        const a = (i + 1) * step
        const x = Math.cos(deg2rad(a - 90)) * (value * r)
        const y = Math.sin(deg2rad(a - 90)) * (value * r)
        points.push([x, y])
    })

    // hack for getting a closed smooth spline path
    const pStart = points[0]
    const pNext = points[1]
    const pEnd = points[points.length - 1]

    points.push(pStart) // add copy of first node to the end
    points.push(pNext) // add copy of second node to the end
    points.unshift(pEnd) // add copy of last node before the first

    const paths = []
    const x: number[] = []
    const y: number[] = []

    points.forEach(point => {
        x.push(point[0])
        y.push(point[1])
    })

    const px = computeControlPoints(x)
    const py = computeControlPoints(y)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < points.length - 1; i++) {
        paths.push(
            reverse
                ? pathSegment(px[0][i], py[0][i], px[1][i], py[1][i], x[i + 1], y[i + 1])
                : pathSegment(px[0][i], py[0][i], px[1][i], py[1][i], x[i + 1], y[i + 1]),
        )
    }

    paths.shift() // remove first
    paths.pop() // remove last
    return `M ${x[1]} ${y[1]} ${reverse ? paths.reverse().join(' ') : paths.join(' ')}z`
}

// RENDER PATH GROUPS
export const renderPathGroup = (values: number[], labels: string[], hrefId: string): string => {
    let result = ''
    const lineArray = createRadarValues((new Array(values.length)).fill(100))

    lineArray.forEach(value => {
        result += `<line x1="250" y1="250" x2="${value[0]}" y2="${value[1]}"></line>`
    })

    // createRadarGraphsVals stroke
    // createRadarGraphsNegVals stroke
    // createRadarGraphsVals (createRadarGraphsNegVals AntiClockW) fillColor
    // (createRadarGraphsVals AntiClockW) createRadarGraphsNegVals fillColor

    result += `
        <path transform="translate(250, 250)" class="radar l-global" d="${createRadarGraphs(values)}"></path>
        <path transform="translate(250, 250)" class="radar l-global" d="${createRadarGraphs(values)}"></path>
        <path transform="translate(250, 250)" class="radar l-global" d="${createRadarGraphs(values)}"></path>
        <path transform="translate(250, 250)" class="radar l-global" d="${createRadarGraphs(values)}"></path>
    `

    const tortenArray = createPieGraphs([(new Array(values.length)).fill(105)])
    const points = createRadarValues(values)
    const offset = 100 / values.length / 2

    lineArray.forEach((a, k) => {
        const p = points[k]
        let oset = (offset * (k + 1) + (100 / 4) / 2)
        let lower = ''

        // setting upside down labels
        if (oset < 50 && oset > 25) {
            lower = '-lower'
            oset = (offset * values.length - offset * (k + 1) + ( 100 / 4 ) / 2)
        }

        result += `
            <g>
                <line x1="250" y1="250" x2="${a[0]}" y2="${a[1]}"></line>
                <text class="emoLbl">
                    <textPath href="#${hrefId}-cp${lower}" text-anchor="middle" startOffset="${oset}%">${labels[k]}</textPath>
                </text>
                
                <path class="tortenelement" transform="translate(250,250)" d="${tortenArray[0][k]}"></path>
                <g class="emoVal l-global">
                    <circle r="4" cx="${p[0]}" cy="${p[1]}"></circle>
                    <text dy=".35em" x="250" y="250">${Math.floor(values[k])}%</text>
                </g>
            </g>
        `
    })

    return result
}
