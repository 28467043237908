import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react'
import type { PropsWithChildren } from 'react'

import CustomAvatar from '../CustomAvatar'

import './style.scss'

const HelpCard: React.FC<PropsWithChildren<{ title: string, icon?: string, img?: string, iconSrc?: string; onClick: () => unknown }>> = ({ children, title, icon, img, onClick, iconSrc }) => {

    return (
        <IonCard
            onClick={onClick}
            className='help-card-small'
        >
            <CustomAvatar
                className='help-avatar'
                type={(img || iconSrc || icon) ? 'network' : 'playlist'}
                width='100%'
                img={img}
                icon={icon}
                iconSrc={iconSrc}
            />

            <IonCardContent>
                <IonCardTitle>
                    {title}
                </IonCardTitle>
                {children}
            </IonCardContent>
        </IonCard>
    )
}

export default HelpCard
