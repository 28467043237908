import { IonInput, IonItem, IonLabel } from '@ionic/react'
import clsx from 'clsx'
import { format, getDate, getMonth, getYear, lastDayOfMonth, set } from 'date-fns'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

import type { Maybe } from '../../../lib/apollo/types'

import '../FormTextField/style.scss'
import './style.scss'

type FormBirthdateFieldProps = {
    name: string
    label: string
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    required?: boolean
    disabled?: boolean
    hidden?: boolean
    defaultValue?: Maybe<string>
    className?: string
}
const FormBirthdateField: React.FC<FormBirthdateFieldProps> = ({
    name,
    label,
    rules,
    required,
    disabled,
    hidden,
    defaultValue,
    className,
}) => {
    const { control, formState, setValue } = useFormContext()
    const error = formState.errors[name]
    const [focused, setFocused] = useState<{ [name: string]: boolean }>({})
    const { t } = useTranslation()

    const [year, setYear] = useState<number>()
    const [month, setMonth] = useState<number>()
    const [day, setDay] = useState<number>()

    const [errors, setErrors] = useState<{ [name: string]: boolean }>({})

    const mergedRules = rules ?? {}
    if (required) {
        mergedRules.required = { value: true, message: t('errors.required', { label }) }
    }

    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            if(defaultValue) {
                const date = new Date(defaultValue)
                setDay(getDate(date))
                setMonth(getMonth(date)+1)
                setYear(getYear(date))
            }
        })
    }, [defaultValue])

    useEffect(() => {
        if (day && month && year) {
            const date = set(new Date(), { year, month: month-1, date: day })
            const firstDate = set(new Date(), { year, month: (month ?? 1)-1, date: 1 })
            const lastDate = lastDayOfMonth(firstDate).getDate()
            if(day > lastDate) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    day: true,
                }))
            } else {
                setValue(name, format(date, 'yyyy-MM-dd'))
            }
        }
    }, [day, month, name, setValue, year])

    return (
        <div
            className='form-birthday-field'
        >
            <IonItem
                lines='none'
                className='form-birthday-field__item'
            >
                <IonLabel
                    hidden={hidden}
                    className={clsx('form-birthday-field__label', { 'error': !!error })}
                    position='stacked'
                >
                    {label + (required ? '*' : '')}
                </IonLabel>
                <Controller
                    control={control}
                    name={name}
                    rules={mergedRules}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                        <div
                            className='form-birthday-field__row'
                        >
                            <div
                                className={clsx('form-input', { 'form-input--focused': focused.year, 'form-input--hidden': hidden })}
                            >
                                <IonItem
                                    lines='none'
                                    className='form-input__item'
                                >
                                    <IonLabel
                                        position='floating'
                                        hidden={hidden}
                                        className={clsx('form-input__label', { 'error': !!error })}
                                    >
                                        {t('form.year')}
                                    </IonLabel>
                                    <IonInput
                                        type='number'
                                        hidden={hidden}
                                        className={clsx(className, 'form-input__input form-birthday-field__row__year')}
                                        value={year}
                                        placeholder='yyyy'
                                        onIonInput={e => {
                                            if(e.target.value && typeof +e.target.value === 'number') {
                                                setYear(+e.target.value)
                                            }
                                        }}
                                        onIonFocus={() => {
                                            setFocused({ year: true })
                                        }}
                                        onIonBlur={() => {
                                            setFocused(prev =>  ({ ...prev, year: false }))
                                            field.onBlur()
                                        }}
                                        onBlur={() => {
                                            setFocused(prev =>  ({ ...prev, year: false }))
                                            field.onBlur()
                                        }}
                                        disabled={disabled}
                                    />
                                </IonItem>
                            </div>
                            <div
                                className={clsx('form-input', { 'form-input--focused': focused.month, 'form-input--hidden': hidden })}
                            >
                                <IonItem
                                    lines='none'
                                    className='form-input__item'
                                >
                                    <IonLabel
                                        position='floating'
                                        hidden={hidden}
                                        className={clsx('form-birthday-field__label__inner', { 'error': !!error })}
                                    >
                                        {t('form.month')}
                                    </IonLabel>
                                    <IonInput
                                        type='number'
                                        hidden={hidden}
                                        className={clsx(className, 'form-input__input form-birthday-field__row__month')}
                                        value={month}
                                        placeholder='mm'
                                        onIonInput={e => {
                                            if(e.target.value && typeof +e.target.value === 'number') {
                                                setMonth(+e.target.value)
                                            }
                                        }}
                                        onIonFocus={() => {
                                            setFocused({ month: true })
                                        }}
                                        onIonBlur={() => {
                                            setFocused(prev =>  ({ ...prev, month: false }))
                                            field.onBlur()
                                        }}
                                        onBlur={() => {
                                            setFocused(prev =>  ({ ...prev, month: false }))
                                            field.onBlur()
                                        }}
                                        disabled={disabled}
                                    />
                                </IonItem>
                            </div>
                            <div
                                className={clsx('form-input', { 'form-input--focused': focused.day, 'form-input--hidden': hidden })}
                            >
                                <IonItem
                                    lines='none'
                                    className='form-input__item'
                                >
                                    <IonLabel
                                        position='floating'
                                        hidden={hidden}
                                        className={clsx('form-birthday-field__label__inner', { 'error': !!error || errors.day })}
                                    >
                                        {t('form.day')}
                                    </IonLabel>
                                    <IonInput
                                        type='number'
                                        hidden={hidden}
                                        className={clsx(className, 'form-input__input form-birthday-field__row__day')}
                                        value={day}
                                        placeholder='dd'
                                        onIonInput={e => {
                                            if(e.target.value && typeof +e.target.value === 'number') {
                                                setDay(+e.target.value)
                                            }
                                        }}
                                        onIonFocus={() => {
                                            setFocused({ day: true })
                                        }}
                                        onIonBlur={() => {
                                            setFocused(prev =>  ({ ...prev, day: false }))
                                            field.onBlur()
                                        }}
                                        onBlur={() => {
                                            setFocused(prev =>  ({ ...prev, day: false }))
                                            field.onBlur()
                                        }}
                                        disabled={disabled}
                                    />
                                </IonItem>
                            </div>
                        </div>
                    )}
                />
            </IonItem>
        </div>
    )
}

export default FormBirthdateField
