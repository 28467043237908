import type { Color } from '@ionic/core'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { barChart, stopwatchOutline } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import usePlayerTypes from '../../../lib/hooks/usePlayerTypes'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import isTalentOwnPersonification from '../../../lib/utils/isTalentOwnPersonification'
import Button from '../../Button'
import LoginButton from '../../Button/LoginButton'
import Buttons from '../../Buttons'
import Modal from '../../Modal'
import TalentShortVoteForm from '../TalentShortVoteForm'
import TalentVoteForm from '../TalentVoteForm'

import './style.scss'

interface TalentVoteButtonProps {
    talent?: Talent
    networkId?: string
    color?: Color
    fill?: 'solid' | 'outline' | 'clear'
}

const TalentVoteButton: React.FC<TalentVoteButtonProps> = ({ fill, talent, networkId, color = 'secondary' }) => {
    const { t } = useTranslation()
    const [showVoteModal, setShowVoteModal] = useState(false)
    const [showSelectModal, setShowSelectModal] = useState(false)
    const [profiVote, setProfiVote] = useState(true)

    const { trackEvent } = useMatomo()
    const playerTypes = usePlayerTypes()
    const playerTypesIds = useMemo(() => playerTypes?.collection.map(type => type.id), [playerTypes])

    return (
        <>
            {talent && (
                <>
                    <Modal
                        onClose={() => {
                            setShowVoteModal(false)
                        }}
                        modalTitle={t('talent.voteModalTitle', { name: talent.caption })}
                        isOpen={showVoteModal}
                    >
                        <div>
                            {profiVote ? (
                                <TalentVoteForm
                                    onVote={() => {
                                        setShowVoteModal(false)
                                    }}
                                    talent={talent}
                                    networkId={networkId}
                                />
                            ) : (
                                <TalentShortVoteForm
                                    onVote={() => {
                                        setShowVoteModal(false)
                                    }}
                                    talent={talent}
                                    networkId={networkId}
                                />
                            )}
                        </div>
                    </Modal>
                    <Modal
                        onClose={() => setShowSelectModal(false)}
                        modalTitle={t('talent.voteModalTitle', { name: talent.caption })}
                        isOpen={showSelectModal}
                        size='alert'
                        className='talent-vote-button__modal'
                    >
                        <div
                            className='talent-vote-button__selection'
                        >
                            <Buttons>
                                <Button
                                    onClick={() => {
                                        trackEvent({
                                            action: 'start-short-vote',
                                            category: MatomoEventCategory.Talent,
                                        })
                                        setProfiVote(false)
                                        setShowSelectModal(false)
                                        setShowVoteModal(true)
                                    }}
                                    icon={stopwatchOutline}
                                >
                                    {t('talent.shortVote')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        trackEvent({
                                            action: 'start-vote',
                                            category: MatomoEventCategory.Talent,
                                        })
                                        setProfiVote(true)
                                        setShowSelectModal(false)
                                        setShowVoteModal(true)
                                    }}
                                    icon={barChart}
                                    color='secondary'
                                >
                                    {t('talent.professionalVote')}
                                </Button>
                            </Buttons>
                        </div>
                    </Modal>
                </>
            )}

            <LoginButton
                disabled={!talent || (!isTalentOwnPersonification(talent) && playerTypesIds?.includes(talent.id))}
                onClick={() => {
                    trackEvent({
                        action: 'start-vote',
                        category: MatomoEventCategory.Talent,
                    })
                    setShowSelectModal(true)
                }}
                fill={fill}
                color={color}
                icon={barChart}
            >
                {t('talent.vote')}
            </LoginButton>
        </>
    )
}

export default TalentVoteButton
