import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { useMemo } from 'react'

import type { Talent } from '../../../../lib/apollo/types'

import './style.scss'

interface FootIconProps {
    talent?: Talent
}

const FootIcon: React.FC<FootIconProps> = ({ talent }) => {
    const foot = useMemo(() => talent?.customMeta?.foot ?? undefined, [talent])

    if (talent?.customMeta && !talent?.customMeta?.foot) return null

    return (
        <div
            className='foot-container'
        >
            <IonIcon
                className={clsx('foot-container__left', { 'foot-container--active': foot && foot !== 'right' })}
                src='/assets/icon/foot.svg'
            />
            <IonIcon
                className={clsx('foot-container__right', { 'foot-container--active': foot && foot !== 'left' })}
                src='/assets/icon/foot.svg'
            />
        </div>
    )
}

export default FootIcon
