import { IonGrid } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'

import './style.scss'
import CompareMetaRow from './CompareMetaRow'

interface CompareMetaProps {
    talent1: Talent,
    talent2: Talent,
}

const CompareMeta: React.FC<CompareMetaProps> = ({
    talent1,
    talent2,
}) => {
    const { t } = useTranslation()

    const age1 = talent1.metas.date ? `${getAge(talent1.metas.date)} ${t('talent.ageSuffix')}` : '-'
    const age2 = talent1.metas.date ? `${getAge(talent2.metas.date)} ${t('talent.ageSuffix')}` : '-'

    const height1 = talent1.customMeta?.height ? `${talent1.customMeta.height} ${t('talent.heightSuffix')}` : '-'
    const height2 = talent2.customMeta?.height ? `${talent2.customMeta.height} ${t('talent.heightSuffix')}` : '-'

    const weight1 = talent1.customMeta?.weight ? `${talent1.customMeta.weight} ${t('talent.weightSuffix')}` : '-'
    const weight2 = talent2.customMeta?.weight ? `${talent2.customMeta.weight} ${t('talent.weightSuffix')}` : '-'

    const foot1 = talent1.customMeta?.foot ? t(`talent.foot.${talent1.customMeta.foot.toLowerCase()}`) as string : '-'
    const foot2 = talent2.customMeta?.foot ? t(`talent.foot.${talent2.customMeta.foot.toLowerCase()}`) as string : '-'

    return (
        <IonGrid
            className='compare-meta'
        >
            <CompareMetaRow
                label={t('talent.agePrefix')}
                value1={age1}
                value2={age2}
            />

            <CompareMetaRow
                label={t('talent.heightPrefix')}
                value1={height1}
                value2={height2}
            />

            <CompareMetaRow
                label={t('talent.weightPrefix')}
                value1={weight1}
                value2={weight2}
            />

            <CompareMetaRow
                label={t('talent.foot.prefix')}
                value1={foot1}
                value2={foot2}
            />

        </IonGrid>
    )
}

export default CompareMeta
