import { IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'

import stopPropagation from '../../../lib/utils/stopPropagation'
import Button from '../../Button'
import CustomAvatarSkeleton from '../../CustomAvatar/CustomAvatarSkeleton'

import './style.scss'

const UserListItemSkeleton: React.FC = () => (
    <IonCard
        className='user-list-item'
    >
        <CustomAvatarSkeleton />

        <IonGrid
            className='user-list-item__grid'
        >
            <IonRow
                className='ion-align-items-center ion-justify-content-between ion-nowrap'
            >
                <IonCol>
                    <IonCardTitle>
                        <IonSkeletonText
                            style={{ width: '128px' }}
                        />
                    </IonCardTitle>
                    <IonRow>
                        <IonCardSubtitle>
                            <IonSkeletonText
                                style={{ width: '80px' }}
                            />
                        </IonCardSubtitle>
                    </IonRow>
                </IonCol>

                <IonCol
                    className='user-list-item__menu'
                >
                    <Button
                        disabled
                        onClick={stopPropagation}
                        size='small'
                        fill='clear'
                        icon={ellipsisVertical}
                    />
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonCard>
)

export default UserListItemSkeleton
