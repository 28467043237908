import { IonSelectOption } from '@ionic/react'
import { saveOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type {
    Talent,
    CreateTalentCustomMetaMutationVariables,
    Network } from '../../../lib/apollo/types'
import {
    useTalentUnlinkImageMutation,
    ListMyPersonificationsDocument,
    ListMyTalentsDocument, NetworkTalentsDocument,
    TalentPosition,
    useCreateTalentCustomMetaMutation,
    useCreateTalentMutation,
    useTalentLinkImageMutation,
    useUpdateTalentCustomMetaMutation,
    useUpdateTalentMutation,
} from '../../../lib/apollo/types'
import countries from '../../../lib/i18n/de'
import { useUser } from '../../../providers/Auth/hooks'
import Button from '../../Button'
import CustomAvatar from '../../CustomAvatar'
import Form from '../../Form'
import FormBirthdateField from '../../Form/FormBirthdayField'
import FormImageInput from '../../Form/FormImageInput'
import FormSelectButtons from '../../Form/FormSelectButtons'
import FormSelectField from '../../Form/FormSelectField'
import FormTextField from '../../Form/FormTextField'
import ItemList from '../../ItemList'
import SalutationSelect from '../../SalutationSelect'

import './style.scss'

interface TalentAddOrEditFormProps {
    talent?: Talent
    network?: Network
    autoProfile?: boolean
    onSave: (talent?: Talent) => any
}

const size = { width: 400, height: 400 }

const TalentAddOrEditForm: React.FC<TalentAddOrEditFormProps> = ({ talent, network, autoProfile = false, onSave }) => {
    const { t } = useTranslation()
    const [preview, setPreview] = useState<string | undefined>()
    const user = useUser()

    const [createTalentMutation] = useCreateTalentMutation({
        refetchQueries: !network ? [
            {
                query: ListMyTalentsDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
            {
                query: ListMyPersonificationsDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
        ] : [
            {
                query: ListMyTalentsDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
            {
                query: NetworkTalentsDocument,
                variables: {
                    networkId: network.id,
                    userId: user?.user.id ?? '',
                },
            },
        ],
    })
    const [updateTalentMutation] = useUpdateTalentMutation()
    const [linkImageMutation] = useTalentLinkImageMutation()
    const [unlinkImageMutation] = useTalentUnlinkImageMutation()
    const [createCustomMetaMutation] = useCreateTalentCustomMetaMutation()
    const [updateCustomMetaMutation] = useUpdateTalentCustomMetaMutation()

    const onImageDelete = useCallback(async () => {
        if (!talent || !talent.asset) return
        await unlinkImageMutation({
            variables: {
                talentId: talent.id,
                url: `/asset/${talent.asset}`,
            },
        })
    }, [talent, unlinkImageMutation])

    const onSubmit = useCallback(async ({
        image,
        caption,
        firstName,
        lastName,
        date,
        fifaCountry,
        fieldPosition,
        weight,
        height,
        foot,
        club,
        team,
        status,
        salutation,
    }: any) => {
        const position = fieldPosition[0]
        let response
        const publicSelfPayload = 'private,keepPlaylists,keepNetworks,keepMyNetworks'
        const variables = {
            classId: position === TalentPosition.Goal ? '2' : '1',
            input: {
                caption,
                metas: {
                    fifaCountry,
                    date,
                    fieldPosition: position,
                    firstName,
                    lastName,
                },
                autoProfile,
                autoPublicSelf: false,
                publicSelf: autoProfile ? publicSelfPayload : undefined,
                networkId: network?.id,
            },
        }

        if (!talent?.id) {
            response = await createTalentMutation({ variables })
        } else {
            response = await updateTalentMutation({
                variables: {
                    ...variables,
                    talentId: talent.id,
                },
            })
        }

        const talentId = response.data?.saveTalent.id

        if (talentId && image) {
            await linkImageMutation({
                variables: {
                    talentId,
                    url: `/asset/${image}`,
                },
            })
        }

        if (talentId) {
            const customMetaVariables: CreateTalentCustomMetaMutationVariables = {
                input: {
                    id: talent?.customMeta?.id,
                    rolemodelid: talent?.customMeta?.rolemodelid,
                    topskillsindexes: talent?.customMeta?.topskillsindexes,
                    // ...talent?.customMeta,
                    weight: +weight,
                    height: +height,
                    foot: foot[0],
                    club,
                    team,
                    status,
                    salutation,
                },
                talentId,
            }

            if (!talent?.customMeta?.id) {
                await createCustomMetaMutation({
                    variables: customMetaVariables,
                })
            } else {
                await updateCustomMetaMutation({
                    variables: {
                        ...customMetaVariables,
                        metaDataId: talent.customMeta.id,
                    },
                })
            }
        }

        onSave(response.data?.saveTalent)
    }, [autoProfile, createCustomMetaMutation, createTalentMutation, linkImageMutation, network?.id, onSave, talent?.customMeta?.id, talent?.customMeta?.rolemodelid, talent?.customMeta?.topskillsindexes, talent?.id, updateCustomMetaMutation, updateTalentMutation])

    if(!user) return null

    return (
        <div
            className='talent-edit-form'
        >
            <CustomAvatar
                type='person'
                width='50%'
                img={preview ?? talent?.image}
                onDelete={talent?.asset ? onImageDelete : undefined}
            />
            <Form
                onSubmit={onSubmit}
                autoComplete='off'
            >
                <ItemList>
                    <FormImageInput
                        name='image'
                        label={t('talent.uploadImage')}
                        size={size}
                        onPreviewChange={setPreview}
                        showPreview={false}
                        avatarWrapperClassName='avatar-wrapper--for-talent'
                    />
                    <FormTextField
                        name='caption'
                        label={t('talent.caption')}
                        defaultValue={talent?.caption ?? (autoProfile ? `${user.user.firstName} ${user.user.lastName}` : undefined)}
                        required
                    />
                    <FormTextField
                        name='firstName'
                        label={t('talent.metas.firstName')}
                        defaultValue={autoProfile ? user.user.firstName : talent?.metas.firstName}
                        required
                        disabled={autoProfile}
                    />
                    <FormTextField
                        name='lastName'
                        label={t('talent.metas.lastName')}
                        defaultValue={autoProfile ? user.user.lastName : talent?.metas.lastName}
                        required
                        disabled={autoProfile}
                    />
                    <FormBirthdateField
                        name='date'
                        label={t('talent.metas.birthDate')}
                        defaultValue={talent?.metas.date}
                        required
                    />
                    <FormSelectField
                        name='fifaCountry'
                        label={t('talent.metas.fifaCountry')}
                        defaultValue={talent?.metas.fifaCountry}
                        required
                    >
                        {Object.keys(countries.translation.i18n.fifa).map((v: string) => (
                            <IonSelectOption
                                value={v}
                                key={v}
                            >
                                {t(`i18n.fifa.${v}`)}
                            </IonSelectOption>
                        ))}
                    </FormSelectField>
                    <FormSelectButtons
                        required
                        name='fieldPosition'
                        label={t('talent.metas.fieldPosition')}
                        defaultValue={talent?.metas.fieldPosition ? [talent.metas.fieldPosition] : undefined}
                        options={[
                            {
                                text: t('talent.position.goal'),
                                value: 'goal',
                            },
                            {
                                text: t('talent.position.defensive'),
                                value: 'defensive',
                            },
                            {
                                text: t('talent.position.midfield'),
                                value: 'midfield',
                            },
                            {
                                text: t('talent.position.offensive'),
                                value: 'offensive',
                            },
                        ]}
                    />
                    <SalutationSelect
                        defaultValue={talent?.customMeta?.salutation ?? undefined}
                    />
                    <FormTextField
                        required
                        name='weight'
                        defaultValue={talent?.customMeta?.weight}
                        label={t('talent.weightPrefix')}
                        type='number'
                        prefix='kg'
                    />
                    <FormTextField
                        required
                        name='height'
                        defaultValue={talent?.customMeta?.height}
                        label={t('talent.heightPrefix')}
                        type='number'
                        prefix='cm'
                    />
                    <FormSelectButtons
                        required
                        name='foot'
                        label={t('talent.foot.prefix')}
                        defaultValue={talent?.customMeta?.foot ? [talent.customMeta.foot] : undefined}
                        options={[
                            {
                                text: t('talent.foot.right'),
                                value: 'right',
                            },
                            {
                                text: t('talent.foot.left'),
                                value: 'left',
                            },
                            {
                                text: t('talent.foot.both'),
                                value: 'both',
                            },
                        ]}
                    />
                    <FormTextField
                        name='club'
                        label={t('talent.club')}
                        defaultValue={talent?.customMeta?.club}
                    />
                    <FormTextField
                        name='team'
                        label={t('talent.team')}
                        defaultValue={talent?.customMeta?.team}
                    />
                    <FormTextField
                        hidden
                        name='status'
                        label={t('talent.status')}
                        defaultValue={talent?.customMeta?.status}
                    />
                </ItemList>
                <div
                    className='talent-edit-form__submit-position-helper'
                >
                    <Button
                        color='secondary'
                        icon={saveOutline}
                        className='talent-edit-form__submit-btn'
                        type='submit'
                    >
                        {t(`talent.${talent?.id ? 'edit' : 'create'}`)}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default TalentAddOrEditForm
