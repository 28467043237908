import { IonCard, IonGrid, IonLabel, IonRange, IonRow, IonText } from '@ionic/react'
import { Fragment } from 'react'

import type { LayerI } from '../../Layer'

const SkillsListViewPie: React.FC<{ layer: LayerI, color1?: string, color2?: string }> = ({
    layer,
    color1 = 'secondary',
    color2 = 'tertiary',
}) => (
    <IonCard
        color='primary'
        className='skills-list-view__card'
    >
        <IonGrid>
            {layer.values.map((value, i) => {
                const value2 = layer.compareValues?.[i]

                return (
                    <Fragment
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                    >
                        <IonRow>
                            <IonLabel>
                                {layer.translations?.[i]}
                            </IonLabel>
                        </IonRow>
                        <IonRow
                            className='skills-list-view__slider-row ion-align-items-center ion-justify-content-between'
                        >
                            <IonRange
                                mode='ios'
                                color={value2 === undefined || value < value2 ? 'medium' : color1}
                                min={0}
                                max={100}
                                value={value}
                                disabled
                            />
                            <IonText
                                color={value2 === undefined || value < value2 ? 'medium' : color1}
                            >
                                <h3
                                    className='skills-list-view__value'
                                >
                                    {value.toFixed(0)}
                                </h3>
                            </IonText>
                        </IonRow>
                        {value2 !== undefined && (
                            <IonRow
                                className='skills-list-view__slider-row skills-list-view__slider-row--compare ion-align-items-center ion-justify-content-between'
                            >
                                <IonRange
                                    mode='ios'
                                    color={value > value2 ? 'medium' : color2}
                                    min={0}
                                    max={100}
                                    value={value2}
                                    disabled
                                />
                                <IonText
                                    color={value > value2 ? 'medium' : color2}
                                >
                                    <h3
                                        className='skills-list-view__value'
                                    >
                                        {value2.toFixed(0)}
                                    </h3>
                                </IonText>
                            </IonRow>
                        )}
                    </Fragment>
                )
            })}
        </IonGrid>
    </IonCard>
)

export default SkillsListViewPie
