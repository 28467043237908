import { IonCol, IonGrid, IonRange, IonRow, IonText } from '@ionic/react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { subDays, getYear, subYears, subQuarters, subMonths, subWeeks } from 'date-fns'
import { pauseCircle, playCircle } from 'ionicons/icons'
import type { MouseEvent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import { useTalentLayerAnimationQuery } from '../../../../lib/apollo/types'
import getAge from '../../../../lib/getAge'
import { MatomoEventCategory } from '../../../../lib/tracking/eventCategories'
import useToast from '../../../../providers/Toast/hooks'
import Button from '../../../Button'
import Modal from '../../../Modal'
import useBackgroundColor from '../../../NewTalentCard/_Core/useBackgroundColor'
import TalentIDSkeleton from '../TalentIDSkeleton'
import TalentID from '../index'

import './style.scss'

interface SkillsPlayAnimationButtonProps {
    talent: Talent
    className?: string
    id?: string
    color?: string,
    activeColor?: string
}

const SkillsPlayAnimationButton: React.FC<SkillsPlayAnimationButtonProps> = ({ talent,className }) => {
    const { t } = useTranslation()
    const backgroundColor = useBackgroundColor()
    const [showModal, setShowModal] = useState(false)
    const { trackEvent } = useMatomo()

    const { data } = useTalentLayerAnimationQuery({
        variables: {
            talentId: talent.id,
        },
        skip: !showModal,
    })

    const [play, setPlay] = useState(true)
    const [currIndex, setCurrIndex] = useState(0)

    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    const currYear = useMemo(() => {
        const labelArr = data?.talentLayerAnimation?.[currIndex]?.label.split('.')
        if(!labelArr || !data?.talentLayerAnimation?.length) return ''

        const { length } = data.talentLayerAnimation

        switch (labelArr[1]) {
            case 'y':
                return getYear(subYears(new Date(), (length - currIndex - 1)))
            case 'q':
                return getYear(subQuarters(new Date(), (length - currIndex - 1)))
            case 'm':
                return getYear(subMonths(new Date(), (length - currIndex - 1)))
            case 'w':
                return getYear(subWeeks(new Date(), (length - currIndex - 1)))
            case 'd':
                return getYear(subDays(new Date(), (length - currIndex - 1)))
            default: return ''
        }
    }, [currIndex, data])

    useEffect(() => {
        if(!play || !showModal) {
            if(intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
            return
        }

        intervalRef.current = setInterval(() => {
            if(!data?.talentLayerAnimation?.[currIndex].dataViews) return

            setCurrIndex(idx => {
                if((idx+1) >= (data?.talentLayerAnimation?.length ?? 0)) {
                    return 0
                }
                return idx + 1
            })
        }, 1500)

        return () => {
            if(intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [play, data, showModal, currIndex])

    const [present] = useToast()
    const onOpenAnimationClick = (e: MouseEvent) => {
        e.stopPropagation()

        if((talent?.rateCount ?? 0) < 3) {
            trackEvent({
                action: 'open-aggregation-failed--ratecount-to-low',
                category: MatomoEventCategory.Talent,
            })
            present(t('talent.noAnimationYet'), 'warning')
            return
        }

        trackEvent({
            action: 'open-aggregation',
            category: MatomoEventCategory.Talent,
        })
        setShowModal(() => true)
    }

    return (
        <>
            <Modal
                modalTitle={talent.caption}
                onClose={() => setShowModal(false)}
                isOpen={showModal}
            >
                { talent && showModal && (
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <h1>{t('talent.animation')}</h1>
                            </IonCol>

                        </IonRow>
                        <IonRow>
                            <IonCol />
                        </IonRow>
                        <IonRow>
                            <IonCol
                                className='talent-animation-modal__talent-id'
                            >
                                {data?.talentLayerAnimation?.[currIndex]?.dataViews ? (
                                    <TalentID
                                        disableEnterAnimation
                                        backgroundColor={backgroundColor}
                                        interactive
                                        talentPosition={talent.metas.fieldPosition}
                                        dataView1={data.talentLayerAnimation[currIndex -1 < 0 ? data.talentLayerAnimation.length - 1 : currIndex - 1].dataViews}
                                        animateTo={data?.talentLayerAnimation}
                                        animationIndex={currIndex}
                                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    />
                                ) : (
                                    <TalentIDSkeleton />
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                className='talent-animation-modal__talent-id'
                            >
                                {data?.talentLayerAnimation?.length && (
                                    <>
                                        <IonText>{`${t(data.talentLayerAnimation[currIndex].label)} ${currYear}`}</IonText>
                                        <IonRange
                                            onIonFocus={() => setPlay(false)}
                                            onIonChange={(v) => setCurrIndex(+v.target.value)}
                                            value={currIndex}
                                            snaps
                                            step={1}
                                            min={0}
                                            max={data.talentLayerAnimation.length - 1}
                                        >
                                            <Button
                                                onClick={() => {
                                                    trackEvent({
                                                        action: play ? 'stop-aggregation' : 'play-aggregation',
                                                        category: MatomoEventCategory.Talent,
                                                    })
                                                    setPlay(p => !p)
                                                }}
                                                style={{ marginLeft: '8px' }}
                                                fill='clear'
                                                round
                                                slot='end'
                                                icon={play ? pauseCircle : playCircle}
                                            />
                                        </IonRange>
                                    </>
                                )}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </Modal>

            <Button
                className={className}
                disabled={!talent}
                onClick={onOpenAnimationClick}
                color='primary'
                fill='clear'
                icon={playCircle}
            />
        </>
    )
}

export default SkillsPlayAnimationButton
