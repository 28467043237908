import type { IonButton } from '@ionic/react'
import { useIonRouter } from '@ionic/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useNetworkQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import type { TalentInvitation } from '../../Activity/ApplyTalentActivity'
import Buttons from '../../Buttons'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import NetworkCardSmall from '../../Network/NetworkCard/NetworkCardSmall/NetworkCardSmall'
import NetworkCardSmallSkeleton from '../../Network/NetworkCard/NetworkCardSmall/NetworkCardSmallSkeleton'
import Button from '../index'

import './style.scss'

const AnswerTalentInvitiationButton: React.FC<{ application: TalentInvitation, userId: string } & React.ComponentProps<typeof IonButton>> = ({
    application,
    userId,
    ...buttonProps
}) => {
    const { t } = useTranslation()
    const user = useUser()
    const [isOpen, setIsOpen] = useState(false)
    const { push } = useIonRouter()

    const { data: network } = useNetworkQuery({
        variables: {
            userId: user?.user.id ?? '',
            networkId: `${application.networkId}`,
        },
        skip: !application.networkId || !user,
    })

    const acceptInvitation = useCallback(async () => {
        setIsOpen(false)
        push(application.link)
    }, [application.link, push])

    return (
        <>
            <Button
                {...buttonProps}
                onClick={() => setIsOpen(true)}
            >
                {t('buttons.answer')}
            </Button>
            <Modal
                size='fullscreen'
                isOpen={isOpen}
                modalTitle={t('title.invitation')}
                onClose={() => setIsOpen(false)}
            >
                {!!application.link && (
                    <ItemList>
                        <div
                            className='application-answer__talent-card'
                        >
                            {network?.network ? (
                                <NetworkCardSmall
                                    network={network?.network}
                                />
                            ) : (
                                <NetworkCardSmallSkeleton />
                            )}
                        </div>

                        <div
                            className='application-answer__content'
                        >
                            <Buttons
                                subtitle={t('networkTalentInvitation.description', { network: network?.network?.caption })}
                            >
                                <Button
                                    color='secondary'
                                    onClick={acceptInvitation}
                                >
                                    {t('buttons.accept')}
                                </Button>
                            </Buttons>
                        </div>
                    </ItemList>
                )}
            </Modal>
        </>
    )
}

export default AnswerTalentInvitiationButton
