import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'

import './style.scss'

interface RolemodelPraiseProps {
    talent: Talent
}

const RolemodelPraise: React.FC<RolemodelPraiseProps> = ({ talent }) => {
    const { t } = useTranslation()
    const praise = useMemo(() => t(`talent.roleModelIntro.praise${Math.floor(Math.random() * 7)}`), [t])

    return (
        <div
            className='rolemodel-praise ion-text-left'
        >
            <Trans
                i18nKey='talent.roleModelIntro1'
                values={{
                    praise,
                    name: talent.caption.replaceAll(' ' ,'\u00a0'),
                }}
                // eslint-disable-next-line react/jsx-key
                components={[<b />]}
            />
            <div>
                {t('talent.roleModelIntro2')}
            </div>
        </div>
    )
}

export default RolemodelPraise
