import {IonButton, IonChip, IonIcon} from '@ionic/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type {Activity, User} from '../../../lib/apollo/types'
import {
    useCreateNetworkTokenInvitationMutation,
    useInviteNetworkWithTalentMutation,
    useNetworkQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import type { TalentApplication } from '../../Activity/InviteTalentActivity'
import Buttons from '../../Buttons'
import { DEEPLINK_JOIN_NETWORK } from '../../DeeplinkRouter/deeplinkRoutes'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import TalentCard from '../../NewTalentCard'
import Button from '../index'

import './style.scss'
import {checkmark} from 'ionicons/icons'

const AnswerTalentApplicationButton: React.FC<{ application: TalentApplication, userId: string, from: User, activity: Activity } & React.ComponentProps<typeof IonButton>> = ({
    application,
    userId,
    from,
    activity,
    ...buttonProps
}) => {
    const { t } = useTranslation()
    const user = useUser()
    const [isOpen, setIsOpen] = useState(false)

    const [createNetworkTokenInvitationMutation] = useCreateNetworkTokenInvitationMutation()
    const [inviteNetworkWithTalentMutation] = useInviteNetworkWithTalentMutation()

    const { data: network } = useNetworkQuery({
        variables: {
            userId: user?.user.id ?? '',
            networkId: `${application.networkId}`,
        },
        skip: !application.networkId || !user,
    })

    const acceptInvitation = useCallback(async () => {
        if(!user) return

        // create a DOI Link
        const response = await createNetworkTokenInvitationMutation({
            variables: {
                networkId: `${application.networkId}`,
                userId: user.user.id,
                input: {
                    type: 'invitationEmail',
                    withObject: true,
                    recipients: [{
                        email: 'aasd@aasd.de',
                    }],
                },
            },
        })
        const shareUrl = DEEPLINK_JOIN_NETWORK +
        response.data?.createNetworkTokenInvitation.tokens[0].token

        // create the activity to invite the user including his talentCARD
        await inviteNetworkWithTalentMutation({
            variables: {
                input: {
                    activityId: activity.id,
                    networkId: application.networkId,
                    link: shareUrl,
                    targetUserId: +from.id,
                },
            },
        })

        setIsOpen(false)
    }, [activity.id, application.networkId, createNetworkTokenInvitationMutation, from.id, inviteNetworkWithTalentMutation, user])

    const declineInvitation = useCallback(async () => {
        setIsOpen(false)
    }, [])

    return (
        <>
            {application.pending ? (
                <IonChip color='success'>
                    <IonIcon icon={checkmark} color='primary' className='ion-no-margin' size='large'/>
                </IonChip>
                ) : (
                <Button
                    {...buttonProps}
                    onClick={() => setIsOpen(true)}
                >
                    {t('buttons.answer')}
                </Button>
            )}
            <Modal
                size='fullscreen'
                isOpen={isOpen}
                modalTitle={t('title.invitation')}
                onClose={() => setIsOpen(false)}
            >
                {!!application.talent && (
                    <ItemList>
                        <div
                            className='application-answer__talent-card'
                        >
                            <TalentCard
                                talent={application.talent}
                                type='small'
                                shared
                            />
                        </div>

                        <div
                            className='application-answer__content'
                        >
                            <Buttons
                                subtitle={t('networkTalentApplication.description', { network: network?.network?.caption, talent: application.talent?.caption })}
                            >
                                <Button
                                    color='secondary'
                                    onClick={acceptInvitation}
                                >
                                    {t('buttons.sendInvitation')}
                                </Button>
                                <Button
                                    onClick={declineInvitation}
                                >
                                    {t('buttons.decline')}
                                </Button>
                            </Buttons>
                        </div>
                    </ItemList>
                )}
            </Modal>
        </>
    )
}

export default AnswerTalentApplicationButton
