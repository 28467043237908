import { Network } from '@capacitor/network'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useToast from '../../providers/Toast/hooks'

const useNetworkStatus = ():void => {
    const { t }  = useTranslation()
    const [present, dismiss] = useToast()
    const [status, setStatus] = useState<'wifi' | 'cellular' | 'none' | 'unknown'>('cellular')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        Network.addListener('networkStatusChange', s => {
            setStatus(s.connectionType)
        })

        return Network.removeAllListeners
    }, [])

    useEffect(() => {
        if (['none', 'unknown'].includes(status)) {
            present(t('networkChanged.warning'), 'warning', {
                duration: undefined,
            })
        } else {
            dismiss()
        }

    }, [dismiss, present, status, t])
}

export default useNetworkStatus
