const HsvRaute: React.FC = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 81.395248 60.236874'
        xmlSpace='preserve'
        version='1.1'
    >
        <metadata
            id='metadata8'
        />
        <defs
            id='defs6'
        />
        <g
            transform='matrix(1.25,0,0,-1.25,0,60.236875)'
            id='g10'
        >
            <path
                id='path12'
                style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d='m 0,0 65.116,0 0,48.19 L 0,48.19 0,0 Z'
            />
            <path
                id='path14'
                style={{ fill: '#1e5cb3', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                d='m 1.531,1.417 62.055,0 0,45.354 -62.055,0 0,-45.354 z'
            />
            <g
                transform='translate(52.3916,24.0947)'
                id='g16'
            >
                <path
                    id='path18'
                    style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                    d='M 0,0 -19.833,19.833 -39.667,0 -19.833,-19.833 0,0 Z'
                />
            </g>
            <g
                transform='translate(44.8374,24.0947)'
                id='g20'
            >
                <path
                    id='path22'
                    style={{ fill: '#231f20', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                    d='M 0,0 -12.279,12.281 -24.559,0 -12.279,-12.279 0,0 Z'
                />
            </g>
            <g
                transform='translate(36.8081,24.0947)'
                id='g24'
            >
                <path
                    id='path26'
                    style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                    d='M 0,0 -4.252,4.25 -8.5,0 -4.252,-4.248 0,0 Z'
                />
            </g>
        </g>
    </svg>
)

export default HsvRaute
