import { IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import type { Playlist } from '../../../lib/apollo/types'
import stopPropagation from '../../../lib/utils/stopPropagation'
import PlaylistContextMenu from '../../../pages/Playlist/PlaylistContextMenu'
import CustomAvatar from '../../CustomAvatar'
import { WATCHLIST_CAPTION } from '../PlayListConstants'

import './style.scss'

interface PlaylistListItemProps {
    playlist: Playlist
    hideContextMenu?: boolean
    onClick?: () => void
}

const PlaylistListItem: React.FC<PlaylistListItemProps> = ({ onClick, playlist, hideContextMenu = false }) => {
    const { t } = useTranslation()
    return (
        <IonCard
            onClick={onClick}
            routerLink={onClick ? undefined : `/playlists/detail/${playlist.id}`}
            className='playlist-list-item'
        >
            <CustomAvatar
                img={playlist.latestObject?.image}
                type='playlist'
                width={106.66}
            />
            <IonGrid
                className='playlist-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='playlist-list-item__name ion-no-margin'
                        >
                            { playlist.caption === WATCHLIST_CAPTION ? t('playlist.watchlist') : playlist.caption }
                        </IonCardTitle>
                        {playlist.public && (
                            <IonCardSubtitle>
                                { t('playlist.isPublic')}
                            </IonCardSubtitle>
                        )}
                        {!playlist.latestObject && (
                            <IonCardSubtitle>
                                <em>
                                    { t('playlist.noTalents')}
                                </em>
                            </IonCardSubtitle>
                        )}
                    </IonCol>

                    <IonCol
                        onClick={stopPropagation}
                        className='playlist-list-item__menu'
                    >
                        {!hideContextMenu && (
                            <PlaylistContextMenu
                                playlist={playlist}
                            />
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default PlaylistListItem
