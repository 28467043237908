import { useLayoutEffect, useMemo, useRef } from 'react'

import { createRadarGraphs } from './talentIdUtils'

interface GraphProps {
    id: string
    values: number[]
    compareValues?: number[]
    animateToValues?: number[][]
    animationIndex?: number
}

const Graph: React.FC<GraphProps> = ({
    id,
    values,
    compareValues,
    animateToValues,
    animationIndex,
}) => {
    const radarGraphsVals = useMemo(() => createRadarGraphs(values), [values])
    const radarGraphsCompareVals = useMemo(() => compareValues ? createRadarGraphs(compareValues) : undefined, [compareValues])
    const radarGraphsAnimateToVals = useMemo(() => animateToValues ? animateToValues.map(vals => createRadarGraphs(vals)) : undefined, [animateToValues])

    const animateRef = useRef<(SVGElement | null)[]>([])

    useLayoutEffect(() => {
        if(typeof animationIndex !== 'undefined' && animationIndex >= 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            animateRef.current[animationIndex]?.beginElement()
        }
    }, [animationIndex])

    if(radarGraphsAnimateToVals) {
        return (
            <path
                transform='translate(250, 250)'
                className='radar l-global radar-transform path--color1'
            >
                {radarGraphsAnimateToVals.map((vals, idx) => (
                    <animate
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${id}-animate-${idx}`}
                        ref={el => { animateRef.current[idx] = el }}
                        attributeName='d'
                        to={vals}
                        dur='.15s'
                        keySplines='0.4, 0, 0.2, 1'
                        begin='indefinete'
                        fill='freeze'
                    />
                ))}
            </path>
        )
    }

    if (!radarGraphsCompareVals) {
        return (
            <path
                transform='translate(250, 250)'
                className='radar l-global radar-transform path--color1'
                d={radarGraphsVals}
            >
                {radarGraphsAnimateToVals && (
                    <animate
                        attributeName='d'
                        values={radarGraphsAnimateToVals}
                        dur='.3s'
                        keyTimes='0; 0.25; 0.5; 0.75; 0.9; 1'
                        calcMode='spline'
                        keySplines='0.42 0 1 1; 0.42 0 1 1; 0.42 0 1 1; 0.42 0 1 1; 0.42 0 1 1'
                        fill='freeze'
                    />
                )}
            </path>
        )
    }

    return (
        <g
            transform='translate(250, 250)'
        >
            <defs>
                <mask
                    id={`${id}-graph-background`}
                >
                    <rect
                        x='-300'
                        y='-300'
                        width='600'
                        height='600'
                        style={{ fill:'white' }}
                    />
                    <path
                        d={radarGraphsCompareVals}
                        style={{ fill:'black', fillOpacity: 1 }}
                    />
                </mask>

                <mask
                    id={`${id}-graph-foreground`}
                >
                    <rect
                        x='-300'
                        y='-300'
                        width='600'
                        height='600'
                        style={{ fill: 'white' }}
                    />
                    <path
                        d={radarGraphsVals}
                        style={{ fill:'black', fillOpacity: 1 }}
                    />
                </mask>

                <mask
                    id={`${id}-graph-top`}
                >
                    <path
                        d={radarGraphsVals}
                        style={{ fill:'white', fillOpacity: 1 }}
                    />
                </mask>
            </defs>

            <path
                d={radarGraphsCompareVals}
                mask={`url(#${id}-graph-foreground`}
                className='radar-transform path--color2'
            />
            <path
                d={radarGraphsVals}
                mask={`url(#${id}-graph-background`}
                className='radar-transform path--color1'
            />
            <path
                d={radarGraphsCompareVals}
                mask={`url(#${id}-graph-top`}
                className='radar-transform path--overlap'
            />

            <path
                d={radarGraphsVals}
                className='radar-transform path--combined'
            />
        </g>

    )
}

export default Graph
