import './style.scss'
import { IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'

import TalentCardPaper from '../_Core/TalentCardPaper'
import { useContext } from 'use-context-selector'
import { TalentCardContext } from '../_Core/Context'
import getAge from '../../../../../lib/getAge'
import Divider from '../../../../Divider'
import {Certificate} from '../../../../../lib/apollo/types'
import TalentID from '../../RBLTalentID'
import TopSkills from '../../RBLTopSkills'

interface TalentCardMediumFrontProps {
  visible: boolean
  onFlipCardClick: () => void
}

const getSignature = (certificate: Certificate): string => {
    const year = certificate.camp.end ? (new Date(certificate.camp.end)).getFullYear().toString() : undefined
    const capture = certificate.camp.caption ?? ''

    return capture + (year ? ` ${year}` : '')
}

const TalentCardMediumFront: React.FC<TalentCardMediumFrontProps> = ({ visible, onFlipCardClick }) => {
  const { certificate, clubImg } = useContext(TalentCardContext)
  if (!certificate) return null

  const age = getAge(certificate.birthday, certificate.camp.end ? new Date(certificate.camp.end) : undefined)

  return (
        <TalentCardPaper
            className={clsx('rbl-talentcard-medium-front rbl-talentcard-medium-front--talent', {
              'rbl-talentcard-medium-front--visible': visible
            })}
        >
            <div
                className='rbl-talentcard-medium-front__top-container'
            >
                <div
                    className='display-flex-column ion-justify-content-center'
                >
                    <span>ALTER</span>
                    <h2
                        className='ion-text-center'
                    >
                        {age ?? (
                            <IonSkeletonText
                                style={{ width: 40 }}
                            />
                        )}
                    </h2>
                    {clubImg && (
                        <img
                            className='rbl-talentcard-medium-front__fifa-country negative-margin'
                            alt='club img'
                            src={clubImg}
                        />
                    )}
                </div>
            </div>

            <div
                className='rbl-talentcard-medium-front__bottom-container'
            >
                <div
                    className='rbl-talentcard-medium-front__profile-link'>
                    <h2
                        className='ion-text-center'
                    >
                        {certificate.caption ?? (
                            <IonSkeletonText
                                style={{ width: 200, margin: '0 auto 4px' }}
                            />
                        )}
                    </h2>
                </div>

                <Divider
                    margin={6}
                />

                <div
                    className='rbl-talentcard-medium-front__team-container'
                >
                    <div
                        className='rbl-talentcard-medium-front__team-container__meta'
                    >
                        <h4
                            className='ion-text-left'
                        >
                            {getSignature(certificate)}
                        </h4>
                        {/* <h4 */}
                        {/*    className='ion-text-left talentcard-medium-front__team' */}
                        {/* > */}
                        {/*    TEST2 */}
                        {/* </h4> */}
                    </div>
                </div>

                <Divider
                    margin={6}
                />

                <div
                    className='rbl-talentcard-medium-front__skills-wrapper'
                >
                    <TopSkills
                        values={certificate.vote}
                        talentClass={certificate.talentClass ?? 1}
                        withValues
                    />

                    <div
                        className='rbl-talentcard-medium-front__talentid-wrapper'
                        tabIndex={-1}
                        role='button'
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onFlipCardClick?.()
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onFlipCardClick()
                        }}
                    >
                        <TalentID
                            values={certificate.vote}
                            talentClass={certificate?.talentClass === 2 ? '2' : '1'}
                        />
                    </div>

                </div>
            </div>
        </TalentCardPaper>
  )
}

export default TalentCardMediumFront
