import {Capacitor} from '@capacitor/core'
import {Keyboard, KeyboardResize, KeyboardStyle} from '@capacitor/keyboard'
import {StatusBar, Style} from '@capacitor/status-bar'
import {ScreenOrientation} from '@ionic-native/screen-orientation'

if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.setStyle({ style: Style.Dark })
    Keyboard.setStyle({ style: KeyboardStyle.Dark })
    Keyboard.setResizeMode({ mode: KeyboardResize.None})
}

if (Capacitor.isNativePlatform() && !window.document.documentElement.classList.contains('plt-tablet')) {
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
}
