import type { Activity, TalentTransferRequest } from '../../lib/apollo/types'
import type { InitAppAction } from '../../lib/hooks/InitActions/initActions.actions'

type MarketplaceRecentSearch = {
    type: 'search'
    data: string
} | {
    type: 'similar',
    data: string
}

export interface AppState {
    newActivities: Activity[]
    newForeignActivities: Activity[]
    marketplaceRecentSearches: MarketplaceRecentSearch[]
    initActions: InitAppAction[]

    pendingTalentTransferRequests: TalentTransferRequest[]
}

export enum AppActionType {
    SetState,
    MarketplaceSearchHistoryAdd,
    InitAppActionAdd,
    InitAppActionRemove
}

export interface SetAppState {
    type: AppActionType.SetState,
    payload: Partial<AppState>
}

export interface MarketplaceSearchHistoryAdd {
    type: AppActionType.MarketplaceSearchHistoryAdd,
    payload: MarketplaceRecentSearch
}

export interface InitAppActionAdd {
    type: AppActionType.InitAppActionAdd,
    action: InitAppAction
}

export interface InitAppActionRemove {
    type: AppActionType.InitAppActionRemove,
    action: InitAppAction
}

export type AppAction = SetAppState
| MarketplaceSearchHistoryAdd
| InitAppActionAdd
| InitAppActionRemove
