import { ApolloProvider } from '@apollo/client'
import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { IonApp , setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { createInstance, MatomoProvider, useMatomo } from '@jonkoops/matomo-tracker-react'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import smoothscroll from 'smoothscroll-polyfill'
import { v4 as uuid4 } from 'uuid'

import AppShell from './components/AppShell'
import AppStateProvider from './components/AppState'
import DeeplinkRouter from './components/DeeplinkRouter'
import apolloClient from './lib/apollo'
import { ENV_NAME } from './lib/apollo/config'
import AuthProvider from './providers/Auth'
import { GlobalLoadingProvider } from './providers/GlobalLoading'
import { OnboardingProvider } from './providers/Onboarding'
import { ToastProvider } from './providers/Toast'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import 'swiper/css'

/* Theme  */
import './theme/theme.scss'

// kick off the polyfill!
smoothscroll.polyfill()

const getSiteId = (): number => {
    switch (ENV_NAME) {
        case 'prod':
            return 3
        case 'sandbox':
            return 2
        default:
            return 1
    }
}

const instance = createInstance({
    urlBase: 'https://matomo.krischey-schulz.de',
    siteId: getSiteId(),
    configurations: {
        disableCookies: true,
    },
})

setupIonicReact()

const App: React.FC = () => {
    const { pushInstruction } = useMatomo()

    useEffect(() => {
        const getUserId = async () => {
            if(Capacitor.isNativePlatform()) {
                const info = await CapacitorApp.getInfo()
                pushInstruction('setCustomDimension', [1, info.version])
            }
            const { value } = await Preferences.get({ key: 'user-id' })
            if (!value) {
                const uuid = uuid4()
                Preferences.set({ key: 'user-id', value: uuid })
                pushInstruction('setUserId', uuid)
            } else {
                pushInstruction('setUserId', value)
            }
        }
        getUserId()
    }, [pushInstruction])

    return (
        <MatomoProvider
            value={instance}
        >
            <HelmetProvider>
                <AppStateProvider>
                    <IonApp>
                        <IonReactRouter>
                            <DeeplinkRouter>
                                <GlobalLoadingProvider>
                                    <ApolloProvider
                                        client={apolloClient}
                                    >
                                        <ToastProvider>
                                            <AuthProvider>
                                                <OnboardingProvider>
                                                    <AppShell />
                                                </OnboardingProvider>
                                            </AuthProvider>
                                        </ToastProvider>
                                    </ApolloProvider>
                                </GlobalLoadingProvider>
                            </DeeplinkRouter>
                        </IonReactRouter>
                    </IonApp>
                </AppStateProvider>
            </HelmetProvider>
        </MatomoProvider>
    )
}

export default App
