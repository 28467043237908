import {
    IonAccordion,
    IonIcon,
    IonItem,
    IonLabel,
    IonText, useIonAlert,
} from '@ionic/react'
import { informationCircleOutline, trash } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import Form from '../../../components/Form'
import FormTextField from '../../../components/Form/FormTextField'
import ItemList from '../../../components/ItemList'
import type { DeleteAccountInput } from '../../../lib/apollo/types'
import { useDeleteAccountMutation } from '../../../lib/apollo/types'
import { signOut } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'

import './style.scss'

const DeleteAccount: React.FC = () => {
    const { t } = useTranslation()
    const [presentToast] = useToast()
    const [presentAlert] = useIonAlert()
    const [deleteAccount, { loading }] = useDeleteAccountMutation()

    const postDeleteAccount = useCallback(async (input: DeleteAccountInput) => {
        try {
            const response = await deleteAccount({
                variables: {
                    input,
                },
                errorPolicy: 'none',
            })

            if (response.data?.deleteAccount) {
                presentToast(t('settings.deleteAccount.success'), 'success')
                setTimeout(() => {
                    signOut()
                }, 3000)
            } else {
                presentToast(t('settings.deleteAccount.failed'), 'danger')
            }
        } catch (e) {
            presentToast(t('settings.deleteAccount.failed'), 'danger')
        }

    }, [deleteAccount, presentToast, t])

    const onSubmit = useCallback( async (input: DeleteAccountInput) => {
        await presentAlert({
            header: t('settings.deleteAccount.hintHeadline'),
            message: t('settings.deleteAccount.hintDescription'),
            buttons: [
                t('buttons.cancel'),
                { text:  t('buttons.ok'), handler: () => postDeleteAccount(input) },
            ],
        })
    }, [postDeleteAccount, presentAlert, t])

    return (
        <IonAccordion
            className='delete-account'
        >
            <IonItem
                slot='header'
            >
                <IonLabel>{t('settings.deleteAccount.title')}</IonLabel>
            </IonItem>
            <IonItem
                lines='none'
                slot='content'
            >
                <IonIcon
                    icon={informationCircleOutline}
                    slot='start'
                />
                <IonLabel
                    className='ion-text-wrap'
                >
                    <IonText
                        color='medium'
                    >
                        {t('settings.deleteAccount.description')}
                    </IonText>
                </IonLabel>
            </IonItem>
            <IonItem
                slot='content'
                lines='none'
            >
                <Form
                    onSubmit={onSubmit}
                    className='password-settings__form'
                >
                    <ItemList
                        className='password-settings__input-wrapper'
                    >
                        <FormTextField
                            required
                            type='password'
                            name='password'
                            label={t('login.password')}
                        />
                    </ItemList>
                    <Buttons>
                        <Button
                            loading={loading}
                            fill='solid'
                            color='secondary'
                            type='submit'
                            icon={trash}
                            size='default'
                            expand='block'
                        >
                            {t('settings.deleteAccount.submit')}
                        </Button>
                    </Buttons>
                </Form>
            </IonItem>
        </IonAccordion>
    )
}

export default DeleteAccount
