import { IonCard, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../../CustomAvatar'

const NetworkAddListItem: React.FC = () => {
    const { t } = useTranslation()

    return (
        <IonCard
            routerLink='/home/network/new/edit'
            className='network-list-item'
        >
            <CustomAvatar
                width={106.66}
                icon={addCircleOutline}
                type='network'
            />
            <IonGrid
                className='network-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='network-list-item__name'
                        >
                            { t('networks.add') }
                        </IonCardTitle>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default NetworkAddListItem
