import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { User } from '../../../lib/apollo/types'
import { NetworkRole } from '../../../lib/apollo/types'

const useNetworkRoleTranslation = (user?: User): string | undefined => {
    const { t } = useTranslation()

    const role = useMemo(() => {
        if (!user?.networkRoles || user.networkRoles.length === 0) return undefined

        if (user.networkRoles.includes(NetworkRole.Owner)) return NetworkRole.Owner
        if (user.networkRoles.includes(NetworkRole.Mod)) return NetworkRole.Mod
        if (user.networkRoles.includes(NetworkRole.Member)) return NetworkRole.Member

        return undefined
    }, [user])

    return role ? t(`user.networkRole.${role}`) : undefined
}

export default useNetworkRoleTranslation
