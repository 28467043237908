import { IonBadge, IonButton, IonIcon, IonLabel } from '@ionic/react'
import clsx from 'clsx'

import './style.scss'

interface TabButtonProps {
    badge?: string | number
    tab: string
    icon: string
    round?: boolean
    active?: boolean
}

const defaultProps: React.ComponentProps<typeof IonButton> = {
    fill: 'clear',
}

const TabButton: React.FC<TabButtonProps & React.ComponentProps<typeof IonButton>> = ({ badge, tab, icon, round, active, children, ...buttonProps }) => {

    return (
        <div
            className='tab-btn'
        >
            <IonButton
                {...defaultProps}
                {...buttonProps}
                routerDirection='root'
                routerLink={`/${tab}`}
                routerOptions={{ unmount: true }}
                className={clsx('tab-btn__btn', { 'tab-btn__btn--active': active, 'tab-btn__btn--round': round })}
            >
                <div
                    className='tab-btn__btn__inner'
                >
                    <IonIcon
                        icon={icon}
                        slot='start'
                    />
                    { children && (
                        <IonLabel>
                            {children}
                        </IonLabel>
                    )}
                </div>
            </IonButton>

            { badge && (
                <IonBadge
                    className='tab-btn__badge'
                >
                    {badge}
                </IonBadge>
            )}
        </div>
    )
}

export default TabButton
