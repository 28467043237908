const Announcement: React.FC<{ className?: string, color?: string }> = ({ className, color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 256 256'
            className={className}
        >
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='10'
                d='M161.4,24.2c-2.5,0.5-4.2,1.6-4.2,2.7c0,0.5,1.3,3.6,3,7c18.1,37.6,29.9,86,31.6,129.1c0.3,7.2,0.4,8.1,1.3,8.9c1.6,1.4,5.9,0.8,7.8-1.1c0.9-1,1-1.3,0.7-7.5c-0.8-18.1-3.9-42.3-7.7-59.3c-4.9-22.3-14.2-49.1-26.3-76.1C165.9,24.4,164.7,23.6,161.4,24.2z'
                style={{ strokeDasharray: '321, 321', strokeDashoffset: '0' }}
            />
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='5'
                d='M204.2,37.9c-2.6,4.7-2.7,5-1.9,5.6c15.3,10.5,23.9,21.4,28.9,36.6c2.4,7.5,3,11.5,3,20.7c0,11.8-1.7,19.4-6.6,29.3c-1.2,2.5-2.3,4.6-2.4,4.8c-0.1,0.2,2.1,1.5,5,2.9l5.1,2.5l2.4-4.7c11-21.9,10.9-48-0.1-70c-6.4-12.9-17.5-24.9-29.1-31.7l-1.7-0.9L204.2,37.9z'
                style={{ strokeDasharray: '252, 252', strokeDashoffset: '0' }}
            />
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='10'
                d='M151.9,36.7c-6.8,6.9-19,17.4-29,24.8c-21.4,15.9-45.4,29.3-69.1,38.4c-3.5,1.3-6.5,2.5-6.6,2.6c-0.3,0.3,0.7,13.9,1.5,18.6c1.4,9.4,4.6,19.4,9.6,30c1.1,2.5,2.3,4.6,2.5,4.8c0.2,0.1,4-0.1,8.3-0.6c30.6-3.5,64-1.6,95.2,5.4c6.5,1.4,19.7,5,22.2,6c0.9,0.4,0.9-1.4-0.2-17.8c-2.1-33.9-10.5-68.1-24.5-100.2c-2.4-5.3-6.8-14.6-7.1-14.6C154.7,33.9,153.4,35.1,151.9,36.7z'
                style={{ strokeDasharray: '450, 450', strokeDashoffset: '0' }}
            />
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='4'
                d='M195.2,54.6c-1.1,2-2.4,4.3-2.8,5l-0.9,1.4l2,1.3c2.8,1.9,8.4,7.4,11,10.9c3.1,4.1,6.2,10.8,7.7,16.4c1.1,4,1.2,5.6,1.2,11.6s-0.2,7.5-1.2,11.6c-0.6,2.5-2.1,6.6-3.3,8.9l-2,4.3l5,2.5c4,2,5.1,2.4,5.6,1.9c0.9-1.1,3.7-7.5,4.9-11.2c3.4-10.8,3.7-21.6,1-32.6c-3.5-14-12.9-27.1-24.9-34.8l-1.2-0.8L195.2,54.6z'
                style={{ strokeDasharray: '188, 188', strokeDashoffset: '0' }}
            />
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='10'
                d='M37.4,105.7c-0.6,0.2-3.6,1.1-6.5,1.9c-7.1,1.9-10.2,3.5-14,7.3c-4.5,4.5-6.9,10.2-6.9,16.4c0,3.1,2.4,13.1,4,16.3c3.1,6.6,10.5,11.8,18.3,12.8c3.8,0.5,4.2,0.5,12-1.3c4.4-1,8.1-1.8,8.1-1.9c0.1,0-1-2.5-2.4-5.6c-6.2-13.8-9.4-26.8-10.3-41.3C39.5,105,39.4,104.8,37.4,105.7z'
                style={{ strokeDasharray: '158, 158', strokeDashoffset: '0' }}
            />
            <path
                fillOpacity='0'
                strokeLinecap='round'
                strokeLinejoin='round'
                stroke={color ? `var(--ion-color-${color})` : '#000000'}
                strokeOpacity='1'
                strokeWidth='10'
                d='M92.6,159.6c-9.9,0.6-21.8,2.6-24.4,4.2c-2.7,1.5-2.3,2.6,2.6,7.4c4.3,4.2,7.6,8.4,8.8,11.4c0.4,0.8,1.3,4.6,2,8.4c3.4,16.9,9.4,30.2,16.5,36.7c4.6,4.2,7.8,5,14.7,3.5c7.5-1.6,12.4-5.9,12.4-10.9c0-1.2-0.8-3.9-2-7c-3.7-9.1-4.2-11.9-4.2-20.7c0-6.9,0.1-8.1,1.3-11.8c2.9-9.9,3.6-12.9,3.4-15.8c-0.1-1.6-0.3-3-0.5-3.2c-0.6-0.6-7.1-1.5-13.7-2C103.7,159.4,98.1,159.3,92.6,159.6z'
                style={{ strokeDasharray: '224, 224', strokeDashoffset: '0' }}
            />
        </svg>
    )
}

export default Announcement
