import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import { useNetworksQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import Slider from '../../Slider'
import NetworkAddCardSmall from '../NetworkCard/NetworkCardSmall/NetworkAddCardSmall'
import NetworkCardSmall from '../NetworkCard/NetworkCardSmall/NetworkCardSmall'
import NetworkCardSmallSkeleton from '../NetworkCard/NetworkCardSmall/NetworkCardSmallSkeleton'

const NetworksTeaserSlider: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data } = useNetworksQuery({ variables: { userId: user?.user.id || '', start: 1, limit: 4 }, skip: !user })
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    // update slider if elements changed
    useEffect(() => {
        controlledSwiper?.update()
    }, [controlledSwiper, data?.networks.collection])

    return (
        <Slider
            freeMode
            onSwiper={setControlledSwiper}
            link='/home/networks'
            title={t('networks.title')}
            subtitle={t('networks.subtitle')}
            className='teams'
            key={JSON.stringify(data?.networks?.collection)}
        >
            {data?.networks.collection.map((network) => (
                <SwiperSlide
                    key={network.id}
                >
                    <NetworkCardSmall
                        network={network}
                    />
                </SwiperSlide>
            )) ?? (new Array(6)).fill(undefined).map((_, i) => (
                <SwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={`preview_${i}`}
                >
                    <NetworkCardSmallSkeleton />
                </SwiperSlide>
            ))}
            <SwiperSlide>
                <NetworkAddCardSmall />
            </SwiperSlide>
        </Slider>
    )
}

export default NetworksTeaserSlider
