import { IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'

import type { CustomAvatarType } from '.'

import './style.scss'

interface CustomAvatarSkeletonProps {
    width?: number | string
    square?: boolean
    type?: CustomAvatarType
    className?: string
}

const CustomAvatarSkeleton: React.FC<CustomAvatarSkeletonProps> = ({
    width = 80,
    type= 'person',
    className,
}) => (
    <div
        style={{ width }}
        className={clsx('custom-avatar', `custom-avatar--${type}`, className)}
    >
        <div
            className='custom-avatar__position-helper'
        >
            <IonSkeletonText
                animated
            />
        </div>
    </div>
)

export default CustomAvatarSkeleton
