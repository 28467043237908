import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import useTabContext from '../../lib/hooks/useTabContext'
import { AppContext } from '../AppState'
import Button from '../Button'
import { getNewForeignActivitesBadge, getTalentTransferBadge, showTabBarRoutes } from '../TabBar'
import './style.scss'

const links = [
    'home',
    'profile',
    'marketplace',
    'activities',
    'playlists',
]

const DesktopMenu: React.FC = () => {
    const { t } = useTranslation()
    const activeUrl = useTabContext()

    const { newForeignActivities, pendingTalentTransferRequests } = useContextSelector(AppContext, state => state.state)
    if (!showTabBarRoutes.includes(`/${activeUrl}`)) return null

    return (
        <div
            className='desktop-menu'
        >
            { links.map(link => {
                const badge = (() => {
                    switch (link) {
                        case 'activities': return getNewForeignActivitesBadge(newForeignActivities)
                        case 'profile': return getTalentTransferBadge(pendingTalentTransferRequests)
                        default: return undefined
                    }
                })()

                return (
                    <Button
                        key={link}
                        className='ion-margin-horizontal'
                        fill='clear'
                        color={activeUrl === link ? 'secondary' : undefined}
                        routerLink={`/${link}`}
                        routerOptions={{ unmount: true }}
                        badge={badge}
                    >
                        {t(`tabbar.${link}`)}
                    </Button>
                )
            })}
            <Button
                className='ion-margin-horizontal'
                fill='clear'
                href='https://backoffice.joinmytalent.com/teams'
            >
                {t('tabbar.backoffice')}
            </Button>
        </div>
    )
}

export default DesktopMenu
