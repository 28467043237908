import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonSkeletonText } from '@ionic/react'

import CustomAvatarSkeleton from '../../../CustomAvatar/CustomAvatarSkeleton'
import './style.scss'

const NetworkCardSmallSkeleton: React.FC = () => (
    <IonCard
        className='network-card-small'
    >
        <CustomAvatarSkeleton
            type='network'
            width='100%'
        />

        <IonCardContent>
            <IonCardTitle>
                <IonSkeletonText
                    style={{ width: '64px' }}
                />
            </IonCardTitle>
            <IonCardSubtitle>
                <IonSkeletonText
                    color='dark'
                    style={{ width: '120px' }}
                />
                <IonSkeletonText
                    style={{ width: '56px' }}
                />
            </IonCardSubtitle>
        </IonCardContent>
    </IonCard>
)

export default NetworkCardSmallSkeleton
