import { useTranslation } from 'react-i18next'

import Content from '../../components/Content'
import Header from '../../components/Header'
import NetworkHelp from '../../components/HelpSlider/Help/Network'
import InfiniteScrollWithHook from '../../components/InfiniteScrollWithHook'
import ItemList from '../../components/ItemList'
import NetworkListItem from '../../components/Network/NetworkListItem'
import NetworkAddListItem from '../../components/Network/NetworkListItem/NetworkAddListItem'
import Page from '../../components/Page'
import type { Network } from '../../lib/apollo/types'
import { useNetworksLazyQuery } from '../../lib/apollo/types'

import NetworksContextMenu from './NetworksContextMenu'

const Networks: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Page
            trackingTitle='networks'
        >
            <Header
                buttonLeft='back'
                title={t('title.networks')}
            >
                <NetworksContextMenu />
            </Header>
            <Content
                fullscreen
            >
                <NetworkHelp />
                <ItemList>
                    <InfiniteScrollWithHook
                        lazyHook={useNetworksLazyQuery}
                        placeholder={(
                            <>
                                <NetworkListItem />
                                <NetworkListItem />
                                <NetworkListItem />
                                <NetworkListItem />
                            </>
                        )}
                    >
                        {(networks: Network[] | undefined) => (
                            <>
                                {networks?.map(network => (
                                    <NetworkListItem
                                        key={network.id}
                                        network={network}
                                    />
                                )) ?? null}
                                <NetworkAddListItem />
                            </>
                        )}
                    </InfiniteScrollWithHook>
                </ItemList>
            </Content>
        </Page>
    )
}

export default Networks
