import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import type { HelpSliderModalProps } from '../TalentId'

const PlaylistHelp: React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()
    return (
        <OnboardingModal
            open={open}
            setOpen={setOpen}
            id='playlists-page'
        >
            <SwiperSlide>
                <h1>
                    {t('playlists.title')}
                </h1>
                <div>
                    {t('playlists.onboarding.1')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div>
                    {t('playlists.onboarding.2')}
                </div>
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default PlaylistHelp
