import { useApolloClient } from '@apollo/client'
import type { RefresherEventDetail } from '@ionic/react'
import { IonContent, IonRefresher, IonRefresherContent, useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { forwardRef, useCallback, useLayoutEffect, useState } from 'react'

import { WhoamiDocument } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import { showTabBarRoutes } from '../TabBar'

import './style.scss'

const Content = forwardRef<HTMLIonContentElement | null, React.ComponentProps<typeof IonContent>>(({ children, ...props }, ref) => {
    const [hideTabBar, setHideTabBar] = useState(true)
    const { routeInfo: { pathname } } = useIonRouter()

    useLayoutEffect(() => {
        setHideTabBar(!showTabBarRoutes.some((route: string) => pathname.startsWith(route)))
    }, [pathname])

    const client = useApolloClient()
    const user = useUser()

    const handleRefresh = useCallback(async (event: CustomEvent<RefresherEventDetail>) => {
        const savedUser = { ...user }
        client.onResetStore(async () => {
            client.writeQuery({
                query: WhoamiDocument,
                data: { whoami: savedUser },
            })
        })
        try {
            await client.resetStore()
        } catch (e) {
            // do nothing
        }
        setTimeout(() => {
            event.detail.complete()
        }, 600)
    }, [user, client])

    return (
        <IonContent
            ref={ref}
            {...props}
            className={clsx('content', props.className, { 'content--with-tab-bar': !hideTabBar })}
        >
            {!hideTabBar && (
                <IonRefresher
                    slot='fixed'
                    onIonRefresh={handleRefresh}
                >
                    <IonRefresherContent
                        refreshingSpinner='circular'
                        color='secondary'
                    />
                </IonRefresher>
            )}

            { children }
        </IonContent>
    )
})

Content.displayName = 'Content'

export default Content
