import { generateUUID } from '@ionic/cli/lib/utils/uuid'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'

import type { AnimationLayer, TalentDataView, TalentPosition } from '../../../lib/apollo/types'

import GlobalDefs from './GlobalDefs'
import Layer from './Layer'
import Levels from './Levels'
import TalentIDSkeleton from './TalentIDSkeleton'
import TouchArea from './TouchArea'
import useLayers from './useLayers'

import './style.scss'

interface TalentIDProps {
    dataView1?: TalentDataView[],
    dataView2?: TalentDataView[],
    talentPosition: TalentPosition,
    defaultIndex?: number
    onIndexChange?: (index: number) => any
    interactive?: boolean
    className?: string
    backgroundColor?: 'secondary' | 'tertiary' | 'black' | 'secondary-transparent' | 'scout' | 'roleModel'
    color1?: 'primary' | 'secondary' | 'tertiary' | 'black'
    color2?: 'primary' | 'secondary' | 'tertiary' | 'black'
    under16: boolean
    id?: string
    animateTo?: AnimationLayer[]
    animationIndex?: number
    disableEnterAnimation?: boolean
}

const TalentID: React.FC<TalentIDProps> = ({
    color1 = 'primary',
    color2= 'tertiary',
    backgroundColor = 'secondary',
    className,
    dataView1,
    dataView2,
    talentPosition,
    defaultIndex = 0,
    onIndexChange,
    interactive ,
    under16,
    id,
    animateTo,
    animationIndex,
    disableEnterAnimation= false,
}) => {
    // textPath fix (multiple svgs with same id leads to wrong path calculation)
    const uuidRef = useRef(generateUUID())

    const [activeIndex, setActiveIndex] = useState(defaultIndex)

    const layers = useLayers(talentPosition, dataView1, dataView2, under16, animateTo)
    useEffect(() => {
        onIndexChange?.(activeIndex)
    }, [activeIndex, onIndexChange])

    const layerStyles: CSSProperties = {}

    if(layers[activeIndex]?.backgroundColor) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layerStyles['--ion-color-secondary'] = layers[activeIndex].backgroundColor
    }

    if (!layers || layers.length === 0) return <TalentIDSkeleton />

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            preserveAspectRatio='xMidYMid meet'
            viewBox='-60 -60 620 620'
            className={clsx(
                'layer-global',
                `layer-global__background--${backgroundColor}`,
                `layer-global__color1--${color1}`,
                `layer-global__color2--${color2}`,
                className,
                { interactive, animated: !disableEnterAnimation },
            )}
            style={layerStyles}
            id={id}
        >
            <GlobalDefs
                id={uuidRef.current}
            />

            {layers[activeIndex] && (
                <Layer
                    id={uuidRef.current}
                    layer={layers[activeIndex]}
                    animationIndex={animationIndex}
                />
            )}

            <Levels />

            <TouchArea
                onClick={() => {
                    setActiveIndex(aId => (aId + 1) % layers.length)
                }}
            />
        </svg>
    )
}

export default TalentID
