import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { arrowBack, football, personCircle, mailOutline } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../lib/apollo/types'
import { PermissionLevel, useCreateShanghaiTalentMutation, useJmtNetworksQuery } from '../../lib/apollo/types'
import useTabContext from '../../lib/hooks/useTabContext'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import { MatomoEventCategory } from '../../lib/tracking/eventCategories'
import type { TalentCardDetailPageMode } from '../../pages/Talent'
import { useUser } from '../../providers/Auth/hooks'
import Button from '../Button'
import Buttons from '../Buttons'
import GetYourSkillsConfirmedButton from '../GetYourSkillsConfirmedButton'
import Modal from '../Modal'
import ShareTalentButton from '../ShareTalentButton'
import TalentPerformanceVoteButton from '../Talent/TalentPerformanceVoteButton'
import TalentVoteButton from '../Talent/TalentVoteButton'
import TopSkills from '../Talent/TopSkills'
import ChoseTopSkillsButton from '../Talent/TopSkills/ChoseTopSkills/ChoseTopSkillsButton'

import './style.scss'

interface TalentActionButtonsProps {
    talent?: Talent
    mode?: TalentCardDetailPageMode
    permissionLevel?: PermissionLevel
    networkId?: string
    shared?: boolean
}

const TalentActionButtons: React.FC<TalentActionButtonsProps> = ({
    talent,
    networkId,
    permissionLevel= talent?.permissionLevel ?? PermissionLevel.None,
    mode= 'default',
    shared
}) => {
    const tab = useTabContext()
    const { isDesktop } = useWindowResizeListener()
    const { t } = useTranslation()

    const user = useUser()

    const certificateUserIds = t('shanghai.allowedUserIds').split(',')

    const [createShanghaiTalent, { loading }] = useCreateShanghaiTalentMutation()
    const [shareCertOpen, setShareCertOpen] = useState(false)
    const [certPages, setCertPages] = useState<string[]>()

    const networks = useJmtNetworksQuery()
    const isMarketPlace = useMemo(() =>
        !!networkId && networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')?.id === networkId
    , [networkId, networks?.data?.jmtNetworks.JMTnetworks.collection])
    const [contactComingSoonOpen, setContactComingSoonOpen] = useState(false)
    const { trackEvent } = useMatomo()

    const buttonsTitle = useMemo(() => {
        if (permissionLevel === PermissionLevel.Personal || permissionLevel === PermissionLevel.Administrative) {
            return talent?.personification
                ? 'buttons.getYourSkillsConfirmed'
                : 'buttons.shareTalent'
        }
        return 'buttons.talentVote'
    }, [permissionLevel, talent?.personification])

    const onCreateShanghaiTalent = useCallback(async () => {
        const response = await createShanghaiTalent({
            variables: {
                talentId: talent?.id ?? '',
            },
        })

        if (response.data?.createShanghaiTalent) {
            setCertPages(response.data.createShanghaiTalent)
            setShareCertOpen(true)
        }
    }, [talent?.id, createShanghaiTalent, setCertPages, setShareCertOpen])

    if (!talent) return null

    return (
        <div
            className='talent-action-buttons'
        >
            {(mode === 'details' && isDesktop && (talent.rateCount ?? 0) > 0) ? (
                <div>
                    <TopSkills
                        asCard
                        talent={talent}
                        withHeadline
                    />
                </div>
            ) : (
                // layout purpose
                <div />
            )}
            {!shared && (
                <Buttons
                    title={t(`${buttonsTitle}Title`)}
                    subtitle={t(`${buttonsTitle}Subtitle`, { name: talent.caption })}
                >
                    {/* dynamic content can not appear as first child here... Ionic b******t */}
                    {(permissionLevel === PermissionLevel.Personal || permissionLevel === PermissionLevel.Administrative) && talent.personification && (
                        <>
                            <GetYourSkillsConfirmedButton />
                            <ShareTalentButton
                                talent={talent}
                            />
                        </>
                    )}
                    {(mode === 'details' && isDesktop && permissionLevel !== PermissionLevel.None) && (
                        <ChoseTopSkillsButton
                            color='primary'
                            talent={talent}
                        />
                    )}
                    <TalentVoteButton
                        fill='solid'
                        talent={talent}
                        networkId={networkId}
                    />
                    {!isMarketPlace && networkId && (
                        <TalentPerformanceVoteButton
                            fill='solid'
                            talent={talent}
                            networkId={networkId}
                        />
                    )}
                    {mode === 'default' && (
                        <Button
                            fill='solid'
                            routerLink={`/${tab}/network/${networkId}/talents/${talent.id}/details`}
                            icon={mode === 'default' ? football : arrowBack}
                        >
                            {t('talent.details')}
                        </Button>
                    )}
                    {(!isMarketPlace && networkId && user?.user.id && certificateUserIds.includes(user.user.id)) ? (
                        <>
                            <Button
                                color='secondary'
                                icon={personCircle}
                                onClick={onCreateShanghaiTalent}
                                loading={loading}
                            >
                                {t('talent.certificate.create')}
                            </Button>
                            <Modal
                                modalTitle={t('talent.certificate.title')}
                                isOpen={shareCertOpen}
                                onDidDismiss={() => {setShareCertOpen(false)}}
                                onClose={() => {setShareCertOpen(false)}}
                                size='alert'
                            >
                                <Buttons>
                                    {Capacitor.isNativePlatform() ? (
                                        <Button
                                            color='secondary'
                                            onClick={async () => {
                                                await Share.share({
                                                    title: t('talent.certificate.title'),
                                                    text: t('talent.certificate.download.text'),
                                                    files: certPages,
                                                    dialogTitle: t('talent.certificate.title'),

                                                })
                                            }}
                                        >
                                            {t('buttons.download')}
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                color='secondary'
                                                onClick={() => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    window.open(certPages[0], '_blank').focus()
                                                }}
                                            >
                                                {t('cert.page.1')}
                                            </Button>
                                            <Button
                                                color='secondary'
                                                onClick={() => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    window.open(certPages[1], '_blank').focus()
                                                }}
                                            >
                                                {t('cert.page.2')}
                                            </Button>
                                        </>
                                    )}
                                </Buttons>
                        </Modal>
                    </>
                ) : null}
                {isMarketPlace && (
                    <>
                        <Modal
                            modalTitle={t('talent.contact')}
                            isOpen={contactComingSoonOpen}
                            onDidDismiss={() => {setContactComingSoonOpen(false)}}
                            onClose={() => {setContactComingSoonOpen(false)}}
                            size='alert'
                        >
                            <p
                                className='n2br ion-padding-horizontal text-white'
                            >
                                {t('talent.contact.comingSoon')}
                            </p>
                            <Buttons>
                                <Button
                                    onClick={() => {setContactComingSoonOpen(false)}}
                                >
                                    {t('buttons.ok')}
                                </Button>
                            </Buttons>
                        </Modal>
                        <Button
                            fill='solid'
                            icon={mailOutline}
                            onClick={() => {
                                trackEvent({
                                    action: 'contact',
                                    category: MatomoEventCategory.Talent,
                                })
                                setContactComingSoonOpen(true)
                            }}
                        >
                            {t('talent.contact')}
                        </Button>
                    </>
                )}
            </Buttons>
            )}
        </div>
    )
}

export default TalentActionButtons
