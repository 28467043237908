import { search } from 'ionicons/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import TeaserCard from '../../../../components/TeaserCard'
import type { Talent } from '../../../../lib/apollo/types'
import { TalentPosition } from '../../../../lib/apollo/types'

import '../../../../components/TeaserCard/style.scss'
import './style.scss'

interface PlayerTypeTeaserCardProps {
    talent?: Talent
}

const PlayerTypeTeaserCard: React.FC<PlayerTypeTeaserCardProps> = ({ talent }) => {
    const { t } = useTranslation()

    const topskills = useMemo(() =>
        talent?.customMeta?.topskillsindexes?.map(i => (
            t(`i18n.class.${talent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`)
        )).join(', '), [t, talent?.customMeta?.topskillsindexes, talent?.metas.fieldPosition])

    return (
        <TeaserCard
            darkColor
            img={talent?.image ?? ''}
            imgAlt='talent'
            title={t(talent?.caption ?? '')}
            subtitle={t('marketplace.findTalents', { topskills })}
            routerLink={`/marketplace/similar/${talent?.id}`}
            buttonText={t('marketplace.explore')}
            buttonIcon={search}
        />
    )
}

export default PlayerTypeTeaserCard
