import type { ActivityProps } from './ActivityProps'
import AddedToNetworkActivity from './AddedToNetworkActivity'
import ApplyTalentActivity from './ApplyTalentActivity'
import DeletedActivity from './DeletedActivity'
import FollowActivity from './FollowActivity'
import InvitationActivity from './InvitationActivity'
import InviteTalentActivity from './InviteTalentActivity'
import ShortVoteActivity from './ShortVoteActivity'
import VoteActivity from './VoteActivity'

const Activity: React.FC<ActivityProps> = ({ activity }) => {

    switch (activity.type) {
        // VOTES
        case 'SHORT_VOTE':{
            return (
                <ShortVoteActivity
                    activity={activity}
                />
            )
        }
        case 'VOTE': {
            return (
                <VoteActivity
                    activity={activity}
                />
            )
        }
        // FOLLOW
        case 'FOLLOW':
        case 'UNFOLLOW': {
            return (
                <FollowActivity
                    activity={activity}
                />
            )
        }
        // INVITATION
        case 'invitation': {
            return (
                <InvitationActivity
                    activity={activity}
                />
            )
        }
        // TALENT APPLICATION
        case 'TALENT_APPLY_NETWORK': {
            return (
                <InviteTalentActivity
                    activity={activity}
                />
            )
        }
        // TALENT INVITATION
        case 'TALENT_INVITATION_NETWORK': {
            return (
                <ApplyTalentActivity
                    activity={activity}
                />
            )
        }
        // You where added to network (via Backoffice)
        case 'NETWORK_ADDED': {
            return (
                <AddedToNetworkActivity
                    activity={activity}
                />
            )
        }

        default: {
            return (
                <DeletedActivity />
            )
        }
    }
}

export default Activity
