import {
    IonMenu,
} from '@ionic/react'
import { useCallback, useRef } from 'react'

import useTabContext from '../../lib/hooks/useTabContext'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import { showTabBarRoutes } from '../TabBar'

import MenuContent from './MenuContent'

import './style.scss'

const Menu: React.FC = () => {
    const menuRef = useRef<HTMLIonMenuElement>(null)
    const { hideMenuButton } = useWindowResizeListener()

    // FIXME: workaround until https://github.com/ionic-team/ionic-framework/issues/24497 is fixed
    const onMenuClick = useCallback(() => {
        menuRef.current?.close()
    }, [])

    const activeUrl = useTabContext()
    if (!showTabBarRoutes.includes(`/${activeUrl}`)) return null

    if (hideMenuButton) {
        return (
            <div
                className='desktop-main-menu'
            >
                <MenuContent />
            </div>
        )
    }

    return (
        <IonMenu
            contentId='app'
            side='start'
            menuId='main-menu'
            class='id__main-menu'
            ref={menuRef}
        >
            <MenuContent
                onMenuClick={onMenuClick}
            />
        </IonMenu>
    )
}

export default Menu
