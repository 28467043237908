import type { Talent } from '../apollo/types'
import { PermissionLevel } from '../apollo/types'

const isTalentOwnPersonification = (talent?: Talent): boolean => {
    if (!talent) return false

    return talent.permissionLevel === PermissionLevel.Personal || talent.permissionLevel === PermissionLevel.Administrative
}

export default isTalentOwnPersonification
