import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react'
import { person } from 'ionicons/icons'

import type { Network } from '../../../../lib/apollo/types'
import CustomAvatar from '../../../CustomAvatar'

import './style.scss'

const NetworkCardSmall: React.FC<{ network: Network }> = ({ network }) => {

    if (!network) {
        return null
    }

    return (
        <IonCard
            routerLink={`/home/network/${network.id}/detail`}
            className='network-card-small'
        >
            <CustomAvatar
                type='network'
                width='100%'
                img={network.image}
            />

            <IonCardContent>
                <IonCardTitle>
                    { network.caption }
                </IonCardTitle>
                <IonCardSubtitle>
                    { network.description }
                </IonCardSubtitle>
            </IonCardContent>
            <IonCardSubtitle
                className='network-card-small__owner'
            >
                <IonIcon
                    icon={person}
                />
                { network.owner }
            </IonCardSubtitle>
        </IonCard>
    )
}

export default NetworkCardSmall
