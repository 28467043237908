import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { TalentDataView , AnimationLayer } from '../../../lib/apollo/types'
import { ChartType, TalentPosition, useTalentClassQuery } from '../../../lib/apollo/types'

import type { LayerI } from './Layer'

const useLayers = (talentPosition: TalentPosition, dataView1?: TalentDataView[], dataView2?: TalentDataView[], under16?: boolean, animateTo?: AnimationLayer[]): LayerI[] => {
    const { t, i18n } = useTranslation()
    const { data } = useTalentClassQuery({
        variables: {
            id: talentPosition ===  TalentPosition.Goal ? '2' : '1',
        },
    })

    let getTranslation: (key: string) => (string)
    getTranslation = useCallback((key: string) => {
        if (under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [under16, i18n, t])

    return useMemo<LayerI[]>(() => {
        if (!data?.talentClass) return []

        const hasProfiVote = dataView1?.[0]?.values.length === 32
        if (!hasProfiVote) {
            const global1 = dataView1?.find(view => view.values.length === 8)
            const global2 = dataView2?.find(view => view.values.length === 8)
            const dataViewGlobal = data?.talentClass.dataViews.find(dv => global1?.key === +dv.id)

            return [{
                id: `${global1?.key ?? 'global'}`,
                withHover: dataViewGlobal?.withLabelHover ?? false,
                withGroupLabels: dataViewGlobal?.withGroupLabels ?? false,
                values: global1?.values ?? [],
                caption: getTranslation(dataViewGlobal?.caption ?? ''),
                compareValues: global2?.values,
                backgroundColor: dataViewGlobal?.backgroundColor ?? undefined,
                borderColor: dataViewGlobal?.borderColor ?? undefined,
                textColor: dataViewGlobal?.textColor ?? undefined,
                animateToValues: animateTo?.map(dataView => dataView.dataViews.find(view => view.key === global1?.key)?.values ?? []),
                translations: dataViewGlobal?.voteScalarGroups?.[0].scalars.map(scalar => t(scalar.caption)),
                translationKeys: dataViewGlobal?.voteScalarGroups?.[0].scalars.map(scalar => scalar.caption),
                type: ChartType.Radar,
            }, ...(data?.talentClass.dataViews || [])
                .filter(dataView => +dataView.id >= 0 && +dataView.id !== global1?.key)
                // filter by dataview key not available in dataView1
                .filter(dataView => dataView1?.find(view => view.key === +dataView.id))
                .map(dataView => ({
                    id: dataView.id,
                    backgroundColor: dataView.backgroundColor ?? undefined,
                    borderColor: dataView.borderColor ?? undefined,
                    textColor: dataView.textColor ?? undefined,
                    withHover: dataView.withLabelHover ?? false,
                    withGroupLabels: dataView.withGroupLabels ?? false,
                    caption: getTranslation(dataView.caption),
                    values: dataView1?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                    compareValues: dataView2?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                    animateToValues: animateTo?.map(dv => dv.dataViews.find(view => view.key === +dataView.id)?.values ?? []),
                    translations: dataView.voteScalarGroups?.[0].scalars.map(scalar => t(scalar.caption)),
                    translationKeys: dataView.voteScalarGroups?.[0].scalars.map(scalar => scalar.caption),
                    type: dataView.chartType,
                })),
            ]

        }

        return [{
            id: 'global',
            withHover: true,
            withGroupLabels: true,
            caption: getTranslation('i18n.class.global'),
            values: dataView1?.find(view => view.key === 0)?.values ?? [],
            compareValues: dataView2?.find(view => view.key === 0)?.values ?? [],
            animateToValues: animateTo?.map(dataView => dataView.dataViews.find(view => view.key === 0)?.values ?? []),
            translations: dataView1?.find(view => view.key === 0)?.values?.map((_, i) => getTranslation(`i18n.class.${talentPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`)) ?? [],
            translationKeys: dataView1?.find(view => view.key === 0)?.values?.map((_, i) => `i18n.class.${talentPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`) ?? [],
            type: ChartType.Radar,
        }, ...(data?.talentClass.dataViews || [])
            .filter(dataView => +dataView.id >= 0)
            // filter by dataview key not available in dataView1
            .filter(dataView => dataView1?.find(view => view.key === +dataView.id))
            .map(dataView => ({
                id: dataView.id,
                backgroundColor: dataView.backgroundColor ?? undefined,
                borderColor: dataView.borderColor ?? undefined,
                textColor: dataView.textColor ?? undefined,
                withHover: dataView.withLabelHover ?? false,
                withGroupLabels: dataView.withGroupLabels ?? false,
                caption: getTranslation(dataView.caption),
                values: dataView1?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                compareValues: dataView2?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                animateToValues: animateTo?.map(dv => dv.dataViews.find(view => view.key === +dataView.id)?.values ?? []),
                translations: dataView.voteScalarGroups?.[0].scalars.map(scalar => t(scalar.caption)),
                translationKeys: dataView.voteScalarGroups?.[0].scalars.map(scalar => scalar.caption),
                type: dataView.chartType,
            })),
        ]
    }, [data?.talentClass, dataView1, getTranslation, dataView2, animateTo, t, talentPosition])
}

export default useLayers
