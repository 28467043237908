import {Certificate} from '../../../../lib/apollo/types'
import HSVCertificateItem from './HSV'
import RBLCertificateItem from './RBL'

export type CertificateItemProps = {
    certificate: Certificate
    setCert: any
}
const CertificateItem: React.FC<CertificateItemProps> = ({ certificate, setCert }) => {
    console.log({ certificate })

    switch (certificate.club) {
        case 'hsv': return <HSVCertificateItem certificate={certificate} setCert={setCert} />
        case 'rbl': return <RBLCertificateItem certificate={certificate} setCert={setCert} />
        default: return null
    }
}

export default CertificateItem