import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import { AppContext } from '../../../components/AppState'
import { AppActionType } from '../../../components/AppState/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import {
    NetworksDocument,
    useJoinNetworkByTokenMutation,
    useNetworkTalentsLazyQuery,
} from '../../apollo/types'

const useInitActionsHandler = (): void => {
    const user = useUser()
    const { push } = useIonRouter()
    const [present] = useToast()
    const { t } = useTranslation()

    const [joinNetworkByTokenMutation] = useJoinNetworkByTokenMutation()
    const [getNetworkTalentsQuery] = useNetworkTalentsLazyQuery()

    const initActions = useContextSelector(AppContext, state => state.state.initActions)
    const dispatch = useContextSelector(AppContext, state => state.dispatch)

    useEffect(() => {
        if(!user?.user?.id) {
            return
        }

        const handleSavedActions = async () => {

            // eslint-disable-next-line no-restricted-syntax
            for (const action of initActions) {
                switch(action.type) {
                    case 'join-private-space': {
                        try {
                            // eslint-disable-next-line no-await-in-loop
                            const response = await joinNetworkByTokenMutation({
                                variables: {
                                    input: {
                                        token: action.token,
                                    },
                                },
                                // TODO: add refetches for private spaces?
                            })

                            if (response.data?.joinNetworkByToken?.id) {
                                present(t('myspace.joined'), 'success')
                                // get async talent from joined network
                                // eslint-disable-next-line no-await-in-loop
                                const talentsResponse = await getNetworkTalentsQuery({
                                    variables: {
                                        networkId: response.data.joinNetworkByToken.id,
                                    },
                                })
                                push(`/home/network/${response.data.joinNetworkByToken.id}/talents/${talentsResponse.data?.networkTalents.collection[0].id}`)
                            }  else {
                                present(t('myspace.joinFailed.invitationInvalid'), 'warning')
                            }
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.error(e)
                        } finally {
                            dispatch({ type: AppActionType.InitAppActionRemove, action })
                        }
                        break
                    }
                    case 'join-network': {
                        try {
                            // eslint-disable-next-line no-await-in-loop
                            const response = await joinNetworkByTokenMutation({
                                variables: {
                                    input: {
                                        token: action.token,
                                    },
                                },
                                refetchQueries: [{
                                    query: NetworksDocument,
                                    variables: {
                                        userId: user.user.id,
                                        start: 1,
                                        limit: 15,
                                    },
                                },
                                {
                                    query: NetworksDocument,
                                    variables: {
                                        userId: user.user.id,
                                    },
                                }],
                            })
                            if (response?.data?.joinNetworkByToken?.id) {
                                present(t('networks.joined', { network: response.data.joinNetworkByToken.caption }), 'success')
                                push(`/home/network/${response.data.joinNetworkByToken.id}/detail`)
                            } else {
                                present(t('networks.joinFailed.invitationInvalid'), 'warning')
                            }
                        } catch (e: any) {
                            if (e.networkError?.statusCode === 409) {
                                present(t('networks.joinFailed.talentAlreadyInNetwork'), 'warning')
                            } else {
                                // eslint-disable-next-line no-console
                                console.error(e)
                            }
                        } finally {
                            dispatch({ type: AppActionType.InitAppActionRemove, action })
                        }
                        break
                    }
                    default: // do nothing
                }

            }
        }

        handleSavedActions()
    }, [user, initActions, joinNetworkByTokenMutation, present, t, getNetworkTalentsQuery, push, dispatch])
}

export default useInitActionsHandler
