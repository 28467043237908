import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'

import { AppContext } from '../../../components/AppState'
import { AppActionType } from '../../../components/AppState/types'
import type { JoinNetworkAction } from '../../../lib/hooks/InitActions/initActions.actions'

const JoinNetwork: React.FC = () => {

    const initActions = useContextSelector(AppContext, ({ state }) => state.initActions)
    const dispatch = useContextSelector(AppContext, state => state.dispatch)
    const { params: { token } } = useRouteMatch<{ token: string }>()

    const [render, setRender] = useState(false)

    useEffect(() => {
        const action: JoinNetworkAction = {
            type: 'join-network',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            token,
        }

        if(!initActions.some(a => JSON.stringify(a) === JSON.stringify(action))) {
            // action not present -> add it
            dispatch({ type: AppActionType.InitAppActionAdd, action })
        }

        setRender(true)
    }, [dispatch, initActions, token])

    if(!render) return null

    return (
        <Redirect
            to='/home'
            push={false}
        />
    )
}

export default JoinNetwork
