import { useContextSelector } from 'use-context-selector'

import type { OnboardingProviderType } from './index'
import Onboarding from './index'

const useOnboarding = (): OnboardingProviderType => {
    return useContextSelector(Onboarding, value => value)
}

export default useOnboarding
