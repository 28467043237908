import type { SuggestUser , MapEntry, Network, Talent, User } from '../../lib/apollo/types'

interface SearchState {
    tab: string
    results: { talents: Talent[], users: User[], networks: Network[] }
    search?: { value?: string | null, event: any },
    sort?: {
        talent: string,
        user: string,
        network: string,
    },
    sortOptions?: {
        talent: MapEntry[],
        user: MapEntry[],
        network: MapEntry[],
    }
    suggestions?: { name: string, icon: string }[]
}

export const tabs = ['talents', 'users', 'networks']
export const initialSearchState: SearchState = {
    tab: '0',
    results: {
        talents: [],
        users: [],
        networks: [],
    },
}

type AddResults = { type: 'AddResults', talents?: Talent[], users?: User[], networks?: Network[] }
type SetTab = { type: 'SetTab', tab: string }
type SetSearch = { type: 'SetSearch', value?: string, event: any }
type SetSort = { type: 'SetSort', talent?: string, user?: string, network?: string }
type SetSortOption = { type: 'SetSortOption', talent?: MapEntry[], user?: MapEntry[], network?: MapEntry[] }
type AddSuggestions = { type: 'AddSuggestions', suggestions: SuggestUser[], icon: string }
type ResetSuggestions = { type: 'ResetSuggestions' }

type Action =
    | AddResults
    | SetTab
    | SetSearch
    | SetSort
    | SetSortOption
    | AddSuggestions
    | ResetSuggestions

const searchReducer = (state: SearchState, action: Action): SearchState => {
    switch (action.type) {
        case 'SetTab': {
            return {
                ...state,
                tab: action.tab,
            }
        }
        case 'SetSearch': {
            return {
                ...state,
                search: {
                    value: action.value,
                    event: action.event,
                },
            }
        }
        case 'AddResults': {
            return {
                ...state,
                results: {
                    talents: action.talents ? action.talents : state.results.talents,
                    users: action.users ? action.users : state.results.users,
                    networks: action.networks ? action.networks : state.results.networks,
                },
            }
        }
        case 'SetSort': {
            return {
                ...state,
                sort: {
                    user: action.user ?? state.sort?.user ?? '',
                    network: action.network ?? state.sort?.network ?? '',
                    talent: action.talent ?? state.sort?.talent ?? '',
                },
            }
        }
        case 'SetSortOption': {
            return {
                ...state,
                sortOptions: {
                    user: action.user ?? state.sortOptions?.user ?? [],
                    network: action.network ?? state.sortOptions?.network ?? [],
                    talent: action.talent ?? state.sortOptions?.talent ?? [],
                },
            }
        }
        case 'AddSuggestions': {
            const suggestions = [...(state.suggestions ?? []), ...action.suggestions.map(s => ({ name: s.name, icon: action.icon }))]
            suggestions.sort()
            return {
                ...state,
                suggestions,
            }
        }
        case 'ResetSuggestions': {
            return {
                ...state,
                suggestions: undefined,
            }
        }
        default:
            throw new Error()
    }
}

export default searchReducer

