import { IonCol, IonGrid, IonIcon, IonRow, useIonViewWillEnter } from '@ionic/react'
import { star } from 'ionicons/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { User, UserMetaData } from '../../../lib/apollo/types'
import { useActivitylevelQuery, UserType } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import TalentAttribute from '../../Talent/TalentAttribute'

import './style.scss'

interface UserProfileContentProps {
    metaData: UserMetaData
    user: User
}

const UserProfileContent: React.FC<UserProfileContentProps> = ({ metaData, user }) => {
    const { t } = useTranslation()

    const { data: { activitylevel } = { activitylevel: 0 }, refetch } = useActivitylevelQuery({
        skip: !user,
        variables: { userId: user?.id ?? '' },
        fetchPolicy: 'cache-and-network',
    })
    const activityState = useMemo(() => {
        if (!activitylevel) return 0
        if (activitylevel < 4) return 1
        if (activitylevel < 9) return 2
        if (activitylevel < 13) return 3
        if (activitylevel < 17) return 4
        return 5
    }, [activitylevel])

    const userTypeString = useMemo(() =>
        metaData?.userType?.filter(type => type !== UserType.Trainer).map(type => t(`user.userType.${type}`)).join(', ')
    , [metaData?.userType, t])

    useIonViewWillEnter(() => {
        if(user && activitylevel) {
            refetch()
        }
    }, [user, activitylevel])

    return (
        <IonGrid
            className='user-card-content'
        >
            <IonRow
                className='user-card-content__attributes ion-nowrap'
            >
                <IonCol>
                    <TalentAttribute
                        topLabel={t('talent.agePrefix')}
                        bottomLabel={t('talent.ageSuffix')}
                    >
                        <h3>
                            {getAge(metaData.birthdate)}
                        </h3>
                    </TalentAttribute>
                </IonCol>
                <IonCol>
                    <TalentAttribute
                        topLabel={`${t('activitylevel.title')} (${activitylevel})`}
                        bottomLabel={(
                            <>
                                {(new Array(5).fill(undefined).map((_, i) => (
                                    <IonIcon
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        className={`user-card-content__star-icon${i < activityState ? '--active': ''}`}
                                        icon={star}
                                    />
                                )))}
                            </>
                        )}
                    >
                        <h3>
                            { t(`activitylevel.${activityState}`) }
                        </h3>
                    </TalentAttribute>
                </IonCol>
                <IonCol>
                    <TalentAttribute
                        topLabel={t('user.ageCategory.label')}
                    >
                        <h3
                            className='ion-text-bold'
                        >
                            {metaData?.ageCategory?.[0] ? (
                                t(`user.ageCategory.${metaData.ageCategory[0]}`)
                            ): '???'}
                        </h3>
                    </TalentAttribute>
                </IonCol>
            </IonRow>

            <IonRow
                className='user-card-content__background ion-text-left'
            >
                <IonCol
                    size='12'
                    className='user-card-content__background--column'
                >
                    <h3
                        className='ion-margin-bottom'
                    >
                        {t('user.userType.cardLabel')}
                    </h3>

                    { userTypeString }
                </IonCol>
                <IonCol
                    size='12'
                    className='user-card-content__background--column'
                >
                    <h3
                        className='ion-margin-bottom'
                    >
                        {t('user.aboutMe')}
                    </h3>
                    <span
                        className='n2br'
                    >
                        { metaData.aboutMe }
                    </span>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default UserProfileContent
