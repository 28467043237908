import { IonText } from '@ionic/react'
import { add, close } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { User } from '../../../lib/apollo/types'
import { useNetworkFriendsQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import Button from '../../Button'
import Buttons from '../../Buttons'
import ItemList from '../../ItemList'
import UserListItem from '../../User/UserListItem'
import UserListItemSkeleton from '../../User/UserListItem/UserListItemSkeleton'
import Modal from '../index'

interface SelectableFriendsModalProps {
    onSubmit: (users: User[]) => void
    onClose: () => void
    open: boolean
    networkId?: string
}

const SelectableFriendsModal: React.FC<SelectableFriendsModalProps> = ({
    open,
    onClose,
    onSubmit,
    networkId,
}) => {
    const { t } = useTranslation()
    const me = useUser()

    const friends = useNetworkFriendsQuery({
        skip: !open || !me,
        variables: {
            userId: me?.user.id ?? '',
            networkId: networkId ?? '',
        },
    })

    const [checked, setChecked] = useState<User[]>([])

    const toggleChecked = useCallback((user: User, checkedFriend: boolean) => {
        if (checkedFriend) {
            setChecked(users => users.filter(_user => _user.id !== user.id))
        } else {
            setChecked(users => [...users, user])
        }
    }, [])

    return (
        <Modal
            modalTitle={t('user.friends')}
            isOpen={open}
            onClose={onClose}
        >
            <div>
                <ItemList>
                    { friends?.data?.networkFriends?.collection.map(friend => {
                        const checkedFriend = checked.some(user => user.id === friend.id)
                        return (
                            <UserListItem
                                key={friend.id}
                                user={friend}
                                selectable
                                checked={checkedFriend}
                                onClick={() => { toggleChecked(friend, checkedFriend) }}
                            />
                        )
                    }) ?? (
                        <>
                            <UserListItemSkeleton />
                            <UserListItemSkeleton />
                            <UserListItemSkeleton />
                            <UserListItemSkeleton />
                        </>
                    )}
                </ItemList>
                { friends?.data?.networkFriends?.collection && friends.data.networkFriends.collection.length > 0 ? (
                    <Buttons>
                        <Button
                            disabled={checked.length === 0}
                            color='secondary'
                            icon={add}
                            onClick={() => {
                                onSubmit(checked)
                                setChecked([])
                                onClose()
                            }}
                        >
                            {t('buttons.add')}
                        </Button>
                    </Buttons>
                ) : (
                    <>
                        <IonText
                            className='ion-margin-bottom'
                            color='medium'
                        >
                            <span>
                                {t('network.noFriendsInNetwork')}
                            </span>
                        </IonText>
                        <Buttons>
                            <Button
                                color='primary'
                                icon={close}
                                onClick={() => {
                                    onClose()
                                }}
                            >
                                {t('buttons.close')}
                            </Button>
                        </Buttons>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default SelectableFriendsModal
