import clsx from 'clsx'
import './style.scss'
import {PropsWithChildren} from 'react'

interface ItemListProps {
    className?: string
}

const ItemList: React.FC<ItemListProps & PropsWithChildren> = ({ className, children }) => {

    return (
        <div
            className='item-list'
        >
            <div
                className={clsx(className, 'item-list__container')}
            >
                { children }
            </div>
        </div>
    )
}

export default ItemList
