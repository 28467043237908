import type { ApolloError } from '@apollo/client'
import { useIonAlert } from '@ionic/react'
import { arrowDown, arrowUp, swapHorizontal, trashBinOutline } from 'ionicons/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import InfiniteScrollWithHook from '../../../components/InfiniteScrollWithHook'
import ItemList from '../../../components/ItemList'
import UserListItem from '../../../components/User/UserListItem'
import {
    NetworkDocument,
    NetworkRole,
    NetworkUsersDocument,
    useNetworkUsersLazyQuery,
    useRemoveUserFromNetworkMutation,
    useToggleModMutation, useTransferOwnerMutation,
} from '../../../lib/apollo/types'
import type { Network , User } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'

import './style.scss'

interface NetworkUsersProps {
    network: Network,
    hideSelf?: boolean
    disablePromotions?: boolean
}

const NetworkUsers: React.FC<NetworkUsersProps> = ({ network, hideSelf = false, disablePromotions = false }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [present] = useIonAlert()
    const [presentToast] = useToast()

    const baseOptions = useMemo(() => ({
        refetchQueries: [
            {
                query: NetworkUsersDocument,
                variables: {
                    userId: user?.user.id,
                    networkId: network.id,
                },
            },
        ],
        onError: (e: ApolloError) => {
            // eslint-disable-next-line no-console
            console.error(e)
            presentToast(t('errors.unknown'), 'danger')
        },
    }), [user?.user.id, network.id, presentToast, t])

    const [toggleMod] = useToggleModMutation(baseOptions)
    const [transferOwner] = useTransferOwnerMutation({
        refetchQueries: [
            {
                query: NetworkDocument,
                variables: {
                    userId: user?.user.id,
                    networkId: network.id,
                },
            },
            {
                query: NetworkUsersDocument,
                variables: {
                    userId: user?.user.id,
                    networkId: network.id,
                },
            },
        ],
        onError: (e: ApolloError) => {
            // eslint-disable-next-line no-console
            console.error(e)
            presentToast(t('errors.unknown'), 'danger')
        },
    })
    const [removeUserMutation] = useRemoveUserFromNetworkMutation(baseOptions)

    const onRemoveClick = (u: User) => {
        present({
            message: t('networks.removeUserAlert', { name: `${u.firstName} ${u.lastName}` }),
            buttons: [
                {
                    text: t('buttons.cancel'),
                    role: 'cancel',
                },
                {
                    text: t('buttons.remove'),
                    handler: async () => {
                        await removeUserMutation({
                            variables: {
                                id: network.id,
                                user: {
                                    _links: {
                                        userByNetwork: {
                                            url: [`/user/${u.id}`],
                                        },
                                    },
                                },
                            },
                        })
                    },
                }],
        })
    }

    const onChangeNetworkRole = (u: User) => {
        toggleMod({
            variables: {
                networkId: network.id,
                id: user?.user.id ?? '',
                idUserTarget: u.id,
            },
        })
    }

    const onTransferOwner = (u: User) => {
        present({
            message: t('networks.transferOwnerAlert', { name: `${u.firstName}` }),
            buttons: [
                {
                    text: t('buttons.cancel'),
                    role: 'cancel',
                },
                {
                    text: t('buttons.transferOwner'),
                    handler: async () => {
                        await transferOwner({
                            variables: {
                                networkId: network.id,
                                id: user?.user.id ?? '',
                                idUserTarget: u.id,
                            },
                        })
                    },
                }],
        })
    }

    return (
        <ItemList
            className='network-users'
        >
            <InfiniteScrollWithHook
                placeholder={(
                    <>
                        <UserListItem />
                        <UserListItem />
                        <UserListItem />
                        <UserListItem />
                    </>
                )}
                variables={{
                    networkId: network.id,
                }}
                lazyHook={useNetworkUsersLazyQuery}
            >
                {(users: User[]) => users
                    .filter(u => !hideSelf || u.id !== user?.user.id)
                    .map((u) => {
                        const isMod = u.networkRoles?.some(role => role === NetworkRole.Mod) ?? false
                        const isOwner = u.networkRoles?.some(role => role === NetworkRole.Owner) ?? false

                        return (
                            <UserListItem
                                key={u.id}
                                user={u}
                                showNetworkRole
                                networkId={network.id}
                            >
                                {network.networkRoles?.find(role => role === NetworkRole.Owner || role === NetworkRole.Mod) && (
                                    <ContextMenuItem
                                        label={t('networks.removeUser')}
                                        icon={trashBinOutline}
                                        onClick={() => onRemoveClick(u)}
                                        disabled={u.id === user?.user.id || isOwner}
                                    />
                                )}
                                {!disablePromotions && network.networkRoles?.find(role => role === NetworkRole.Owner) && (
                                    <ContextMenuItem
                                        label={t(isMod ? 'networks.demoteUser' : 'networks.promoteUser')}
                                        icon={isMod ? arrowDown : arrowUp}
                                        onClick={() => onChangeNetworkRole(u)}
                                        disabled={u.id === user?.user.id}
                                    />
                                )}
                                {!disablePromotions && network.networkRoles?.find(role => role === NetworkRole.Owner) && (
                                    <ContextMenuItem
                                        label={t('networks.transferOwner')}
                                        icon={swapHorizontal}
                                        onClick={() => onTransferOwner(u)}
                                        disabled={u.id === user?.user.id || !isMod}
                                    />
                                )}
                            </UserListItem>
                        )
                    })}
            </InfiniteScrollWithHook>
        </ItemList>
    )
}

export default NetworkUsers
