import {HistoryItem, Talent, TalentDataView} from '../../../../lib/apollo/types'
import TeamEntry from './TeamEntry'
import InjuryEntry from './InjuryEntry'
import EventEntry from './EventEntry'

export enum HistoryType {
    TEAM = 'TEAM',
    INJURY = 'INJURY',
    EVENT = 'EVENT',
}

export type TimelineItemProps = {
    talent: Talent
    date: Date
    entry: HistoryItem
    disableOnClick?: boolean
    dataViews?: TalentDataView[]
}

const TimelineItem: React.FC<TimelineItemProps> = ({talent, date, entry, disableOnClick = false, dataViews}) => {

    const data = JSON.parse(entry.data)

    switch (entry.type) {
        case HistoryType.TEAM:
            return (
                <TeamEntry data={data} talent={talent} start={data.start} end={data.end} disableOnClick={disableOnClick} dataViews={dataViews} shared={!!dataViews} />
            )
        case HistoryType.INJURY:
            return (
                <InjuryEntry data={data} start={data.start} end={data.end} disableOnClick={disableOnClick} />
            )
        case HistoryType.EVENT:
            return (
                <EventEntry data={data} date={entry.date} disableOnClick={disableOnClick} />
            )
        default:
            return null
    }
}

export default TimelineItem