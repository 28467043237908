import { IonCard, IonText } from '@ionic/react'
import { Trans, useTranslation } from 'react-i18next'

import type { Network, User } from '../../../lib/apollo/types'
import Button from '../../Button'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

import '../FollowActivity/style.scss'

const getName = (user?: User): (string | undefined) => {
    if (user?.firstName && user?.lastName) {
        return `${user.firstName} ${user.lastName}`
    }

    if (user?.firstName) {
        return user.firstName
    }

    return 'Anonymous'
}

const AddedToNetworkActivity: React.FC<ActivityProps> = ({ activity }) => {
    const { t } = useTranslation()

    const network = JSON.parse(activity.data) as Network

    const hours = useHours(activity)

    return (
        <IonCard
            className='follow-activity'
        >
            <CustomAvatar
                className='follow-activity__avatar'
                img={activity.from.image}
                alt={activity.from.id}
            />
            <IonText
                className='follow-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey='activity.addedToNetwork'
                    values={{ user: getName(activity.from), network: network.caption, date: hours }}
                    components={{
                        strong: (<strong />),
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            <Button
                size='small'
                color='secondary'
                routerLink={`/home/network/${network.id}/detail`}
            >
                {t('activity.goToNetwork')}
            </Button>
        </IonCard>
    )
}

export default AddedToNetworkActivity
