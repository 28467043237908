import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { IonPage } from '@ionic/react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

interface PageProps {
    trackingTitle?: string,
}

const Page: React.FC<PageProps & React.ComponentProps<typeof IonPage>> = ({
    children,
    trackingTitle,
    ...ionPageProps
}) => {
    const { trackPageView } = useMatomo()
    const { t } = useTranslation()

    useEffect(() => {
        const track = async () => {
            let customDimensions
            if(Capacitor.isNativePlatform()) {
                const info = await CapacitorApp.getInfo()
                customDimensions = [{
                    id: 1,
                    value: info.version,
                }]
            }
            if(trackingTitle) {
                trackPageView({
                    documentTitle: trackingTitle,
                    customDimensions,
                })
            }
        }

        track()
    }, [trackPageView, trackingTitle])

    return (
        <IonPage
            {...ionPageProps}
        >
            <Helmet>
                <title>Join My Talent</title>
                <meta
                    property='og:title'
                    content='Join My Talent'
                />
                <meta
                    property='og:description'
                    content={t('og.fallback.description')}
                />
                <meta
                    property='og:image'
                    content='https://app.joinmytalent.com/assets/icon/favicon.png'
                />
            </Helmet>
            {children}
        </IonPage>
    )
}

export default Page
