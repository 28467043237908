import ContextMenu from '../../components/ContextMenu'
import NetworkAddOrEditContextMenuItem from '../../components/Network/NetworkAddOrEditContextMenuItem'
import NetworkAddTalentsContextMenuItem from '../../components/Network/NetworkAddTalentsContextMenuItem'
import NetworkAddUsersContextMenuItem from '../../components/Network/NetworkAddUsersContextMenuItem'
import NetworkApplyContextMenuItem from '../../components/Network/NetworkApplyContextMenuItem'
import NetworkCreateTalentContextMenuItem from '../../components/Network/NetworkCreateTalentContextMenuItem'
import NetworkDeleteContextMenuItem from '../../components/Network/NetworkDeleteContextMenuItem'
import NetworkInviteTalentsContextMenuItem from '../../components/Network/NetworkInviteTalentsContextMenuItem'
import NetworkLeaveContextMenuItem from '../../components/Network/NetworkLeaveContextMenuItem'
import ShowOnboardingContextMenuItem from '../../components/OnboardingWizard/ShowOnboardingContextMenuItem'
import type { Network } from '../../lib/apollo/types'
import { NetworkRole } from '../../lib/apollo/types'

interface NetworkContextMenuProps {
    network: Network
}

const NetworkContextMenu: React.FC<NetworkContextMenuProps> = ({
    network,
}) => {
    return (
        <ContextMenu>
            <ShowOnboardingContextMenuItem
                ids={['networks-page']}
            />
            {network.networkRoles?.some(role => role === NetworkRole.Owner || role === NetworkRole.Mod) && (
                <>
                    <NetworkAddUsersContextMenuItem
                        network={network}
                    />
                    <NetworkInviteTalentsContextMenuItem
                        network={network}
                    />
                    <NetworkAddTalentsContextMenuItem
                        network={network}
                    />
                    <NetworkCreateTalentContextMenuItem
                        network={network}
                    />
                    <NetworkAddOrEditContextMenuItem
                        network={network}
                    />
                </>
            )}
            {network.networkRoles?.find(role => role === NetworkRole.Owner) && (
                <NetworkDeleteContextMenuItem
                    network={network}
                />
            )}
            {!network.networkRoles?.some(role => role === NetworkRole.Owner || role === NetworkRole.None) && (
                <NetworkLeaveContextMenuItem
                    network={network}
                />
            )}
            {network.networkRoles?.some(role => role === NetworkRole.None) && (
                <NetworkApplyContextMenuItem
                    network={network}
                />
            )}
        </ContextMenu>
    )
}

export default NetworkContextMenu
