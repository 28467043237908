import { IonCard, IonGrid, IonIcon, IonLabel, IonRange, IonRow, IonText, useIonAlert } from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { TalentPosition } from '../../../../../lib/apollo/types'
import type { LayerI } from '../../Layer'

const SkillsListViewRadarWithoutGroupLabels: React.FC<{ layer: LayerI, color1?: string, color2?: string, talentPosition: TalentPosition, under16?: boolean }> = ({
    layer,
    color1 = 'secondary',
    color2 = 'tertiary',
    under16 = false,
}) => {
    const { t, i18n } = useTranslation()
    const getColor1 = useCallback((value: number, value2?: number) => {
        if(value2 === undefined)
            return 'light'

        return value < value2 ? 'medium' : color1
    }, [color1])

    const [present] = useIonAlert()

    const getTranslation = useCallback((key: string) => {
        if(under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [under16, i18n, t])

    return (
        <IonCard
            color='primary'
            className='skills-list-view__card'
        >
            <IonGrid>
                {layer.values.map((value, i) => {
                    const value2 = layer.compareValues?.[i]

                    return (
                        <Fragment
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                        >
                            <IonRow>
                                <IonLabel
                                    onClick={() => present(getTranslation( `${layer.translationKeys?.[i]}_tip`))}
                                >
                                    {layer.translations?.[i]}
                                    <IonIcon
                                        icon={informationCircleOutline}
                                    />
                                </IonLabel>
                            </IonRow>
                            <IonRow
                                className='skills-list-view__slider-row ion-align-items-center ion-justify-content-between'
                            >
                                <IonRange
                                    mode='ios'
                                    color={getColor1(value, value2)}
                                    min={0}
                                    max={100}
                                    value={value}
                                    disabled
                                />
                                <IonText
                                    color={getColor1(value, value2)}
                                >
                                    <h3
                                        className='skills-list-view__value'
                                    >
                                        {value.toFixed(0)}
                                    </h3>
                                </IonText>
                            </IonRow>
                            {value2 !== undefined && (
                                <IonRow
                                    className='skills-list-view__slider-row skills-list-view__slider-row--compare ion-align-items-center ion-justify-content-between'
                                >
                                    <IonRange
                                        mode='ios'
                                        color={value > value2 ? 'medium' : color2}
                                        min={0}
                                        max={100}
                                        value={value2}
                                        disabled
                                    />
                                    <IonText
                                        color={value > value2 ? 'medium' : color2}
                                    >
                                        <h3
                                            className='skills-list-view__value'
                                        >
                                            {value2.toFixed(0)}
                                        </h3>
                                    </IonText>
                                </IonRow>
                            )}
                        </Fragment>
                    )
                })}
            </IonGrid>
        </IonCard>
    )
}

export default SkillsListViewRadarWithoutGroupLabels
