import { IonCard, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react'
import { searchCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../../CustomAvatar'

import './style.scss'

const UserListItemFind: React.FC = () => {
    const { t } = useTranslation()

    return (
        <IonCard
            className='user-list-item'
            routerLink='/home/search'
        >
            <CustomAvatar
                icon={searchCircleOutline}
            />
            <IonGrid
                className='user-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='user-list-item__title'
                        >
                            {t('home.dashboard.findFriends')}
                        </IonCardTitle>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default UserListItemFind
