import { IonIcon, IonItem, IonList, IonPopover, IonSpinner, IonText } from '@ionic/react'
import { filterOutline, swapVerticalOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { MapEntry, Network, Talent, User } from '../../../lib/apollo/types'
import Button from '../../Button'

import './style.scss'

interface UserSearchResultProps {
    results: User[] | Talent[] | Network[]
    sortOptions: MapEntry[]
    headline: string
    sortOption?: string
    onSortOptionChange: (sortOption: string) => void
    loading: boolean
    children?: any
}

const SearchResultList: React.FC<UserSearchResultProps> = ({
    results,
    sortOptions,
    sortOption,
    onSortOptionChange,
    loading,
    children,
    headline,
}) => {
    const { t } = useTranslation()
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })

    const onSearchFilterClick = useCallback((e: any) => setShowPopover({
        showPopover: true,
        event: e,
    }), [setShowPopover])

    const onSearchFilterPopoverClick = useCallback(() => setShowPopover({
        showPopover: false,
        event: undefined,
    }), [setShowPopover])

    const onSortOptionClick = useCallback((option: MapEntry) => () => onSortOptionChange(`&sort=${option.value}`),
        [onSortOptionChange])

    return (
        <div
            className='search-result'
        >
            {results.length > 0 && (
                <>
                    <IonItem
                        className='search-result__headline'
                    >
                        <h1>{headline}</h1>
                        <Button
                            className='search-result__filter-button'
                            slot='end'
                            fill='clear'
                            size='large'
                            icon={filterOutline}
                            color='primary'
                            onClick={onSearchFilterClick}
                        />
                    </IonItem>
                    <IonPopover
                        event={popoverState.event}
                        isOpen={popoverState.showPopover}
                        onDidDismiss={onSearchFilterPopoverClick}
                        className='search-result__filter'
                    >
                        <IonList
                            className='search-result__context-menu'
                            onClick={onSearchFilterPopoverClick}
                        >
                            {sortOptions.map((option, index) => {
                                const color = `&sort=${option.value}` === sortOption || (!sortOption && index === 0) ? 'tertiary' : undefined
                                return (
                                    <IonItem
                                        lines={index === sortOptions.length - 1 ? 'none' : undefined}
                                        key={option.key}
                                        onClick={onSortOptionClick(option)}
                                    >
                                        <IonText
                                            color={color}
                                        >
                                            {t(option.key)}
                                        </IonText>
                                        <IonIcon
                                            slot='end'
                                            icon={swapVerticalOutline}
                                            color={color}
                                        />
                                    </IonItem>
                                )
                            })}
                        </IonList>
                    </IonPopover>
                </>
            )}

            {loading && (
                <div
                    className='search-result__loading'
                >
                    <IonSpinner
                        color='secondary'
                    />
                </div>
            )}
            {children?.(results)}
        </div>
    )
}

export default SearchResultList
