import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import type { Playlist } from '../../../lib/apollo/types'
import { usePlaylistsQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import Slider from '../../Slider'
import PlaylistAddCardSmall from '../PlaylistCardSmall/PlaylistAddCardSmall'
import PlaylistCardSmall from '../PlaylistCardSmall/PlaylistCardSmall'

const PlaylistsTeaserSlider: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data, loading } = usePlaylistsQuery({ variables: { userId: user?.user.id ?? '' }, skip: !user })
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    // update slider if elements changed
    useEffect(() => {
        controlledSwiper?.update()
    }, [controlledSwiper, data?.playlists.collection])

    if (!data || loading) return null

    return (
        <Slider
            freeMode
            onSwiper={setControlledSwiper}
            link='/playlists'
            title={t('playlists.title')}
            subtitle={t('playlists.subtitle')}
            key={JSON.stringify(data.playlists?.collection)}
        >
            {data.playlists?.collection.map((playlist) => (
                <SwiperSlide
                    key={playlist?.id}
                >
                    <PlaylistCardSmall
                        playlist={playlist as Playlist}
                    />
                </SwiperSlide>
            ))}
            <SwiperSlide>
                <PlaylistAddCardSmall />
            </SwiperSlide>
        </Slider>
    )
}

export default PlaylistsTeaserSlider
