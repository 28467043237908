import type { User } from '../../../lib/apollo/types'
import { UserRelationship } from '../../User/types'
import useRelationship from '../../User/useRelationship'

import FollowerIcon from './FollowerIcon'
import FollowingIcon from './FollowingIcon'
import FriendsIcon from './FriendsIcon'

interface RelationshipIconProps {
    user: User
    className?: string
}

const RelationshipIcon: React.FC<RelationshipIconProps> = ({ user, className }) => {
    const relationship = useRelationship(user)

    switch (relationship) {
        case UserRelationship.Following: {
            return (
                <FollowingIcon
                    className={className}
                />
            )
        }

        case UserRelationship.Follower: {
            return (
                <FollowerIcon
                    className={className}
                />
            )
        }

        case UserRelationship.Friend: {
            return (
                <FriendsIcon
                    className={className}
                />
            )
        }

        default: return null
    }
}

export default RelationshipIcon
