import { useIonRouter } from '@ionic/react'
import { bookmark } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import ContextMenu from '../../components/ContextMenu'
import ContextMenuItem from '../../components/ContextMenu/ContextMenuItem/ContentMenuItem'

const PlaylistsContextMenu: React.FC = () => {
    const { t } = useTranslation()
    const { push } = useIonRouter()

    return (
        <ContextMenu>
            <ContextMenuItem
                icon={bookmark}
                label={t('playlist.add')}
                onClick={() => { push('/playlists/addoredit/new') }}
            />
        </ContextMenu>
    )
}

export default PlaylistsContextMenu
