import { Browser } from '@capacitor/browser'
import { IonContent, IonHeader, IonItemDivider, IonItemGroup, IonList, IonTitle, IonToolbar } from '@ionic/react'
import {
    informationCircleOutline,
    logOutOutline,
    personOutline,
    settingsOutline,
    shirtOutline,
} from 'ionicons/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserMetaDataLazyQuery } from '../../../lib/apollo/types'
import { signOut, useUser } from '../../../providers/Auth/hooks'
import MenuItem from '../MenuItem'

import './style.scss'

interface MenuContentProps {
    onMenuClick?: () => void
}

const MenuContent: React.FC<MenuContentProps> = ({ onMenuClick }) => {
    const { t } = useTranslation()
    const user = useUser()

    const [userMetaDataQuery, { data: userMetaData, loading }] = useUserMetaDataLazyQuery()

    useEffect(() => {
        if(user && !loading) {
            userMetaDataQuery({
                variables: {
                    userId: user.user.id,
                },
            })
        }
    }, [loading, user, userMetaDataQuery])

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('menu.hello', { name: user?.user.firstName })}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='menu-content__content'
            >
                <IonList
                    className='menu-content__list'
                >
                    {userMetaData?.userMetaData?.user?.data?.behavior?.termsAcceptedAt && (
                        <IonItemGroup
                            onClick={onMenuClick}
                        >
                            <MenuItem
                                icon={personOutline}
                                label={t('menu.myspace')}
                                routerLink='/home/myspace'
                            />
                            <MenuItem
                                icon={shirtOutline}
                                label={t('menu.subscriptions')}
                                routerLink='/home/subscriptions'
                            />
                            <MenuItem
                                icon={settingsOutline}
                                label={t('menu.settings')}
                                routerLink='/settings'
                            />
                        </IonItemGroup>
                    )}
                    <IonItemGroup
                        onClick={onMenuClick}
                    >
                        <IonItemDivider />
                        <MenuItem
                            icon={logOutOutline}
                            label={t('menu.logout')}
                            onClick={signOut}
                        />
                    </IonItemGroup>
                    <IonItemGroup
                        onClick={onMenuClick}
                    >
                        <IonItemDivider />
                        <MenuItem
                            icon={informationCircleOutline}
                            label={t('menu.faq')}
                            onClick={() => Browser.open({
                                url: 'https://joinmytalent.com/faq/',
                            })}
                        />
                        <MenuItem
                            icon={informationCircleOutline}
                            label={t('menu.useConditions')}
                            onClick={() => Browser.open({
                                url: 'https://joinmytalent.com/nutzungbedingungen/',
                            })}
                        />
                        <MenuItem
                            icon={informationCircleOutline}
                            label={t('menu.imprint')}
                            onClick={() => Browser.open({
                                url: 'https://joinmytalent.com/impressum-3/',
                            })}
                        />
                        <MenuItem
                            icon={informationCircleOutline}
                            label={t('menu.privacy')}
                            onClick={() => Browser.open({
                                url: 'https://joinmytalent.com/datenschutzbestimmungen/',
                            })}
                        />
                    </IonItemGroup>
                </IonList>
            </IonContent>
        </>
    )
}

export default MenuContent
