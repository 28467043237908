import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow, IonSkeletonText,
} from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import getAge from '../../../lib/getAge'
import stopPropagation from '../../../lib/utils/stopPropagation'
import TalentContextMenu from '../../Talent/TalentContextMenu'
import TalentID from '../../Talent/TalentID'
import TalentIDSkeleton from '../../Talent/TalentID/TalentIDSkeleton'
import { useTalentCardContext } from '../_Core/Context'
import useBackgroundColor from '../_Core/useBackgroundColor'

import './style.scss'

const TalentListItem: React.FC = () => {
    const { talent, talentType, contextMenuItems, onClick, routerLink, networkId } = useTalentCardContext()
    const { t } = useTranslation()
    const backgroundColor = useBackgroundColor()

    const caption = talent?.caption.startsWith('playerTypes.')
        ? t(talent.caption)
        : talent?.caption

    return (
        <IonCard
            onClick={onClick}
            color='light'
            className={`talentcard-list-item list-item talentcard-list-item--${talentType}`}
            routerLink={onClick ? undefined : routerLink}
        >
            <div
                className='talentcard-list-item__avatar-wrapper'
            >
                <img
                    className={clsx('talentcard-list-item__avatar', { 'talentcard-list-item__avatar--no-img': !talent?.image })}
                    src={talent?.image ?? '/assets/icon/logo.svg'}
                    alt={talent?.id}
                />
            </div>
            <IonGrid
                className='talentcard-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol
                        className='talentcard-list-item__caption-wrapper'
                    >
                        <IonCardTitle>
                            { caption ?? (
                                <IonSkeletonText
                                    style={{ width: '55px' }}
                                />
                            ) }
                        </IonCardTitle>
                        <IonRow>
                            <IonCardSubtitle>
                                {talent?.metas.fieldPosition ?
                                    t(`talent.position.${talent.metas.fieldPosition}`)
                                    : (
                                        <IonSkeletonText
                                            style={{ width: '40px' }}
                                        />
                                    )}
                            </IonCardSubtitle>
                        </IonRow>
                    </IonCol>

                    <IonCol
                        className='talentcard-list-item__talent-id'
                    >
                        { talent
                            ? (
                                <TalentID
                                    defaultIndex={talent.dataViews.length > 1 ? 1 : 0}
                                    dataView1={talent.dataViews}
                                    talentPosition={talent.metas.fieldPosition}
                                    backgroundColor={backgroundColor}
                                    color1={talentType === 'roleModel' ? 'black' : undefined}
                                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                                />
                            ) : (
                                <TalentIDSkeleton />
                            )}
                    </IonCol>

                    <IonCol
                        className='talentcard-list-item__menu'
                        onClick={stopPropagation}
                    >
                        { talent && (
                            <TalentContextMenu
                                color='dark'
                                talent={talent}
                                networkId={networkId}
                            >
                                { contextMenuItems }
                            </TalentContextMenu>
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default TalentListItem
