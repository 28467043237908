import { IonSkeletonText, useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { arrowUndo } from 'ionicons/icons'
import type { CSSProperties } from 'react'
import { useMemo, useState } from 'react'

import type { Layer } from '../../../../lib/apollo/types'
import { TalentPosition } from '../../../../lib/apollo/types'
import getAge from '../../../../lib/getAge'
import Button from '../../../Button'
import TalentID from '../../../Talent/TalentID'
import SkillsListViewButton from '../../../Talent/TalentID/SkillsListView/SkillsListViewButton'
import SkillsPlayAnimationButton from '../../../Talent/TalentID/SkillsPlayAnimationButton'
import SkillsSelectLayersButton from '../../../Talent/TalentID/SkillsSelectLayersView/SkillsSelectLayersButton'
import useLayers from '../../../Talent/TalentID/useLayers'
import { useTalentCardContext } from '../../_Core/Context'
import TalentCardPaper from '../../_Core/TalentCardPaper'
import useBackgroundColor from '../../_Core/useBackgroundColor'

import './style.scss'

interface TalentCardMediumBackProps {
    visible: boolean
    onFlipCardClick: () => void
}

const TalentCardMediumBack: React.FC<TalentCardMediumBackProps> = ({ visible, onFlipCardClick }) => {
    const { push } = useIonRouter()

    const { talent, shared, networkId, talentType, routerLink } = useTalentCardContext()
    const backgroundColor = useBackgroundColor()

    const [layers, setLayers] = useState<Layer[]>([{ name: 'global', dataViews: talent?.dataViews ?? [] }])
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const hasVote = ((talent?.dataViews[0]?.values ?? []).reduce((prev, curr) => curr + prev, 0)) > 0

    const talentLayers = useLayers(talent?.metas.fieldPosition ?? TalentPosition.Midfield, !shared ? layers[0]?.dataViews : talent?.dataViews, undefined, false, undefined)

    const style = useMemo(() => {
        if(talentLayers[activeIndex]?.backgroundColor) {
            return {
                '--ion-color-secondary': talentLayers[activeIndex]?.backgroundColor,
            } as CSSProperties
        }

        return undefined
    }, [talentLayers, activeIndex])

    return (
        <TalentCardPaper
            key={`${talent?.id}-${talent?.dataViews.toString()}`}
            className={clsx('talentcard-medium-back' , { 'talentcard-medium-back--visible': visible })}
            isBackground
            style={style}
        >
            { talent && (
                <>
                    <Button
                        className='talentcard-medium-back__switch-btn'
                        fill='clear'
                        onClick={(e) => {
                            e.stopPropagation()
                            onFlipCardClick()
                        }}
                        icon={arrowUndo}
                        size='small'
                    />

                    <div
                        className='talentcard-medium-back__talentid-wrapper'
                    >
                        <div
                            className='talentcard-medium-back__profile-link'
                            tabIndex={-1}
                            role='button'
                            onKeyDown={(e) => {
                                if (talent && e.key === 'Enter' && routerLink) {
                                    push(routerLink)
                                }
                            }}
                            onClick={() => {
                                if (talent && routerLink) {
                                    push(routerLink)
                                }
                            }}
                        >
                            <h3
                                className='ion-text-center'
                            >
                                {talent?.caption ?? (
                                    <IonSkeletonText
                                        style={{ width: 200 }}
                                    />
                                )}
                            </h3>
                        </div>

                        <div>{talentLayers[activeIndex]?.caption}</div>
                        <TalentID
                            dataView1={!shared ? layers[0]?.dataViews : talent.dataViews}
                            dataView2={!shared ? layers[1]?.dataViews : undefined}
                            defaultIndex={0}
                            talentPosition={talent.metas.fieldPosition}
                            interactive
                            backgroundColor={backgroundColor}
                            color1={(() => {
                                if (layers[1]?.dataViews) {
                                    return 'tertiary'
                                }

                                return talentType === 'roleModel' ? 'black' : undefined
                            })()}
                            color2={talentType === 'roleModel' ? 'black' : 'primary'}
                            onIndexChange={setActiveIndex}
                            under16={(getAge(talent.metas.date) ?? 99) < 16}
                            id='talent-id-detail'
                        />
                        {(!shared && hasVote) && (
                            <>
                                <SkillsSelectLayersButton
                                    color='dark'
                                    activeColor={backgroundColor}
                                    className='talentcard-medium-back__select-layers-btn'
                                    talent={talent}
                                    networkId={networkId}
                                    onSelectionChanged={setLayers}
                                    id='talent-id-selectLayers'
                                />
                                <SkillsPlayAnimationButton
                                    color='dark'
                                    activeColor={backgroundColor}
                                    className='talentcard-medium-back__play-animation-btn'
                                    talent={talent}
                                />
                            </>
                        )}
                        <SkillsListViewButton
                            className='ion-margin-top'
                            color='dark'
                            talentPosition={talent.metas.fieldPosition}
                            dataView1={!shared ? layers[0]?.dataViews : talent.dataViews}
                            dataView2={!shared ? layers[1]?.dataViews : undefined}
                            activeIndex={activeIndex}
                            color1={layers[1]?.dataViews ? 'tertiary' : undefined}
                            color2='light'
                            under16={(getAge(talent.metas.date) ?? 99) < 16}
                            id='talent-id-listView'
                        />
                    </div>
                </>
            )}
        </TalentCardPaper>
    )
}

export default TalentCardMediumBack
