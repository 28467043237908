import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import GetYourSkillsConfirmedButton from '../../../GetYourSkillsConfirmedButton'
import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import type { HelpSliderModalProps } from '../TalentId'

const MySpaceHelp : React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()

    return (
        <OnboardingModal
            open={open}
            setOpen={setOpen}
            id='myspace-page'
        >
            <SwiperSlide>
                <h1>
                    {t('myspace.title')}
                </h1>
                <div>
                    {t('myspace.onboarding.1')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div
                    className='ion-margin-bottom'
                >
                    <GetYourSkillsConfirmedButton />
                </div>
                <div>
                    {t('myspace.onboarding.2')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div>
                    {t('myspace.onboarding.3')}
                </div>
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default MySpaceHelp
