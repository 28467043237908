import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../components/Content'
import Header from '../../../components/Header'
import NetworkHelp from '../../../components/HelpSlider/Help/Network'
import NetworkAddOrEditForm from '../../../components/Network/NetworkAddOrEditForm'
import Page from '../../../components/Page'
import { useNetworkQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

export interface NetworkEditParams {
    talentIds: string // JSON.stringify(Array) -> "[1,2,3,4]"
    networkId: string
}

const NetworkEdit: React.FC = () => {
    const { t } = useTranslation()
    const { params: { networkId } } = useRouteMatch<NetworkEditParams>()
    const user = useUser()
    const { data: network, loading } = useNetworkQuery({
        variables: {
            networkId,
            userId: user?.user.id ?? '',
        },
        skip: !networkId || networkId === 'new',
    })

    return (
        <Page
            trackingTitle={networkId && networkId !== 'new' ? 'network edit' : 'network create'}
        >
            <Header
                title={t(networkId && networkId !== 'new' ? 'network.title.edit' : 'network.title.create')}
                buttonLeft='back'
            />
            <Content
                fullscreen
            >
                <NetworkHelp />
                {!loading && (
                    <NetworkAddOrEditForm
                        network={network?.network}
                    />
                )}
            </Content>
        </Page>
    )
}

export default NetworkEdit
