import { Capacitor } from '@capacitor/core'
import { IonRouterOutlet } from '@ionic/react'
import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'

import useInitActionsHandler from '../../lib/hooks/InitActions'
import useGlobalHooks from '../../lib/hooks/useGlobalHooks'
import useNetworkStatus from '../../lib/hooks/useNetworkStatus'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import Activities from '../../pages/Activities'
import ComparisonPage from '../../pages/ComparisonPage'
import ComparisonSelectionPage from '../../pages/ComparisonSelectionPage'
import FemaleRoleModels from '../../pages/FemaleRoleModels'
import ForgotPasswordOrName from '../../pages/ForgotPasswordOrName'
import Home from '../../pages/Home'
import Login from '../../pages/Login'
import Logout from '../../pages/Logout'
import Marketplace from '../../pages/Marketplace'
import MarketplaceAuthProxy from '../../pages/Marketplace/AuthProxy'
import MarketplaceSearch from '../../pages/MarketplaceSearch'
import MarketplaceSearchSimilar from '../../pages/MarketplaceSearch/Similar'
import MySpace from '../../pages/MySpace'
import Network from '../../pages/Network'
import NetworkEdit from '../../pages/Network/NetworkEdit'
import NetworkAddTalents from '../../pages/Network/NetworkTalents/NetworkAddTalents'
import NetworkAddUsers from '../../pages/Network/NetworkUsers/NetworkAddUsers'
import Networks from '../../pages/Networks'
import Onboarding from '../../pages/Onboarding'
import Playlist from '../../pages/Playlist'
import PlaylistAddOrEdit from '../../pages/PlaylistAddOrEdit'
import Playlists from '../../pages/Playlists'
import Profile from '../../pages/Profile'
import ProfileEdit from '../../pages/Profile/ProfileEdit'
import Register from '../../pages/Register'
import RoleModels from '../../pages/RoleModels'
import Search from '../../pages/Search'
import Settings from '../../pages/Settings'
import BorussiaShanghaiPage from '../../pages/Shared/BorussiaShanghai'
import JoinNetwork from '../../pages/Shared/JoinNetwork'
import JoinPrivateSpace from '../../pages/Shared/JoinPrivateSpace'
import SharedTalent from '../../pages/Shared/SharedTalent'
import SharedTalentPreview from '../../pages/Shared/SharedTalentPreview'
import SubscriptionsPage from '../../pages/SubscriptionsPage'
import TalentPage from '../../pages/Talent'
import TalentEdit from '../../pages/Talent/TalentEdit'
import UserPage from '../../pages/User'
import { AppContext } from '../AppState'
import { AppActionType } from '../AppState/types'
import { DEEPLINK_JOIN_MY_SPACE, DEEPLINK_JOIN_NETWORK } from '../DeeplinkRouter/deeplinkRoutes'
import Hide from '../Hide'
import Menu from '../Menu'
import TabBar from '../TabBar'

const AppShell: React.FC = () => {
    const { newForeignActivities, newActivities, pendingTalentTransferRequests } = useGlobalHooks()
    const { isDesktop } = useWindowResizeListener()
    useInitActionsHandler()
    useNetworkStatus()

    const dispatch = useContextSelector(AppContext, state => state.dispatch)

    useEffect(() => {
        dispatch({
            type: AppActionType.SetState,
            payload: { newForeignActivities, pendingTalentTransferRequests },
        })
    }, [newForeignActivities, pendingTalentTransferRequests, dispatch])

    return (
        <>
            <Menu />
            <IonRouterOutlet
                animated={Capacitor.isNativePlatform() || !isDesktop}
                id='app'
            >
                <Redirect
                    from='/'
                    to='/home'
                    exact
                />

                <Route
                    exact
                    path='/forgotpassword'
                >
                    <ForgotPasswordOrName />
                </Route>

                <Route
                    exact
                    path='/login'
                >
                    <Login />
                </Route>

                <Route
                    exact
                    path='/logout'
                >
                    <Logout />
                </Route>

                <Route
                    exact
                    path='/register'
                >
                    <Register />
                </Route>

                <Route
                    exact
                    path='/settings'
                >
                    <Settings />
                </Route>

                <Redirect
                    from='comparison'
                    to='/playlists'
                    exact
                />

                <Route
                    exact
                    path='/comparison/:talentId1/'
                >
                    <ComparisonSelectionPage />
                </Route>

                <Route
                    exact
                    path='/comparison/:talentId1/:talentId2'
                >
                    <ComparisonPage />
                </Route>

                <Route
                    exact
                    path='/onboarding'
                >
                    <Onboarding />
                </Route>

                {/* ----HOME---- */}
                <Route
                    exact
                    path='/:tab/user/:userId'
                >
                    <UserPage />
                </Route>

                <Route
                    exact
                    path='/home/network/:networkId/user/:userId'
                >
                    <UserPage />
                </Route>

                <Route
                    exact
                    path='/home'
                >
                    <Home />
                </Route>

                <Route
                    exact
                    path='/home/search'
                >
                    <Search />
                </Route>

                <Route
                    exact
                    path='/home/networks'
                >
                    <Networks />
                </Route>

                <Route
                    exact
                    path='/home/network/:networkId/detail'
                >
                    <Network />
                </Route>

                <Route
                    exact
                    path='/home/rolemodels'
                >
                    <RoleModels />
                </Route>

                <Route
                    exact
                    path='/home/femalerolemodels'
                >
                    <FemaleRoleModels />
                </Route>

                <Route
                    exact
                    path='/:tab/network/:networkId/edit/:talentIds?'
                >
                    <NetworkEdit />
                </Route>

                <Route
                    exact
                    path='/:tab/network/:networkId/add/users'
                >
                    <NetworkAddUsers />
                </Route>

                <Route
                    exact
                    path='/:tab/network/:networkId/add/talents'
                >
                    <NetworkAddTalents />
                </Route>

                <Route
                    exact
                    path='/:tab/network/:networkId/edit/talents/:talentId'
                >
                    <TalentEdit />
                </Route>

                <Route
                    exact
                    path='/:tab/network/:networkId/talents/:talentId/:mode?'
                >
                    <TalentPage />
                </Route>

                <Route
                    exact
                    path='/home/subscriptions'
                >
                    <SubscriptionsPage />
                </Route>

                {/* ----PROFILE---- */}
                <Route
                    exact
                    path='/profile'
                >
                    <Profile
                        pendingTalentTransferRequests={pendingTalentTransferRequests}
                    />
                </Route>
                <Route
                    exact
                    path='/profile/tab/:tab'
                >
                    <Profile
                        pendingTalentTransferRequests={pendingTalentTransferRequests}
                    />
                </Route>

                <Route
                    exact
                    path='/profile/edit'
                >
                    <ProfileEdit />
                </Route>

                <Route
                    exact
                    path='/profile/edit/talent/:talentId'
                >
                    <TalentEdit />
                </Route>

                <Route
                    exact
                    path='/profile/edit/talent/:talentId/:autoProfile'
                >
                    <TalentEdit />
                </Route>

                {/* ----MARKETPLACE---- */}
                <Route
                    exact
                    path='/marketplace'
                >
                    <MarketplaceAuthProxy>
                        <Marketplace />
                    </MarketplaceAuthProxy>
                </Route>

                <Route
                    exact
                    path='/marketplace/search'
                >
                    <MarketplaceAuthProxy>
                        <MarketplaceSearch />
                    </MarketplaceAuthProxy>
                </Route>

                <Route
                    exact
                    path='/marketplace/similar/:talentId'
                >
                    <MarketplaceAuthProxy>
                        <MarketplaceSearchSimilar />
                    </MarketplaceAuthProxy>
                </Route>

                {/* ----ACTIVITIES---- */}
                <Route
                    exact
                    path='/activities'
                >
                    <Activities
                        newActivities={newActivities}
                    />
                </Route>

                {/* ----PLAYLISTS---- */}
                <Route
                    exact
                    path='/playlists'
                >
                    <Playlists />
                </Route>

                <Route
                    exact
                    path='/playlists/addoredit/:id/:talentIds?'
                >
                    <PlaylistAddOrEdit />
                </Route>

                <Route
                    exact
                    path='/playlists/detail/:id'
                >
                    <Playlist />
                </Route>

                {/* ----MYSPACE---- */}
                <Route
                    exact
                    path='/home/myspace'
                >
                    <MySpace />
                </Route>

                {/* ---- PUBLIC SHARED ---- */}
                <Route
                    exact
                    path='/s/talent/:hash'
                >
                    <SharedTalent />
                </Route>
                <Route
                    exact
                    path='/s/talent/:hash/previewImage'
                >
                    <SharedTalentPreview />
                </Route>
                <Route
                    exact
                    path='/s/Aquu3EekeeYeakoo/:token'
                >
                    <BorussiaShanghaiPage />
                </Route>
                <Route
                    exact
                    path={`${DEEPLINK_JOIN_NETWORK}:token`}
                >
                    <JoinNetwork />
                </Route>

                <Route
                    exact
                    path={`${DEEPLINK_JOIN_MY_SPACE}:token`}
                >
                    <JoinPrivateSpace />
                </Route>

                <Redirect
                    from='/s'
                    to='/home'
                    exact
                />

            </IonRouterOutlet>
            <Hide
                on='desktop'
            >
                <TabBar />
            </Hide>
        </>
    )
}

export default AppShell
