import { useIonRouter } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkAddOrEditContextMenuItemProps {
    network?: Network
}

const NetworkAddOrEditContextMenuItem: React.FC<NetworkAddOrEditContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const { push } = useIonRouter()

    return (
        <ContextMenuItem
            icon={add}
            label={network
                ? t('networks.edit')
                : t('networks.add')}
            onClick={() => push(`/home/network/${network ? network.id : 'new'}/edit/`)}
        />
    )
}

export default NetworkAddOrEditContextMenuItem
