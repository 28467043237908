import { ChartType } from '../../../../lib/apollo/types'
import type { TalentDataView , TalentPosition } from '../../../../lib/apollo/types'
import useLayers from '../useLayers'

import SkillsListViewPie from './SkillsListViewPie'
import SkillsListViewRadarWithGroupLabels from './SkillsListViewRadarWithGroupLabels'
import SkillsListViewRadarWithoutGroupLabels from './SkillsListViewRadarWithoutGroupLabels'

import './style.scss'

export interface SkillsListViewProps {
    talentPosition: TalentPosition
    dataView1?: TalentDataView[]
    dataView2?: TalentDataView[]
    activeIndex?: number
    color1?: string
    color2?: string
    under16: boolean
}

const SkillsListView: React.FC<SkillsListViewProps> = ({
    activeIndex = 0,
    talentPosition,
    dataView1,
    dataView2,
    color1,
    color2,
    under16,
}) => {
    const layers = useLayers(talentPosition, dataView1, dataView2, under16)
    const layer = layers[activeIndex]

    if (!layer) return null

    switch (layer.type) {
        case ChartType.Pie: return (
            <SkillsListViewPie
                layer={layer}
                color1={color1}
                color2={color2}
            />
        )

        case ChartType.Radar: {
            if (layer.withGroupLabels) {
                return (
                    <SkillsListViewRadarWithGroupLabels
                        activeIndex={activeIndex}
                        talentPosition={talentPosition}
                        dataView1={dataView1}
                        dataView2={dataView2}
                        color1={color1}
                        color2={color2}
                        under16={under16}
                    />
                )
            }
            return (
                <SkillsListViewRadarWithoutGroupLabels
                    layer={layer}
                    color1={color1}
                    color2={color2}
                    talentPosition={talentPosition}
                    under16={under16}
                />
            )
        }

        default: return null
    }
}

export default SkillsListView
