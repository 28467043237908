import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import type { HelpSliderModalProps } from '../TalentId'

const VoteFormHelp: React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()
    return (
        <OnboardingModal
            id='vote-form'
            open={open}
            setOpen={setOpen}
        >
            <SwiperSlide>
                <h1>
                    {t('vote.title')}
                </h1>
                <div>
                    {t('vote.onboarding.1')}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div>
                    {t('vote.onboarding.2')}
                </div>
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default VoteFormHelp
