import { IonText } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    UserMetaDataDocument,
    useUpdateUserMetaDataMutation,
} from '../../../lib/apollo/types'
import type { UserMetaData ,
    User } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import Button from '../../Button'
import Buttons from '../../Buttons'
import Modal from '../../Modal'
import StatusChangeForm from '../../StatusChangeForm'

import '../style.scss'

interface UserStatusProps {
    user: User
    userMeta: UserMetaData
}

const UserStatus: React.FC<UserStatusProps> = ({ user, userMeta }) => {
    const { t } = useTranslation()
    const [showToast] = useToast()
    const me = useUser()

    const [updateUserMetaData, { loading }] = useUpdateUserMetaDataMutation({
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user.id,
            },
        }],
    })

    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false)
    const [status, setStatus] = useState<string | number | null | undefined>(userMeta?.status ?? '')
    const editable = me?.user.id === user.id

    const userStates = useMemo(() => (new Array(7))
        .fill(undefined)
        .map((_, i) => t(`status.userStatus${i}`)), [t])

    const talentStates = useMemo(() => (new Array(7))
        .fill(undefined)
        .map((_, i) => t(`status.talentStatus${i}`)), [t])

    const onChange = (val?: string | number | null) => { setStatus(val) }
    const onSave = async () => {

        const userMetaData = {
            ...userMeta,
            behavior: {
                ...userMeta.behavior,
                __typename: undefined,
            },
            __typename: undefined,
        }

        await updateUserMetaData({
            variables: {
                input: {
                    ...userMetaData,
                    status: status as string,
                },
            },
        })

        showToast(t('status.changed'), 'success')
        setShowStatusChangeModal(() => false)
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                role={!editable ? undefined : 'button'}
                tabIndex={!editable ? undefined : -1}
                onKeyDown={!editable ? undefined : (e) => {
                    if (e.key === 'Enter') {
                        setShowStatusChangeModal(() => true)
                    }
                }}
                onClick={!editable ? undefined : () => { setShowStatusChangeModal(() => true) }}
                className='status'
            >
                <IonText
                    color='medium'
                >
                    <em>{ status || t('user.status.placeHolder') }</em>
                </IonText>

            </div>
            <Modal
                modalTitle={t('status.userStatusChangeModalTitle')}
                isOpen={showStatusChangeModal}
                onClose={() => { setShowStatusChangeModal(() => false) }}
            >
                <StatusChangeForm
                    predefinedStates={[...userStates, ...talentStates]}
                    onChange={onChange}
                    status={status as string ?? ''}
                />
                <Buttons>
                    <Button
                        loading={loading}
                        color='secondary'
                        icon={checkmark}
                        onClick={onSave}
                    >
                        {t('buttons.save')}
                    </Button>
                </Buttons>
            </Modal>
        </>
    )
}

export default UserStatus
