import { IonButton, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import type { User } from '../../../lib/apollo/types'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import Segment from '../../Segment'
import UserListItem from '../UserListItem'
import UserListItemFind from '../UserListItem/UserListItemFind'

import './style.scss'

export type UserTeaserSliderTab = {
    label: string | JSX.Element,
    users: User[]
}

interface UserTeaserSliderProps {
    tabs?: UserTeaserSliderTab[] | null
    title: string
    subtitle: string
}

const UserTeaserSlider: React.FC<UserTeaserSliderProps> = ({ title, subtitle, tabs }) => {
    const { t } = useTranslation()
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    const [activeSegment, setActiveSegment] = useState<string>('0')
    const [showModal, setShowModal] = useState(false)

    const onIonSlideWillChange = useCallback((e: SwiperClass) => { setActiveSegment(e.activeIndex.toString()) }, [setActiveSegment])

    const onSegmentChange = useCallback((e: any) => {
        if (controlledSwiper) {
            controlledSwiper.slideTo(e.detail.value)
            setActiveSegment(() => e.detail.value)
        }
    }, [controlledSwiper, setActiveSegment])

    const activeTab = tabs?.[+activeSegment]

    // BS RENDER FIX ON ITEM CHANGE
    const key = useMemo(() => tabs?.reduce((acc, tab) => `${acc + tab?.users?.length.toString()}-`, '') ?? '', [tabs])

    useEffect(() => {
        if(!tabs || !controlledSwiper?.params) return
        requestAnimationFrame(() => {
            controlledSwiper.updateAutoHeight()
        })
    }, [tabs, controlledSwiper])

    return (
        <>
            <IonGrid>
                <IonRow>
                    <h2
                        className='ion-margin-bottom'
                    >
                        { title }
                    </h2>
                </IonRow>
                <IonRow>
                    <IonText
                        className='ion-margin-bottom'
                        color='medium'
                    >
                        <span>
                            { subtitle }
                        </span>
                    </IonText>
                </IonRow>
                <IonRow>
                    <Segment
                        value={activeSegment}
                        onIonChange={onSegmentChange}
                        tabs={tabs?.map(tab => ({ tab: tab.label }))}
                    />
                </IonRow>

                <IonRow>
                    <IonCol>
                        {tabs ? (
                            <Swiper
                                modules={[Controller]}
                                autoHeight
                                key={key}
                                spaceBetween={16}
                                onSwiper={setControlledSwiper}
                                onSlideChange={onIonSlideWillChange}
                            >
                                {tabs.map((tab, i) => (
                                    <SwiperSlide
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                    >
                                        <div
                                            className='user-teaser-slider__slide'
                                        >
                                            <ItemList>
                                                {tab.users.slice(0, 4).map((user) => (
                                                    <UserListItem
                                                        key={user.id}
                                                        user={user}
                                                    />
                                                ))}
                                                {tab.users.length === 0 && (
                                                    <UserListItemFind />
                                                )}
                                            </ItemList>
                                            <IonButton
                                                disabled={tab.users.length < 4}
                                                onClick={() => { setShowModal(() => true) }}
                                                size='small'
                                                fill='clear'
                                                color='tertiary'
                                                className='user-teaser-slider__show-more ion-text-uppercase'
                                            >
                                                {t('buttons.showAll')}
                                            </IonButton>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <ItemList>
                                <UserListItem />
                                <UserListItem />
                                <UserListItem />
                                <UserListItem />
                            </ItemList>
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>

            <Modal
                onClose={() => { setShowModal(() => false) }}
                isOpen={showModal}
                modalTitle={activeTab?.label ?? ''}
            >
                <ItemList>
                    {activeTab?.users.map(user => (
                        <UserListItem
                            key={user.id}
                            user={user}
                            onClick={() => setShowModal(() => false)}
                        />
                    ))}
                </ItemList>
            </Modal>
        </>
    )
}

export default UserTeaserSlider
