import { IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import clsx from 'clsx'
import { shirt } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import TrainerIcon from '../../../../assets/img/trainer.png'
import type { User, UserMetaData } from '../../../../lib/apollo/types'
import { UserType } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import FollowButton from '../../../Button/FollowButton'
import Buttons from '../../../Buttons'
import CustomAvatar from '../../../CustomAvatar'
import UserStatus from '../../../Status/UserStatus'
import UserContextMenu from '../../UserContextMenu'
import UserProfileContent from '../../UserProfileContent'

import UserCardMediumSkeleton from './UserCardMediumSkeleton'

import './style.scss'

interface UserCardMediumProps {
    user?: User
    disableFollowButton?: boolean
}

const UserCardMedium: React.FC<UserCardMediumProps> = ({ user, disableFollowButton = false }) => {
    const me = useUser()
    const isMe = me?.user.id === user?.id

    const { t } = useTranslation()

    const metaData = user?.userMetaData?.user?.data
    const isTrainer = metaData?.userType?.includes(UserType.Trainer)

    if (!user) return <UserCardMediumSkeleton />

    return (
        <div>
            <IonCard
                className={clsx('user-card-medium', { 'user-card-medium--trainer': isTrainer })}
                color='primary'
            >
                <IonGrid>
                    <IonRow
                        className='user-card-medium__head ion-nowrap'
                    >
                        <CustomAvatar
                            width={136}
                            img={user.image}
                            alt={user.name}
                        />

                        <IonCol
                            className='user-card-medium__head-col'
                        >
                            <IonGrid
                                className='user-card-medium__head__content'
                            >
                                <IonRow>
                                    <IonCol>
                                        <IonCardTitle>
                                            {`${user.firstName} ${user.lastName}`}
                                        </IonCardTitle>
                                        <IonRow>
                                            {metaData?.experience?.[0] && (
                                                <IonCardSubtitle>
                                                    {t(`user.experience.${metaData.experience?.[0]}`)}
                                                </IonCardSubtitle>
                                            )}
                                        </IonRow>
                                    </IonCol>
                                    <IonCol
                                        className='ion-text-right user-card-medium__ctx-menu-wrapper'
                                    >
                                        <UserContextMenu
                                            color='light'
                                            user={user}
                                        />
                                    </IonCol>
                                </IonRow>

                                {isTrainer && (
                                    <IonRow
                                        className='user-card-medium__trainer-wrapper'
                                    >
                                        <img
                                            src={TrainerIcon}
                                            alt='trainer'
                                        />
                                        <h4>
                                            {t('user.userType.trainer', { context: metaData })}
                                        </h4>
                                    </IonRow>
                                )}

                                <hr />

                                <IonRow
                                    className='ion-align-items-center ion-justify-content-start'
                                >
                                    <IonCol
                                        size='auto'
                                    >
                                        <IonIcon
                                            className='user-card-medium__club-img'
                                            icon={shirt}
                                            size='large'
                                            color='medium'
                                        />
                                    </IonCol>
                                    <IonCol>
                                        <IonCardTitle
                                            className='text'
                                        >
                                            {metaData?.club ?? '???'}
                                        </IonCardTitle>
                                        <IonCardSubtitle>
                                            {metaData?.team ?? '???'}
                                        </IonCardSubtitle>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>

                    {metaData && (
                        <IonRow>
                            <UserProfileContent
                                metaData={metaData}
                                user={user}
                            />
                        </IonRow>
                    )}

                    <IonRow>
                        <UserStatus
                            user={user}
                            userMeta={metaData as UserMetaData}
                        />
                    </IonRow>
                </IonGrid>
            </IonCard>
            { (!isMe && !disableFollowButton) && (
                <Buttons>
                    <FollowButton
                        className='user-card-medium__follow-btn'
                        withIcon
                        expand='block'
                        user={user}
                    />
                </Buttons>
            )}
        </div>
    )
}

export default UserCardMedium
