import { useTranslation } from 'react-i18next'

import type { Certificate, Talent } from '../../lib/apollo/types'

import BorussiaShanghaiCertificate from './BorussiaShanghai'
import HsvCertificate from './HSV'
import RBLCertificate from './RBL'

export type CertificateProps = {
    certificate: Certificate,
    talent?: Talent,
}
const CertificateComponent: React.FC<CertificateProps> = ({ certificate, talent }) => {
    const { t } = useTranslation()

    switch (certificate.club) {
        case 'hsv':
            return (
                <HsvCertificate
                    certificate={certificate}
                    header={{
                        intro: 'Bei uns bist du der Star',
                        title: 'HSV TALENTCARD',
                    }}
                    footer={{
                        text: t('certificate.footer') as string,
                        img: '/assets/icon/hsv_logo.svg',
                    }}
                    text='Toll, dass Du mitgemacht hast. Deine digitale Talentcard findest Du hinter dem QR-Code oder hier:'
                    clubImg='/assets/icon/hsv_logo.svg'
                    schoolImg='/assets/hsv-fubaschu.jpeg'
                />
            )
        case 'rbl':
            return (
                <RBLCertificate
                    certificate={certificate}
                    talent={talent}
                />
            )
        case 'borussia-shanghai':
            return (
                <BorussiaShanghaiCertificate
                    {...certificate}
                    talent={talent}
                />
            )
        default: return null
    }

}

export default CertificateComponent
