import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'

import { NetworkRole, TalentPosition } from '../../../../lib/apollo/types'
import NetworkCardSmall from '../../../Network/NetworkCard/NetworkCardSmall/NetworkCardSmall'
import NetworkListItem from '../../../Network/NetworkListItem'
import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import TalentID from '../../../Talent/TalentID'
import type { HelpSliderModalProps } from '../TalentId'

import './style.scss'

const NetworkHelp: React.FC<HelpSliderModalProps> = ({ open, setOpen }) => {
    const { t } = useTranslation()
    return (
        <OnboardingModal
            open={open}
            setOpen={setOpen}
            id='networks-page'
        >
            <SwiperSlide>
                <NetworkListItem
                    onClick={() => {}}
                    network={{
                        networkRoles: [NetworkRole.None],
                        id: 'preview-1',
                        caption: 'Join my Talent',
                    }}
                />
                <NetworkListItem
                    onClick={() => {}}
                    network={{
                        networkRoles: [NetworkRole.None],
                        id: 'preview-2',
                        caption: 'Super Team',
                    }}
                />
                {t('networks.onboarding.1')}
            </SwiperSlide>
            <SwiperSlide>
                <NetworkCardSmall
                    network={{
                        networkRoles: [NetworkRole.None],
                        id: 'preview-2',
                        caption: 'Super Team',
                    }}
                />
                <p>{t('networks.onboarding.2')}</p>
            </SwiperSlide>
            <SwiperSlide>
                <TalentID
                    className='talent-card-medium__talent-id'
                    dataView2={[{ key: 0, values: [41,61,41,61,61,61,71,81,51,41,50,61,61,71,81,61,51,45,59,71,81,91,71,61,81,51,61,61,61,61,61,61] }]}
                    dataView1={[{ key: 0, values: [50,58,52,70,61,63,60,55,59,50,84,94,64,64,54,74,64,74,84,74,84,64,74,84,94,84,74,64,74,64,54,64] }]}
                    defaultIndex={0}
                    talentPosition={TalentPosition.Midfield}
                    backgroundColor='secondary'
                    color1='tertiary'
                    color2='primary'
                    under16={false}
                />
                {t('networks.onboarding.3')}
            </SwiperSlide>
        </OnboardingModal>
    )
}

export default NetworkHelp
