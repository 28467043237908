import { IonCard, IonCardSubtitle, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import { useListMyPersonificationsQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import ChoseRoleModelButton from '../../Talent/TalentCard/TalentCardRoleModel/ChoseRoleModelButton'

import './style.scss'
import '../../TeaserCard/style.scss'

interface RoleModelTeaserCardProps {
    talent?: Talent
    usePersonification?: boolean
}

const RoleModelTeaserCard: React.FC<RoleModelTeaserCardProps> = ({ talent, usePersonification = false }) => {
    const { t } = useTranslation()

    const user = useUser()
    const { data: myPersonifications } = useListMyPersonificationsQuery({
        skip: !usePersonification || !user,
        variables: {
            userId: user?.user.id ?? '',
        },
    })

    const ttalent = usePersonification
        ? myPersonifications?.listMyPersonifications.collection?.[0]
        : talent

    if (usePersonification && !ttalent) return null

    return (
        <IonCard
            color='primary'
            className='teaser-card rolemodel-teaser-card'
        >
            <video
                width='auto'
                height='100%'
                autoPlay
                loop
                muted
                playsInline
                className='rolemodel-teaser-card__video'
            >
                <source
                    src='/assets/mov/type_of_player.mov'
                    type='video/mp4; codecs="hvc1"'
                />
                <source
                    src='/assets/mov/type_of_player_VP9.webm'
                    type='video/webm'
                />
            </video>
            <IonGrid>
                <IonRow
                    className='ion-align-items-center teaser-card__row'
                >
                    <IonCol
                        className='teaser-card__content'
                    >
                        <IonText>
                            <h2>
                                {t('talent.roleModelTeaserCardTitle')}
                            </h2>
                        </IonText>

                        <IonCardSubtitle>
                            {t('talent.roleModelTeaserCardSubtitle')}
                        </IonCardSubtitle>

                        <ChoseRoleModelButton
                            loading={!ttalent}
                            talent={ttalent}
                            disabled={ttalent?.rateCount === 0}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default RoleModelTeaserCard
