import './style.scss'
import {Talent, TalentDataView, TalentPosition, useGlobalVoteForDateRangeQuery,} from '../../../../lib/apollo/types'
import {addDays, format} from 'date-fns'
import TalentID from '../../TalentID'
import getAge from '../../../../lib/getAge'
import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow
} from '@ionic/react'
import React, {useMemo, useState} from 'react'
import useTalentType from '../../../NewTalentCard/_Core/useTalentType'
import {useTranslation} from 'react-i18next'
import Modal from '../../../Modal'
import useLayers from '../../TalentID/useLayers'

type TimelineItemProps = {
    talent: Talent
    start: string
    end?: string
    data: { title: string, content: string }
    disableOnClick: boolean
    shared?: boolean
    dataViews?: TalentDataView[]
}

const TeamEntry: React.FC<TimelineItemProps> = ({ talent, start, end, data, disableOnClick, shared = false, dataViews }) => {
    const { t } = useTranslation()
    const talentType = useTalentType(talent)
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const talentLayers = useLayers(talent?.metas.fieldPosition ?? TalentPosition.Midfield, talent?.dataViews, undefined, false, undefined)

    const backgroundColor = useMemo(() => {
        switch (talentType) {
            case 'scout': return 'scout'
            case 'talent': return 'secondary'
            default: return 'roleModel'
        }
    }, [talentType])

    const { data: loadedVote } = useGlobalVoteForDateRangeQuery({
        variables: {
            talentId: talent?.id ?? '',
            startDate: format(start, 'yyyy-MM-dd'),
            endDate: format(end ?? addDays(new Date(), 1), 'yyyy-MM-dd'),
        },
        skip: !talent?.id || shared,
        fetchPolicy: 'network-only',
    })

    const vote = shared ? dataViews : loadedVote?.globalVoteForDateRange

    const showTalentId = (vote?.[0]?.values.reduce((sum, current) => sum + current, 0) ?? 0) > 0
    const [open, setOpen] = useState(false)

    return (
        <>
            <IonCard
                className="talent-timeline-item__entry__card talent-timeline-item__entry__card--team"
                color={!end ? 'dark' : undefined}
                onClick={() => {
                    if(!showTalentId || disableOnClick) return
                    setOpen(true)
                }}
            >
                <IonGrid
                    className='talentcard-list-item__grid'
                >
                    <IonRow
                        className='ion-align-items-center ion-justify-content-between ion-nowrap'
                    >
                        <IonCol
                            className='talentcard-list-item__caption-wrapper'
                        >
                            <IonCardTitle>
                                {data.title}
                            </IonCardTitle>
                            <IonRow>
                                <IonCardSubtitle color='dark'>
                                    {data.content}
                                </IonCardSubtitle>
                            </IonRow>
                            <div className='talentcard-list-item__time'>
                                {`${new Date(start).toLocaleDateString(navigator.language, {year: '2-digit', month: '2-digit', day: '2-digit',})} - ${!end ? t('talent.timeline.today') : new Date(end).toLocaleDateString(navigator.language, {year: '2-digit', month: '2-digit', day: '2-digit',})}`}
                            </div>
                        </IonCol>
                        <IonCol
                            className='talentcard-list-item__talent-id'
                        >
                            {showTalentId && (
                                <TalentID
                                    dataView1={vote}
                                    talentPosition={talent.metas.fieldPosition}
                                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                                    backgroundColor={backgroundColor}
                                />
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
            <Modal
                modalTitle={data.title}
                isOpen={open} onClose={() => setOpen(false)}
                onDidDismiss={() => setOpen(false)}
                size='alert'
            >
                <div
                    className='ion-text-center talentcard-list-item__popup__talent-id-name'>
                    {(vote?.length ?? 0) > 1 ? talentLayers[activeIndex]?.caption : talentLayers.find(l => +l.id === vote?.[0]?.key)?.caption}
                </div>
                <TalentID
                    dataView1={vote}
                    talentPosition={talent.metas.fieldPosition}
                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                    backgroundColor={backgroundColor}
                    interactive
                    onIndexChange={setActiveIndex}
                />
                <div
                    className='ion-text-center talentcard-list-item__popup__talent-id-time'>
                    <div className='ion-margin-top'>
                        {`${new Date(start).toLocaleDateString(navigator.language, {year: '2-digit', month: '2-digit', day: '2-digit',})} - ${!end ? t('talent.timeline.today') : new Date(end).toLocaleDateString(navigator.language, {year: '2-digit', month: '2-digit', day: '2-digit',})}`}
                    </div>
                    <div className='ion-margin-top'>
                        {data.title}
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default TeamEntry