import clsx from 'clsx'
import { useState } from 'react'

import TalentCardMediumBack from './TalentCardMediumBack'
import TalentCardMediumFront from './TalentCardMediumFront'


import './style.scss'
import { TalentCardContext } from './_Core/Context'
import {Certificate} from '../../../../lib/apollo/types'

interface TalentCardMediumProps {
  certificate: Certificate
  clubImg?: string
  schoolImg?: string
}
const TalentCardMedium = ({ certificate, clubImg, schoolImg }: TalentCardMediumProps) => {
  const [showBack, setShowBack] = useState(false)

  return (
      <TalentCardContext.Provider
          value={{
            certificate,
            clubImg,
            schoolImg
          }}>
        <div
            className={clsx('rbl-talentcard-medium')}
        >
            <TalentCardMediumFront
                onFlipCardClick={() => { setShowBack(() => true) }}
                visible={!showBack}
            />
            <TalentCardMediumBack
                onFlipCardClick={() => { setShowBack(() => false) }}
                visible={showBack}
                className={clsx({ 'rbl-talentcard-medium--hide': !showBack })}
            />
        </div>

      </TalentCardContext.Provider>
  )
}

export default TalentCardMedium
