import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { checkmark } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import {
    TalentDocument,
    TalentPosition, useCreateTalentCustomMetaMutation,
    useUpdateTalentCustomMetaMutation,
} from '../../../../lib/apollo/types'
import { MatomoEventCategory } from '../../../../lib/tracking/eventCategories'
import { useUser } from '../../../../providers/Auth/hooks'
import Button from '../../../Button'
import type { SelectWithOrderState } from '../../../SelectWithOrder'
import SelectWithOrder from '../../../SelectWithOrder'

import './style.scss'

interface ChoseTopSkillsProps {
    talent: Talent,
    onFinish: () => any
}

interface SkillsI {
    value: number,
    translation: string
    rotation: number
}

const CHOSE_FROM_X_TOPSKILLS_AMOUNT = 10

const ChoseTopSkills: React.FC<ChoseTopSkillsProps> = ({ talent, onFinish }) => {
    const { values } = talent.dataViews[0]
    const { t } = useTranslation()
    const user = useUser()

    const { trackEvent } = useMatomo()

    const defaultValue = useMemo<SelectWithOrderState | undefined>(() => talent?.customMeta?.topskillsindexes?.map(value => ({
        value,
        icon: '',
        label: t(`i18n.class.${talent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${value}`),
    })), [t, talent?.customMeta?.topskillsindexes, talent?.metas.fieldPosition])

    const [selected, setSelected] = useState<SelectWithOrderState>(defaultValue ?? [])

    const [updateCustomMeta] = useUpdateTalentCustomMetaMutation({
        refetchQueries: [{
            query: TalentDocument,
            variables: { userId: user?.user.id ?? '', talentId: talent.id },
        }],
    })

    const [createCustomMeta] = useCreateTalentCustomMetaMutation({
        refetchQueries: [{
            query: TalentDocument,
            variables: { userId: user?.user.id ?? '', talentId: talent.id },
        }],
    })

    const onSaveCLick = useCallback(() => {
        trackEvent({
            action: 'save-edit-topskills',
            category: MatomoEventCategory.Talent,
        })

        const customMeta = {
            ...talent.customMeta,
            __typename: undefined,
        }

        if(!talent.customMeta?.id) {
            createCustomMeta({
                variables: {
                    talentId: talent.id,
                    input: {
                        ...customMeta,
                        topskillsindexes: selected.map(v => +v.value),
                    },
                },
            })
        } else {
            updateCustomMeta({
                variables: {
                    talentId: talent.id,
                    metaDataId: talent.customMeta.id,
                    input: {
                        ...customMeta,
                        topskillsindexes: selected.map(v => +v.value),
                    },
                },
            })
        }

        onFinish()
    }, [trackEvent, talent.customMeta, talent.id, onFinish, createCustomMeta, selected, updateCustomMeta])

    const orderedTranslations = useMemo<SkillsI[]>(() => values?.map((value, i) => ({
        value: i,
        compareValue: value,
        translation: t(`i18n.class.${talent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`),
        rotation: Math.floor(i / ((values?.length ?? 1) / 4)) * 90,
    }))
        .sort((left, right) => right.compareValue - left.compareValue)
        .slice(0, CHOSE_FROM_X_TOPSKILLS_AMOUNT) ?? [],
    [values, t, talent?.metas.fieldPosition])

    const options = useMemo<SelectWithOrderState>(() => [0,1,2,3,4,5,6,7,8,9].map(i => ({
        value: orderedTranslations[i].value.toString(),
        icon: '',
        label: orderedTranslations[i].translation,
    })), [orderedTranslations])

    return (
        <IonGrid
            className='chose-topskills'
        >
            <IonRow>
                <IonCol>
                    <h1>{t('talent.topskills.selectHeadline')}</h1>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <SelectWithOrder
                        max={4}
                        selected={selected}
                        setSelected={setSelected}
                        options={options}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol
                    className='chose-topskills__hint-text'
                >
                    <IonText
                        color='medium'
                    >
                        {t('talent.topskills.hint')}
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol
                    className='ion-text-center'
                >
                    <Button
                        icon={checkmark}
                        onClick={onSaveCLick}
                    >
                        {t('buttons.done')}
                    </Button>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ChoseTopSkills
