import { useIonRouter } from '@ionic/react'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Playlist } from '../../../lib/apollo/types'
import {
    useListMyPersonificationsQuery,
    usePlaylistsLazyQuery,
    usePlaylistTalentsQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import InfiniteScrollWithHook from '../../InfiniteScrollWithHook'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import TalentCard from '../../NewTalentCard'
import PlaylistListItem from '../../Playlist/PlaylistListItem'
import TalentQueryContainer from '../../Talent/TalentQueryContainer'

import './style.scss'

interface CompareWithProps {
    talentId: string
}

const CompareWith: React.FC<CompareWithProps> = ({ talentId }) => {
    const redirectRef = useRef<string | undefined>()
    const { push } = useIonRouter()
    const { t } = useTranslation()
    const me = useUser()
    const [activePlaylist, setActivePlaylist] = useState<string | undefined>()

    const { data: { playlistTalents } = {} } = usePlaylistTalentsQuery({
        skip: !activePlaylist || !me,
        variables: {
            userId: me?.user.id ?? '',
            id: activePlaylist ?? '',
        },
    })

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: me?.user.id ?? '',
        },
        skip: !me,
    })

    const myTalentId = `${myPersonifications?.listMyPersonifications.collection?.[0]?.id}`

    return (
        <div
            className='compare-with'
        >
            <ItemList>
                <TalentQueryContainer
                    talentId={talentId}
                    routerLink={`/comparison/${talentId}`}
                />
            </ItemList>

            <h3
                className='compare-width__divider ion-text-center'
            >
                {t('talent.compareWith')}
            </h3>

            <ItemList>
                {(myTalentId && myTalentId !== 'undefined' && (myTalentId !== talentId)) && (
                    <TalentQueryContainer
                        talentId={myTalentId}
                        routerLink={`/comparison/${talentId}/${myTalentId}`}
                    />
                )}
                <InfiniteScrollWithHook
                    lazyHook={usePlaylistsLazyQuery}
                >
                    {(playlists: Playlist[]) => playlists.map(playlist => (
                        <React.Fragment
                            key={playlist.id}
                        >
                            <PlaylistListItem
                                onClick={() => { setActivePlaylist(() => playlist.id) }}
                                hideContextMenu
                                playlist={playlist as Playlist}

                            />
                            <Modal
                                onClose={() => { setActivePlaylist(() => undefined) }}
                                modalTitle={t('talent.compareWith')}
                                isOpen={activePlaylist === playlist.id}
                                onDidDismiss={() => {
                                    if (redirectRef.current) {
                                        push(redirectRef.current)
                                        redirectRef.current = undefined
                                    }
                                }}
                            >
                                <div>
                                    {playlistTalents && playlistTalents.collection.length === 0 && (
                                        <h3
                                            className='ion-text-center'
                                        >
                                            {t('playlist.noTalents')}
                                        </h3>
                                    )}
                                    <ItemList>
                                        { playlistTalents?.collection.map(talent => (
                                            <TalentCard
                                                type='listItem'
                                                onClick={() => {
                                                    setActivePlaylist(() => undefined)
                                                    redirectRef.current = `/comparison/${talentId}/${talent.id}`
                                                }}
                                                key={talent.id}
                                                talent={talent}
                                            />
                                        ))}
                                    </ItemList>
                                </div>
                            </Modal>
                        </React.Fragment>
                    ))}
                </InfiniteScrollWithHook>
            </ItemList>
        </div>
    )
}

export default CompareWith
