import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const BE_URL = 'https://static.joinmytalent.com/jmt'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        load: 'languageOnly',
        backend: {
            loadPath: `${BE_URL}/{{lng}}.json`,
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: false,
        },
        returnNull: false,
        returnEmptyString: false,
    })

export default i18n
