import './style.scss'

import { IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import Divider from '../Divider'
import Announcement from '../Icon/Announcement'

const WhatsNew: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol
                        className='n2br ion-padding-top'
                    >
                        <IonCard
                            className='whats-new__card'
                        >
                            <IonCardTitle
                                className='ion-padding-start ion-padding-top'
                            >
                                <h2>{t('whatsNew.title')}</h2>
                            </IonCardTitle>
                            <IonCardContent>
                                <IonText
                                    color='light'
                                >
                                    {t('whatsNew.text')}
                                </IonText>
                            </IonCardContent>
                            <Announcement
                                className='whats-new__icon'
                                color='secondary'
                            />
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <Divider />
        </>
    )
}

export default WhatsNew
