import { IonIcon, IonItem, IonLabel, IonText, useIonRouter } from '@ionic/react'
import { checkmark, search } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import {
    NetworksDocument, NetworkTalentsDocument,
    useAddNetworkMutation, useAddTalentsToNetworkMutation,
    useEditNetworkMutation,
    useNetworkLinkImageMutation, useNetworkUnlinkImageMutation,
} from '../../../lib/apollo/types'
import type { Network , NetworkInput } from '../../../lib/apollo/types'
import type { NetworkEditParams } from '../../../pages/Network/NetworkEdit'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import Button from '../../Button'
import CustomAvatar from '../../CustomAvatar'
import Form from '../../Form'
import FormCheckbox from '../../Form/FormCheckbox'
import FormImageInput from '../../Form/FormImageInput'
import FormTextField from '../../Form/FormTextField'
import ItemList from '../../ItemList'

interface NetworkAddOrEditFormProps {
    network?: Network,
}

const NetworkAddOrEditForm: React.FC<NetworkAddOrEditFormProps> = ({ network }) => {
    const user = useUser()
    const { params: { talentIds } } = useRouteMatch<NetworkEditParams>()
    const { push } = useIonRouter()
    const [addTalentMutation, { loading: addTalentsLoading }] = useAddTalentsToNetworkMutation({
        errorPolicy: 'none',
    })

    const [createNetworkMutation, { loading: addLoading }] = useAddNetworkMutation({
        refetchQueries: [{
            query: NetworksDocument,
            variables: {
                userId: user?.user.id ?? '',
            },
        },
        {
            query: NetworksDocument,
            variables: {
                userId: user?.user.id ?? '',
                start: 1,
                limit: 15,
            },
        }, {
            query: NetworksDocument,
            variables: {
                userId: user?.user.id ?? '',
                search: '?role=mod',
            },
        }],
    })
    const [editNetworkMutation, { loading: editLoading }] = useEditNetworkMutation()
    const [linkNetworkImageMutation, { loading: imageLoading }] = useNetworkLinkImageMutation()
    const [unlinkNetworkImageMutation] = useNetworkUnlinkImageMutation()

    const [preview, setPreview] = useState<string | undefined>()

    const { t } = useTranslation()
    const { goBack } = useIonRouter()
    const [present] = useToast()

    const onImageDelete = useCallback(async () => {
        if (!network || !network.asset) return
        await unlinkNetworkImageMutation({
            variables: {
                networkId: network.id,
                url: `/asset/${network.asset}`,
            },
        })
    }, [network, unlinkNetworkImageMutation])

    const onSubmit = useCallback(async (data: NetworkInput & { image?: string }) => {
        const { image, ...input } = data
        try {
            if (network?.id) {
                await editNetworkMutation({
                    variables: {
                        input,
                        id: network.id,
                    },
                })
                if (image) {
                    await linkNetworkImageMutation({
                        variables: {
                            url: `/asset/${image}`,
                            networkId: network.id,
                        },
                    })
                }
            } else {
                const response = await createNetworkMutation({
                    variables: {
                        input,
                    },
                })

                if (talentIds && response.data?.addNetwork.id) {
                    await addTalentMutation({
                        variables: {
                            id: response.data.addNetwork.id,
                            talents: { _links: { objectByNetwork: { url: JSON.parse(talentIds).map((talentId: number) => `/object/${talentId}`) } } },
                        },
                        refetchQueries: [{
                            query: NetworkTalentsDocument,
                            variables: {
                                userId: user?.user.id ?? '',
                                networkId: response.data.addNetwork.id,
                            },
                        }],
                    })
                }

                if (image && response.data?.addNetwork.id) {
                    await linkNetworkImageMutation({
                        variables: {
                            url: `/asset/${image}`,
                            networkId: response.data.addNetwork.id,
                        },
                    })
                }
            }
            goBack()
        } catch (e) {
            present(t('network.addOrEditError'), 'danger')
        }
    }, [addTalentMutation, goBack, createNetworkMutation, editNetworkMutation, linkNetworkImageMutation, network?.id, present, t, talentIds, user?.user.id])

    return (
        <div
            className='network-edit-form'
        >
            <IonItem
                lines='none'
            >
                <IonIcon
                    icon={search}
                    slot='start'
                    onClick={() => {
                        push('/home/search')
                    }}
                />
                <IonLabel
                    className='ion-text-wrap'
                >
                    <IonText
                        color='medium'
                    >
                        {t('dashboard.nextSteps.searchTeam.description')}
                    </IonText>
                </IonLabel>
            </IonItem>

            <CustomAvatar
                type='network'
                width={400}
                img={preview ?? network?.image}
                onDelete={network?.asset ? onImageDelete : undefined}
            />
            <Form
                onSubmit={onSubmit}
            >
                <FormImageInput
                    name='image'
                    label={t('network.edit.uploadImage')}
                    size={{ width: 400, height: 300 }}
                    onPreviewChange={setPreview}
                    showPreview={false}
                />
                <ItemList>
                    <FormTextField
                        name='caption'
                        label={t('network.edit.caption')}
                        defaultValue={network?.caption}
                        required
                    />
                    <FormTextField
                        name='description'
                        label={t('network.edit.description')}
                        multiline
                        defaultValue={network?.description ?? ''}
                        maxLength={500}
                    />
                    <FormCheckbox
                        name='public'
                        label={t('network.edit.public')}
                        defaultValue={!!network?.public}
                        hint={t('network.edit.publicHint')}
                    />
                    <FormCheckbox
                        name='open'
                        label={t('network.edit.open')}
                        defaultValue={!!network?.open}
                        hint={t('network.edit.openHint')}
                    />
                </ItemList>
                <Button
                    color='secondary'
                    type='submit'
                    icon={checkmark}
                    loading={addTalentsLoading || addLoading || editLoading || imageLoading}
                >
                    {t(`network.edit.save.${network ? 'edit' : 'create'}`)}
                </Button>
            </Form>
        </div>
    )
}

export default NetworkAddOrEditForm
