import type { Certificate } from '../../../lib/apollo/types'

const getSignature = (certificate: Certificate): string => {
    const year = certificate.camp.end ? (new Date(certificate.camp.end)).getFullYear().toString() : undefined
    const capture = certificate.camp.caption ?? ''

    return capture + (year ? ` ${year}` : '')
}

export default getSignature
