import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { useIonAlert } from '@ionic/react'
import { share } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FE_URL } from '../../lib/apollo/config'
import type { Talent } from '../../lib/apollo/types'
import { useShareTalentMutation } from '../../lib/apollo/types'
import useToast from '../../providers/Toast/hooks'
import Button from '../Button'

interface ShareTalentButtonProps {
    talent: Talent,
    roleModel?: Talent,
    disabled?: boolean
}

const ShareTalentButton: React.FC<ShareTalentButtonProps> = ({ disabled, talent, roleModel }) => {
    const { t } = useTranslation()
    const [show] = useIonAlert()
    const [showToast] = useToast()
    const [shareTalentMutation] = useShareTalentMutation()

    const onClick = useCallback(async () => {
        const response = await shareTalentMutation({
            variables: {
                input: {
                    talentId: talent.id,
                    roleModel: !!roleModel,
                },
            },
        })

        if (!response.data?.shareTalent.publicToken) {
            return
        }

        if (!Capacitor.isNativePlatform()) {
            show({
                message: `${FE_URL}/s/talent/${response.data.shareTalent.publicToken}`,
                buttons: [
                    {
                        text: t('talent.share.linkCopy'),
                        handler: async () => {
                            await navigator.clipboard.writeText(`${FE_URL}/s/talent/${response.data?.shareTalent.publicToken}`)
                            showToast(t('talent.share.linkCopied'), 'success')
                        },
                        role: 'success',
                    },
                ],
            })
            return
        }

        Share.share({
            dialogTitle: t('talent.share.dialogTitle'),
            title: t(`${roleModel ? 'roleModel' : 'talent'}.share.title`),
            text: t(`${roleModel ? 'roleModel' : 'talent'}.share.text`, { name: talent.caption, roleModel: roleModel?.caption }),
            url: `${FE_URL}/s/talent/${response.data.shareTalent.publicToken}`,
        })
    }, [shareTalentMutation, talent.id, talent.caption, roleModel, t, show, showToast])

    return (
        <Button
            icon={share}
            color='primary'
            onClick={onClick}
            disabled={disabled}
        >
            {t(`talent.${roleModel ? 'shareRoleModel' : 'shareTalent'}`)}
        </Button>
    )
}

export default ShareTalentButton
