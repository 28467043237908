import { useTranslation } from 'react-i18next'

import SelectButtons from '../Form/FormSelectButtons/SelectButtons'
import TextField from '../Form/FormTextField/TextField'

interface StatusChangeFormProps {
    predefinedStates: string[]
    status?: string
    onChange: (status?: string | number | null) => void
}

const StatusChangeForm: React.FC<StatusChangeFormProps> = ({ predefinedStates, status, onChange }) => {
    const { t } = useTranslation()

    return (
        <div>
            <TextField
                onChange={onChange}
                value={status ?? ''}
                label={t('status.status')}
            />

            <SelectButtons
                onChange={onChange}
                label={t('status.predefined')}
                value={status}
                options={predefinedStates.map(state => ({
                    value: state,
                    text: state,
                }))}
            />
        </div>
    )
}

export default StatusChangeForm
