import { useContextSelector } from 'use-context-selector'

import type { ToastProviderType } from './index'
import { Toast } from './index'

const useToast = (): ToastProviderType => {
    return useContextSelector(Toast, value => value)
}

export default useToast
