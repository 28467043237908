import { useIonRouter } from '@ionic/react'
import { arrowBack, arrowForward, checkmark } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from '../../../components/Button'
import Content from '../../../components/Content'
import CustomAvatar from '../../../components/CustomAvatar'
import Form from '../../../components/Form'
import FormBirthdateField from '../../../components/Form/FormBirthdayField'
import FormImageInput from '../../../components/Form/FormImageInput'
import FormSelectButtons from '../../../components/Form/FormSelectButtons'
import FormTextField from '../../../components/Form/FormTextField'
import Header from '../../../components/Header'
import ItemList from '../../../components/ItemList'
import Page from '../../../components/Page'
import SalutationSelect from '../../../components/SalutationSelect'
import type {
    UserMetaDataInput } from '../../../lib/apollo/types'
import {
    UserMetaDataDocument,
    useUpdateUserMetaDataMutation, useUserLinkImageMutation,
    useUserMetaDataQuery, useUserUnlinkImageMutation,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'

import './style.scss'

const slideOpts = {
    spaceBetween: 16,
    allowTouchMove: false,
}

const ProfileEdit: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const [formData, setFormData] = useState({})
    const [profileEditComplete, setProfileEditComplete] = useState<boolean>(false)
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [show] = useToast()
    const router = useIonRouter()

    const { data } = useUserMetaDataQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
        skip: !user,
    })

    const [updateUserMetaData] = useUpdateUserMetaDataMutation({
        onError: () => {
            show(t('profile.savingError'), 'danger')
        },
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user?.user.id ?? '',
            },
        }],
    })
    const [linkProfileMutation] = useUserLinkImageMutation()
    const [unlinkImageMutation] = useUserUnlinkImageMutation()

    const onImageDelete = useCallback(async () => {
        if (!user?.user?.asset) return
        await unlinkImageMutation({
            variables: {
                userId: user?.user.id,
                url: `/asset/${user?.user.asset}`,
            },
        })
    }, [user?.user, unlinkImageMutation])

    useEffect(() => {
        if (!profileEditComplete) {
            return
        }

        const patchData = async () => {
            const { image, ...metaData } = formData as UserMetaDataInput & { image: string }
            const userMeta = {
                ...metaData,
                __typename: undefined,
            }
            const behavior = {
                ...data?.userMetaData?.user?.data?.behavior,
                __typename: undefined,
            }
            const variables: { input: UserMetaDataInput, userId: string } = {
                input: {
                    ...userMeta,
                    year: userMeta.year ? +userMeta.year : undefined,
                    behavior,
                    username: user?.user.name ?? '',
                    activitiesLastViewed: data?.userMetaData?.user?.data?.activitiesLastViewed,
                },
                userId: user?.user.id ?? '',
            }

            await updateUserMetaData({ variables })

            // keep this order to prevent loss of image link
            if(image) {
                await linkProfileMutation({
                    variables: {
                        url: `/asset/${image}`,
                        userId: user?.user.id ?? '',
                    },
                })
            }

            router.push('/profile', 'back')
        }

        patchData()
    }, [profileEditComplete, data, formData, user?.user.name, user?.user.id, updateUserMetaData, router, linkProfileMutation])

    return (
        <Page
            trackingTitle='profile edit'
        >
            <Header
                title={t('profile.edit')}
                buttonLeft='back'
            />
            <Content
                fullscreen
            >
                {data && (
                    <div>
                        <Swiper
                            {...slideOpts}
                            modules={[Controller, Pagination]}
                            onSwiper={setControlledSwiper}
                            className='profile-edit__slides'
                            pagination={{
                                type: 'progressbar',
                            }}
                        >
                            <SwiperSlide>
                                <Form
                                    accessoryBarVisible
                                    onSubmit={(v) => {
                                        controlledSwiper?.slideNext()
                                        setFormData((state: any) => ({
                                            ...state,
                                            ...v,
                                        }))
                                    }}
                                >
                                    <CustomAvatar
                                        type='person'
                                        width='50%'
                                        img={user?.user?.image}
                                        onDelete={user?.user?.asset ? onImageDelete : undefined}
                                    />
                                    <ItemList>
                                        <FormImageInput
                                            size={{ width: 400, height: 533.33 }}
                                            name='image'
                                            label={t('user.profileImage')}
                                        />
                                        <FormTextField
                                            name='phone'
                                            type='tel'
                                            label={t('user.phone')}
                                            defaultValue={data.userMetaData?.user?.data?.phone}
                                        />
                                    </ItemList>

                                    <div
                                        className='profile-edit__button-bar'
                                    >
                                        <Button
                                            round
                                            icon={arrowForward}
                                            type='submit'
                                            color='secondary'
                                        />
                                    </div>
                                </Form>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Form
                                    accessoryBarVisible
                                    onSubmit={(v) => {
                                        controlledSwiper?.slideNext()
                                        setFormData((state: any) => ({
                                            ...state,
                                            ...v,
                                        }))
                                    }}
                                >
                                    <ItemList>
                                        <SalutationSelect
                                            defaultValue={data.userMetaData?.user?.data?.salutation ?? undefined}
                                        />
                                        <FormBirthdateField
                                            required
                                            name='birthdate'
                                            label={t('user.birthdate')}
                                            defaultValue={data.userMetaData?.user?.data?.birthdate}
                                        />
                                    </ItemList>
                                    <div
                                        className='profile-edit__button-bar'
                                    >
                                        <Button
                                            round
                                            icon={arrowBack}
                                            color='primary'
                                            fill='outline'
                                            onClick={() => controlledSwiper?.slidePrev()}
                                        />
                                        <Button
                                            round
                                            icon={arrowForward}
                                            type='submit'
                                            color='secondary'
                                        />
                                    </div>
                                </Form>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Form
                                    accessoryBarVisible
                                    onSubmit={(v) => {
                                        controlledSwiper?.slideNext()
                                        setFormData((state: any) => ({
                                            ...state,
                                            ...v,
                                        }))
                                    }}
                                >
                                    {' '}
                                    <ItemList>
                                        <FormTextField
                                            required
                                            name='country'
                                            label={t('user.country')}
                                            defaultValue={data.userMetaData?.user?.data?.country}
                                        />
                                        <FormTextField
                                            required
                                            name='city'
                                            label={t('user.city')}
                                            defaultValue={data.userMetaData?.user?.data?.city}
                                        />
                                        <FormTextField
                                            required
                                            name='zip'
                                            label={t('user.zip')}
                                            defaultValue={data.userMetaData?.user?.data?.zip}
                                        />
                                        <FormTextField
                                            name='street'
                                            label={t('user.street')}
                                            defaultValue={data.userMetaData?.user?.data?.street}
                                        />
                                    </ItemList>
                                    <div
                                        className='profile-edit__button-bar'
                                    >
                                        <Button
                                            round
                                            icon={arrowBack}
                                            color='primary'
                                            fill='outline'
                                            onClick={() => controlledSwiper?.slidePrev()}
                                        />
                                        <Button
                                            round
                                            icon={arrowForward}
                                            type='submit'
                                            color='secondary'
                                        />
                                    </div>
                                </Form>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Form
                                    accessoryBarVisible
                                    onSubmit={(v) => {
                                        controlledSwiper?.slideNext()
                                        setFormData((state: any) => ({
                                            ...state,
                                            ...v,
                                        }))
                                    }}
                                >
                                    <ItemList>
                                        <FormTextField
                                            name='club'
                                            label={t('user.club')}
                                            defaultValue={data.userMetaData?.user?.data?.club}
                                        />
                                        <FormTextField
                                            name='team'
                                            label={t('user.team')}
                                            defaultValue={data.userMetaData?.user?.data?.team}
                                        />
                                        <FormSelectButtons
                                            name='userType'
                                            label={t('user.userType.label')}
                                            multiple
                                            defaultValue={data.userMetaData?.user?.data?.userType ? data.userMetaData.user?.data.userType as string[] : ['other']}
                                            options={[
                                                {
                                                    text: t('user.userType.player', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'player',
                                                },
                                                {
                                                    text: t('user.userType.trainer', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'trainer',
                                                },
                                                {
                                                    text: t('user.userType.supervisor', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'supervisor',
                                                },
                                                {
                                                    text: t('user.userType.scout', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'scout',
                                                },
                                                {
                                                    text: t('user.userType.relatives', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'relatives',
                                                },
                                                {
                                                    text: t('user.userType.other', { context: data.userMetaData?.user?.data?.salutation }),
                                                    value: 'other',
                                                },
                                            ]}
                                        />
                                        <FormTextField
                                            name='year'
                                            label={t('user.year')}
                                            type='number'
                                            defaultValue={data.userMetaData?.user?.data?.year}
                                        />
                                        <FormTextField
                                            hidden
                                            name='status'
                                            label={t('user.status')}
                                            defaultValue={data.userMetaData?.user?.data?.status}
                                        />
                                    </ItemList>

                                    <div
                                        className='profile-edit__button-bar'
                                    >
                                        <Button
                                            round
                                            icon={arrowBack}
                                            color='primary'
                                            fill='outline'
                                            onClick={() => controlledSwiper?.slidePrev()}
                                        />
                                        <Button
                                            round
                                            icon={arrowForward}
                                            type='submit'
                                            color='secondary'
                                        />
                                    </div>
                                </Form>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Form
                                    accessoryBarVisible
                                    onSubmit={(v) => {
                                        setFormData((state: any) => ({
                                            ...state,
                                            ...v,
                                        }))
                                        setProfileEditComplete(true)
                                    }}
                                >
                                    <ItemList>
                                        <FormSelectButtons
                                            name='experience'
                                            label={t('user.experience.label')}
                                            defaultValue={data.userMetaData?.user?.data?.experience ? [data.userMetaData.user.data.experience.toString()] : undefined}
                                            options={[
                                                {
                                                    text: t('user.experience.professional'),
                                                    value: 'professional',
                                                },
                                                {
                                                    text: t('user.experience.semi-professional'),
                                                    value: 'semiProfessional',
                                                },
                                                {
                                                    text: t('user.experience.amateur'),
                                                    value: 'amateur',
                                                },
                                            ]}
                                        />
                                        <FormSelectButtons
                                            name='ageCategory'
                                            label={t('user.ageCategory.label')}
                                            defaultValue={data.userMetaData?.user?.data?.ageCategory ? [data.userMetaData.user.data.ageCategory.toString()] : undefined}
                                            options={[
                                                {
                                                    text: t('user.ageCategory.kids'),
                                                    value: 'kids',
                                                },
                                                {
                                                    text: t('user.ageCategory.juniors'),
                                                    value: 'juniors',
                                                },
                                                {
                                                    text: t('user.ageCategory.men'),
                                                    value: 'men',
                                                },
                                                {
                                                    text: t('user.ageCategory.women'),
                                                    value: 'women',
                                                },
                                            ]}
                                        />
                                        <FormTextField
                                            name='aboutMe'
                                            label={t('user.aboutMe')}
                                            type='text'
                                            maxLength={100}
                                            multiline
                                            defaultValue={data.userMetaData?.user?.data?.aboutMe}
                                        />
                                    </ItemList>
                                    <div
                                        className='profile-edit__button-bar'
                                    >
                                        <Button
                                            round
                                            icon={arrowBack}
                                            color='primary'
                                            fill='outline'
                                            onClick={() => controlledSwiper?.slidePrev()}
                                        />
                                        <Button
                                            round
                                            icon={checkmark}
                                            type='submit'
                                            color='secondary'
                                        />
                                    </div>
                                </Form>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                )}
            </Content>
        </Page>
    )
}

export default ProfileEdit
