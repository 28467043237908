interface TouchAreaProps {
    onClick: () => void
}

const TouchArea: React.FC<TouchAreaProps> = ({ onClick }) => (
    <circle
        onClick={onClick}
        className='touchClick'
        cx='250'
        cy='250'
        r='100'
    />
)

export default TouchArea
