import { useIonRouter } from '@ionic/react'
import { trash } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import { NetworksDocument, useDeleteNetworkMutation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkDeleteContextMenuItemProps {
    network: Network
}

const NetworkDeleteContextMenuItem: React.FC<NetworkDeleteContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const router = useIonRouter()
    const [present] = useToast()
    const [deleteNetworkMutation] = useDeleteNetworkMutation()
    const user = useUser()

    const onDeleteNetworkClick = async () => {
        const response = await deleteNetworkMutation({
            variables: {
                id: network.id,
            },
            refetchQueries: [{
                query: NetworksDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
            {
                query: NetworksDocument,
                variables: {
                    userId: user?.user.id ?? '',
                    start: 1,
                    limit: 15,
                },
            }],
        })
        if(!response.errors) {
            present(t('networks.deleteComplete', { name: network.caption }), 'success')
            router.goBack()
        }
    }

    return (
        <ContextMenuItem
            alertText={t('networks.deleteAlert', { name: network.caption })}
            onClick={onDeleteNetworkClick}
            label={t('networks.delete')}
            icon={trash}
        />
    )
}

export default NetworkDeleteContextMenuItem
