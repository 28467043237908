import { IonCardSubtitle, IonGrid, IonRow, IonText } from '@ionic/react'
import {PropsWithChildren, ReactNode} from 'react'
import './style.scss'

interface TalentAttributeProps {
    topLabel: string
    bottomLabel?: string | ReactNode
}

const TalentAttribute: React.FC<TalentAttributeProps & PropsWithChildren> = ({
    bottomLabel,
    topLabel,
    children,
}) => (
    <IonGrid
        className='talent-attribute'
    >
        <IonRow
            className='ion-justify-content-center'
        >
            <IonText
                color='medium'
            >
                <IonCardSubtitle>
                    { topLabel }
                </IonCardSubtitle>
            </IonText>
        </IonRow>
        <IonRow
            className='ion-justify-content-center talent-attribute__main'
        >
            <IonText
                color='light'
            >
                { children }
            </IonText>
        </IonRow>
        <IonRow
            className='ion-justify-content-center'
        >
            <IonText
                color='medium'
            >
                { typeof bottomLabel === 'string'
                    ? (
                        <IonCardSubtitle>
                            { bottomLabel }
                        </IonCardSubtitle>
                    ) : bottomLabel}
            </IonText>
        </IonRow>
    </IonGrid>
)

export default TalentAttribute
