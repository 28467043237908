import { IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'

import './style.scss'

interface TopSkillsSkeletonProps {
    amount: number
    compareSkeleton?: boolean
}

const TopSkillsSkeleton: React.FC<TopSkillsSkeletonProps> = ({ amount, compareSkeleton }) => {
    return (
        <div
            className={clsx('talent-top-skills', { 'talent-top-skills--compare': compareSkeleton })}
        >
            {compareSkeleton && (
                <div
                    className='talent-top-skills__row'
                >
                    <h4
                        className='talent-top-skills__title'
                    >
                        <IonSkeletonText
                            style={{ width: 40 }}
                        />
                    </h4>
                    {(new Array(amount)).fill(undefined).map((_, i) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            className='talent-top-skills__skill-wrapper'
                        >
                            <img
                                className='talent-top-skills__img'
                                alt='skill-indicator'
                                src='/assets/icon/logo.svg'
                            />

                            <h4
                                className='talent-top-skills__skill'
                            >
                                <IonSkeletonText
                                    style={{ width: 64 }}
                                />
                            </h4>
                        </div>
                    ))}
                </div>
            )}

            <div
                className='talent-top-skills__row'
            >
                { compareSkeleton && (
                    <h4
                        className='talent-top-skills__title'
                    >
                        <IonSkeletonText
                            style={{ width: 40 }}
                        />
                    </h4>
                ) }
                {(new Array(amount)).fill(undefined).map((_, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className='talent-top-skills__skill-wrapper'
                    >
                        <img
                            className='talent-top-skills__img'
                            alt='skill-indicator'
                            src='/assets/icon/logo.svg'
                        />

                        <h4
                            className='talent-top-skills__skill'
                        >
                            <IonSkeletonText
                                style={{ width: 64 }}
                            />
                        </h4>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopSkillsSkeleton
