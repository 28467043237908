import { IonLabel, IonSegment, IonSegmentButton, IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'
import { useLayoutEffect, useRef } from 'react'

import './style.scss'

type SegmentTab = {
    disabled?: boolean,
    tab: string | JSX.Element
}

const Segment: React.FC<{ tabs?: SegmentTab[] } & React.ComponentProps<typeof IonSegment>> = ({ tabs, ...props }) => {
    const segmentRef = useRef<HTMLIonSegmentElement>(null)

    useLayoutEffect(() => {
        // scroll into view
        segmentRef?.current?.querySelector(`ion-segment-button[value="${props.value}"]`)?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
        })
    }, [props.value])

    return (
        <IonSegment
            {...props}
            scrollable
            mode='md'
            ref={segmentRef}
            className={clsx('segment', props.className)}
        >
            {tabs?.map(({ tab, disabled }, i) => (
                <IonSegmentButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    value={`${i}`}
                    layout='icon-end'
                    disabled={disabled ?? false}
                >
                    { typeof tab === 'string'
                        ? <IonLabel>{tab}</IonLabel>
                        : tab}
                </IonSegmentButton>
            )) ?? (
                <>
                    <IonSegmentButton>
                        <IonLabel>
                            <IonSkeletonText
                                style={{ width: '70px' }}
                            />
                        </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton>
                        <IonLabel>
                            <IonSkeletonText
                                style={{ width: '70px' }}
                            />
                        </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton>
                        <IonLabel>
                            <IonSkeletonText
                                style={{ width: '70px' }}
                            />
                        </IonLabel>
                    </IonSegmentButton>
                </>
            )}
        </IonSegment>
    )
}

export default Segment
