import type { Checkbox } from '@ionic/core/dist/types/components/checkbox/checkbox'
import {
    IonCard,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    useIonAlert,
} from '@ionic/react'
import clsx from 'clsx'
import { informationCircleOutline } from 'ionicons/icons'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'

import type { PerformanceVote, Talent } from '../../../lib/apollo/types'
import { TalentPosition, useUpdateUserMetaDataMutation, useUserMetaDataQuery } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import Modal from '../../Modal'
import { useTalentCardContext } from '../../NewTalentCard/_Core/Context'
import Segment from '../../Segment'
import type { LayerI } from '../../Talent/TalentID/Layer'
import useLayers from '../../Talent/TalentID/useLayers'

import './style.scss'

interface PerformanceIconPropsI {
    performance?: PerformanceVote,
    className?: string,
    talent?: Talent
}

type PerformanceIconProps = PerformanceIconPropsI

const Icon = () => (
    <svg
        viewBox='0 0 24 24'
        shapeRendering='geometricPrecision'
    >
        <path
            className='first'
            stroke='#fff'
            strokeWidth='.2'
            d='M16.59,9.42L12,4.83L7.41,9.42L6,8L12,2L18,8L16.59,9.42'
        />
        <path
            className='second'
            stroke='#fff'
            strokeWidth='.2'
            d='M16.59,15.42L12,10.83L7.41,15.42L6,14L12,8L18,14L16.59,15.42'
        />
        <path
            className='third'
            stroke='#fff'
            strokeWidth='.2'
            d='M16.59,21.42L12,16.83L7.41,21.42L6,20L12,14L18,20L16.59,21.42Z'
        />
        <rect
            x='5'
            y='8'
            width='14'
            height='2'
            stroke='#fff'
            strokeWidth='.2'
            className='steady'
        />
        <rect
            x='5'
            y='13'
            width='14'
            height='2'
            stroke='#fff'
            strokeWidth='.2'
            className='steady'
        />
    </svg>
)

const getClassByValue = (value: number, isPrivate = false) => {
    if(value < -4) return 'performance-icon--negative performance-icon--three'
    if(value < -3) return 'performance-icon--negative performance-icon--two'
    if(value < -1) return 'performance-icon--negative performance-icon--one'
    if(value < 1) return `performance-icon--none ${!isPrivate ? 'performance-icon--hide' : ''}`
    if(value < 3) return 'performance-icon--one'
    if(value < 4) return 'performance-icon--two'
    return 'performance-icon--three'
}

const getClassByValueSingleRow = (value: number) => {
    if(value < -2) return 'performance-icon--negative performance-icon--three'
    if(value < -1) return 'performance-icon--negative performance-icon--two'
    if(value < 0) return 'performance-icon--negative performance-icon--one'
    if(value === 0) return 'performance-icon--none performance-icon--hide'
    if(value < 2) return 'performance-icon--one'
    if(value < 3) return 'performance-icon--two'
    return 'performance-icon--three'
}

export const tabs = ['Public', 'Private']

const slideOptions = {
    spaceBetween: 16,
}

const PerformanceIcon: React.FC<PerformanceIconProps> = ({ performance, className, talent }) => {
    const { t, i18n } = useTranslation()
    const getTranslation = useCallback((key: string) => {
        if (!talent) return ''
        const under16 = (getAge(talent.metas.date) ?? 99) < 16
        if(under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [i18n, t, talent])
    const [present] = useToast()
    const [alert] = useIonAlert()
    const { networkId } = useTalentCardContext()

    const isNetwork = useMemo(() => {
        return !!networkId && networkId !== 'undefined'
    }, [networkId])

    const [preferred, setPreferred] = useState<'public' | 'private'>('public')
    const [updateUserMetaData] = useUpdateUserMetaDataMutation()

    const [currentTab, setCurrentTab] = useState<string>('0')
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    const performanceClass: string = useMemo(() => {
        let sum = performance?.public ?? 0
        if (preferred === 'private' && performance?.privateCount) {
            sum = performance?.private?.reduce((a, b) => a + b, 0) ?? 0
            sum = Math.round(sum / performance.privateCount)
            return getClassByValue(sum, true)
        }

        return getClassByValue(sum)

    }, [performance, preferred])

    const [open, setOpen] = useState<boolean>(false)
    const layers = useLayers(talent?.metas.fieldPosition ?? TalentPosition.Midfield, talent?.dataViews ?? [], undefined, (getAge(talent?.metas.date) ?? 99) < 16)

    const layer: LayerI | undefined = useMemo(() => {
        return layers.find(l => l.values.length === 8)
    }, [layers])

    const onIconClick = useCallback(() => {
        if (!layer) {
            present(t('talent.noBehaviorTalentID'), 'warning')
        } else {
            setOpen(true)
        }
    }, [layer, present, t])

    const user = useUser()
    const { data: userMetaData } = useUserMetaDataQuery({
        variables: {
            userId: user?.user?.id ?? '',
        },
        skip: !user?.user?.id,
    })

    const onChange = useCallback((mode: 'private'|'public') => async ($event: any) => {
        const cb: Checkbox = $event.target
        cb.checked = true

        setPreferred(mode)
        if (!userMetaData?.userMetaData?.user?.data?.behavior) {
            return
        }

        const userMeta = {
            ...userMetaData.userMetaData.user.data,
            __typename: undefined,
        }

        const behavior = {
            ...userMetaData.userMetaData.user.data.behavior,
            __typename: undefined,
        }

        await updateUserMetaData({
            variables: {
                input: {
                    ...userMeta,
                    behavior: {
                        ...behavior,
                        preferredPerformanceView: mode,
                    },
                },
            },
        })
    }, [setPreferred, userMetaData, updateUserMetaData])

    useEffect(() => {
        if (userMetaData?.userMetaData?.user?.data?.behavior?.preferredPerformanceView) {
            setPreferred(userMetaData.userMetaData.user.data.behavior.preferredPerformanceView as 'private'|'public')
        }
    }, [userMetaData])

    const onSegmentChange = useCallback((e: CustomEvent) => {
        if (!controlledSwiper) {
            return
        }
        setCurrentTab(e.detail.value)
        controlledSwiper.slideTo(e.detail.value)
    }, [controlledSwiper, setCurrentTab])

    const onSlideWillChange = useCallback((e: SwiperClass) => {
        setCurrentTab(e.activeIndex.toString())
    }, [])

    return (
        <>
            <Modal
                onDidDismiss={() => { setOpen(() => false) }}
                onClose={() => { setOpen(() => false) }}
                modalTitle={t('talent.performanceVoteModalTitle', { name: talent?.caption })}
                isOpen={open}
                size='alert'
            >
                <div
                    className='talent-performance__modal-content'
                >
                    <IonText
                        className='ion-text-justify font-s-regular'
                        color='medium'
                    >
                        <span>
                            {t(`talent.performanceModalIntro${isNetwork ? 'Network' : 'Public'}`)}
                        </span>
                    </IonText>
                    { isNetwork && (
                        <Segment
                            value={currentTab}
                            onIonChange={onSegmentChange}
                            tabs={tabs.map((v: string) => ({ tab: t(`talent.performanceDetail${v}`) }))}
                        />
                    )}
                    <Swiper
                        {...slideOptions}
                        modules={[Controller]}
                        onSwiper={setControlledSwiper}
                        onSlideChange={onSlideWillChange}
                    >
                        <SwiperSlide>
                            <div
                                className='talent-performance__card-wrapper'
                            >
                                <IonCard
                                    color='dark'
                                    className='talent-performance__card'
                                >
                                    <IonGrid>
                                        {layer?.values.map((v, i) => (
                                            <Fragment
                                            // eslint-disable-next-line react/no-array-index-key
                                                key={i}
                                            >
                                                <IonRow>
                                                    <IonCol
                                                        className='ion-align-items-end'
                                                    >
                                                        <IonLabel
                                                            onClick={() => alert(getTranslation( `${layer.translationKeys?.[i]}_tip`))}
                                                        >
                                                            {layer.translations?.[i]}
                                                            <IonIcon
                                                                icon={informationCircleOutline}
                                                            />
                                                            {!!getClassByValueSingleRow(performance?.publicDetail?.[i] ?? 0) && (
                                                                <div
                                                                    className={clsx('.performance-icon talent-performance__card__icon', getClassByValueSingleRow(performance?.publicDetail?.[i] ?? 0))}
                                                                >
                                                                    <Icon />
                                                                </div>
                                                            )}
                                                        </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <div />
                                            </Fragment>
                                        ))}
                                    </IonGrid>
                                </IonCard>
                            </div>
                            { isNetwork && (
                                <div
                                    className='talent-performance__card-wrapper'
                                >
                                    <IonItem
                                        lines='none'
                                    >
                                        <IonCheckbox
                                            onIonChange={() => {}}
                                            onClick={onChange('public')}
                                            color='secondary'
                                            checked={preferred === 'public'}
                                            value={preferred === 'public'}
                                            slot='start'
                                        />
                                        <IonLabel>
                                            {t('talent.performancePreferPublicLabel')}
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            )}
                        </SwiperSlide>
                        { isNetwork && (
                            <SwiperSlide>
                                <div
                                    className='talent-performance__card-wrapper'
                                >
                                    <IonCard
                                        color='dark'
                                        className={clsx('talent-performance__card talent-performance__card--private', { 'talent-performance__card--disabled': !performance?.private })}
                                    >
                                        <IonGrid>
                                            {layer?.values.map((v, i) => (
                                                <Fragment
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={i}
                                                >
                                                    <IonRow>
                                                        <IonCol
                                                            className='ion-align-items-end'
                                                        >
                                                            <IonLabel
                                                                onClick={() => alert(getTranslation( `${layer.translationKeys?.[i]}_tip`))}
                                                            >
                                                                {layer.translations?.[i]}
                                                                <IonIcon
                                                                    icon={informationCircleOutline}
                                                                />
                                                                {!!getClassByValueSingleRow(performance?.private?.[i] ?? 0) && (
                                                                    <div
                                                                        className={clsx('.performance-icon talent-performance__card__icon', getClassByValueSingleRow(performance?.private?.[i] ?? 0))}
                                                                    >
                                                                        <Icon />
                                                                    </div>
                                                                )}
                                                            </IonLabel>
                                                        </IonCol>
                                                    </IonRow>
                                                    <div />
                                                </Fragment>
                                            ))}
                                        </IonGrid>
                                    </IonCard>
                                </div>
                                <div
                                    className='talent-performance__card-wrapper'
                                >
                                    <IonItem
                                        lines='none'
                                    >
                                        <IonCheckbox
                                            onClick={onChange('private')}
                                            color='secondary'
                                            checked={preferred === 'private'}
                                            value={preferred === 'private'}
                                            slot='start'
                                        />
                                        <IonLabel>
                                            {t('talent.performancePreferPrivateLabel')}
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </Modal>
            <div
                className={`performance-icon ${performanceClass} ${className ?? ''}`}
                role='button'
                tabIndex={-1}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setOpen(true)
                    }
                }}
                onClick={onIconClick}
            >
                <Icon />
            </div>
        </>
    )
}

export default PerformanceIcon
