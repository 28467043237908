import { useIonRouter } from '@ionic/react'
import { saveOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormTextField from '../../components/Form/FormTextField'
import FormToggle from '../../components/Form/FormToggle'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import type {
    Playlist,
    PlaylistInput,
} from '../../lib/apollo/types'
import {
    PlaylistDocument,
    PlaylistsDocument,
    useAddPlaylistMutation, useAddTalentsToPlaylistMutation,
    useEditPlaylistMutation,
    usePlaylistQuery,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

interface PlaylistParams {
    id: string
    talentIds: string // JSON.stringify(Array) -> "[1,2,3,4]"
}

const PlaylistAddOrEdit: React.FC = () => {
    const { t } = useTranslation()
    const me = useUser()
    const { push, goBack } = useIonRouter()

    const { params: { id, talentIds } } = useRouteMatch<PlaylistParams>()

    const { data: { playlist } = {} } = usePlaylistQuery({
        skip: !id,
        variables: {
            userId: me?.user.id ?? '',
            id: id === 'new' ? '' : id,
        },
    })

    const [addTalentsToPlaylist] = useAddTalentsToPlaylistMutation()

    const [addPlaylist] = useAddPlaylistMutation({
        refetchQueries: !talentIds
            ? [{
                query: PlaylistsDocument,
                variables: {
                    userId: me?.user.id ?? '',
                },
            }]
            : undefined,
        onCompleted: async ({ addPlaylist: aP }: { addPlaylist: Playlist }) => {
            if (talentIds) {
                await addTalentsToPlaylist({
                    variables: {
                        id: aP.id,
                        talents: { _links: { objectByPlaylist: { url: JSON.parse(talentIds).map((talentId: number) => `/object/${talentId}`) } } },
                    },
                    refetchQueries: [{
                        query: PlaylistsDocument,
                        variables: {
                            userId: me?.user.id ?? '',
                        },
                    }, {
                        query: PlaylistDocument,
                        variables: {
                            userId: me?.user.id ?? '',
                            id: aP.id,
                        },
                    }],
                })
            }

            if (id !== 'new') {
                push(`/playlists/detail/${id}`, 'back')
            } else if (talentIds) {
                goBack()
            } else {
                push('/playlists', 'back')
            }
        },
    })

    const [editPlaylist] = useEditPlaylistMutation({
        refetchQueries: !talentIds
            ? [{
                query: PlaylistsDocument,
                variables: {
                    userId: me?.user.id ?? '',
                },
            }]
            : undefined,
        onCompleted: async () => {
            if (talentIds) {
                await addTalentsToPlaylist({
                    variables: {
                        id,
                        talents: { _links: { objectByPlaylist: { url: JSON.parse(talentIds).map((talentId: number) => `/object/${talentId}`) } } },
                    },
                    refetchQueries: [{
                        query: PlaylistsDocument,
                        variables: {
                            userId: me?.user.id ?? '',
                        },
                    }, {
                        query: PlaylistDocument,
                        variables: {
                            userId: me?.user.id ?? '',
                            id,
                        },
                    }],
                })
            }

            push(`/playlists/detail/${id}`, 'back')
        },
    })

    const onSubmit = ({ caption, public: p }: PlaylistInput) => {
        if (id !== 'new') {
            editPlaylist({
                variables: {
                    id,
                    input: {
                        caption,
                        public: p,
                    },
                },
            })
        } else {
            addPlaylist({
                variables: {
                    input: {
                        caption,
                        public: p,
                    },
                },
            })
        }
    }

    return (
        <Page
            trackingTitle='PlaylistsAddOrEdit'
        >
            <Header
                title={t('title.playlist')}
            />
            <Content
                fullscreen
            >
                { (id === 'new' || playlist) && (
                    <Form
                        onSubmit={onSubmit}
                    >
                        <ItemList>
                            <FormTextField
                                defaultValue={playlist?.caption}
                                name='caption'
                                label={t('playlist.name')}
                            />
                            <FormToggle
                                defaultValue={playlist?.public ?? false}
                                name='public'
                                label={t('playlist.isPublic')}
                            />
                        </ItemList>
                        <Button
                            className='ion-margin-top'
                            color='secondary'
                            type='submit'
                            icon={saveOutline}
                        >
                            {t('buttons.save')}
                        </Button>
                    </Form>
                )}
            </Content>
        </Page>
    )
}

export default PlaylistAddOrEdit
