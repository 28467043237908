import type { IonButton } from '@ionic/react'
import { useIonRouter } from '@ionic/react'
import { peopleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { User , Maybe } from '../../lib/apollo/types'
import { RelationshipsDocument, useRelationshipsQuery, useUserFollowMutation } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import Button from './index'

interface FollowButtonProps {
    className?: string
    user?: Maybe<User | undefined>
    withIcon?: boolean
    showProfileOnFollowed?: boolean // replaces unfollow function with "go to profile"
}

const getTranslation = (showProfileOnFollowd: boolean, followed: boolean) => {
    if (showProfileOnFollowd && followed) return 'user.goToProfile'
    if (followed) return 'user.unfollow'
    return 'user.follow'
}

const FollowButton: React.FC<FollowButtonProps & React.ComponentProps<typeof IonButton>> = ({ className, withIcon = false, user, showProfileOnFollowed = false, ...props }) => {
    const { t } = useTranslation()
    const [present] = useToast()
    const router = useIonRouter()
    const me = useUser()

    const { data } = useRelationshipsQuery()
    const followed = data?.followed?.collection?.some(f => f.id === user?.id) ?? false

    const [toggleFollow] = useUserFollowMutation({
        variables: {
            method: followed ? 'UNLINK' : 'LINK',
            userId: me?.user.id ?? '',
            url: `/user/${user?.id}`,
        },
        onCompleted: () => {
            present(t(followed ? 'user.unfollowToast' : 'user.followToast', { name: `${user?.firstName} ${user?.lastName}` }), 'success')
        },
        refetchQueries: [{
            query: RelationshipsDocument,
            variables: {
                userId: me?.user.id ?? '',
            },
        }],
    })

    return (
        <Button
            className={className}
            icon={withIcon ? peopleOutline : undefined}
            color={followed ? 'primary' : 'secondary'}
            {...props}
            disabled={!user || !data?.followed}
            onClick={() => {
                if (showProfileOnFollowed && followed) {
                    router.push(`/activities/user/${user?.id}`)
                } else {
                    toggleFollow()
                }
            }}
        >
            {t(getTranslation(showProfileOnFollowed, followed))}
        </Button>
    )
}

export default FollowButton
