interface GlobalDefsProps {
    id: string
}

const GlobalDefs: React.FC<GlobalDefsProps> = ({ id }) => (
    <>
        <defs>
            <path
                id={`${id}-cp`}
                d='M0,261A261,261 0 1,1 0,-261A261,261 0 1,1 0,261A261,261 0 1,1 0,-261A261,261 0 1,1 0,261Z'
                transform='translate(250, 250) rotate(90)'
            />
            <path
                id={`${id}-cp-lower`}
                d='M0,274A274,274 0 1,0 0,-274A274,274 0 1,0 0,274A274,274 0 1,0 0,-274A274,274 0 1,0 0,274Z'
                transform='translate(250, 250) rotate(270)'
            />
            <path
                id={`${id}-cp2`}
                d='M0,259A259,259 0 1,1 0,-259A259,259 0 1,1 0,259A259,259 0 1,1 0,-259A259,259 0 1,1 0,259Z'
                transform='translate(250, 250) rotate(90)'
            />
            <path
                id={`${id}-cp2-lower`}
                d='M0,276A276,276 0 1,0 0,-276A276,276 0 1,0 0,276A276,276 0 1,0 0,-276A276,276 0 1,0 0,276Z'
                transform='translate(250, 250) rotate(270)'
            />
        </defs>
        <circle
            className='stageBg'
            cx='250'
            cy='250'
            r='300'
        />
        <circle
            className='advBg'
            cx='250'
            cy='250'
            r='285'
        />
        <circle
            className='innerStageBg'
            cx='250'
            cy='250'
            r='250'
        />
    </>
)

export default GlobalDefs
