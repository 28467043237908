import { useMemo } from 'react'

import ContextMenu from '../../components/ContextMenu'
import NetworkAddTalentsContextMenuItem from '../../components/Network/NetworkAddTalentsContextMenuItem'
import NetworkCreateTalentContextMenuItem from '../../components/Network/NetworkCreateTalentContextMenuItem'
import type { Network } from '../../lib/apollo/types'
import { NetworkRole } from '../../lib/apollo/types'

interface RoleModelsContextMenuProps {
    network?: Network
}

const RoleModelsContextMenu: React.FC<RoleModelsContextMenuProps> = ({
    network,
}) => {

    const showMenu = useMemo(() => {
        return !!network?.networkRoles?.some(role => role === NetworkRole.Owner || role === NetworkRole.Mod)
    }, [network])

    if (!showMenu) {
        return null
    }

    return (
        <ContextMenu>
            {network && showMenu && (
                <>
                    <NetworkAddTalentsContextMenuItem
                        network={network}
                    />
                    <NetworkCreateTalentContextMenuItem
                        network={network}
                    />
                </>
            )}
        </ContextMenu>
    )
}

export default RoleModelsContextMenu
