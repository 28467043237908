import { IonItem, IonLabel, IonText, IonToggle } from '@ionic/react'
import clsx from 'clsx'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CollapsibleContent from '../../Collapsible/CollapsibleContent'

import './style.scss'

interface FormToggleProps {
    name: string
    label: string
    color?: string
    required?: boolean
    defaultValue?: boolean
    className?: string
}

const FormToggle: React.FC<FormToggleProps> = ({
    required,
    name,
    label,
    defaultValue,
    color = 'secondary',
    className,
}) => {
    const { control, formState, setValue } = useFormContext()
    const error = formState.errors[name]

    useEffect(() => {
        setValue(name, !!defaultValue)
    }, [defaultValue, name, setValue])

    const { t } = useTranslation()
    const mergedRules = required ? { required: { value: true, message: t('errors.checkRequired', { label }) } } : {}

    return (
        <>
            <div
                className='form-toggle'
            >
                <IonItem
                    lines='none'
                >
                    <IonLabel
                        className={clsx({ 'error': !!error })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>
                    <Controller
                        control={control}
                        name={name}
                        rules={mergedRules}
                        render={({ field }) => {
                            return (
                                <IonToggle
                                    className={className}
                                    color={color}
                                    checked={!!field.value}
                                    onIonChange={(e) => field.onChange(e.detail.checked)}
                                    onBlur={field.onBlur}
                                />
                            )
                        }}
                    />
                </IonItem>
            </div>
            <CollapsibleContent
                open={!!error}
            >
                <IonText
                    className='form-toggle__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message as string}
                </IonText>
            </CollapsibleContent>
        </>
    )
}

export default FormToggle
