import { IonCard, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react'

import CustomAvatarSkeleton from '../../../CustomAvatar/CustomAvatarSkeleton'
import ShareScreenButtons from '../../../ShareScreenButtons'

import './style.scss'

const UserCardMediumSkeleton: React.FC = () => (
    <IonCard
        className='user-card-medium'
        color='primary'
    >
        <IonGrid>
            <IonRow
                className='user-card-medium__head ion-nowrap'
            >
                <CustomAvatarSkeleton
                    width={136}
                />

                <IonCol
                    className='user-card-medium__head-col'
                >
                    <IonGrid
                        className='user-card-medium__head__content'
                    >
                        <IonRow>
                            <IonCol>
                                <IonCardTitle>
                                    <IonSkeletonText
                                        style={{ width: 80 }}
                                    />
                                </IonCardTitle>
                                <IonRow>
                                    <IonSkeletonText
                                        style={{ width: 96 }}
                                    />
                                </IonRow>
                            </IonCol>
                        </IonRow>

                        <hr />

                        <IonRow
                            className='ion-align-items-center ion-justify-content-start'
                        >
                            <IonCol
                                size='auto'
                            >
                                <CustomAvatarSkeleton
                                    width={40}
                                    type='network'
                                    className='user-card-medium__club-img'
                                />

                            </IonCol>
                            <IonCol>
                                <IonCardTitle
                                    className='text'
                                >
                                    <IonSkeletonText
                                        style={{ width: 96 }}
                                    />
                                </IonCardTitle>

                                <IonCardSubtitle>
                                    <IonSkeletonText
                                        style={{ width: 96 }}
                                    />
                                </IonCardSubtitle>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol>
                    <ShareScreenButtons />
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonCard>
)

export default UserCardMediumSkeleton
