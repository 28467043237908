import { IonCard, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonRow } from '@ionic/react'

import type { User } from '../../../lib/apollo/types'
import stopPropagation from '../../../lib/utils/stopPropagation'
import CustomAvatar from '../../CustomAvatar'
import RelationShipIcon from '../../Icon/RelationshipIcon'
import UserContextMenu from '../UserContextMenu'

import UserListItemSkeleton from './UserListItemSkeleton'
import useNetworkRoleTranslation from './useNetworkRoleTranslation'

import './style.scss'
import {PropsWithChildren, useMemo} from 'react'

interface UserListItemProps {
    user?: User,
    onClick?: any
    selectable?: boolean
    checked?: boolean
    showNetworkRole?: boolean
    disableLink?: boolean
    networkId?: string
}

const UserListItem: React.FC<UserListItemProps & PropsWithChildren> = ({ disableLink = false, checked, selectable, user, children, onClick, showNetworkRole, networkId }) => {
    const networkRoleTranslation = useNetworkRoleTranslation(showNetworkRole ? user : undefined)

    const routerLink = useMemo(() => {
        if(disableLink || selectable) return undefined

            if(networkId) {
                return `/home/network/${networkId}/user/${user?.id}`
            }

            return `/home/user/${user?.id}`
    }, [disableLink, networkId, selectable, user?.id])

    if (!user) return <UserListItemSkeleton />

    return (
        <IonCard
            className='user-list-item'
            routerLink={routerLink}
            onClick={onClick}
        >
            <CustomAvatar
                img={user.image}
                alt={user.id}
            />
            <IonGrid
                className='user-list-item__grid'
            >
                <IonRow
                    className='ion-align-items-center ion-justify-content-between ion-nowrap'
                >
                    <IonCol>
                        <IonCardTitle
                            className='user-list-item__title'
                        >
                            { user.firstName }
                            {' '}
                            { user.lastName }
                            <RelationShipIcon
                                user={user}
                                className='user-list-item__relationship-icon'
                            />
                        </IonCardTitle>
                        { networkRoleTranslation && (
                            <IonRow>
                                <IonCardSubtitle>
                                    { networkRoleTranslation }
                                </IonCardSubtitle>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCardSubtitle>
                                { user.email }
                            </IonCardSubtitle>
                        </IonRow>
                    </IonCol>

                    <IonCol
                        className='user-list-item__menu'
                        onClick={stopPropagation}
                    >
                        {!selectable ? (
                            <UserContextMenu
                                user={user}
                            >
                                {children}
                            </UserContextMenu>
                        ) : (
                            <IonCheckbox
                                checked={checked}
                                onClick={onClick}
                            />
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default UserListItem
