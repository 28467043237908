import { menuController } from '@ionic/core'
import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import { useCallback } from 'react'

import './style.scss'

interface MenuItemProps {
    label: string
    icon: string
    routerLink?: string
    onClick?: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({ label, routerLink, onClick, icon }) => {

    const onItemClick = useCallback(() => {
        menuController.get('main-menu').then(menu => {
            menu?.close()
        })
        if (onClick) {
            onClick()
        }
    }, [onClick])

    return (
        <IonItem
            lines='none'
            onClick={onItemClick}
            routerLink={routerLink}
            routerOptions={{ unmount: true }}
            className='menu-item'
            button
        >
            <IonIcon
                slot='start'
                icon={icon}
            />
            <IonLabel>{label}</IonLabel>
        </IonItem>
    )
}

export default MenuItem
