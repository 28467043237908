import {PropsWithChildren, useCallback, useRef} from 'react'
import type { PointerEvent } from 'react'

interface PinchAndPanProps {
    zoom: (factor: number) => any
    className?: string
}

const PinchAndPan: React.FC<PinchAndPanProps & PropsWithChildren> = ({ className, children, zoom }) => {
    const ref = useRef<HTMLDivElement>(null)
    // Global vars to cache event state
    const evCache = useRef<PointerEvent[]>([])
    const prevDiff = useRef(-1)
    const pointerdownHandler = useCallback((ev: PointerEvent<HTMLDivElement>) => {
        // The pointerdown event signals the start of a touch interaction.
        // This event is cached to support 2-finger gestures
        evCache.current.push(ev)
    }, [])

    const pointerupHandler = useCallback((ev: PointerEvent<HTMLDivElement>) => {

        // Remove this pointer from the cache and reset the target's
        // background and border
        for (let i = 0; i < evCache.current.length; i += 1) {
            if (evCache.current[i].pointerId === ev.pointerId) {
                evCache.current.splice(i, 1)
                break
            }
        }

        // If the number of pointers down is less than two then reset diff tracker
        if (evCache.current.length < 2) {
            prevDiff.current = -1
        }
    }, [])

    const pointermoveHandler = useCallback((ev: PointerEvent<HTMLDivElement>) => {

        // This function implements a 2-pointer horizontal pinch/zoom gesture.
        //
        // If the distance between the two pointers has increased (zoom in),
        // the target element's background is changed to "pink" and if the
        // distance is decreasing (zoom out), the color is changed to "lightblue".
        //
        // This function sets the target element's border to "dashed" to visually
        // indicate the pointer's target received a move event.

        // Find this event in the cache and update its record with this event
        for (let i = 0; i < evCache.current.length; i += 1) {
            if (ev.pointerId === evCache.current[i].pointerId) {
                evCache.current[i] = ev
                break
            }
        }
        // If two pointers are down, check for pinch gestures
        if (evCache.current.length === 2) {
            // Calculate the distance between the two pointers
            const curDiff = Math.abs(evCache.current[0].clientX - evCache.current[1].clientX)

            if (prevDiff.current > 0) {
                zoom(curDiff - prevDiff.current)
            }
            // Cache the distance for the next move event
            prevDiff.current = curDiff
        }
    }, [zoom])

    return (
        <div
            className={className}
            ref={ref}
            onPointerDown={pointerdownHandler}
            onPointerMove={pointermoveHandler}
            onPointerUp={pointerupHandler}
            onPointerCancel={pointerupHandler}
            onPointerOut={pointerupHandler}
            onPointerLeave={pointerupHandler}
        >
            { children }
        </div>
    )
}

export default PinchAndPan
