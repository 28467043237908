import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { star, trash } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Content from '../../components/Content'
import ContextMenu from '../../components/ContextMenu'
import ContextMenuItem from '../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import Header from '../../components/Header'
import MySpaceHelp from '../../components/HelpSlider/Help/MySpace'
import InfiniteScrollWithHook from '../../components/InfiniteScrollWithHook'
import ItemList from '../../components/ItemList'
import NetworkAddUsersContextMenuItem from '../../components/Network/NetworkAddUsersContextMenuItem'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import Segment from '../../components/Segment'
import type { Network, Talent } from '../../lib/apollo/types'
import {
    ListMyPersonificationsDocument,
    MyspaceTalentsDocument,
    NetworkRole,
    PermissionLevel, RoleModelIdsDocument, useAddRoleModelMutation,
    useJmtNetworksQuery,
    useListMyPersonificationsQuery,
    useListMyTalentsLazyQuery,
    useMyspaceQuery,
    useMyspaceTalentsQuery,
    useRemoveUserFromNetworkMutation,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import NetworkUsers from '../Network/NetworkUsers'

import './style.scss'

const slideOptions = {
    spaceBetween: 16,
}

const MySpace: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const [present] = useToast()
    const [tab, setTab] = useState<string>('0')
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const onSlideWillChange = useCallback((e: SwiperClass) => {
        setTab(e.activeIndex.toString())
    }, [])

    const { data: foreignTalents } = useMyspaceTalentsQuery({ variables: { query: '' } })

    const { data } = useMyspaceQuery({ skip: !user })

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
        skip: !user,
    })

    const networks = useJmtNetworksQuery()

    const roleModelAdmin = useMemo(() => {
        const rmNetwork = networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.RoleModels') as Network
        return rmNetwork?.networkRoles?.includes(NetworkRole.Mod)
    }, [networks])

    const [addRoleModelMutation] = useAddRoleModelMutation({
        refetchQueries: [
            {
                query: RoleModelIdsDocument,
            },
            {
                query: ListMyPersonificationsDocument,
                variables: {
                    userId: user?.user.id ?? '',
                },
            },
        ],
    })

    const onAddRoleModelClick = useCallback(async (roleModelId: number) => {
        const response = await addRoleModelMutation({
            variables: {
                input: {
                    roleModelId,
                },
            },
        })
        if (!response.errors) {
            present(t('myspace.addRoleModelComplete'), 'success')
        }
    }, [addRoleModelMutation, present, t])

    useEffect(() => {
        controlledSwiper?.update()
    }, [controlledSwiper, foreignTalents?.myspaceTalents.collection])

    const [leaveNetworkMutation] = useRemoveUserFromNetworkMutation()
    const onLeaveNetworkClick = async (networkId: string, talentCaption: string) => {
        const response = await leaveNetworkMutation({
            variables: {
                id: networkId,
                user: {
                    _links: {
                        userByNetwork: {
                            url: [`/user/${user?.user.id}`],
                        },
                    },
                },
            },
            refetchQueries: [{
                query: MyspaceTalentsDocument,
                variables: { query: '' },
            }],
        })
        if (!response.errors) {
            present(t('myspace.talentLeaveComplete', { name: talentCaption }), 'success')
        }
    }

    if (!data?.myspace) {
        return null
    }

    return (
        <Page
            className='myspace-page'
            trackingTitle='my space'
        >
            <Header
                title={t('myspace.title')}
            >
                <ContextMenu>
                    <NetworkAddUsersContextMenuItem
                        network={data.myspace}
                        personalSpace
                    />
                </ContextMenu>
            </Header>
            <Content
                fullscreen
            >
                <MySpaceHelp />
                <Segment
                    scrollable
                    value={tab}
                    onIonChange={(e) => {
                        if (e.detail.value) {
                            controlledSwiper?.slideTo(+e.detail.value)
                        }
                    }}
                    tabs={['talents', 'users', 'scoutcards'].map(v => ({ tab: t(`myspace.${v}`) }))}
                />
                {foreignTalents?.myspaceTalents && (
                    <Swiper
                        {...slideOptions}
                        modules={[Controller]}
                        onSwiper={setControlledSwiper}
                        onSlideChange={onSlideWillChange}
                    >
                        {foreignTalents?.myspaceTalents.collection && (
                            <SwiperSlide>
                                <IonGrid
                                    className='network-talent'
                                >
                                    <IonRow>
                                        <h2
                                            className='ion-margin-bottom ion-text-left'
                                        >
                                            {t('myspace.talentSwiper.title')}
                                        </h2>
                                    </IonRow>

                                    <IonRow>
                                        <IonText
                                            className='ion-margin-bottom ion-text-left n2br'
                                            color='medium'
                                        >
                                            <span>
                                                {t('myspace.talentSwiper.subtitle')}
                                            </span>
                                        </IonText>
                                    </IonRow>
                                    <IonRow>
                                        <ItemList>
                                            {foreignTalents?.myspaceTalents.collection.map(({ talent, network }) => (
                                                <TalentCard
                                                    type='listItem'
                                                    key={talent.id}
                                                    talent={talent}
                                                    networkId={network.id}
                                                    contextMenuItems={(
                                                        <ContextMenuItem
                                                            label={t('mysapce.talent.leave')}
                                                            icon={trash}
                                                            onClick={() => onLeaveNetworkClick(network.id, talent.caption)}
                                                        />
                                                    )}
                                                />
                                            ))}
                                        </ItemList>
                                    </IonRow>
                                </IonGrid>
                            </SwiperSlide>
                        )}
                        <SwiperSlide>
                            <IonGrid>
                                <IonRow>
                                    <h2
                                        className='ion-margin-bottom ion-text-left'
                                    >
                                        {t('myspace.userSwiper.title')}
                                    </h2>
                                </IonRow>

                                <IonRow>
                                    <IonText
                                        className='ion-margin-bottom ion-text-left n2br'
                                        color='medium'
                                    >
                                        <span>
                                            {t('myspace.userSwiper.subtitle')}
                                        </span>
                                    </IonText>
                                </IonRow>
                                <IonRow>
                                    <NetworkUsers
                                        network={data.myspace}
                                        hideSelf
                                        disablePromotions
                                    />
                                </IonRow>
                            </IonGrid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <IonGrid>
                                <IonRow>
                                    <InfiniteScrollWithHook
                                        lazyHook={useListMyTalentsLazyQuery}
                                    >
                                        {(myTalents: Talent[]) => myTalents.filter(talent => talent.id !== myPersonifications?.listMyPersonifications.collection[0]?.id).map(talent => (
                                            <IonCol
                                                sizeLg='3'
                                                sizeMd='4'
                                                sizeSm='6'
                                                sizeXs='6'
                                                key={talent.id}
                                                className='ion-text-center'
                                            >
                                                <TalentCard
                                                    type='small'
                                                    talent={talent}
                                                    permissionLevel={PermissionLevel.Administrative}
                                                    contextMenuItems={roleModelAdmin ? (
                                                        <ContextMenuItem
                                                            label={t('mysapce.talent.addRoleModel')}
                                                            icon={star}
                                                            onClick={() => onAddRoleModelClick(+talent.id)}
                                                        />
                                                    ) : undefined}
                                                />
                                            </IonCol>
                                        ))}
                                    </InfiniteScrollWithHook>
                                </IonRow>
                            </IonGrid>
                        </SwiperSlide>
                    </Swiper>
                )}
            </Content>
        </Page>
    )
}

export default MySpace
