const de = {
    'translation': {
        'i18n': {
            'group': {
                '0': 'Athletik',
                '1': 'Charakter',
                '2': 'Taktik',
                '3': 'Technik',
            },
            'fifa': {
                'GER': 'Deutschland',
                'AFG': 'Afghanistan',
                'AIA': 'Anguilla',
                'ALB': 'Albanien',
                'ALG': 'Algerien',
                'AND': 'Andorra',
                'ANG': 'Angola',
                'ARG': 'Argentinien',
                'ARM': 'Armenien',
                'ARU': 'Aruba',
                'ASA': 'Amerikanisch-Samoa',
                'ATG': 'Antigua und Barbuda',
                'AUS': 'Australien',
                'AUT': 'Österreich',
                'AZE': 'Aserbaidschan',
                'BAH': 'Bahamas',
                'BAN': 'Bangladesch',
                'BDI': 'Burundi',
                'BEL': 'Belgien',
                'BEN': 'Benin',
                'BER': 'Bermuda',
                'BFA': 'BurkinaFaso',
                'BHR': 'Bahrain',
                'BHU': 'Bhutan',
                'BIH': 'Bosnien und Herzegowina',
                'BLR': 'Weißrussland',
                'BLZ': 'Belize',
                'BOL': 'Bolivien',
                'BOT': 'Botswana',
                'BRA': 'Brasilien',
                'BRB': 'Barbados',
                'BRU': 'Brunei',
                'BUL': 'Bulgarien',
                'CAM': 'Kambodscha',
                'CAN': 'Kanada',
                'CAY': 'Cayman Islands',
                'CGO': 'Republik Kongo',
                'CHA': 'Tschad',
                'CHI': 'Chile',
                'CHN': 'China VR',
                'CIV': 'Elfenbeinküste',
                'CMR': 'Kamerun',
                'COD': 'Demokratische Republik Kongo',
                'COK': 'Cook-Inseln',
                'COL': 'Kolumbien',
                'COM': 'Komoren',
                'CPV': 'KapVerde',
                'CRC': 'Costa Rica',
                'CRO': 'Kroatien',
                'CTA': 'Zentralafrikanische Republik',
                'CUB': 'Kuba',
                'CUW': 'Curaçao',
                'CYP': 'Zypern',
                'CZE': 'Tschechien',
                'DEN': 'Dänemark',
                'DJI': 'Dschibuti',
                'DMA': 'Dominica',
                'DOM': 'Dominikanische Republik',
                'ECU': 'Ecuador',
                'EGY': 'Ägypten',
                'ENG': 'England',
                'EQG': 'Äquatorialguinea',
                'ERI': 'Eritrea',
                'ESP': 'Spanien',
                'EST': 'Estland',
                'ETH': 'Äthiopien',
                'FIJ': 'Fidschi',
                'FIN': 'Finnland',
                'FRA': 'Frankreich',
                'FRO': 'Färöer',
                'GAB': 'Gabun',
                'GAM': 'Gambia',
                'GEO': 'Georgien',
                'GHA': 'Ghana',
                'GNB': 'Guinea-Bissau',
                'GRE': 'Griechenland',
                'GRN': 'Grenada',
                'GUA': 'Guatemala',
                'GUI': 'Guinea',
                'GUM': 'Guam',
                'GUY': 'Guyana',
                'HAI': 'Haiti',
                'HKG': 'Hongkong',
                'HON': 'Honduras',
                'HUN': 'Ungarn',
                'IDN': 'Indonesien',
                'IND': 'Indien',
                'IRL': 'Irland',
                'IRN': 'Iran',
                'IRQ': 'Irak',
                'ISL': 'Island',
                'ISR': 'Israel',
                'ITA': 'Italien',
                'JAM': 'Jamaika',
                'JOR': 'Jordanien',
                'JPN': 'Japan',
                'KAZ': 'Kasachstan',
                'KEN': 'Kenia',
                'KGZ': 'Kirgisistan',
                'KOR': 'Südkorea',
                'KSA': 'Saudi-Arabien',
                'KUW': 'Kuwait',
                'LAO': 'Laos',
                'LBR': 'Liberia',
                'LBY': 'Libyen',
                'LCA': 'St.Lucia',
                'LES': 'Lesotho',
                'LIB': 'Libanon',
                'LIE': 'Liechtenstein',
                'LTU': 'Litauen',
                'LUX': 'Luxemburg',
                'LVA': 'Lettland',
                'MAC': 'Macau',
                'MAD': 'Madagaskar',
                'MAR': 'Marokko',
                'MAS': 'Malaysia',
                'MDA': 'Moldawien',
                'MDV': 'Malediven',
                'MEX': 'Mexiko',
                'MGL': 'Mongolei',
                'MKD': 'Mali',
                'MLI': 'Malta',
                'MLT': 'Montenegro',
                'MNE': 'Mongolei',
                'MOZ': 'Mosambik',
                'MRI': 'Mauritius',
                'MSR': 'Montserrat',
                'MTN': 'Mauretanien',
                'MWI': 'Malawi',
                'MYA': 'Myanmar',
                'NAM': 'Namibia',
                'NCA': 'Nicaragua',
                'NCL': 'Neukaledonien',
                'NED': 'Niederlande',
                'NEP': 'Nepal',
                'NGA': 'Nigeria',
                'NIG': 'Niger',
                'NIR': 'Nordirland',
                'NOR': 'Norwegen',
                'NZL': 'Neuseeland',
                'OMA': 'Oman',
                'PAK': 'Pakistan',
                'PAL': 'Panama',
                'PAN': 'Paraguay',
                'PAR': 'Peru',
                'PER': 'Philippinen',
                'PHI': 'Palästina',
                'PNG': 'Papua-Neuguinea',
                'POL': 'Polen',
                'POR': 'Portugal',
                'PRK': 'Nordkorea',
                'PUR': 'Puerto Rico',
                'QAT': 'Katar',
                'ROU': 'Rumänien',
                'RSA': 'Südafrika',
                'RUS': 'Russland',
                'RWA': 'Ruanda',
                'SAM': 'Samoa',
                'SCO': 'Schottland',
                'SDN': 'Sudan',
                'SEN': 'Senegal',
                'SEY': 'Seychellen',
                'SIN': 'Singapur',
                'SKN': 'St. Kitts und Nevis',
                'SLE': 'SierraLeone',
                'SLV': 'ElSalvador',
                'SMR': 'San Marin',
                'SOL': 'Salomon-Inseln',
                'SOM': 'Somalia',
                'SRB': 'Serbien',
                'SRI': 'SriLanka',
                'SSD': 'Südsudan',
                'STP': 'SãoTomé und Príncipe',
                'SUI': 'Schweiz',
                'SUR': 'Suriname',
                'SVK': 'Slowakei',
                'SVN': 'Slowenien',
                'SWE': 'Schweden',
                'SWZ': 'Swasiland',
                'SYR': 'Syrien',
                'TAH': 'Tahiti',
                'TAN': 'Tansania',
                'TCA': 'Turks- und Caicosinseln',
                'TGA': 'Tonga',
                'THA': 'Thailand',
                'TJK': 'Tadschikistan',
                'TKM': 'Turkmenistan',
                'TLS': 'Osttimor',
                'TOG': 'Togo',
                'TPE': 'Taiwan',
                'TRI': 'Trinidad und Tobago',
                'TUN': 'Tunesien',
                'TUR': 'Türkei',
                'UAE': 'Vereinigte Arabische Emirate',
                'UGA': 'Uganda',
                'UKR': 'Ukraine',
                'URU': 'Uruguay',
                'USA': 'USA',
                'UZB': 'Usbekistan',
                'VAN': 'Vanuatu',
                'VEN': 'Venezuela',
                'VGB': 'Britische Jungferninseln',
                'VIE': 'Vietnam',
                'VIN': 'Vincent und die Grenadinen',
                'VIR': 'Amerikanische Jungferninseln',
                'WAL': 'Wales',
                'YEM': 'Jemen',
                'ZAM': 'Sambia',
                'ZIM': 'Simbabwe',
            },
            'class': {
                'goalie': {
                    'caption': 'Torwart',
                    'group_0': 'Athletik',
                    'group_1': 'Charakter',
                    'group_2': 'Taktik',
                    'group_3': 'Technik',
                    'scalar_0': 'Fitnessstand',
                    'scalar_1': 'Kräftig',
                    'scalar_2': 'Reflexe',
                    'scalar_3': 'Koordination',
                    'scalar_4': 'Schnell',
                    'scalar_5': 'Ausdauernd',
                    'scalar_6': 'Beweglich',
                    'scalar_7': 'Schnellkräftig',
                    'scalar_8': 'Mannschaftsdienlich',
                    'scalar_9': 'Loyal',
                    'scalar_10': 'Vorbildlich',
                    'scalar_11': 'Willensstark',
                    'scalar_12': 'Lernfähig',
                    'scalar_13': 'Selbstbewusst',
                    'scalar_14': 'Risikobereit',
                    'scalar_15': 'Persönlichkeit',
                    'scalar_16': 'Coaching / Dirigieren',
                    'scalar_17': 'Offensivspiel',
                    'scalar_18': 'Torverteidigung',
                    'scalar_19': 'Spielgeschwindigkeit',
                    'scalar_20': 'Spielaktivität',
                    'scalar_21': 'Spiel im Raum',
                    'scalar_22': '1:Torwart',
                    'scalar_23': 'Stellungsspiel',
                    'scalar_24': 'Aktive Verteidigung',
                    'scalar_25': 'Spieleröffnung',
                    'scalar_26': 'Abschlag',
                    'scalar_27': 'Fangsicher',
                    'scalar_28': 'Passgenau',
                    'scalar_29': 'Ballkontrolle',
                    'scalar_30': 'Beidfüssig',
                    'scalar_31': 'Umsetzung unter Druck',
                    'scalar_0_tip': 'Austrainiert, nicht verletzungsanfällig',
                    'scalar_1_tip': 'Allgemeines Kraftniveau, nicht Körperstatur',
                    'scalar_2_tip': 'Reaktions- und Bewegungsgeschwindigkeit',
                    'scalar_3_tip': 'Runder Bewegungsablauf, Gewandtheit, Geschicklichkeit',
                    'scalar_4_tip': 'Zyklische, azyklische Geschwindigkeit',
                    'scalar_5_tip': 'Grundlagenausdauer',
                    'scalar_6_tip': 'Körperbeherrschung, Bewegungstalent',
                    'scalar_7_tip': 'Explosivität bei Sprung und Schuss',
                    'scalar_8_tip': 'Integrativer Mannschaftspieler',
                    'scalar_9_tip': 'Treue und Unterstützung gegenüber Trainer, Mannschaft und Verein',
                    'scalar_10_tip': 'Anständiges und sozialkompetentes Verhalten',
                    'scalar_11_tip': 'Selbstdisziplin, Selbstkontrolle und Entschlossenheit',
                    'scalar_12_tip': 'Ausbildungsinhalte eigenständig, langfristig aufnehmen und aus eigenen Fehlern lernen',
                    'scalar_13_tip': 'Sich des eigenen Wertes und des eigenen Könnens bewusst sein',
                    'scalar_14_tip': 'Unvoreingenommenes mutiges Handeln',
                    'scalar_15_tip': 'Integrationsfigur und Führungsspieler auf und neben dem Spielfeld',
                    'scalar_16_tip': 'Fähigkeit Mitspieler zu stellen, anzuleiten und zu motivieren',
                    'scalar_17_tip': 'Offensive Auslegung des Torwartspiels, auch außerhalb des Strafraums und als Anspielstation',
                    'scalar_18_tip': 'Defensives Torwartspiel mit dem Fokus den Torschuss und den Torerfolg zu verhindern',
                    'scalar_19_tip': 'Fähigkeit, das Spiel schnell zu machen und Geschwindigkeit der eigenen Aktionen',
                    'scalar_20_tip': 'Aktive Teilnahme am Spiel, den Ball Fordern und Inszenieren von Situation',
                    'scalar_21_tip': 'Strafraumbeherrschung, Antizipation und Mitspielen',
                    'scalar_22_tip': 'Zweikampfverhalten Mann gegen Mann',
                    'scalar_23_tip': 'Richtiges Positionieren bei Torschüssen und Standards.',
                    'scalar_24_tip': 'Aktive Bewegung zum Ball und Gegner',
                    'scalar_25_tip': 'Qualität und Geschwindigkeit beim Abspielen abgefangener und ruhender Bälle',
                    'scalar_26_tip': 'Genauigkeit langer Bälle - geschossen und geworfen',
                    'scalar_27_tip': 'Fähigkeit, hohe Bälle sicher unter Kontrolle zu bringen',
                    'scalar_28_tip': 'Genauigkeit der Pässe zum Mitspieler und in die Bewegung',
                    'scalar_29_tip': 'Ballannahme, -mitnahme und -weiterleitung',
                    'scalar_30_tip': 'Pass- und Schusstechnik mit beiden Füßen',
                    'scalar_31_tip': 'Abrufen der technischen Fähigkeiten unter Gegner-, Raum- und Zeitdruck',
                },
                'player': {
                    'caption': 'Spieler',
                    'group_0': 'Athletik',
                    'group_1': 'Charakter',
                    'group_2': 'Taktik',
                    'group_3': 'Technik',
                    'scalar_0': 'Fitnessstand',
                    'scalar_1': 'Kräftig',
                    'scalar_2': 'Stehvermögend',
                    'scalar_3': 'Koordination',
                    'scalar_4': 'Schnell',
                    'scalar_5': 'Ausdauernd',
                    'scalar_6': 'Beweglich',
                    'scalar_7': 'Schnellkräftig',
                    'scalar_8': 'Mannschaftsdienlich',
                    'scalar_9': 'Loyal',
                    'scalar_10': 'Vorbildlich',
                    'scalar_11': 'Willensstark',
                    'scalar_12': 'Lernfähig',
                    'scalar_13': 'Selbstbewusst',
                    'scalar_14': 'Risikobereit',
                    'scalar_15': 'Persönlichkeit',
                    'scalar_16': 'Spielwitzig',
                    'scalar_17': 'Offensivstark',
                    'scalar_18': 'Defensivstark',
                    'scalar_19': 'Spielgeschwindigkeit',
                    'scalar_20': 'Spielaktivität',
                    'scalar_21': '1:1 offensiv',
                    'scalar_22': '1:1 defensiv',
                    'scalar_23': 'Kreativität',
                    'scalar_24': 'Kopfballstark',
                    'scalar_25': 'Flanken',
                    'scalar_26': 'Torschuss',
                    'scalar_27': 'Dribbelstark',
                    'scalar_28': 'Passgenau',
                    'scalar_29': 'Ballkontrolle',
                    'scalar_30': 'Beidfüssig',
                    'scalar_31': 'Umsetzung unter Druck',
                    'scalar_0_tip': 'Austrainiert, nicht verletzungsanfällig',
                    'scalar_1_tip': 'Allgemeines Kraftniveau, nicht Körperstatur',
                    'scalar_2_tip': 'Durchhaltevemögen, Ausdauer',
                    'scalar_3_tip': 'Runder Bewegungsablauf, Gewandtheit, Geschicklichkeit',
                    'scalar_4_tip': 'Zyklische, azyklische Geschwindigkeit',
                    'scalar_5_tip': 'Grundlagenausdauer',
                    'scalar_6_tip': 'Körperbeherrschung, Bewegungstalent',
                    'scalar_7_tip': 'Explosivität bei Sprung und Schuss',
                    'scalar_8_tip': 'Integrativer Mannschaftspieler',
                    'scalar_9_tip': 'Treue und Unterstützung gegenüber Trainer, Mannschaft und Verein',
                    'scalar_10_tip': 'Anständiges und sozialkompetentes Verhalten',
                    'scalar_11_tip': 'Selbstdisziplin, Selbstkontrolle und Entschlossenheit',
                    'scalar_12_tip': 'Ausbildungsinhalte eigenständig, langfristig aufnehmen und aus eigenen Fehlern lernen',
                    'scalar_13_tip': 'Sich des eigenen Wertes und des eigenen Könnens bewusst sein',
                    'scalar_14_tip': 'Unvoreingenommenes mutiges Handeln',
                    'scalar_15_tip': 'Integrationsfigur und Führungsspieler auf und neben dem Spielfeld',
                    'scalar_16_tip': 'Freude am Spiel, Fähigkeit für Überraschungsmomente zu sorgen',
                    'scalar_17_tip': 'Vorwärtsbewegung mit Fokus auf: Stellungsspiel, Raumdeckung, Antizipation',
                    'scalar_18_tip': 'Rückwärtsbewegung mit Fokus auf: Stellungsspiel, Raumdeckung, Antizipation',
                    'scalar_19_tip': 'Fähigkeit, das Spiel schnell zu machen, Geschwindigkeit der eigenen Aktionen',
                    'scalar_20_tip': 'Aktive Teilnahme am Spiel, den Ball Fordern und Inszenieren von Situation',
                    'scalar_21_tip': 'Zweikampfverhalten Mann gegen Mann in der Vorwärtsbewegeung, Pressing',
                    'scalar_22_tip': 'Zweikampfverhalten Mann gegen Mann in der Rückwärtsbewegung',
                    'scalar_23_tip': 'Die Kunst, Spielsituationen spontan zu verändern und überraschend zu beeinflussen',
                    'scalar_24_tip': 'Technik und Timing beim Kopfballspiel und Bereitschaft zum Kopfball zu gehen',
                    'scalar_25_tip': 'Qualität der Flanken, Übersicht, Erfolgsquote',
                    'scalar_26_tip': 'Torschussqualität bzw. -geschwindigkeit, Explosivität und Schusstechnik',
                    'scalar_27_tip': 'Ballführung und Geschwindigkeit mit/am Ball',
                    'scalar_28_tip': 'Genauigkeit der Pässe zum Mitspieler und in die Bewegung',
                    'scalar_29_tip': 'Ballannahme, -mitnahme und -weiterleitung',
                    'scalar_30_tip': 'Pass- und Schusstechnik mit beiden Füßen',
                    'scalar_31_tip': 'Abrufen der technischen Fähigkeiten unter Gegner-, Raum- und Zeitdruck',
                    'abstract_1_caption': 'Verhalten',
                    'abstract_1_basics': 'Grundlagen',
                    'abstract_1_offensivindex': 'Offensiv-Index',
                    'abstract_1_teamspirit': 'Teamspirit',
                    'abstract_1_dynamik': 'Dynamik',
                    'abstract_1_defensivindex': 'Defensiv-Index',
                    'abstract_1_talent': 'Talent',
                    'Behavior': 'Verhalten',
                    'GameControl': 'Spielkontrolle',
                    'BallControl': 'Ballkontrolle',
                    'FindingSolutions': 'Lösungsorientiert',
                    'Activity': 'Aktivität',
                    'DevelopmentPotential': 'Entwicklungspotential',
                    'Teamspirit': 'Teamspirit',
                    'Coordination': 'Koordination',
                    'Fitness': 'Fitness',
                    'abstract_6_caption': 'Wesen des Spielers',
                    'abstract_6_tactical': 'Spieltaktik',
                    'abstract_6_talent': 'Talent',
                    'abstract_6_teamspirit': 'Kammeradschaft',
                    'abstract_6_leadership': 'Führungsqualität',
                    'abstract_6_oquality': 'Angriff',
                    'abstract_6_dquality': 'Abwehr',
                    'abstract_6_fitness': 'Fitness',
                    'comunio': 'Comunio',
                    'comunio_fitness': 'Fitnesslevel',
                    'comunio_teamer': 'Stammspieler Index',
                    'comunio_spox': 'Notengarant',
                    'comunio_scorer': 'Scorer Index',
                },
            },
            'sort': {
                'newestFirst': 'Neuste zuerst',
                'captionAsc': 'Alphabetisch',
                'dobAsc': 'Geburtsdatum: Älteste zuerst',
                'dobDesc': 'Geburtsdatum: Jüngste zuerst',
                'mostRates': 'Am meisten eingeschätzt',
                'leastRates': 'Am wenigsten eingeschätzt',
                'mostPlaylists': 'In den meisten Favoritenlisten',
                'lastNameAsc': 'Nachname alphabetisch',
                'lastNameDesc': 'Nachname umgekehrt alphabetisch',
            },
            'meta': {
                'date': 'Geburtsdatum',
                'firstName': 'Vorname',
                'lastName': 'Nachname',
                'fifaCountry': 'Geburtsland',
                'fieldPosition': 'Feldposition',
            },
            'pos_enum': {
                'offensive': 'Angriff',
                'defensive': 'Abwehr',
                'midfield': 'Mittelfeld',
                'goal': 'Tor',
            },
            '_fieldPosition_values': {
                'midfield': 'Mittelfeld',
                'defensive': 'Abwehr',
                'offensive': 'Angriff',
                'goal': 'Tor',
            },
            '_fifaCountry_values': {
                'AFG': 'Afghanistan',
                'AIA': 'Anguilla',
                'ALB': 'Albanien',
                'ALG': 'Algerien',
                'AND': 'Andorra',
                'ANG': 'Angola',
                'ARG': 'Argentinien',
                'ARM': 'Armenien',
                'ARU': 'Aruba',
                'ASA': 'Amerikanisch-Samoa',
                'ATG': 'Antigua und Barbuda',
                'AUS': 'Australien',
                'AUT': 'Österreich',
                'AZE': 'Aserbaidschan',
                'BAH': 'Bahamas',
                'BAN': 'Bangladesch',
                'BDI': 'Burundi',
                'BEL': 'Belgien',
                'BEN': 'Benin',
                'BER': 'Bermuda',
                'BFA': 'BurkinaFaso',
                'BHR': 'Bahrain',
                'BHU': 'Bhutan',
                'BIH': 'Bosnien und Herzegowina',
                'BLR': 'Weißrussland',
                'BLZ': 'Belize',
                'BOL': 'Bolivien',
                'BOT': 'Botswana',
                'BRA': 'Brasilien',
                'BRB': 'Barbados',
                'BRU': 'Brunei',
                'BUL': 'Bulgarien',
                'CAM': 'Kambodscha',
                'CAN': 'Kanada',
                'CAY': 'Cayman Islands',
                'CGO': 'Republik Kongo',
                'CHA': 'Tschad',
                'CHI': 'Chile',
                'CHN': 'China VR',
                'CIV': 'Elfenbeinküste',
                'CMR': 'Kamerun',
                'COD': 'Demokratische Republik Kongo',
                'COK': 'Cook-Inseln',
                'COL': 'Kolumbien',
                'COM': 'Komoren',
                'CPV': 'KapVerde',
                'CRC': 'Costa Rica',
                'CRO': 'Kroatien',
                'CTA': 'Zentralafrikanische Republik',
                'CUB': 'Kuba',
                'CUW': 'Curaçao',
                'CYP': 'Zypern',
                'CZE': 'Tschechien',
                'DEN': 'Dänemark',
                'DJI': 'Dschibuti',
                'DMA': 'Dominica',
                'DOM': 'Dominikanische Republik',
                'ECU': 'Ecuador',
                'EGY': 'Ägypten',
                'ENG': 'England',
                'EQG': 'Äquatorialguinea',
                'ERI': 'Eritrea',
                'ESP': 'Spanien',
                'EST': 'Estland',
                'ETH': 'Äthiopien',
                'FIJ': 'Fidschi',
                'FIN': 'Finnland',
                'FRA': 'Frankreich',
                'FRO': 'Färöer',
                'GAB': 'Gabun',
                'GAM': 'Gambia',
                'GEO': 'Georgien',
                'GER': 'Deutschland',
                'GHA': 'Ghana',
                'GNB': 'Guinea-Bissau',
                'GRE': 'Griechenland',
                'GRN': 'Grenada',
                'GUA': 'Guatemala',
                'GUI': 'Guinea',
                'GUM': 'Guam',
                'GUY': 'Guyana',
                'HAI': 'Haiti',
                'HKG': 'Hongkong',
                'HON': 'Honduras',
                'HUN': 'Ungarn',
                'IDN': 'Indonesien',
                'IND': 'Indien',
                'IRL': 'Irland',
                'IRN': 'Iran',
                'IRQ': 'Irak',
                'ISL': 'Island',
                'ISR': 'Israel',
                'ITA': 'Italien',
                'JAM': 'Jamaika',
                'JOR': 'Jordanien',
                'JPN': 'Japan',
                'KAZ': 'Kasachstan',
                'KEN': 'Kenia',
                'KGZ': 'Kirgisistan',
                'KOR': 'Südkorea',
                'KSA': 'Saudi-Arabien',
                'KUW': 'Kuwait',
                'LAO': 'Laos',
                'LBR': 'Liberia',
                'LBY': 'Libyen',
                'LCA': 'St.Lucia',
                'LES': 'Lesotho',
                'LIB': 'Libanon',
                'LIE': 'Liechtenstein',
                'LTU': 'Litauen',
                'LUX': 'Luxemburg',
                'LVA': 'Lettland',
                'MAC': 'Macau',
                'MAD': 'Madagaskar',
                'MAR': 'Marokko',
                'MAS': 'Malaysia',
                'MDA': 'Moldawien',
                'MDV': 'Malediven',
                'MEX': 'Mexiko',
                'MGL': 'Mongolei',
                'MKD': 'Mali',
                'MLI': 'Malta',
                'MLT': 'Montenegro',
                'MNE': 'Mongolei',
                'MOZ': 'Mosambik',
                'MRI': 'Mauritius',
                'MSR': 'Montserrat',
                'MTN': 'Mauretanien',
                'MWI': 'Malawi',
                'MYA': 'Myanmar',
                'NAM': 'Namibia',
                'NCA': 'Nicaragua',
                'NCL': 'Neukaledonien',
                'NED': 'Niederlande',
                'NEP': 'Nepal',
                'NGA': 'Nigeria',
                'NIG': 'Niger',
                'NIR': 'Nordirland',
                'NOR': 'Norwegen',
                'NZL': 'Neuseeland',
                'OMA': 'Oman',
                'PAK': 'Pakistan',
                'PAL': 'Panama',
                'PAN': 'Paraguay',
                'PAR': 'Peru',
                'PER': 'Philippinen',
                'PHI': 'Palästina',
                'PNG': 'Papua-Neuguinea',
                'POL': 'Polen',
                'POR': 'Portugal',
                'PRK': 'Nordkorea',
                'PUR': 'Puerto Rico',
                'QAT': 'Katar',
                'ROU': 'Rumänien',
                'RSA': 'Südafrika',
                'RUS': 'Russland',
                'RWA': 'Ruanda',
                'SAM': 'Samoa',
                'SCO': 'Schottland',
                'SDN': 'Sudan',
                'SEN': 'Senegal',
                'SEY': 'Seychellen',
                'SIN': 'Singapur',
                'SKN': 'St. Kitts und Nevis',
                'SLE': 'SierraLeone',
                'SLV': 'ElSalvador',
                'SMR': 'San Marin',
                'SOL': 'Salomon-Inseln',
                'SOM': 'Somalia',
                'SRB': 'Serbien',
                'SRI': 'SriLanka',
                'SSD': 'Südsudan',
                'STP': 'SãoTomé und Príncipe',
                'SUI': 'Schweiz',
                'SUR': 'Suriname',
                'SVK': 'Slowakei',
                'SVN': 'Slowenien',
                'SWE': 'Schweden',
                'SWZ': 'Swasiland',
                'SYR': 'Syrien',
                'TAH': 'Tahiti',
                'TAN': 'Tansania',
                'TCA': 'Turks- und Caicosinseln',
                'TGA': 'Tonga',
                'THA': 'Thailand',
                'TJK': 'Tadschikistan',
                'TKM': 'Turkmenistan',
                'TLS': 'Osttimor',
                'TOG': 'Togo',
                'TPE': 'Taiwan',
                'TRI': 'Trinidad und Tobago',
                'TUN': 'Tunesien',
                'TUR': 'Türkei',
                'UAE': 'Vereinigte Arabische Emirate',
                'UGA': 'Uganda',
                'UKR': 'Ukraine',
                'URU': 'Uruguay',
                'USA': 'USA',
                'UZB': 'Usbekistan',
                'VAN': 'Vanuatu',
                'VEN': 'Venezuela',
                'VGB': 'Britische Jungferninseln',
                'VIE': 'Vietnam',
                'VIN': 'Vincent und die Grenadinen',
                'VIR': 'Amerikanische Jungferninseln',
                'WAL': 'Wales',
                'YEM': 'Jemen',
                'ZAM': 'Sambia',
                'ZIM': 'Simbabwe',
            },
        },
        'playerTypes': {
            'defensiveBulwark': 'Defensiv Bollwerk',
            'dribbleArtist': 'Dribbel Künstler',
            'fightingPig': 'Kampfschwein',
            'fineStrategist': 'Feiner Stratege',
            'mentalityMonster': 'Mentalitätsmonster',
            'runningWonder': 'Laufwunder',
            'headerMonster': 'Kopballungeheuer',
        },
        'activitylevel': {
            '0': 'ausbaufähig',
            '1': 'aktiv',
            '2': 'engagiert',
            '3': 'Hohe Aktivität',
            '4': 'Super User!',
            'title': 'Aktivität',
        },
        'watchlist': {
            'noTalents': 'Du hast keine Talente in der Merkliste',
        },
        'loading': 'Daten werden geladen...',
        'menu': {
            'hello': 'Hi {{name}}',
            'logout': 'Abmelden',
            'settings': 'Einstellungen',
            'imprint': 'Impressum',
            'useConditions': 'Nutzungsbedingungen',
            'privacy': 'Datenschutz',
            'myspace': 'Mein Bereich',
        },
        'settings': {
            'biometric': {
                'title': 'Biometrische Authentifizierung',
                'auth': 'Authentifizierung aktivieren',
                'delete': 'Nutzerdaten löschen',
                'deleteError': 'Gespeicherte Daten konnten nicht gelöscht werden',
                'deleteSuccess': 'Gespeicherte Daten erfolgreich gelöscht',
                'gotoSettings': 'Aktiviere die biometrische Authentifizierung in deinen Einstellungen',
            },
            'privacy': {
                'title': 'Datenschutz',
                'publicProfile': {
                    'description': 'Ein öffentliches Profil kann von anderen Nutzern über die Suche gefunden werden. Freunde können dich nicht finden, wenn dein Profil privat ist',
                    'label': 'Öffentliches Profil',
                },
            },
            'language': {
                'title': 'Sprache wählen',
                'description': 'Die Sprache, welche in der App verwendet wird',
                'languages': {
                    'german': 'Deutsch',
                    'english': 'Englisch',
                },
            },
        },
        'login': {
            'permanent': 'Angemeldet bleiben',
            'username': 'Benutzername',
            'password': 'Passwort',
            'register': 'Registrieren',
            'login': 'Anmelden',
            'failed': 'Benutzername und/oder Passwort nicht korrekt',
            'biometricAuth': {
                'title': 'Authentifizierung',
            },
        },
        'errors': {
            'minLength': 'muss mindestens {{number}} Zeichen lang sein',
            'maxLength': 'darf maximal {{number}} Zeichen lang sein',
            'required': '{{label}} ist ein Pflichtfeld',
            'imageRequired': '{{label}} muss hochgeladen werden',
            'checkRequired': '{{label}} muss gesetzt werden',
            'email': '{{label}} ist keine gültige E-Mail',
            'passwordStrength': 'muss mindestens eine Zahl und einen Großbuchstaben enthalten',
            'integer': '{{label}} muss eine Zahl sein',
            'imageSize': 'Bild muss mindestens die Maße {{width}} x {{height}} px haben',
            'unknown': 'Ein Fehler ist aufgetreten. Wenn das wiederholt passiert lass uns doch Feedback da',
        },
        'title': {
            'similar': 'Ähnliche Suche',
            'home': 'Home',
            'networks': 'Netzwerke',
            'profile': 'Profil',
            'marketplace': 'Marktplatz',
            'activities': 'Aktivitäten',
            'tips': 'Tipps',
            'watchlist': 'Merkliste',
            'login': 'Join My Talent',
            'settings': 'Einstellungen',
            'register': 'Join My Talent',
            'comparison': 'Vergleich',
            'invitation': 'Einladung',
        },
        'marketplace': {
            'join': 'Zum Marktplatz hinzufügen',
            'findTalents': 'Finde aufstrebende Talente.',
            'explore': 'Entdecke',
            'search': {
                0: 'Profi-Spieler',
                1: 'Spielertypen',
                2: 'Verlauf',
                3: 'Suche',
            },
            'intro': 'Trete jetzt dem Marktplatz bei um aufstrebende Talente zu entdecken. Dieses Feature wird während der Betaphase kostenlos sein.',
            'searchHint': 'Durchsuche den Marktplatz nach Talenten.',
            'searchNoResults': 'Keine Talente gefunden.',
            'whatTypeOfPlayer': 'Nach welcher Art von Spieler suchst du?',
        },
        'activity': {
            'deleted': 'Aktivität nicht mehr verfügbar.',
            'vote': {
                'me': 'Du hast einen Vote erhalten! <date>{{date}}</date>',
                'you': 'Du hast eine Bewertung für <strong>{{name}}</strong> abgegeben. <date>{{date}}</date>',
            },
            'follow': {
                'me': '<strong>{{name}}</strong> folgt dir jetzt. <date>{{date}}</date>',
                'you': 'Du folgst jetzt <strong>{{name}}</strong>. <date>{{date}}</date>',
            },
            'unfollow': {
                'me': '<strong>{{name}}</strong> folgt dir nicht mehr. <date>{{date}}</date>',
                'you': 'Du folgst <strong>{{name}}</strong> nicht mehr. <date>{{date}}</date>',
            },
            'invite': {
                'invite': 'Einladung von <strong>{{network}}</strong> erhalten. <date>{{date}}</date>',
                'network': '<strong>{{user}}</strong> möchte <strong>{{network}}</strong> beitreten. <date>{{date}}</date>',
            },
        },
        'tabbar': {
            'home': 'Home',
            'profile': 'Profil',
            'activities': 'Aktivitäten',
            'watchlist': 'Merkliste',
        },
        'inputs': {
            'search': 'Suche',
        },
        'buttons': {
            'back': 'Zurück',
            'showAll': 'Alle Anzeigen',
            'explore': 'Entdecken',
            'cancel': 'Abbrechen',
            'save': 'Speichern',
            'done': 'Fertig',
            'delete': 'Löschen',
            'remove': 'Entfernen',
            'ok': 'Ok',
            'share': 'Teilen',
            'close': 'Schließen',
            'chose': 'Auswählen',
            'add': 'Hinzufügen',
            'answer': 'Beantworten',
            'accept': 'Annehmen',
            'decline': 'Ablehnen',
            'compare': 'Vergleichen',
        },
        'myspace': {
            'title': 'Mein Bereich',
            'talent': 'Ich als Spieler',
            'users': 'Bekannte',
        },
        'networks': {
            'addUsers': 'Nutzer einladen',
            'removeUser': 'Nutzer entfernen',
            'removeUserAlert': 'Möchtest du den Nutzer "{{name}}" wirklich aus deinem Team entfernen?',
            'addTalents': 'Spieler hinzufügen',
            'removeTalent': 'Spieler entfernen',
            'removeTalentAlert': 'Möchtest du den Spieler "{{name}}" wirklich aus deinem Team entfernen?',
            'createTalent': 'Spieler erstellen',
            'leave': 'Team verlassen',
            'leaveAlert': 'Möchtest du das Team "{{name}}" wirklich verlassen?',
            'leaveComplete': 'Du hast das Team "{{name}}" verlassen',
            'add': 'Team erstellen',
            'addSubTitle': 'Erstelle dein eigenes Team',
            'edit': 'Team editieren',
            'delete': 'Team löschen',
            'deleteAlert': 'Willst du das Team "{{name}}" wirklich löschen?',
            'deleteComplete': 'Team "{{name}}" wurde gelöscht',
            'title': 'Teams',
            'details': 'Details',
            'talents': 'Talente',
            'users': 'Mitglieder',
            'invitation': '{{user}} hat dich eingeladen dem Team {{network}} beizutreten',
            'invitationComplete': 'Einladung erfolgreich gesendet',
            'alreadyInvited': '{{user}} hat bereits eine Einladung erhalten',
            'join': 'Beitreten',
            'joinComplete': 'Beitrittsanfrage erfolgreich gesendet',
            'alreadyRequestedToJoin': 'Du hast bereits eine Beitrittsanfrage gestellt',
            'apply': {
                'defaultMessage': 'Bitte lass mich in dein Team.',
            },
            'invite': {
                'linkCopy': 'URL der Einladung kopieren',
                'linkCopied': 'Link wurde kopiert!',
                'dialogTitle': 'Lade Freunde in dein Team ein',
                'title': 'Einladung',
                'text': 'Trete meinem Team {{network}} bei.',
                'userNotFound': 'User nicht gefunden? Klicke hier, um die Einladung zu teilen',
            },
            'joined': 'Team erfolgreich beigetreten',
        },
        'network': {
            'title': {
                'edit': 'Team bearbeiten',
                'create': 'Team erstellen',
            },
            'edit': {
                'uploadImage': 'Bild hochladen',
                'caption': 'Name',
                'description': 'Beschreibung',
                'public': 'Öffentliches Team',
                'open': 'Offenes Team',
                'save': {
                    'create': 'Team erstellen',
                    'edit': 'Team bearbeiten',
                },
                'openHint': 'Benutzer können offenen Teams jederzeit und ohne Anfrage beitreten',
                'publicHint': 'Öffentliche Netzwerke können über die Suche gefunden werden',
            },
            'addOrEditError': 'Oops, hier ist ein Fehler aufgetreten. Bitte versuch es später erneut.',
            'user': {
                'addSuccessful': '{{user}} erfolgreich dem Team hinzugefügt',
                'alreadyInNetwork': 'Dieser Benutzer ist bereits in deinem Team',
            },
            'talent': {
                'addSuccessful': '{{talent}} erfolgreich dem Team hinzugefügt',
                'alreadyInNetwork': 'Dieser Spieler ist bereits in deinem Team',
            },
        },
        'talent': {
            'missingVoteForHint': '{{name}} fehlen noch {{votes}} Bewertungen um die Talent ID freizuschalten. Du kannst dabei helfen, indem du für {{name}} votest.',
            'missingVotesHint': 'Dir fehlen noch {{votes}} Bewertungen um deine Talent ID freizuschalten. Frage deine Freunde, ob sie Dir dabei helfen.',
            'roleModelTeaserCardTitle': 'Was für ein Spielertyp bist du?',
            'roleModelTeaserCardSubtitle': 'Vergleiche Dich mit den Top-Spielern der Welt und erstelle dein Vorbild',
            'updated': 'Talent wurde erfolgreich aktualisiert',
            'incomparable': 'Die Talente sind unvergleichbar. Feldspieler können nicht mit Torwärten verglichen werden',
            'compareWith': 'Vergleichen mit...',
            'talentId': 'Talent ID',
            'talentIdClickHint': 'Klick in die Mitte um die Ansicht umzuschalten.',
            'listView': 'Listenansicht',
            'roleModelOnboarding': 'Hier kannst du dein Rolemodel wählen. Welcher Spieler-Typ bist du?...',
            'choseRoleModel': 'Vorbild wählen',
            'yourRoleModel': 'Dein Vorbild',
            'addToWatchlist': 'Zur Merkliste hinzufügen',
            'addedToWatchlist': '{{name}} wurde zur Merkliste hinzugefügt.',
            'addedToMarketplace': 'Talent wurde zum Marktplatz hinzugefügt.',
            'removeFromWatchlist': 'Von Merkliste entfernen',
            'removedFromWatchlist': '{{name}} wurde von der Merkliste entfernt.',
            'addToNetwork': 'Zum Netzwerk hinzufügen',
            'roleModelIntro1': 'Glückwunsch, <0>{{name}}</0>',
            'roleModelIntro2': 'Du hast ähnliche Spielanlagen wie:',
            'previousVote': 'Alte Einschätzung',
            'topSkills': 'Topskills',
            'newTopSkills': 'Aktuelle Einschätzung',
            'getYourSkillConfirmed': 'Lass deine Fähigkeiten bestätigen',
            'voteModalTitle': '{{name}} bewerten',
            'vote': 'Bewerten',
            'theVote': 'Bewertung',
            'votePreview': 'Vorschau',
            'voteZeroCount': 'Bitte überprüfe deine Einschätzung nochmal.',
            'voteComplete': 'Vielen Dank für deine Einschätzung.',
            'voteUpdated': 'Du kannst {{name}} nur einmal am Tag bewerten. Deine Bewertung wurde aktualisiert.',
            'details': 'Details',
            'playerCard': 'Spieler Karte',
            'age': 'Alter',
            'agePrefix': 'Alter',
            'ageSuffix': 'Jahre alt',
            'heightPrefix': 'Größe',
            'heightSuffix': 'cm',
            'weightPrefix': 'Gewicht',
            'weightSuffix': 'kg',
            'club': 'Verein',
            'team': 'Mannschaft',
            'delete': 'Möchtest du {{talent}} wirklich unwiderruflich löschen? Die Bewertungen können nicht wieder hergestellt werden.',
            'foot': {
                'prefix': 'Fuß',
                'right': 'Rechts',
                'left': 'Links',
                'both': 'Beidfüßig',
            },
            'position': {
                'offensive': 'Stürmer',
                'midfield': 'Mittelfeld',
                'defensive': 'Verteidiger',
                'goal': 'Torwart',
            },
            'caption': 'Trikotname',
            'metas': {
                'firstName': 'Vorname',
                'lastName': 'Nachname',
                'birthDate': 'Geburtsdatum',
                'fifaCountry': 'Geburtsland',
                'fieldPosition': 'Position',
            },
            'create': 'Spieler erstellen',
            'edit': 'Aktualisieren',
            'uploadImage': 'Spielerbild hochladen',
            'public': {
                'description': 'Ein öffentlicher Spieler kann von anderen Nutzern über die Suche gefunden werden und ihren Teams hinzugefügt werden.',
                'label': 'Öffentlicher Spieler',
            },
            'linkWithProfile': 'Spieler mit meinem Profil verknüpfen',
            'layers': {
                'selectLayers': 'Welche Votes sollen gezeigt werden?',
                'public': 'öffentliche',
                'global': 'globale (auch private)',
                'network': 'aus diesem Netzwerk',
                'self': 'meine Votes',
                'followed': 'Usern denen ich folge',
                'maxOfTwoAllowed': 'Maximal zwei auswählen',
                'minOfOneNeeded': 'Mindestens eine Auswahl treffen',
                'primaryHelper': 'Deine <1>obere Auswahl</1> wird in der <1>Talent ID primär dargestellt</1>. Ändere die Reihenfolge einfach durch Drag & Drop',
            },
            'topskills': {
                'headline': 'Auf diese Topskills ist {{name}} besonders stolz',
                'scoutHeadline': '{{name}}`s Topskills',
                'mineHeadline': 'Auf diese Topskills bin ich besonders stolz',
                'selectHeadline': 'Wähle deine besten Fertigkeiten',
                'hint': 'Zeige deine besten Eigenschaften und wähle die, auf die du besonders stolz bist.',
                'chose': 'Wähle deine Topskills',
                'choseScout': 'Topskills auswählen',
                'modalTitle': 'Wähle deine Topskills',
            },
            'share': {
                'title': 'Talent ID teilen',
                'dialogTitle': 'Teile deine Talent ID',
                'text': 'Kennst du schon {{name}}? Schaue dir hier sein Profil an und geb doch eine Bewertung ab.',
                'linkCopy': 'URL zum Teilen kopieren',
                'linkCopied': 'Link zum teilen wurde kopiert!',
            },
        },
        'registration': {
            'finish': 'Registrieren',
            'username': 'Benutzername',
            'password': 'Passwort',
            'lastName': 'Name',
            'firstName': 'Vorname',
            'email': 'E-Mail',
            'usernameTaken': 'Benutzername bereits vergeben',
            'subject': 'Join My Talent E-Mail betätigen',
            'finished': {
                'text': 'Du hast dich erfolgreich registriert! Du wirst in Kürze ine E-Mail von uns erhalten. Nach der Bestätigung kannst du Join My Talent und alle Funktionen nutzen!',
                'title': 'Registrierung abgeschlossen!',
            },
        },
        'onboarding': {
            'title': 'Join My Talent',
            'chooseAvatar': 'Profilbild',
            'zoom': 'Zoom',
            'next': 'Weiter',
            'back': 'Zurück',
            'finish': 'Fertig',
            'termsAndConditionsText': 'Zur Verwendung von Join My Talent musst du die <1>Nutzungsbedingungen</1> akzeptieren. Bitte lies diese sorgfältig durch.',
            'readTermsAndConditions': 'Nutzungsbedingungen',
            'acceptTermsAndConditions': 'Akzeptieren',
        },
        'user': {
            'goToProfile': 'Zum Profil',
            'unfollowToast': 'Du folgst {{name}} nicht mehr.',
            'followToast': 'Du folgst jetzt {{name}}.',
            'unfollow': 'Nicht mehr Folgen',
            'follow': 'Folgen',
            'follower': 'Follower',
            'followed': 'Folge ich',
            'friends': 'Freunde',
            'profileImage': 'Profilbild',
            'birthdate': 'Geburtsdatum',
            'phone': 'Telefon',
            'country': 'Land',
            'city': 'Stadt',
            'zip': 'Postleitzahl',
            'street': 'Straße und Hausnummer',
            'club': 'Verein',
            'team': 'Mannschaft',
            'year': 'Jahrgang',
            'userType': {
                'label': 'Bezeichnung',
                'player': 'Spieler',
                'trainer_male': 'Trainer Male',
                'trainer_female': 'Trainer Female',
                'supervisor': 'Betreuer',
                'relatives': 'Angehörige',
                'scout': 'Scout',
                'other': 'Andere',
            },
            'experience': {
                'label': 'Erfahrung',
                'professional': 'Profi',
                'semi-professional': 'Semi-Profi',
                'amateur': 'Amateur',
            },
            'ageCategory': {
                'label': 'Altersklasse',
                'kids': 'Kinder',
                'juniors': 'Junioren',
                'men': 'Herren',
                'women': 'Damen',
            },
            'aboutMe': 'Über mich',
        },
        'search': {
            'results': 'Suchergebnisse',
            'talents': 'Spieler',
            'networks': 'Teams',
            'users': 'Benutzer',
        },
        'profile': {
            'edit': 'Profil bearbeiten',
            'user': 'Profil',
            'personification': 'Spieler',
            'talents': 'Scoutcards',
            'talentMenu': {
                'edit': 'Spieler bearbeiten',
                'delete': 'Spieler löschen',
                'gotoNetwork': 'Zum Netzwerk',
            },
            'createTalent': {
                'title': 'Du als Spieler',
                'info': 'Hier erscheint dein persönliches Spielerprofil, sobald du es erstellt hast. Dieses Profil repräsentiert dich als Spieler.',
                'create': 'Eigenen Spieler erstellen',
            },
        },
    },
}

export default de
