import { shirt } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import useNetworkInvite from '../../../lib/hooks/useNetworkInvite'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkAddTalentsContextMenuItemProps {
    network: Network
}

const NetworkAddTalentsContextMenuItem: React.FC<NetworkAddTalentsContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const invite = useNetworkInvite(network, false, true)
    return (
        <ContextMenuItem
            icon={shirt}
            label={t('networks.inviteTalents')}
            onClick={invite}
        />
    )
}

export default NetworkAddTalentsContextMenuItem
