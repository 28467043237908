import './style.scss'
import clsx from 'clsx'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { ChartType } from '../../../../lib/apollo/types'

import GlobalDefs from './GlobalDefs'
import type { LayerI } from './Layer'
import Layer from './Layer'
import Levels from './Levels'
import TalentIDSkeleton from './TalentIDSkeleton'
import TouchArea from './TouchArea'

export const translationKeys = [
    'i18n.vote.0',
    'i18n.vote.1',
    'i18n.vote.2',
    'i18n.vote.3',
    'i18n.vote.4',
    'i18n.vote.5',
    'i18n.vote.6',
    'i18n.vote.7',
]

interface TalentIDProps {
    values?: number[],
    interactive?: boolean
    className?: string
    backgroundColor?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'secondary-transparent' | 'scout' | 'roleModel'
    color1?: 'primary' | 'secondary' | 'tertiary' | 'black'
    color2?: 'primary' | 'secondary' | 'tertiary' | 'black'
    id?: string
    animationIndex?: number
    disableEnterAnimation?: boolean
}

const TalentID: React.FC<TalentIDProps> = ({
    color1 = 'primary',
    color2= 'tertiary',
    backgroundColor = 'primary',
    className,
    interactive ,
    id,
    disableEnterAnimation= false,
    values,
}) => {
    // textPath fix (multiple svgs with same id leads to wrong path calculation)
    const uuidRef = useRef(uuid())

    const { t } = useTranslation()

    const layer: LayerI = {
        id: 'global',
        withHover: false,
        withGroupLabels: false,
        values: values ?? [],
        type: ChartType.Radar,
        translationKeys,
        translations: translationKeys.map(key => t(key)),
    }

    if (!values) return <TalentIDSkeleton />

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            preserveAspectRatio='xMidYMid meet'
            viewBox='-60 -60 620 620'
            className={clsx(
                'layer-global',
                `layer-global__background--${backgroundColor}`,
                `layer-global__color1--${color1}`,
                `layer-global__color2--${color2}`,
                className,
                { interactive, animated: !disableEnterAnimation },
            )}
            id={id}
        >
            <GlobalDefs
                id={uuidRef.current}
            />

            <Layer
                id={uuidRef.current}
                layer={layer}
            />

            <Levels />

            <TouchArea
                onClick={() => {
                    // setActiveIndex(aId => (aId + 1) % layers.length)
                }}
            />
        </svg>
    )
}

export default TalentID
