import { useIonRouter } from '@ionic/react'
import { analytics } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkAddTalentsContextMenuItemProps {
    network: Network
}

const NetworkAddTalentsContextMenuItem: React.FC<NetworkAddTalentsContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const { push } = useIonRouter()
    return (
        <ContextMenuItem
            icon={analytics}
            label={t('networks.addTalents')}
            onClick={() => { push(`/home/network/${network.id}/add/talents` ) }}
        />
    )
}

export default NetworkAddTalentsContextMenuItem
