import { IonSkeletonText, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import getAge from '../../../lib/getAge'
import Divider from '../../Divider'
import PerformanceIcon from '../../Icon/PerformanceIcon'
import TalentContextMenu from '../../Talent/TalentContextMenu'
import TalentID from '../../Talent/TalentID'
import TalentIDSkeleton from '../../Talent/TalentID/TalentIDSkeleton'
import { useTalentCardContext } from '../_Core/Context'
import FootIcon from '../_Core/FootIcon'
import TalentCardPaper from '../_Core/TalentCardPaper'
import useBackgroundColor from '../_Core/useBackgroundColor'

import './style.scss'

const TalentCardSmall: React.FC = () => {
    const { talent, talentType, contextMenuItems, routerLink, shared, networkId, performance } = useTalentCardContext()
    const backgroundColor = useBackgroundColor()
    const age = getAge(talent?.metas.date)
    const { push } = useIonRouter()
    const { t } = useTranslation()

    return (
        <TalentCardPaper
            className={`talentcard-small talentcard-small--${talentType}`}
        >
            <div
                className='talentcard-small__top-container'
            >
                <div
                    className='display-flex-column ion-justify-content-center'
                >
                    <h2
                        className='ion-text-center'
                    >
                        {age ?? (
                            <IonSkeletonText
                                style={{ width: 40 }}
                            />
                        ) }
                    </h2>
                    { talent?.metas.fifaCountry && (
                        <img
                            className='talentcard-small__fifa-country'
                            alt='fifacountry'
                            src={`/assets/flags/${talent?.metas.fifaCountry}.webp`}
                        />
                    )}
                    <PerformanceIcon
                        className='talentcard-small__performance-icon'
                        performance={performance}
                        talent={talent}
                    />
                </div>
            </div>

            <div
                className='talentcard-small__bottom-container'
            >
                <div
                    className='talentcard-small__profile-link'
                    tabIndex={-1}
                    role='button'
                    onKeyDown={(e) => {
                        if (!shared && talent && e.key === 'Enter' && routerLink) {
                            push(routerLink)
                        }
                    }}
                    onClick={() => {
                        if (!shared && talent && routerLink) {
                            push(routerLink)
                        }
                    }}
                >
                    <h2
                        className='ion-text-center'
                    >
                        {talent?.caption ?? (
                            <IonSkeletonText
                                style={{ width: 120, margin: '0 auto 4px' }}
                            />
                        )}
                    </h2>
                </div>

                <Divider
                    margin={6}
                />

                <div
                    className='talentcard-small__info-wrapper'
                >
                    <div
                        className='talentcard-small__attr-wrapper'
                    >
                        <div
                            className='talentcard-small__attr-wrapper__attr'
                        >
                            <h4
                                className='talentcard-small__attr-title'
                            >
                                {t('talent.card.position.label')}
                            </h4>
                            <h4
                                className='talentcard-small__attr-text'
                            >
                                {talent?.metas.fieldPosition
                                    ?
                                    t(`talent.position.${talent?.metas.fieldPosition}Short`)
                                    : (
                                        <IonSkeletonText
                                            style={{ width: 20 }}
                                        />
                                    )}
                            </h4>
                        </div>
                        <div
                            className='talentcard-small__attr-wrapper__attr'
                        >
                            <h4
                                className='talentcard-small__attr-title'
                            >
                                {t('talent.foot.prefix')}
                            </h4>

                            <FootIcon
                                talent={talent}
                            />
                        </div>
                    </div>

                    <div
                        className='talentcard-small__talentid-wrapper'
                        tabIndex={-1}
                        role='button'
                        onKeyDown={(e) => {
                            if (talent && e.key === 'Enter' && routerLink) {
                                push(routerLink)
                            }
                        }}
                        onClick={() => {
                            if (talent && routerLink) {
                                push(routerLink)
                            }
                        }}
                    >
                        { talent
                            ? (
                                <TalentID
                                    dataView1={talent.dataViews}
                                    talentPosition={talent.metas.fieldPosition}
                                    backgroundColor={backgroundColor}
                                    color1={talentType === 'roleModel' ? 'black' : undefined}
                                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                                />
                            ) : (
                                <TalentIDSkeleton />
                            )}

                    </div>

                </div>
            </div>

            { !shared && talent && (
                <div
                    className='talentcard-small__ctx-menu'
                >
                    <TalentContextMenu
                        color='dark'
                        horizontalIcon={false}
                        talent={talent}
                        networkId={networkId}
                    >
                        {contextMenuItems}
                    </TalentContextMenu>
                </div>
            )}
        </TalentCardPaper>
    )
}

export default TalentCardSmall
