import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { heart } from 'ionicons/icons'
import './style.scss'

interface FollowingIconProps {
    className?: string
}

const FollowingIcon: React.FC<FollowingIconProps> = ({ className }) => {
    return (
        <IonIcon
            className={clsx(className, 'relationship-icon')}
            icon={heart}
        />
    )
}

export default FollowingIcon
