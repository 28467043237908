import { IonCard, IonCardSubtitle, IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import './style.scss'

interface TeaserCardProps {
    darkColor?: boolean
    img: string
    imgAlt: string
    title: string
    subtitle: string
    routerLink?: string
    buttonText?: string
    buttonIcon?: string
}

const TeaserCard: React.FC<TeaserCardProps> = ({
    darkColor = false,
    img,
    imgAlt,
    title,
    subtitle,
    routerLink,
    buttonText,
    buttonIcon,
}) => {
    const { t } = useTranslation()

    return (
        <IonCard
            routerLink={routerLink}
            className={clsx('teaser-card', { 'teaser-card--dark': darkColor })}
            // style={{ backgroundImage: `url('${img}')` }}
        >
            { img && (
                <IonImg
                    alt={imgAlt}
                    src={img}
                />
            )}

            <IonGrid
                className='teaser-card__overlay'
            >
                <IonRow
                    className='ion-align-items-center teaser-card__row'
                >
                    <IonCol
                        className='teaser-card__content'
                    >
                        <IonText
                            color='dark'
                        >
                            <h2>
                                { title }
                            </h2>
                        </IonText>

                        <IonCardSubtitle>
                            { subtitle }
                        </IonCardSubtitle>

                        <Button
                            color='secondary'
                            icon={buttonIcon}
                        >
                            {buttonText ?? t('buttons.explore')}
                        </Button>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default TeaserCard
