import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import { checkmark, eye } from 'ionicons/icons'
import type { Ref } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import plusLayer from '../../../../../assets/img/plus_layer.svg'
import type { Layer, Talent } from '../../../../../lib/apollo/types'
import { useMyTalentLayerQuery } from '../../../../../lib/apollo/types'
import { useUser } from '../../../../../providers/Auth/hooks'
import useToast from '../../../../../providers/Toast/hooks'
import Button from '../../../../Button'
import Modal from '../../../../Modal'
import type { SelectWithOrderState } from '../../../../SelectWithOrder'
import SkillsSelectLayersView from '../index'

import './style.scss'

interface SkillsLayersButtonProps {
    talent: Talent
    networkId?: string
    onSelectionChanged: (layers: Layer[]) => any
    className?: string
    ref?: Ref<HTMLIonButtonElement>
    id?: string
    color?: string,
    activeColor?: string
}

const SkillsSelectLayersButton: React.FC<SkillsLayersButtonProps> = ({ color, activeColor, talent, networkId, onSelectionChanged, className, id }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [show] = useToast()
    const [showModal, setShowModal] = useState(false)

    const publicLayer = {
        value: 'public',
        label: t('talent.layers.public'),
        icon: eye,
    }
    const [selected, setSelected] = useState<SelectWithOrderState>([publicLayer])

    const { data: layerA } = useMyTalentLayerQuery({
        variables: {
            userId: user?.user?.id ?? '',
            talentId: talent.id,
            layerParams: `?layers=${selected[0]?.value}${selected[0]?.payload ?? ''}${selected[0]?.value === 'network' ? `&networkId=${networkId}` : ''}`,
        },
        fetchPolicy: 'network-only',
        skip: !user || !selected[0] || selected[0].value === 'public' || (selected[0].value === 'network_friends' && !selected[0].payload),
    })

    const { data: layerB } = useMyTalentLayerQuery({
        variables: {
            userId: user?.user?.id ?? '',
            talentId: talent.id,
            layerParams: `?layers=${selected[1]?.value}${selected[1]?.payload ?? ''}${selected[1]?.value === 'network' ? `&networkId=${networkId}` : ''}`,
        },
        fetchPolicy: 'network-only',
        skip: !user || !selected[1] || selected[1].value === 'public' || (selected[1].value === 'network_friends' && !selected[1].payload),
    })

    useEffect(() => {
        const publicIndex = selected.findIndex(s => s.value === 'public')

        const pl = {
            name: 'public',
            dataViews: talent.dataViews,
        }

        const layers = [
            ...(publicIndex === 0 ? [pl] : layerA?.talentLayer ?? [] as Layer[]),
            ...(publicIndex === 1 ? [pl] : layerB?.talentLayer ?? [] as Layer[]),
        ].filter(Boolean)

        // check if votes exists
        layers.map((layer, i) => {
            const sum = layer.dataViews[0]?.values.reduce((accumulator, value) => {
                return accumulator + value
            }, 0)
            if (sum === 0) {
                show(t('talent.layers.noVotesYet'), 'warning')
                setSelected((s) => {
                    if(i === 0) {
                        return s[1] ? [s[1]]: []
                    }
                    return [s[0]]
                })
                return undefined
            }
            return layer
        }).filter(Boolean)

        if(layers && layers.length > 0) {
            onSelectionChanged(layers)
        } else {
            onSelectionChanged([{
                name: 'public',
                dataViews: talent.dataViews,
            }])
        }

    }, [layerA, layerB, onSelectionChanged, setSelected, selected, talent.dataViews, show, t])

    const c = useMemo<string>(() => {
        if (selected.length === 1 &&  selected.findIndex(s => s.value === 'public') !== -1) {
            return  color ?? 'light'
        }

        return activeColor ?? 'light'
    }, [selected, activeColor, color])

    const onCloseModal = useCallback(() => {
        if (selected.length === 0) {
            show(t('talent.layers.minOfOneNeeded'), 'warning')
            return
        }

        setShowModal(false)
    }, [selected.length, show, t])

    return (
        <>
            <Modal
                modalTitle={talent.caption}
                onClose={onCloseModal}
                isOpen={showModal}
            >
                { talent && (
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <h1>{t('talent.layers.selectLayers')}</h1>
                            </IonCol>

                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <SkillsSelectLayersView
                                    talent={talent}
                                    selected={selected}
                                    setSelected={setSelected}
                                    networkId={networkId}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                className='talent-layers-modal__helper-text'
                            >
                                <Trans
                                    i18nKey='talent.layers.primaryHelper'
                                >
                                    Deine
                                    <IonText
                                        color='tertiary'
                                    >
                                        obere Auswahl
                                    </IonText>
                                    wird in der
                                    <IonText
                                        color='tertiary'
                                    >
                                        Talent ID primär dargestellt.
                                    </IonText>
                                    Ändere die Reihenfolge einfach durch Drag & Drop
                                </Trans>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                className='ion-text-center'
                            >
                                <Button
                                    icon={checkmark}
                                    onClick={onCloseModal}
                                >
                                    {t('buttons.done')}
                                </Button>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </Modal>

            <Button
                className={className}
                disabled={!talent}
                onClick={(e) => {
                    e.stopPropagation()
                    setShowModal(() => true)
                }}
                color={c}
                fill='clear'
                // icon={optionsOutline}
                iconSrc={plusLayer}
                id={id}
            />
        </>
    )
}

export default SkillsSelectLayersButton
