import { Preferences } from '@capacitor/preferences'
import { useIonRouter } from '@ionic/react'
import type { PropsWithChildren } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

import { useUserMetaDataQuery } from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'

import { useSignInWithToken, signOut, useUser } from './hooks'

const publicRoutes = ['/login', '/register', '/confirm', '/s/', '/forgotpassword']

const date = new Date('2022-04-10T19:06:07.051Z')

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isInit, setIsInit] = useState(false)
    const [signinWithToken] = useSignInWithToken()

    const user = useUser()
    const history = useHistory()
    const redirectUrl = useRef<string>('')

    const { data: metaData, loading: metaDataLoading } = useUserMetaDataQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
        skip: !user,
    })

    useEffect(() => {
        if (isInit) return

        const checkLogin = async () => {
            const { value: token } = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
            if (!token || token === 'undefined') {
                redirectUrl.current = history.location.pathname
            } else {
                try {
                    await signinWithToken({ variables: { input: { token } } })
                } catch(e) {
                    await signOut()
                }
            }
            setIsInit(true)
        }

        checkLogin()
    }, [history.location.pathname, isInit, signinWithToken])

    const { routeInfo: { pathname } } = useIonRouter()

    if (!isInit || metaDataLoading) return null

    const isPublicPage = publicRoutes.some(r => pathname.startsWith(r))

    const hasMetaData =
        !!metaData?.userMetaData?.user?.data?.behavior?.termsAcceptedAt
        && +new Date(metaData?.userMetaData.user.data.behavior.termsAcceptedAt) >= +date

    if(!user?.user && !isPublicPage) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { referrer: pathname },
                }}
            />
        )
    }

    if(user?.user && !hasMetaData && pathname !== '/onboarding') {
        return (
            <Redirect
                to='/onboarding'
            />
        )
    }

    if(user?.user && hasMetaData && pathname === '/onboarding') {
        return (
            <Redirect
                to='/home'
            />
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default AuthProvider
