import { ellipsisVertical, pencilOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { User } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import ContextMenu from '../../ContextMenu'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

import UserFollowContextMenuItem from './UserFollowContextMenuItem'
import {PropsWithChildren} from 'react'

interface UserContextMenuProps {
    user: User,
    color?: 'light' | 'medium' | 'dark'
}

const UserContextMenu: React.FC<UserContextMenuProps & PropsWithChildren> = ({ color = 'dark', user, children }) => {
    const { t } = useTranslation()
    const currentUser = useUser()

    return (
        <ContextMenu
            size='small'
            fill='clear'
            color={color}
            icon={ellipsisVertical}
        >
            { children }
            {user.id === currentUser?.user.id && (
                <ContextMenuItem
                    label={t('profile.edit')}
                    icon={pencilOutline}
                    routerLink='/profile/edit'
                />
            )}
            {user.id !== currentUser?.user.id && (
                <UserFollowContextMenuItem
                    user={user}
                />
            )}
        </ContextMenu>
    )
}

export default UserContextMenu
