import { useIonRouter } from '@ionic/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../components/Content'
import Header from '../../../components/Header'
import Page from '../../../components/Page'
import TalentAddOrEditForm from '../../../components/Talent/TalentAddOrEditForm'
import type { Talent } from '../../../lib/apollo/types'
import {
    NetworkTalentsDocument,
    useAddTalentsToNetworkMutation,
    useMyspaceQuery,
    useNetworkQuery,
    useTalentQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

const TalentEdit: React.FC = () => {
    const { t } = useTranslation()
    const { params: { talentId, networkId, autoProfile } } = useRouteMatch<{ networkId?: string, talentId: string, autoProfile?: string }>()
    const user = useUser()

    const { data: talent, loading } = useTalentQuery({
        variables: {
            talentId,
            userId: user?.user.id ?? '',
        },
        skip: !talentId || talentId === 'new',
    })
    const { data: network, loading: networkLoading } = useNetworkQuery({
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            networkId: networkId!,
            userId: user?.user.id ?? '',
        },
        skip: !networkId,
    })

    const { data: myNetwork } = useMyspaceQuery({
        skip: !user,
    })

    const [addTalentMutation] = useAddTalentsToNetworkMutation({
        refetchQueries: [{
            query: NetworkTalentsDocument,
            variables: {
                userId: user?.user.id ?? '',
                networkId: myNetwork?.myspace.id ?? '',
            },
        }],
        errorPolicy: 'none',
    })

    const { push } = useIonRouter()

    const onSave = useCallback(async (savedTalent?: Talent) => {
        if(myNetwork?.myspace.id && savedTalent && !networkId && autoProfile !== 'false') { // is personification -> add to my place
            try {
                await addTalentMutation({
                    variables: {
                        id: myNetwork.myspace.id,
                        talents: {
                            _links: {
                                objectByNetwork: {
                                    url: [`/object/${savedTalent.id}`],
                                },
                            },
                        },
                    },
                })
            } catch (e) {
                // talent already in network -> ignore this error
            }
        }

        if(!network?.network) {
            push('/profile', 'back')
        } else {
            push(`/home/network/${network.network.id}/detail`, 'back')
        }
    }, [myNetwork?.myspace.id, networkId, autoProfile, network?.network, addTalentMutation, push])

    return (
        <Page
            trackingTitle={talentId && talentId !== 'new' ? 'talent edit' : 'talent create'}
        >
            <Header
                title={talentId && talentId !== 'new' ? talent?.talent.caption ?? '...' : t('talent.create')}
                buttonLeft='back'
            />
            <Content
                fullscreen
            >
                {(!loading && !networkLoading) && (
                    <TalentAddOrEditForm
                        talent={talent?.talent}
                        network={network?.network}
                        autoProfile={!networkId && autoProfile !== 'false'}
                        onSave={onSave}
                    />
                )}
            </Content>
        </Page>
    )
}

export default TalentEdit
