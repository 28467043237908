import { useTalentCardContext } from './Context'

const useBackgroundColor = (): 'secondary' | 'scout' | 'roleModel' => {
    const { talentType } = useTalentCardContext()
    switch (talentType) {
        case 'scout': return 'scout'
        case 'talent': return 'secondary'
        default: return 'roleModel'
    }
}

export default useBackgroundColor
