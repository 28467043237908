import { Browser } from '@capacitor/browser'
import { Preferences } from '@capacitor/preferences'
import { IonText, IonFab } from '@ionic/react'
import { arrowBack, arrowForward, checkmark } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormBirthdateField from '../../components/Form/FormBirthdayField'
import FormSelectButtons from '../../components/Form/FormSelectButtons'
import FormToggle from '../../components/Form/FormToggle'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import {
    useCreateUserMetaDataMutation, UserMetaDataDocument,
    useUpdateUserMetaDataMutation, useUserLinkImageMutation,
    useUserMetaDataQuery, useUserStatusQuery,
} from '../../lib/apollo/types'
import type { UserMetaDataInput } from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'
import { useSignInWithToken, useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import './style.scss'

const slideOpts = {
    spaceBetween: 16,
    allowTouchMove: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
}

const Onboarding: React.FC = () => {
    const { t } = useTranslation()
    const [formData, setFormData] = useState({})
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [show] = useToast()
    const user = useUser()

    // join system networks if needed by asking for status:
    useUserStatusQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
    })

    const [linkProfileMutation] = useUserLinkImageMutation()
    const [signinWithToken] = useSignInWithToken()

    const [createUserMetaData] = useCreateUserMetaDataMutation({
        onError: () => {
            show(t('onboarding.savingError'), 'danger')
        },
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user?.user.id ?? '',
            },
        }],
    })
    const [updateUserMetaData] = useUpdateUserMetaDataMutation({
        onError: () => {
            show(t('onboarding.savingError'), 'danger')
        },
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user?.user.id ?? '',
            },
        }],
    })

    const { data } = useUserMetaDataQuery({
        variables: {
            userId: user?.user.id ?? '',
        },
    })

    const [onboardingComplete, setOnboardingComplete] = useState(false)

    useEffect(() => {
        if (!onboardingComplete) {
            return
        }

        const patchData = async () => {
            const { image, ...metaData } = formData as UserMetaDataInput & { image: string }
            const oldBehavior = data?.userMetaData?.user?.data?.behavior

            const variables = {
                input: {
                    ...metaData,
                    behavior: {
                        ...oldBehavior,
                        ...metaData.behavior,
                        __typename: undefined,
                    },
                    username: user?.user.name ?? '',
                    __typename: undefined,
                },
                userId: user?.user.id ?? '',
            }

            if (!data?.userMetaData?.user?.data) {
                await createUserMetaData({ variables })
            } else {
                await updateUserMetaData({ variables })
            }
            // keep this order to prevent loss of image link
            if(image) {
                await linkProfileMutation({
                    variables: {
                        url: `/asset/${image}`,
                        userId: user?.user.id ?? '',
                    },
                })
            }
            const { value: token } = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
            if(token)
                await signinWithToken({ variables: { input: { token } } })
        }

        patchData()
    }, [onboardingComplete, data, formData, user?.user.name, user?.user.id, signinWithToken, createUserMetaData, updateUserMetaData, linkProfileMutation])

    if (!data) {
        return null
    }

    return (
        <Page
            trackingTitle='onboarding'
            className='onboarding'
        >
            <Header
                buttonLeft='menu'
                title={t('onboarding.title')}
            />
            <Content>
                <Swiper
                    {...slideOpts}
                    modules={[Controller]}
                    pagination
                    onSwiper={setControlledSwiper}
                    className='onboarding__slides'
                >
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={() => {
                                controlledSwiper?.slideNext()
                                setFormData(state => ({
                                    ...state,
                                    behavior: {
                                        termsAcceptedAt: new Date().toISOString(),
                                        termsVersionAccepted: 1,
                                    },
                                }))
                            }}
                        >
                            <div
                                className='ion-margin-bottom'
                            >
                                {t('onboarding.welcome')}
                            </div>
                            <Trans
                                i18nKey='onboarding.termsAndConditionsText'
                            >
                                Zur Verwendung von Join My Talent musst du die
                                <IonText
                                    color='secondary'
                                    onClick={() => Browser.open({
                                        url: 'https://joinmytalent.com/nutzungbedingungen/',
                                    })}
                                >
                                    Nutzungsbedingungen
                                </IonText>
                                akzeptieren. Bitte lies diese sorgfältig durch.
                            </Trans>
                            <FormToggle
                                required
                                name='termsAccepted'
                                label={t('onboarding.acceptTermsAndConditions')}
                            />
                            <div
                                className='ion-padding-top ion-margin-top'
                            >
                                {t('onboarding.atLeast16Text')}
                            </div>
                            <FormToggle
                                required
                                name='atLeast16'
                                label={t('onboarding.atLeast16')}
                            />
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowForward}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={(v) => {
                                controlledSwiper?.slideNext()
                                setFormData((state: any) => ({
                                    ...state,
                                    ...v,
                                }))
                            }}
                        >
                            <div
                                className='ion-margin-bottom'
                            >
                                {t('onboarding.weNeedSomeData')}
                            </div>
                            <ItemList>
                                <FormBirthdateField
                                    required
                                    name='birthdate'
                                    label={t('user.birthdate')}
                                    defaultValue={data.userMetaData?.user?.data?.birthdate}
                                />
                            </ItemList>
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowBack}
                                    color='primary'
                                    fill='outline'
                                    onClick={() => controlledSwiper?.slidePrev()}
                                />
                                <Button
                                    round
                                    icon={arrowForward}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={(v) => {
                                setFormData((state: any) => ({
                                    ...state,
                                    ...v,
                                }))
                                setOnboardingComplete(true)
                            }}
                        >
                            <h2>{t('onboarding.role.headline')}</h2>
                            <div>
                                <IonText
                                    className='ion-margin-bottom'
                                    color='medium'
                                >
                                    {t('onboarding.role.description')}
                                </IonText>
                            </div>
                            <FormSelectButtons
                                name='userType'
                                label={t('user.userType.label')}
                                multiple
                                defaultValue={data.userMetaData?.user?.data?.userType ? data.userMetaData.user?.data.userType as string[] : ['other']}
                                options={[
                                    {
                                        text: t('user.userType.player'),
                                        value: 'player',
                                    },
                                    {
                                        text: t('user.userType.trainer'),
                                        value: 'trainer',
                                    },
                                    {
                                        text: t('user.userType.supervisor'),
                                        value: 'supervisor',
                                    },
                                    {
                                        text: t('user.userType.scout'),
                                        value: 'scout',
                                    },
                                    {
                                        text: t('user.userType.relatives'),
                                        value: 'relatives',
                                    },
                                    {
                                        text: t('user.userType.other'),
                                        value: 'other',
                                    },
                                ]}
                            />
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowBack}
                                    color='primary'
                                    fill='outline'
                                    onClick={() => controlledSwiper?.slidePrev()}
                                />
                                <Button
                                    round
                                    icon={checkmark}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                </Swiper>
            </Content>
        </Page>
    )
}

export default Onboarding
