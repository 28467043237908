import type { Maybe } from './apollo/types'

const getAge = (dateString?: Maybe<string>, keyDate: Date = new Date()): number | undefined => {
    if (!dateString) {
        return undefined
    }

    const birthDate = new Date(dateString)
    const age = keyDate.getFullYear() - birthDate.getFullYear()
    const m = keyDate.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && keyDate.getDate() < birthDate.getDate())) {
        return age - 1
    }
    return age
}

export default getAge
