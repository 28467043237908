import { IonFab, IonFabButton, IonIcon, useIonRouter } from '@ionic/react'
import { arrowBack, arrowForward, checkmark, help } from 'ionicons/icons'
import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'

import useOnboarding from '../../../providers/Onboarding/hooks'
import Button from '../../Button'
import type { HelpSliderModalProps } from '../../HelpSlider/Help/TalentId'
import Modal from '../../Modal'

import './style.scss'

type OnboardingModalProps = {
    id: string
    sort?: number
} & HelpSliderModalProps & PropsWithChildren

const slideOptions: SwiperProps = {
    autoHeight: false,
    height: 100,
    spaceBetween: 16,
    allowTouchMove: false,
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ children, id, sort = 0, open: forceOpen, setOpen: setForceOpen }) => {
    const { t } = useTranslation()
    const { mount, unmount, current, finish } = useOnboarding()
    const { routeInfo: { pathname } } = useIonRouter()

    const [open, setOpen] = useState<boolean>(forceOpen ?? false)

    const modalRef = useRef<HTMLIonModalElement>(null)

    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const lastSlideIdx = useMemo(() => {
        if(Array.isArray(children)) {
            return children.filter(Boolean).length - 1
        }
        return 0

    }, [children])

    const onNextClick = useCallback(() => {
        if(activeIndex < lastSlideIdx) {
            controlledSwiper?.slideTo(activeIndex + 1)
        } else {
            finish(id)
            setOpen(false)
        }
    }, [activeIndex, lastSlideIdx, controlledSwiper, finish, id])

    useEffect(() => {
        mount({
            type: 'modal',
            id,
            sort,
        })

        return () => {
            unmount(id)
        }
    }, [id, mount, sort, unmount])

    useEffect(() => {
        modalRef?.current?.dismiss()
    }, [pathname])

    useEffect(() => {
        if(typeof forceOpen !== 'undefined') {
            setOpen(forceOpen)
        }
    }, [forceOpen])

    return (
        <>
            <Modal
                size='onboarding'
                onClose={() => {
                    setOpen(false)
                }}
                ref={modalRef}
                modalTitle={t('helpModal.title')}
                isOpen={open}
                className='onboarding-modal'
                onDidDismiss={(() => {
                    setForceOpen?.(false)
                    setActiveIndex(0)
                })}
                footer={(
                    <div
                        className='onboarding-modal__footer'
                    >
                        <div
                            className='onboarding-modal__footer__buttons'
                        >
                            {activeIndex > 0 && (
                                <Button
                                    round
                                    icon={arrowBack}
                                    onClick={() => controlledSwiper?.slideTo(activeIndex - 1)}
                                />
                            )}
                            <Button
                                round
                                color='secondary'
                                icon={activeIndex === lastSlideIdx ? checkmark : arrowForward}
                                onClick={onNextClick}
                            />
                        </div>
                    </div>
                )}
            >
                <Swiper
                    {...slideOptions}
                    modules={[Controller]}
                    onSwiper={setControlledSwiper}
                    onSlideChange={(swiper: SwiperClass) => setActiveIndex(swiper.activeIndex)}
                >
                    <div
                        className='onboarding-modal__content'
                    >
                        {children}
                    </div>
                </Swiper>
            </Modal>
            {current?.id === id && typeof forceOpen === 'undefined' && (
                <IonFab
                    className='onboarding-fab'
                    slot='fixed'
                    horizontal='end'
                >
                    <IonFabButton
                        className='onboarding-fab__button'
                        color='secondary'
                        onClick={() => setOpen(true)}
                    >
                        <IonIcon
                            icon={help}
                        />
                    </IonFabButton>
                </IonFab>
            )}
        </>
    )
}

export default OnboardingModal
