import type { ItemReorderEventDetail } from '@ionic/core'
import {
    IonCard,
    IonCardContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonReorder,
    IonReorderGroup,
} from '@ionic/react'
import { add, trash } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

export type SelectWithOrderState = {
    label: string
    value: any
    icon: string
    payload?: any
    multiple?: boolean
    className?: string
    onClick?: any
}[]

interface SelectWithOrderProps {
    options: SelectWithOrderState
    selected: SelectWithOrderState,
    setSelected:  React.Dispatch<React.SetStateAction<SelectWithOrderState>>
    className?: string
    max?: number
}

const SelectWithOrder: React.FC<SelectWithOrderProps> = ({ options, className, max = options.length, selected, setSelected }) => {
    const { t } = useTranslation()

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })

    const [innerSelected, setInnerSelected] = useState(selected)

    const doReorder = useCallback((event: CustomEvent<ItemReorderEventDetail>) => {
        setSelected(prevState => {
            const from = prevState[event.detail.from]
            const copy = [...prevState]
            // delete moved element from copy
            copy.splice(event.detail.from, 1)
            // build new state
            copy.splice(event.detail.to, 0, from)
            return copy
        })
        // Finish the reorder
        event.detail.complete()
    }, [setSelected])

    const onOptionAddClick = useCallback((option: any) => {
        setShowPopover({
            showPopover: false,
            event: undefined,
        })
        if (typeof option.onClick === 'function') {
            option.onClick()
        }
        setSelected(prevState => [...prevState, option])
    }, [setSelected, setShowPopover])

    const onRemoveSelectionClick = useCallback((selection: any) => {
        setSelected(prevState => {
            const copy = [...prevState]
            const index = prevState.indexOf(selection)
            copy.splice(index, 1)
            return copy
        })
    }, [setSelected])

    useEffect(() => {
        setInnerSelected(selected)
    }, [selected])

    return (
        <>
            <IonReorderGroup
                onIonItemReorder={doReorder}
                disabled={false}
                className={className}
            >
                {innerSelected.map((selection, i) => (
                    <IonCard
                        // eslint-disable-next-line react/no-array-index-key
                        key={selection.label + i}
                        className='select-with-order__sortable-card'
                    >
                        <IonCardContent>
                            <IonItem
                                lines='none'
                            >
                                <IonReorder
                                    slot='start'
                                />
                                <div
                                    className='select-with-order__label-wrapper'
                                >
                                    <IonIcon
                                        icon={selection.icon}
                                        className={selection.className}
                                    />
                                    <IonLabel>
                                        {selection.label}
                                    </IonLabel>
                                </div>
                                <IonIcon
                                    slot='end'
                                    icon={trash}
                                    onClick={() => onRemoveSelectionClick(selection)}
                                />
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                ))}
            </IonReorderGroup>
            <div>
                {new Array(max - selected.length).fill(1).map((_, i) => (
                    <IonCard
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className='select-with-order__sortable-card'
                    >
                        <IonCardContent>
                            <IonItem
                                lines='none'
                                className='ion-text-center select-with-order__add-btn'
                                onClick={(e: any) => setShowPopover({
                                    showPopover: true,
                                    event: e,
                                })}
                            >
                                <IonLabel>
                                    <IonIcon
                                        icon={add}
                                    />
                                    {t('buttons.add')}
                                </IonLabel>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                ))}
            </div>
            <IonPopover
                arrow={false}
                className='select-with-order__add-value ion-popover--centered'
                isOpen={popoverState.showPopover}
                event={popoverState.event}
                onWillDismiss={() => setShowPopover({
                    showPopover: false,
                    event: undefined,
                })}
            >
                <IonList
                    className='search-result__context-menu'
                >
                    {options.filter(o => o.multiple || !selected.some(s => s.value === o.value)).map(option => (
                        <IonItem
                            lines='none'
                            key={option.value}
                            onClick={() => onOptionAddClick(option)}
                        >
                            {!!option.icon && (
                                <IonIcon
                                    slot='start'
                                    icon={option.icon}
                                    className={option.className}
                                />
                            )}
                            <IonLabel>
                                {option.label}
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonPopover>
        </>
    )
}

export default SelectWithOrder
