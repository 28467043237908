import './style.scss'
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import type { CSSProperties } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { QRCode } from 'react-qrcode-logo'

import siegel from '../../../assets/img/certified_pos.png'
import type { Certificate } from '../../../lib/apollo/types'

import CertificateTalentCard from './CertificateTalentCardMedium'
import HSVTalentcard from './HsvRaute/HSVTalentcard'
import PlayerSignature from './PlayerSignature'
import getSignature from './getSignature'

const getKey = (vote: number) => {
    if(vote < 24) return '5'
    if(vote < 44) return '4'
    if(vote < 51) return '3'
    if(vote < 64) return '3+'
    if(vote < 84) return '2'
    return '1'
}

interface CertificateProps {
    certificate: Certificate;
    clubImg: string;
    schoolImg?: string;

    header: {
        intro: string;
        title: string;
    }

    text: string;

    footer?: {
        img?: string;
        text?: string;
    };
}

export type HsvPlayerSignature = {
    caption: string
    signature: string
    styles?: CSSProperties
}

const getPlayerSignature: (certificate: Certificate) => HsvPlayerSignature = (certificate) => {

    const player = JSON.parse(certificate.player ?? '{}')

    switch (`${player.lastname}-${player.number}`) {

        case 'Königsdörffer-11': return {
            caption: 'Ransford Königsdörffer',
            signature: 'Koenigsdoerfer_white.svg',
            styles: { left: '32px', bottom: '4px' },
        }

        case 'Dompé-27': return {
            caption: 'Jean-Luc Dompé',
            signature: 'Dompe_white.svg',
            styles: { left: '8px' },
        }

        case 'Glatzel-9': return {
            caption: 'Robert Glatzel',
            signature: 'Glatzel_white.svg',
            styles: { left: '16px' },
        }

        case 'Heuer Fernandes-1': return {
            caption: 'Daniel Heuer Fernandes',
            signature: 'Heuer_Fernandes_white.svg',
        }

        case 'Jatta-18': return {
            caption: 'Bakery Jatta',
            signature: 'Jatta_white.svg',
        }

        case 'Muheim-28': return {
            caption: 'Miro Muheim',
            signature: 'Muheim_white.svg',
        }

        case 'Reis-14': return {
            caption: 'Ludovit Reis',
            signature: 'Reis_white.svg',
            styles: { left: '8px' },
        }

        default: return {
            caption: 'Sebastian Schonlau',
            signature: 'Schonlau_white.svg',
            styles: {},
        }
    }
}

const HsvCertificate: React.FC<CertificateProps> = (props: CertificateProps) => {
    const { t } = useTranslation()

    return (
        <>
            <div
                className='hsv-certificate-wrapper'
            >
                <IonGrid
                    className='certificate'
                >
                    {/* MAIN */}
                    <IonRow
                        className='certificate__main__content__header__title__headline--mobile'
                    >
                        <IonCol>
                            <IonText>
                                <h3>
                                    {props.header.intro}
                                </h3>
                            </IonText>
                        </IonCol>
                        <IonCol>
                            <IonText>
                                <h1
                                    className='hsv-font-slab'
                                >
                                    <HSVTalentcard />
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow
                        className='certificate__main'
                    >
                        <IonCol
                            className='certificate__main__talent-card-wrapper'
                        >
                            <CertificateTalentCard
                                certificate={props.certificate}
                                clubImg={props.clubImg}
                                schoolImg={props.schoolImg}
                            />
                        </IonCol>

                        <IonCol
                            className='certificate__main__content'
                        >
                            <IonGrid>
                                <IonRow
                                    className='certificate__main__content__header hide-small'
                                >
                                    <IonCol
                                        className='certificate__main__content__header__title'
                                    >
                                        <IonRow
                                            className='certificate__main__content__header__title__headline'
                                        >
                                            <IonCol>
                                                <IonText>
                                                    <h3>
                                                        {props.header.intro}
                                                    </h3>
                                                </IonText>
                                            </IonCol>
                                            <IonCol>
                                                <HSVTalentcard />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow
                                            className='certificate__main__content__header__title__name'
                                        >
                                            <IonText>
                                                <h1
                                                    className='name'
                                                >
                                                    {props.certificate.caption}
                                                </h1>
                                            </IonText>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol
                                        className='certificate__main__content__header__img-wrapper display-flex display-flex-column ion-text-justify hide-small'
                                    >
                                        { props.schoolImg && (
                                            <img
                                                src={props.schoolImg}
                                                alt='camp'
                                            />
                                        )}
                                        <QRCode
                                            value='https://hsv.joinmytalent.com'
                                        />
                                    </IonCol>
                                </IonRow>

                                <IonRow
                                    className='certificate__main__content__signature'
                                >
                                    <IonCol>
                                        <Trans
                                            i18nKey='certificate.thankYou'
                                            values={{ name: getSignature(props.certificate) }}
                                        >
                      Introtext und so
                                            <span
                                                className='certificate__main__content__signature__name'
                                            >
                        CampName
                                            </span>
                                        </Trans>
                                    </IonCol>
                                </IonRow>
                                <IonRow
                                    className='certificate__main__signature'
                                >
                                    <IonCol
                                        size='auto'
                                        className='ion-margin-end ion-padding-end'
                                    >
                                        <img
                                            alt='siegel'
                                            src={siegel}
                                            className='certificate__main__signature__siegel'
                                            style={{ height: '90px', minWidth: '90px' }}
                                        />
                                    </IonCol>
                                    <IonCol>
                                        <PlayerSignature
                                            player={getPlayerSignature(props.certificate)}
                                        />
                                    </IonCol>
                                </IonRow>
                                {/* <IonRow */}
                                {/*    className='certificate__main__content__text' */}
                                {/* > */}
                                {/*  <IonCol> */}
                                {/*    {props.text} <a target='_blank' href='http://hsv.joinmytalent.com'>https://hsv.joinmytalent.com</a> */}
                                {/*  </IonCol> */}
                                {/* </IonRow> */}
                            </IonGrid>
                        </IonCol>
                    </IonRow>

                    {/* FOOTER */}
                    { props.footer && (
                        <IonRow
                            className='certificate__footer ion-align-items-center hide-small'
                        >
                            {props.footer.img && (
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-align-items-center'
                                >
                                    <img
                                        className='certificate__footer__img'
                                        src={props.footer.img}
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            )}
                            {props.footer.text && (
                                <IonCol>
                                    <IonText>
                                        {props.footer.text}
                                    </IonText>
                                </IonCol>
                            )}
                            <IonCol
                                size='auto'
                                className='ion-padding-end ion-padding-start'
                            >
                                <img
                                    className='certificate__footer__logo'
                                    src='/assets/icon/logo.svg'
                                    alt='certificate footer logo'
                                />
                            </IonCol>
                        </IonRow>
                    )}

                    {/* SECOND PAGE */}
                </IonGrid>
            </div>
            <div
                className='hsv-certificate-wrapper'
            >
                <IonGrid
                    className='certificate'
                >
                    <IonRow
                        className='certificate__page-2'
                    >
                        {/* <IonCol */}
                        {/*  className='certificate__id hide-small' */}
                        {/* > */}
                        {/*  <CertificateTalentCard */}
                        {/*      title={props.talentCardTitle} */}
                        {/*      certificate={props.certificate} */}
                        {/*      clubImg={props.clubImg} */}
                        {/*      schoolImg={props.schoolImg} */}
                        {/*  /> */}
                        {/* </IonCol> */}
                        <IonCol
                            className='certificate__texts'
                        >
                            <h1>{t('certificate.page2.title')}</h1>
                            <IonRow>
                                <IonCol>
                                    {[0,1,2,3].map((i) => (
                                        <div
                                            className='certificate__texts__block ion-padding-end'
                                            key={i}
                                        >
                                            <h5>
                                                {t(`i18n.vote.${i}`)}
                                                {' '}
&nbsp;&nbsp;
                                                {' '}
                                                {props.certificate?.vote[i]}
                                            </h5>
                                            <div
                                                className='certificate__texts__block__description'
                                            >
                                                <IonText>
                                                    {t(`certificate.${i}.${getKey(props.certificate?.vote[i])}`)}
                                                </IonText>
                                            </div>
                                        </div>
                                    ))}
                                </IonCol>
                                <IonCol>
                                    {[4,5,6,7].map((i) => (
                                        <div
                                            className='certificate__texts__block'
                                            key={i}
                                        >
                                            <h5>
                                                {t(`i18n.vote.${i}`)}
                                                {' '}
&nbsp;&nbsp;
                                                {' '}
                                                {props.certificate?.vote[i]}
                                            </h5>
                                            <div
                                                className='certificate__texts__block__description'
                                            >
                                                <IonText>
                                                    {t(`certificate.${i}.${getKey(props.certificate?.vote[i])}`)}
                                                </IonText>
                                            </div>
                                        </div>
                                    ))}
                                </IonCol>
                            </IonRow>
                            {/* {props.certificate?.vote.map((vote, i) => ( */}
                            {/*    <div className='certificate__texts__block'> */}
                            {/*      <h5>{t(`i18n.vote.${i}`)} - ({vote}%)</h5> */}
                            {/*      <IonText className='certificate__texts__block__description'> */}
                            {/*        {t(`certificate.${i}.${getKey(vote)}`)} */}
                            {/*      </IonText> */}
                            {/*    </div> */}
                            {/* ))} */}
                        </IonCol>
                    </IonRow>
                    {/* FOOTER */}
                    { props.footer && (
                        <>
                            <IonRow
                                className='certificate__footer ion-align-items-center hide-small'
                            >
                                {props.footer.img && (
                                    <IonCol
                                        size='auto'
                                        className='ion-padding-end ion-align-items-center'
                                    >
                                        <img
                                            className='certificate__footer__img'
                                            src={props.footer.img}
                                            alt='certificate footer logo'
                                        />
                                    </IonCol>
                                )}
                                {props.footer.text && (
                                    <IonCol>
                                        <IonText>
                                            {props.footer.text}
                                        </IonText>
                                    </IonCol>
                                )}
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-padding-start'
                                >
                                    <img
                                        className='certificate__footer__logo'
                                        src='/assets/icon/logo.svg'
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow
                                className='certificate__footer ion-align-items-center hide-big'
                            >
                                <IonRow>
                                    {props.footer.text && (
                                        <IonCol>
                                            <IonText>
                                                {props.footer.text}
                                            </IonText>
                                        </IonCol>
                                    )}
                                </IonRow>
                                {props.footer.img && (
                                    <IonCol
                                        className='ion-padding-end ion-align-items-center'
                                    >
                                        <img
                                            className='certificate__footer__img'
                                            src={props.footer.img}
                                            alt='certificate footer logo'
                                        />
                                    </IonCol>
                                )}
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-padding-start'
                                >
                                    <img
                                        className='certificate__footer__logo'
                                        src='/assets/icon/logo.svg'
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </IonGrid>
            </div>
        </>
    )
}

export default HsvCertificate
