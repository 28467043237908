import {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonProgressBar,
    IonBackButton,
    IonSkeletonText,
} from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import { ENV_NAME } from '../../lib/apollo/config'
import useTabContext from '../../lib/hooks/useTabContext'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import IS_IOS from '../../lib/isIOS'
import { useLoadingIndicator } from '../../providers/GlobalLoading/hooks'
import DesktopMenu from '../DesktopMenu'
import Hide from '../Hide'

import './style.scss'

interface HeaderProps {
    title: string | JSX.Element
    buttonLeft?: 'back' | 'menu' | 'none'
    isModal?: boolean
}

const Header: React.FC<HeaderProps & Omit<React.ComponentProps<typeof IonHeader>, 'title'>> = ({ isModal, title, buttonLeft= 'back', mode, children, ...headerProps }) => {
    const { visible } = useLoadingIndicator()
    const { t } = useTranslation()
    const { path } = useRouteMatch()
    const { hideMenuButton } = useWindowResizeListener()

    const splitPath = path.split('/')
    const tab = useTabContext()
    const defaultHref = splitPath.length > 2 ? `/${tab}` : '/'

    return (
        <IonHeader
            {...headerProps}
            className={clsx(headerProps.className, 'header')}
        >
            {ENV_NAME === 'sandbox' && (
                <div
                    className='ribbon'
                >
                Demo
                </div>
            )}
            <IonToolbar
                className={`header__toolbar header__toolbar--${ENV_NAME}`}
            >
                <IonButtons
                    className={clsx('header__btn', { 'header__btn--hide-desktop': buttonLeft === 'menu' && hideMenuButton })}
                    slot='start'
                >
                    { buttonLeft === 'back' && (
                        <IonBackButton
                            text={IS_IOS ? t('buttons.back') : undefined}
                            defaultHref={defaultHref}
                        />
                    )}

                    { buttonLeft === 'menu' && <IonMenuButton /> }
                </IonButtons>

                <Hide
                    fallback={
                        isModal
                            ? (
                                <IonTitle
                                    className='ion-text-center'
                                >
                                    { title ?? (
                                        <IonSkeletonText
                                            className='header__skeleton'
                                        />
                                    ) }
                                </IonTitle>
                            ) : (
                                <DesktopMenu />
                            )
                    }
                    on='desktop'
                >
                    <IonTitle
                        className='ion-text-center'
                    >
                        { title ?? (
                            <IonSkeletonText
                                className='header__skeleton'
                            />
                        ) }
                    </IonTitle>
                </Hide>

                <IonButtons
                    className='header__btn'
                    collapse
                    slot='end'
                >
                    { children }
                </IonButtons>
                {visible && (
                    <IonProgressBar
                        type='indeterminate'
                        color='secondary'
                    />
                )}
            </IonToolbar>
        </IonHeader>
    )
}

export default Header
