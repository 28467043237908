import { IonButton, IonItem, IonLabel, IonText } from '@ionic/react'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CollapsibleContent from '../../Collapsible/CollapsibleContent'

import './style.scss'

interface FormSelectButtonProps {
    name: string,
    label: string,
    options: { text: string, value: string | number }[]
    multiple?: boolean
    required?: boolean
    defaultValue?: (string | number)[]
    className?: string
}

const FormSelectButtons: React.FC<FormSelectButtonProps> = ({
    name,
    label,
    options,
    multiple,
    required,
    defaultValue,
    className,
}) => {
    const { control, formState, setValue } = useFormContext()
    const error = formState.errors[name]
    const { t } = useTranslation()

    const [internalVal, setInternalVal] = useState<(string | number)[]>(defaultValue ?? [])

    const handleOnButtonClick = useCallback((value: string | number) => {
        if (!multiple) {
            setInternalVal(s => s?.some((v: string | number) => v === value) ? [] : [value])
        } else {
            const index = internalVal?.indexOf(value)
            if (index > -1) {
                const newValues = [...internalVal]
                newValues.splice(index, 1)
                setInternalVal(newValues)
            } else {
                setInternalVal([...internalVal, value])
            }
        }
    }, [multiple, internalVal])

    useEffect(() => {
        setValue(name, internalVal)
    }, [internalVal, name, setValue])

    const rules = required ? { required: { value: true, message: t('errors.required', { label }) } } : undefined

    return (
        <div>
            <div
                className={clsx('form-select-buttons', className)}
            >
                <IonItem
                    lines='none'
                    className='form-select-buttons__item'
                >
                    <IonLabel
                        position='floating'
                        className={clsx('form-select-buttons__label', { 'error': !!error })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>
                    <Controller
                        control={control}
                        name={name}
                        rules={rules}
                        render={({ field }) => (
                            <div
                                className='form-select-buttons__button-list'
                            >
                                {options.map(({ text, value }) => (
                                    <IonButton
                                        fill={internalVal?.some((v: string | number) => v === value) ? 'solid' : 'outline'}
                                        color='primary'
                                        key={value}
                                        onClick={() => {
                                            handleOnButtonClick(value)
                                            field.onBlur()
                                        }}
                                    >
                                        {text}
                                    </IonButton>
                                ))}
                            </div>
                        )}
                    />
                </IonItem>
            </div>
            <CollapsibleContent
                className='form-select-buttons__error-container'
                open={!!error}
            >
                <IonText
                    className='form-select-buttons__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message as string}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default FormSelectButtons
