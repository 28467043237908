import './style.scss'

interface DividerProps {
    margin?: number
}

const Divider: React.FC<DividerProps> = ({ margin }) => {

    return (
        <hr
            style={{ marginTop: margin, marginBottom: margin }}
            className='divider'
        />
    )
}

export default Divider
