import { type TFunction } from 'i18next'
import {Certificate} from '../../../lib/apollo/types'

const getRblSignature = (certificate: Certificate, t: TFunction): string => {
  const year = certificate.camp.end ? (new Date(certificate.camp.end)).getFullYear().toString() : undefined
  const month = certificate.camp.end ? ((new Date(certificate.camp.end)).getMonth() + 1).toString() : undefined

  return 'Leipzig, ' + (month ? ` ${t(`animation_label.m.${month}`)}` : '') + (year ? ` ${year}` : '')
}

export default getRblSignature
