import type { IonButton } from '@ionic/react'
import { IonList, IonModal } from '@ionic/react'
import { closeOutline, ellipsisVertical } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import ContextMenuItem from './ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from './context'

import './style.scss'

const ContextMenu: React.FC<{ icon?: string, label?: string } & React.ComponentProps<typeof IonButton>> = ({ label, icon = ellipsisVertical, children, ...buttonProps }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const closeContextMenu = useCallback((cb?: () => void) => {
        setOpen(false)
        if(typeof cb === 'function') {
            cb()
        }
    }, [setOpen])

    return (
        <>
            <Button
                {...buttonProps}
                icon={icon}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (!buttonProps.routerLink) {
                        setOpen(true)
                    }
                }}
            >
                {label}
            </Button>
            <IonModal
                className='context-menu-modal'
                isOpen={open}
                showBackdrop
                onDidDismiss={() => { closeContextMenu() }}
            >
                <IonList>
                    <ContextMenuContext.Provider
                        value={{ closeContextMenu }}
                    >
                        { children }
                    </ContextMenuContext.Provider>
                </IonList>
                <ContextMenuItem
                    label={t('buttons.close')}
                    icon={closeOutline}
                    onClick={closeContextMenu}
                    lines='none'
                    underlined
                />
            </IonModal>
        </>
    )
}

export default ContextMenu
