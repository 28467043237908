import { useIonRouter } from '@ionic/react'
import { shirtOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkCreateTalentContextMenuItemProps {
    network: Network
}

const NetworkCreateTalentContextMenuItem: React.FC<NetworkCreateTalentContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const { push } = useIonRouter()
    return (
        <ContextMenuItem
            icon={shirtOutline}
            label={t('networks.createTalent')}
            onClick={() => { push(`/home/network/${network.id}/edit/talents/new` ) }}
        />
    )
}

export default NetworkCreateTalentContextMenuItem
