import './style.scss'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'

interface TopSkillsProps {
    talent?: Talent
    amount?: number
    values?: number[] // overrides publicrating
    compareValues?: number[]
    oldTitle?: string
    newTitle?: string
    className?: string
    asCard?: boolean
    withHeadline?: boolean
}

// interface SkillsI {
//     value: number,
//     translation: string
//     rotation: number
// }

const TopSkills: React.FC<TopSkillsProps> = ({
    // withHeadline,
    // asCard,
    className,
    amount = 4,
    values: v,
    // compareValues,
    // oldTitle,
    // newTitle,
}) => {
    const { t } = useTranslation()

    // map array of numbers to sorted index
    const values = useMemo(() => v
        ?.map((value, index) => ({ value, index }))
        .sort((a, b) => b.value - a.value)
        .slice(0, amount)
    , [v, amount])

    if (!values) return null

    return (
        <>
            <div
                className={clsx(className, 'talent-top-skills')}
            >
                <div
                    className='talent-top-skills__row'
                >
                    {values.map(({ index }) => {
                        return (
                            <div
                                key={index}
                                className={clsx('talent-top-skills__skill-wrapper')}
                            >
                                <img
                                    className='talent-top-skills__img'
                                    alt='skill-indicator'
                                    src='/assets/icon/logo.svg'
                                />

                                <h4
                                    className='talent-top-skills__skill ion-text-left'
                                >
                                    {t(`i18n.vote.${index}`)}
                                </h4>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default TopSkills
