import './style.scss'
import {
    type Certificate,
    HistoryItem,
    PermissionLevel, SharedTalentId,
    Talent,
    useCertificatesQuery,
    useTalentHistoryQuery
} from '../../../lib/apollo/types'
import {useTranslation} from 'react-i18next'
import React, {useLayoutEffect, useMemo, useState} from 'react'
import Modal from '../../Modal'
import CertificateComponent from '../../Certificate'
import TimelineItem from './TimelineItem'
import Divider from '../../Divider'
import Buttons from '../../Buttons'
import Button from '../../Button'
import {addCircleOutline, pencil} from 'ionicons/icons'
import TalentHistoryItemEditForm from './TalentHistoryItemEditForm'
import clsx from 'clsx'
import ContextMenu from '../../ContextMenu'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'
import {MatomoEventCategory} from '../../../lib/tracking/eventCategories'
import {useMatomo} from '@jonkoops/matomo-tracker-react'
import CertificateItem from './CertificateItem'

type Entry = {
    date: Date,
    entry: Certificate | HistoryItem
}

type TalentTimelineProps = {
    shared?: boolean
    talent?: Talent
    history?: HistoryItem[]
    talentIds?: SharedTalentId[]
    certificates?: Certificate[]
}

const TalentTimeline: React.FC<TalentTimelineProps> = ({talent, shared = false, history: cachedHistory, talentIds, certificates}) => {
    const {t} = useTranslation()
    const { trackEvent } = useMatomo()

    const [cert, setCert] = useState<Certificate>()
    const [addHistoryOpen, setAddHistoryOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editItem, setEditItem] = useState<Entry>()

    const {data: loadedCertificates} = useCertificatesQuery({
        variables: {
            talentId: talent?.id ?? '',
        },
        skip: !talent?.id || shared,
    })

    useLayoutEffect(() => {
        if (cert) {
            document.body.classList.add('cert')
        } else {
            document.body.classList.remove('cert')
        }
    }, [cert])

    const certs = shared ? certificates : loadedCertificates?.certificates

    const { data: loadedHistory } = useTalentHistoryQuery({
        variables: {
            talentId: talent?.id ?? '',
        },
        skip: !talent?.id || shared,
    })

    const history = shared ? cachedHistory : loadedHistory?.talentHistory

    const entries = useMemo<Entry[]>(() => {
        const entries: Entry[] = []
        history?.forEach((entry) => {
            entries.push({
                date: new Date(entry.date),
                entry: entry
            })
        })
        certs?.forEach((entry) => {
            entries.push({
                date: new Date(entry.camp.end),
                entry: entry
            })
        })
        // add year dividers
        const yearDividers: Entry[] = []
        entries.forEach((entry) => {
            const year = entry.date.getFullYear()
            const divider = yearDividers.find((divider) => divider.date.getFullYear() === year)
            if (!divider) {
                yearDividers.push({
                    date: new Date(year, 11, 31, 23, 59),
                    entry: {
                        id: year.toString(),
                        date: new Date(year, 11, 31, 23, 59).toUTCString(),
                        type: 'DIVIDER',
                        data: year.toString()
                    }
                })
            }
        })
        entries.push(...yearDividers)

        return entries.sort((a, b) => b.date.getTime() - a.date.getTime())
    }, [certs, history])

    if (!talent || (!entries.length && talent.permissionLevel === PermissionLevel.None)) return null

    return (
        <>
            <Divider />
            <div className='display-flex ion-align-items-center ion-text-center talent-timeline__headline'>
                <h2>
                    {t('talent.timeline.title')}
                </h2>
                <div className='ion-margin-start'>
                    {talent.permissionLevel !== PermissionLevel.None && (
                        <ContextMenu
                            fill='clear'
                            color='dark'
                        >
                            <ContextMenuItem
                                label={t('talent.timeline.add.button')}
                                icon={addCircleOutline}
                                onClick={() => {
                                    trackEvent({
                                        action: 'start-create-talent-history',
                                        category: MatomoEventCategory.Talent,
                                    })
                                    setAddHistoryOpen(true)
                                }}
                            />
                            <ContextMenuItem
                                label={!editMode ? t('talent.timeline.edit.button') : t('talent.timeline.edit.done')}
                                icon={pencil}
                                onClick={() => {
                                    trackEvent({
                                        action: 'edit-mode-talent-history',
                                        category: MatomoEventCategory.Talent,
                                    })
                                    setEditMode(mode => !mode)
                                }}
                                disabled={(loadedHistory?.talentHistory.length ?? 0) === 0}
                            />
                        </ContextMenu>
                    )}
                </div>
            </div>
            <div className='talent-timeline'>
                {!!entries.length && (
                    <div className='talent-timeline__line' />
                )}
                {entries.map((entry) => {
                    if(entry.entry.__typename === 'Certificate') {
                        return (
                            <CertificateItem certificate={entry.entry} setCert={setCert} key={`cert-${entry.entry.id}`}/>
                        )
                    } else if((entry.entry as HistoryItem).type === 'DIVIDER') {
                        return (
                            <h2 key={entry.entry.id} className='talent-timeline__divider'>{(entry.entry as HistoryItem).data}</h2>
                        )
                    } else {
                        const dataViews = talentIds?.find((id) => id.key === entry.entry.id)?.dataViews

                        return (
                            <div
                                className='talent-timeline__entry-wrapper'
                                key={entry.entry.id}
                                onClick={() => {
                                    if (editMode) {
                                        trackEvent({
                                            action: 'start-edit-talent-history',
                                            category: MatomoEventCategory.Talent,
                                        })
                                        setEditItem(entry)
                                        setAddHistoryOpen(true)
                                    }
                                }}
                            >
                                <div
                                    className={clsx('talent-timeline__entry', {'talent-timeline__entry--edit': editMode})}
                                >
                                    <TimelineItem disableOnClick={editMode} talent={talent} date={entry.date} entry={entry.entry as HistoryItem} dataViews={dataViews}/>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            {talent.permissionLevel !== PermissionLevel.None && (
                <>
                    <Buttons
                        subtitle={t('talent.timeline.add.subtitle')}
                    >
                        <Button
                            icon={addCircleOutline}
                            onClick={() => {
                                trackEvent({
                                    action: 'start-create-talent-history',
                                    category: MatomoEventCategory.Talent,
                                })
                                setAddHistoryOpen(true)}
                            }
                            color='secondary'
                        >
                            {t('talent.timeline.add.button')}
                        </Button>
                    </Buttons>
                    <Modal
                        size='fullscreen'
                        modalTitle={t('talent.timeline.add.button')}
                        isOpen={addHistoryOpen}
                        onClose={() => {
                            setAddHistoryOpen(false)
                            setEditMode(false)
                            setEditItem(undefined)
                        }}
                        onDidDismiss={() => {
                            setAddHistoryOpen(false)
                            setEditMode(false)
                            setEditItem(undefined)
                        }}
                    >
                        <TalentHistoryItemEditForm item={editItem?.entry as HistoryItem | undefined} talent={talent} onSave={() => {
                            setAddHistoryOpen(false)
                            setEditMode(false)
                            setEditItem(undefined)
                        }}/>
                    </Modal>
                </>
            )}
            <Modal
                className='modal__certificate-modal'
                size='fullscreen'
                modalTitle={cert?.caption ?? ''}
                isOpen={!!cert}
                onClose={() => setCert(undefined)}
                onDidDismiss={() => setCert(undefined)}
            >
                {cert && (
                    <CertificateComponent
                        certificate={cert}
                    />
                )}
            </Modal>
        </>
    )
}

export default TalentTimeline